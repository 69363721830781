import React,{useState,useEffect} from 'react'
import Keep_reading_arrow_nowaday from '../assets/images/keep_reading_arrow_nowaday'
import KeepReading from '../assets/images/keep-reading' 
import Nowadaymap from '../assets/images/nowadaymap'
const HomeSection10 =({fullpageApi}) =>{    
    return(
            <div className={`section section10`} id="section10">
              <div className="left-col-box">
            <div className="text-table relief-section en-text">
                    <h2 className="hd-std">Nowadays</h2> 
                    <p>
                        <span className="text1">Today we offer</span>{' '}
                         <span className="border_underline black_br line_un_1"> various companies </span>{' '}
                         <span className="text2">in Belgium an</span> {' '}
                         <span className="border_underline black_br line_un_2"> extension </span> {' '}
                         <span className="text4">of their</span>{' '}
                          <span className="border_underline black_br line_un_3">current team</span>{' '}
                           <span className="text5">from within our office in</span> {' '}
                           <span className="border_underline black_br line_un_4"> Poland </span>.{' '} 
                           <span className="text6">Almost each company that started working with us is still working with us today. Why? Because our flexible approach just works like a perfectly oiled machine.</span>
                           </p>
                    <p className="green-pgh keep_reading_nowaday_arrow_wraper">
                        <span className="text7">Do you want to know how (simply) this actually works? <br/>Keep reading then.</span>
                      <span className="keep_reading_arrow_nowaday">
                      <Keep_reading_arrow_nowaday/>
                      </span>
                    </p> 
                  </div>

                  </div>
                  <div className="nowaday-map">
                        <Nowadaymap/>
                  </div>
                  <a to="#" className="get-start-btn-2 en-text"  onClick={() => fullpageApi.moveSectionDown()}>
                 {/* <KeepReading/> */}
                 <span>Keep reading</span>
                </a>
            </div>
            
    )
}
export default HomeSection10;