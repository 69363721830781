import React from 'react'
import './style.scss'
const MobileImgReliefSvg = ()=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="2519.057 1080.864 334.344 265.235">
        <defs>
          <clipPath id="relief-path">
            <path id="path" d="M0,22.6.067,0,19.3,11.1l-.067,22.6Z"></path>
          </clipPath>
          <clipPath id="relief-path-2">
            <path id="path-2" data-name="path" className="relief-1" d="M26.717,69.8l-.027-.054-.242-1.4c1.075.618,4.219-.967,5.052-1.236L31.393,63.4c-2.687.134-4.434,1.263-7.095.054a4.669,4.669,0,0,1-3.413,1.693,4.617,4.617,0,0,1-3.332-1.586c-.027.027-.081.054-.107.081-.081.054-.188.108-.269.161h0c-2.257,1.209-5.052,2.822-6.477,5.133l2.311,2.016c1.182-.779,1.8-1.263,2.822-1.908C14.6,73.155,14.624,77.509,14.6,80.707c0,.027.054,1.236.188,2.634H28.491l-.161-2.2A62.091,62.091,0,0,0,26.717,69.8Z" transform="translate(-10.7 -63.4)"></path>
          </clipPath>
          <clipPath id="relief-path-3">
            <path id="path-3" data-name="path" d="M0,12.091.026,0l12.6,7.27L12.6,19.361Z"></path>
          </clipPath>
          <clipPath id="relief-path-4">
            <path id="path-4" data-name="path" d="M0,27.48.078,0,23.993,13.8l-.078,27.48Z"></path>
          </clipPath>
          <clipPath id="relief-path-5">
            <path id="path-5" data-name="path" d="M0,6.693.023,0,46.4,26.772l-.023,6.693Z"></path>
          </clipPath>
        </defs>
        <g id="Group_354" data-name="Group 354" transform="translate(2497 729)">
          <g id="Group_259" data-name="Group 259" transform="translate(-2495.872 -1081.057)">
            <path id="path-7" data-name="path" className="relief-2" d="M176.174,406.977V323.7L120,356.165v83.277Z" transform="translate(2561.09 1220.848)"></path>
            <path id="path-8" data-name="path" className="relief-3" d="M1,335.3l49.152,27.674v83.277L1,418.578Z" transform="translate(2630.938 1214.039)"></path>
            <path id="path-9" data-name="path" className="relief-2" d="M106.325,284.374,57.173,256.7,1,289.165l49.152,27.674Z" transform="translate(2630.938 1260.174)"></path>
            <path id="path-10" data-name="path" className="relief-4" d="M189.4,422.3,154,442.415v8.261l35.4-20.28Z" transform="translate(2540.409 1161.163)"></path>
            <path id="path-11" data-name="path" className="relief-4" d="M189.4,466.4,154,486.515v8.261l35.4-20.28Z" transform="translate(2541.133 1137.088)"></path>
            <path id="path-12" data-name="path" className="relief-4" d="M12.1,508.6l35.4,20.115v8.3l-35.4-20.28Z" transform="translate(2624.423 1112.319)"></path>
            <path id="path-13" data-name="path" className="relief-5" d="M106.614,363,50.441,395.424,1,366.883" transform="translate(2630.938 1185.472)"></path>
            <g id="group" className="relief-6" transform="translate(2644.328 1574.444)">
              <g id="group-2" data-name="group" transform="translate(0.031 4.325)">
                <g id="group-3" data-name="group">
                  <g id="group-4" data-name="group">
                    <path id="path-14" data-name="path" className="relief-7" d="M15.605,76.131c-1.788-1.028-3.57-2.064-5.358-3.093v.007c1.788,1.028,3.57,2.064,5.358,3.085h0M4.688,69.737h0c.03.127.067.261.1.388-.037-.127-.067-.253-.1-.38-.007,0-.007,0-.007-.007M16.85,66.19c-.306.2-.641.373-.961.559-.6.343-1.207.693-1.811,1.036a12.323,12.323,0,0,0-2.116-2.66,9.238,9.238,0,0,0-1.669-1.244,6.023,6.023,0,0,0-1.215-.551,3.52,3.52,0,0,0-2.944.179A3.4,3.4,0,0,0,4.7,65.341h0a3.411,3.411,0,0,1,1.431-1.826,3.491,3.491,0,0,1,2.944-.179,6.571,6.571,0,0,1,1.222.559,9.6,9.6,0,0,1,1.669,1.244,11.971,11.971,0,0,1,2.116,2.66c.6-.343,1.207-.686,1.811-1.036.32-.194.648-.365.954-.574.007.007.007,0,0,0M6.685,58.157a5.007,5.007,0,0,0-5.2,2.452,7.376,7.376,0,0,0-.864,2.347,15.44,15.44,0,0,0,.827,7.8,23.483,23.483,0,0,0,2.66,5.313,21.5,21.5,0,0,0,3.57,4.136,15.647,15.647,0,0,0,2.578,1.87,12.544,12.544,0,0,0,2.452,1.1,5.519,5.519,0,0,0,4.032-.082,5.031,5.031,0,0,0,2.5-3.249,12.616,12.616,0,0,0,.41-4.412,12.954,12.954,0,0,1-.41,4.4,5.013,5.013,0,0,1-2.5,3.249v-.007a5.519,5.519,0,0,1-4.032.082,12.407,12.407,0,0,1-2.459-1.1,15.647,15.647,0,0,1-2.578-1.87,21.764,21.764,0,0,1-3.562-4.136,23.558,23.558,0,0,1-2.653-5.306h.007a15.361,15.361,0,0,1-.835-7.8A7.375,7.375,0,0,1,1.491,60.6a5.008,5.008,0,0,1,5.194-2.444" transform="translate(-0.419 -58.1)"></path>
                    <path id="path-15" data-name="path" className="relief-8" d="M23.616,59.4a10.37,10.37,0,0,0-3.234-1.252c-.134-.022-.261-.045-.388-.06A5.024,5.024,0,0,0,14.8,60.538c1.073,1.58,2.139,3.152,3.212,4.732a3.4,3.4,0,0,1,1.431-1.833,3.52,3.52,0,0,1,2.944-.179,6.978,6.978,0,0,1,1.215.551,9.33,9.33,0,0,1,1.669,1.244,12.145,12.145,0,0,1,2.116,2.66c.6-.343,1.207-.686,1.811-1.036.32-.186.648-.35.961-.559h0A21.98,21.98,0,0,0,27,62.036,16.735,16.735,0,0,0,23.616,59.4" transform="translate(-13.728 -58.037)"></path>
                    <path id="path-16" data-name="path" className="relief-9" d="M1.589,91.6a7.443,7.443,0,0,0-.864,2.347,15.263,15.263,0,0,0,.842,7.795c1.073-.335,2.146-.671,3.219-1.013h.007a7.681,7.681,0,0,1,.015-4.4h0C3.727,94.752,2.654,93.18,1.589,91.6" transform="translate(-0.517 -89.099)"></path>
                    <path id="path-17" data-name="path" className="relief-10" d="M141.563,204.643q-4.628-2.672-9.248-5.343c-.007,1.468-.007,2.944-.015,4.4h.007c1.789,1.028,3.57,2.064,5.358,3.093v.007a2.617,2.617,0,0,1-1.975,2.355q1.554,2.292,3.115,4.583h0v.007a5.031,5.031,0,0,0,2.5-3.249,12.769,12.769,0,0,0,.41-4.4c-.037-.469-.082-.961-.156-1.453" transform="translate(-122.472 -188.773)"></path>
                    <path id="path-18" data-name="path" className="relief-11" d="M17.527,214.2c-1.073.343-2.146.678-3.219,1.014H14.3a23.558,23.558,0,0,0,2.653,5.306,21.277,21.277,0,0,0,3.57,4.136,15.649,15.649,0,0,0,2.578,1.87,12.407,12.407,0,0,0,2.459,1.1,5.481,5.481,0,0,0,4.032-.082h0q-1.554-2.291-3.115-4.583a.007.007,0,0,0-.007-.007,4.046,4.046,0,0,1-2.169-.268,8.057,8.057,0,0,1-1.207-.574,10.38,10.38,0,0,1-1.14-.782,12.239,12.239,0,0,1-2.183-2.347,14.454,14.454,0,0,1-2.139-4.374c-.037-.142-.067-.268-.1-.4h0" transform="translate(-13.266 -202.563)"></path>
                  </g>
                </g>
              </g>
            </g>
            <g id="" transform="translate(2649.873 1473.69)">
              <g id="group-26" transform="translate(0 3.789)">
                <g id="group-24" transform="translate(0 2.49)">
                  <path id="path-19" data-name="path" className="relief-3" d="M-302.505,523.22c-.724-4.527-4.6-8.312-8.438-9.876-.3-.414.043-.851.3-1.093q.113-.089.223-.186h0a7.515,7.515,0,0,0,2.444-5.243,2.391,2.391,0,0,0,.538-.705c.425-.821.339-1.709-.188-1.981a.824.824,0,0,0-.638-.03c-.789-2.848-3.05-4.907-5.717-4.907s-4.955,2.081-5.73,4.95a.87.87,0,0,0-.756-.014c-.538.269-.614,1.16-.188,1.981a2.307,2.307,0,0,0,.681.807,7.446,7.446,0,0,0,2.643,5.308h0l.056.043c.229.183.942.851-.046,1.464l-.269.151h0c-3.7,2-7.335,5.59-7.437,10.306-.081,3.588.668,10.417,2.5,13.332h0l.067.11a15.147,15.147,0,0,1,.5,1.516h0a3.209,3.209,0,0,0,.659,1.192,2.524,2.524,0,0,0,3.486.6,2.526,2.526,0,0,0-.1-3.534,2.928,2.928,0,0,0-2.269-1.076c-1.284-1.666-1.841-7.268-1.79-9.59.059-2.667.331-5.922,3.066-7.633-1.241,4.121-1.2,8.462-1.241,11.663,0,.027.129,2.565.42,4.468a1.76,1.76,0,0,0,.213.1l.431-1.943h10.549a5.36,5.36,0,0,1-.458.4l1.12,2.352c.091-.043.729-2.054,1.615-2.751l-.151-2.215a63.692,63.692,0,0,0-1.884-11.3l-.013-.049-.519-1.41c2.581,1.515,3.23,5.227,3.642,7.728.342,2.134.538,6.936-.269,9.049a2.923,2.923,0,0,0-2.261,1.1,2.527,2.527,0,0,0-.051,3.537,2.524,2.524,0,0,0,3.475-.651,3.185,3.185,0,0,0,.668-1.305h0a2.776,2.776,0,0,0,.067-.428,8.186,8.186,0,0,1,.39-1.47v-.016C-301.751,532.983-301.94,526.752-302.505,523.22Z" transform="translate(325.032 -499.2)"></path>
                  <path id="path-20" data-name="path" className="relief-7" d="M-301.48,733.74" transform="translate(307.819 -670.61)"></path>
                  <path id="path-21" data-name="path" className="relief-3" d="M-304.064,558.832l-.013-.048-.522-1.413a6,6,0,0,1,2.334,2.767l2.821-1.4a14.974,14.974,0,0,0-6.772-6.261,4.658,4.658,0,0,1-3.432,1.685,4.6,4.6,0,0,1-3.332-1.577c-.035.024-.067.051-.108.075l-.269.151h0a15.725,15.725,0,0,0-5.922,5.346l2.439,2.39a5.722,5.722,0,0,1,2.169-2.5c-1.244,4.129-1.2,8.481-1.244,11.69,0,.019.062,1.244.2,2.627h13.727l-.151-2.21A68.226,68.226,0,0,0-304.064,558.832Z" transform="translate(320.828 -538.139)"></path>
                  <path id="path-22" data-name="path" className="relief-12" d="M21.884,154.4h3.472l.63-21.956h-.051l1.408,22.08,3.1.25,1.938-27.789L18.63,126.92Z" transform="translate(-13.61 -92.76)"></path>
                  <path id="path-23" data-name="path" className="relief-12" d="M-271.626,727.74h-3.365l-.24,4.094s8.53,1.117,7.569-.248A31.2,31.2,0,0,0-271.626,727.74Z" transform="translate(288.635 -666.225)"></path>
                  <path id="path-24" data-name="path" className="relief-12" d="M-258.88,732.81s8.36,3.243,1,3.157" transform="translate(276.686 -669.931)"></path>
                  <rect id="rectangle" className="relief-7" width="0.506" height="2.326" rx="0.253" transform="translate(17.974 61.447) rotate(60)"></rect>
                  <rect id="rectangle-2" data-name="rectangle" className="relief-7" width="0.506" height="2.326" rx="0.253" transform="translate(18.787 61.989) rotate(60)"></rect>
                  <rect id="rectangle-3" data-name="rectangle" className="relief-7" width="0.506" height="2.326" rx="0.253" transform="translate(19.553 62.521) rotate(60)"></rect>
                  <path id="path-81" className="relief-12" d="M3.035,4.515l-.007.007c-7.157.084.56-2.984.983-3.15C4.892.6,5.642,0,5.642,0H9.106l.248,4.094a56.9,56.9,0,0,1-5.727.432C3.42,4.526,3.222,4.522,3.035,4.515Z" transform="translate(2.633 61.638)"></path>
                  <rect id="rectangle-4" data-name="rectangle" className="relief-7" width="0.506" height="2.326" rx="0.253" transform="translate(5.34 63.331) rotate(-60)"></rect>
                  <rect id="rectangle-5" data-name="rectangle" className="relief-7" width="0.506" height="2.326" rx="0.253" transform="translate(6.153 62.786) rotate(-60)"></rect>
                  <rect id="rectangle-6" data-name="rectangle" className="relief-7" width="0.506" height="2.326" rx="0.253" transform="translate(6.917 62.256) rotate(-60)"></rect>
                </g>
                <path id="path-17-2" data-name="path-17" className="relief-4" d="M-306.086,496.505a5.883,5.883,0,0,1,.308-4.478s.663,1.127,1.591,0,1.79-4.971,8.087-2.055c0,0-1.259,1.458-.53,2.055,0,0,3.044,1.776,1.821,4.436,0,0-2.309-5-5.247-3.757S-301.82,490.975-306.086,496.505Z" transform="translate(311.41 -488.902)"></path>
              </g>
              <g id="group-150" transform="translate(0.287 0)">
                <path id="path-25" data-name="path" className="relief-3" d="M11.1,13c.611.188,1.175.376,1.739.564l3.385,1.128a.4.4,0,0,0,.329,0c1.645-.564,3.291-1.081,4.936-1.645A.173.173,0,0,0,21.63,13v2.962a1.4,1.4,0,0,1-.376.94,3.341,3.341,0,0,1-1.41.846,10.958,10.958,0,0,1-3.714.564,8.65,8.65,0,0,1-3.479-.658A8.279,8.279,0,0,1,11.57,17a1.21,1.21,0,0,1-.47-1.034Q11.1,14.481,11.1,13Z" transform="translate(-5.641 -8.095)"></path>
                <path id="path-26" data-name="path" className="relief-4" d="M5.094,16.162H3.6c.133-.534.267-1.04.4-1.574a.706.706,0,0,1-.373-.454A.772.772,0,0,1,4,13.281c.08-.027.08-.08.08-.16V11.467c0-.16.08-.267.24-.267a.239.239,0,0,1,.267.267v1.681a.147.147,0,0,0,.08.133.748.748,0,0,1,.08,1.307c-.053.027-.053.08-.027.133.107.454.24.907.347,1.361A.206.206,0,0,0,5.094,16.162Z" transform="translate(-0.943 -6.417)"></path>
                <path id="path-27" data-name="path" className="relief-4" d="M0,3.479l1.692-.564C4.56,1.974,7.428.987,10.3.047A.8.8,0,0,1,10.624,0L20.778,3.385a.794.794,0,0,1,.235.094c-.658.235-1.269.423-1.88.611-2.774.94-5.547,1.833-8.321,2.774a.8.8,0,0,1-.658,0C8.462,6.252,6.722,5.688,5.03,5.124A.794.794,0,0,1,4.8,5.03c.658-.235,1.269-.423,1.88-.658,1.269-.423,2.539-.893,3.855-1.316a.5.5,0,0,0,.235-.846.374.374,0,0,0-.47-.094c-.846.282-1.645.611-2.492.893C6.44,3.526,5.03,4,3.667,4.513a.3.3,0,0,1-.329.047C2.256,4.231,1.128,3.855,0,3.479Z" transform="translate(0)"></path>
              </g>
            </g>
            <g id="" transform="translate(2690.321 1470.917)">
              <g id="group-26-2" data-name="group-26" transform="translate(0 5.468)">
                <g id="group-24-2" data-name="group-24" transform="translate(0 2.49)">
                  <path id="path-28" data-name="path" className="relief-3" d="M-302.505,523.22c-.724-4.527-4.6-8.312-8.438-9.876-.3-.414.043-.851.3-1.093q.113-.089.223-.186h0a7.515,7.515,0,0,0,2.444-5.243,2.391,2.391,0,0,0,.538-.705c.425-.821.339-1.709-.188-1.981a.824.824,0,0,0-.638-.03c-.789-2.848-3.05-4.907-5.717-4.907s-4.955,2.081-5.73,4.95a.87.87,0,0,0-.756-.014c-.538.269-.614,1.16-.188,1.981a2.307,2.307,0,0,0,.681.807,7.446,7.446,0,0,0,2.643,5.308h0l.056.043c.229.183.942.851-.046,1.464l-.269.151h0c-3.7,2-7.335,5.59-7.437,10.306-.081,3.588.668,10.417,2.5,13.332h0l.067.11a15.147,15.147,0,0,1,.5,1.516h0a3.209,3.209,0,0,0,.659,1.192,2.524,2.524,0,0,0,3.486.6,2.526,2.526,0,0,0-.1-3.534,2.928,2.928,0,0,0-2.269-1.076c-1.284-1.666-1.841-7.268-1.79-9.59.059-2.667.331-5.922,3.066-7.633-1.241,4.121-1.2,8.462-1.241,11.663,0,.027.129,2.565.42,4.468a1.76,1.76,0,0,0,.213.1l.431-1.943h10.549a5.36,5.36,0,0,1-.458.4l1.12,2.352c.091-.043.729-2.054,1.615-2.751l-.151-2.215a63.692,63.692,0,0,0-1.884-11.3l-.013-.049-.519-1.41c2.581,1.515,3.23,5.227,3.642,7.728.342,2.134.538,6.936-.269,9.049a2.923,2.923,0,0,0-2.261,1.1,2.527,2.527,0,0,0-.051,3.537,2.524,2.524,0,0,0,3.475-.651,3.185,3.185,0,0,0,.668-1.305h0a2.776,2.776,0,0,0,.067-.428,8.186,8.186,0,0,1,.39-1.47v-.016C-301.751,532.983-301.94,526.752-302.505,523.22Z" transform="translate(325.032 -499.2)"></path>
                  <path id="path-29" data-name="path" className="relief-7" d="M-301.48,733.74" transform="translate(307.819 -670.61)"></path>
                  <path id="path-30" data-name="path" className="relief-3" d="M-304.064,558.832l-.013-.048-.522-1.413a6,6,0,0,1,2.334,2.767l2.821-1.4a14.974,14.974,0,0,0-6.772-6.261,4.658,4.658,0,0,1-3.432,1.685,4.6,4.6,0,0,1-3.332-1.577c-.035.024-.067.051-.108.075l-.269.151h0a15.725,15.725,0,0,0-5.922,5.346l2.439,2.39a5.722,5.722,0,0,1,2.169-2.5c-1.244,4.129-1.2,8.481-1.244,11.69,0,.019.062,1.244.2,2.627h13.727l-.151-2.21A68.226,68.226,0,0,0-304.064,558.832Z" transform="translate(320.828 -538.139)"></path>
                  <path id="path-31" data-name="path" className="relief-12" d="M21.884,154.4h3.472l.63-21.956h-.051l1.408,22.08,3.1.25,1.938-27.789L18.63,126.92Z" transform="translate(-13.61 -92.76)"></path>
                  <path id="path-32" data-name="path" className="relief-12" d="M-271.626,727.74h-3.365l-.24,4.094s8.53,1.117,7.569-.248A31.2,31.2,0,0,0-271.626,727.74Z" transform="translate(288.635 -666.225)"></path>
                  <path id="path-33" data-name="path" className="relief-12" d="M-258.88,732.81s8.36,3.243,1,3.157" transform="translate(276.686 -669.931)"></path>
                  <rect id="rectangle-7" data-name="rectangle" className="relief-7" width="0.506" height="2.326" rx="0.253" transform="translate(17.974 61.447) rotate(60)"></rect>
                  <rect id="rectangle-8" data-name="rectangle" className="relief-7" width="0.506" height="2.326" rx="0.253" transform="translate(18.787 61.989) rotate(60)"></rect>
                  <rect id="rectangle-9" data-name="rectangle" className="relief-7" width="0.506" height="2.326" rx="0.253" transform="translate(19.553 62.521) rotate(60)"></rect>
                  <path id="path-81-2" data-name="path-81" className="relief-12" d="M3.035,4.515l-.007.007c-7.157.084.56-2.984.983-3.15C4.892.6,5.642,0,5.642,0H9.106l.248,4.094a56.9,56.9,0,0,1-5.727.432C3.42,4.526,3.222,4.522,3.035,4.515Z" transform="translate(2.633 61.638)"></path>
                  <rect id="rectangle-10" data-name="rectangle" className="relief-7" width="0.506" height="2.326" rx="0.253" transform="translate(5.34 63.331) rotate(-60)"></rect>
                  <rect id="rectangle-11" data-name="rectangle" className="relief-7" width="0.506" height="2.326" rx="0.253" transform="translate(6.153 62.786) rotate(-60)"></rect>
                  <rect id="rectangle-12" data-name="rectangle" className="relief-7" width="0.506" height="2.326" rx="0.253" transform="translate(6.917 62.256) rotate(-60)"></rect>
                </g>
                <path id="path-17-3" data-name="path-17" className="relief-4" d="M-306.086,496.505a5.883,5.883,0,0,1,.308-4.478s.663,1.127,1.591,0,1.79-4.971,8.087-2.055c0,0-1.259,1.458-.53,2.055,0,0,3.044,1.776,1.821,4.436,0,0-2.309-5-5.247-3.757S-301.82,490.975-306.086,496.505Z" transform="translate(311.41 -488.902)"></path>
              </g>
              <g id="group-150-2" data-name="group-150" transform="translate(0.069 0)">
                <path id="path-34" data-name="path" className="relief-4" d="M0,3.479l1.692-.564C4.56,1.974,7.428.987,10.3.047A.8.8,0,0,1,10.624,0L20.778,3.385a.794.794,0,0,1,.235.094c-.658.235-1.269.423-1.88.611-2.774.94-5.547,1.833-8.321,2.774a.8.8,0,0,1-.658,0C8.462,6.252,6.722,5.688,5.03,5.124A.794.794,0,0,1,4.8,5.03c.658-.235,1.269-.423,1.88-.658,1.269-.423,2.539-.893,3.855-1.316a.5.5,0,0,0,.235-.846.374.374,0,0,0-.47-.094c-.846.282-1.645.611-2.492.893C6.44,3.526,5.03,4,3.667,4.513a.3.3,0,0,1-.329.047C2.256,4.231,1.128,3.855,0,3.479Z"></path>
                <path id="path-35" data-name="path" className="relief-1" d="M11.1,13c.611.188,1.175.376,1.739.564l3.385,1.128a.4.4,0,0,0,.329,0c1.645-.564,3.291-1.081,4.936-1.645A.173.173,0,0,0,21.63,13v2.962a1.4,1.4,0,0,1-.376.94,3.341,3.341,0,0,1-1.41.846,10.958,10.958,0,0,1-3.714.564,8.65,8.65,0,0,1-3.479-.658A8.279,8.279,0,0,1,11.57,17a1.21,1.21,0,0,1-.47-1.034Q11.1,14.481,11.1,13Z" transform="translate(-5.641 -8.095)"></path>
                <path id="path-36" data-name="path" className="relief-4" d="M5.094,16.162H3.6c.133-.534.267-1.04.4-1.574a.706.706,0,0,1-.373-.454A.772.772,0,0,1,4,13.281c.08-.027.08-.08.08-.16V11.467c0-.16.08-.267.24-.267a.239.239,0,0,1,.267.267v1.681a.147.147,0,0,0,.08.133.748.748,0,0,1,.08,1.307c-.053.027-.053.08-.027.133.107.454.24.907.347,1.361A.206.206,0,0,0,5.094,16.162Z" transform="translate(-0.943 -6.417)"></path>
              </g>
            </g>
            <g transform="translate(2668.142 1434.057)">
              <g id="group-145" transform="translate(0 0)">
                <g id="group-143" transform="translate(33.119 0)">
                  <line id="line-30" className="relief-13" y1="30.298" transform="translate(0.02 35.959)"></line>
                  <g id="group-134" transform="translate(0)">
                    <path id="path-37" data-name="path" className="relief-14" d="M35.031,20.758a69.411,69.411,0,0,0-12.739-5.4A75.37,75.37,0,0,1,8.8,9.638,46.183,46.183,0,0,1,.808,3.7L.7,41.378a68.86,68.86,0,0,1,18.461,7.989c3.563,2.051,7.125,4.318,10.688,6.586s7.233,4.642,11.012,6.694a96.5,96.5,0,0,0,10.472,5.4c-3.455-6.37-2.375-12.631-1.511-18.245a38.915,38.915,0,0,0,.756-7.557,31.65,31.65,0,0,1,.216-5.614c.108-.972.108-.972-1.08-2.267-.864-.864-2.159-2.267-4.1-4.642a39.322,39.322,0,0,0-10.58-8.961" transform="translate(-0.7 -3.7)"></path>
                  </g>
                </g>
                <g id="group-49" transform="translate(0 58.386)">
                  <g id="group-48" transform="translate(0 0)">
                    <g id="group-47" transform="translate(0 0)">
                      <path id="path-38" data-name="path" className="relief-3" d="M33.743,13.829a2.5,2.5,0,0,0-2.906,2.056,2.786,2.786,0,0,0,.6,2.44,11.164,11.164,0,0,1-3.043,5.1c-3.646,3.372-9.02,3.509-11.213,3.4a1.307,1.307,0,0,1-.219-.11h0c-.082-.055-.137-.082-.219-.137h0l-.164-.082c-.822-.493-.356-1.1-.055-1.426a1.567,1.567,0,0,0,.219-.192h0a7.769,7.769,0,0,0,2.495-5.346,2.359,2.359,0,0,0,.548-.713c.439-.85.356-1.727-.192-2.029a.78.78,0,0,0-.658-.027A6.2,6.2,0,0,0,13.182,11.8c-2.742,0-5.044,2.111-5.839,5.044a.943.943,0,0,0-.768-.027c-.548.274-.631,1.179-.192,2.029a2.609,2.609,0,0,0,.685.822,7.526,7.526,0,0,0,2.687,5.4h0l.055.055c.219.192.96.877-.055,1.48-.082.055-.192.11-.274.164h0C5.725,28.8.1,33.1-.005,37.9c-.137,6.47,7.21,10.555,7.539,10.719l.439-1.974H16.5l4.469-.247-.219-3.016a63.776,63.776,0,0,0-1.892-10.857h0l.082-1.919a19.2,19.2,0,0,0,2.632-.247,18.009,18.009,0,0,0,8.746-4.5A13.48,13.48,0,0,0,33.826,18.9,2.739,2.739,0,0,0,35.5,16.927,2.555,2.555,0,0,0,33.743,13.829ZM6.876,45.11A9.1,9.1,0,0,1,3.2,37.954c.055-2.714,2.495-5.291,5.264-7.046C7.041,35.679,6.876,42.176,6.876,45.11Z" transform="translate(0.007 -11.8)"></path>
                      <path id="path-77" className="relief-12" d="M3.087,4.6l0,0C-4.4,4.688,4.1,1.4,4.1,1.4L4.59.959l0,.018C5.265.4,5.769,0,5.769,0H9.306l.247,4.167A57.636,57.636,0,0,1,3.7,4.613C3.488,4.613,3.281,4.609,3.087,4.6Z" transform="translate(4.651 62.37)"></path>
                      <path id="path-78" className="relief-12" d="M5.511,4.606l0-.005C3.212,4.583,0,4.167,0,4.167L.247,0H3.674S4.835.965,5.953,2.026c2.005.934,4.842,2.582-.088,2.583Z" transform="translate(15.629 62.233)"></path>
                      <path id="path-39" data-name="path" className="relief-7" d="M30.8,244.8" transform="translate(-22.354 -180.922)"></path>
                      <g id="group-5" data-name="group" transform="translate(7.801 62.438)">
                        <path id="path-40" data-name="path" className="relief-7" d="M31.251,245.584h0a.261.261,0,0,1-.356.082l-1.618-.932a.261.261,0,0,1-.082-.356h0a.261.261,0,0,1,.356-.082l1.618.932A.261.261,0,0,1,31.251,245.584Z" transform="translate(-29.156 -243.159)"></path>
                        <path id="path-41" data-name="path" className="relief-7" d="M34.351,243.584h0a.261.261,0,0,1-.356.082l-1.618-.932a.261.261,0,0,1-.082-.356h0a.261.261,0,0,1,.356-.082l1.618.932A.281.281,0,0,1,34.351,243.584Z" transform="translate(-31.406 -241.707)"></path>
                        <path id="path-42" data-name="path" className="relief-7" d="M37.151,241.584h0a.261.261,0,0,1-.356.082l-1.618-.932a.261.261,0,0,1-.082-.356h0a.261.261,0,0,1,.356-.082l1.618.932A.261.261,0,0,1,37.151,241.584Z" transform="translate(-33.438 -240.256)"></path>
                      </g>
                      <g id="group-6" data-name="group" transform="translate(18.003 62.329)">
                        <path id="path-43" data-name="path" className="relief-7" d="M67.051,239.977h0a.261.261,0,0,0-.356-.082l-1.618.932a.261.261,0,0,0-.082.356h0a.261.261,0,0,0,.356.082l1.617-.932A.261.261,0,0,0,67.051,239.977Z" transform="translate(-64.956 -239.856)"></path>
                        <path id="path-44" data-name="path" className="relief-7" d="M70.151,241.977h0a.261.261,0,0,0-.356-.082l-1.618.932a.261.261,0,0,0-.082.356h0a.261.261,0,0,0,.356.082l1.618-.932A.281.281,0,0,0,70.151,241.977Z" transform="translate(-67.206 -241.307)"></path>
                        <path id="path-45" data-name="path" className="relief-7" d="M72.951,243.977h0a.261.261,0,0,0-.356-.082l-1.618.932a.261.261,0,0,0-.082.356h0a.261.261,0,0,0,.356.082l1.618-.932A.261.261,0,0,0,72.951,243.977Z" transform="translate(-69.238 -242.759)"></path>
                      </g>
                      <g id="group-7" data-name="group" transform="translate(2.935 14.146)">
                        <path id="path-46" data-name="path" className="relief-3" d="M27.04,69.925l-.027-.055-.247-1.426c1.1.631,4.3-.987,5.154-1.261L31.81,63.4c-2.742.137-4.524,1.289-7.238.055a4.763,4.763,0,0,1-3.482,1.727,4.71,4.71,0,0,1-3.4-1.618c-.027.027-.082.055-.11.082-.082.055-.192.11-.274.164h0C15,65.045,12.153,66.69,10.7,69.048L13.058,71.1c1.206-.8,1.837-1.289,2.879-1.946-1.261,4.195-1.234,8.636-1.261,11.9,0,.027.055,1.261.192,2.687H28.849l-.164-2.248A63.341,63.341,0,0,0,27.04,69.925Z" transform="translate(-10.7 -63.4)"></path>
                      </g>
                      <path id="path-47" data-name="path" className="relief-12" d="M29.217,165.191h3.537l.631-22.371H33.33l1.426,22.508,3.18-.335,1.974-27.711L25.9,137.2Z" transform="translate(-18.798 -102.821)"></path>
                    </g>
                  </g>
                </g>
              </g>
              <g id="group-144" transform="translate(2.616 51.573)">
                <g id="group-9" data-name="group" transform="translate(0 0)">
                  <g id="group-149">
                    <path id="path-48" data-name="path" className="relief-4" d="M0,3.479l1.692-.564C4.56,1.974,7.428.987,10.3.047A.8.8,0,0,1,10.624,0L20.778,3.385a.794.794,0,0,1,.235.094c-.658.235-1.269.423-1.88.611-2.774.94-5.547,1.833-8.321,2.774a.8.8,0,0,1-.658,0C8.462,6.252,6.722,5.688,5.03,5.124A.794.794,0,0,1,4.8,5.03c.658-.235,1.269-.423,1.88-.658,1.269-.423,2.539-.893,3.855-1.316a.5.5,0,0,0,.235-.846.374.374,0,0,0-.47-.094c-.846.282-1.645.611-2.492.893C6.44,3.526,5.03,4,3.667,4.513a.3.3,0,0,1-.329.047C2.256,4.231,1.128,3.855,0,3.479Z"></path>
                    <path id="path-49" data-name="path" className="relief-3" d="M11.1,13c.611.188,1.175.376,1.739.564l3.385,1.128a.4.4,0,0,0,.329,0c1.645-.564,3.291-1.081,4.936-1.645A.173.173,0,0,0,21.63,13v2.962a1.4,1.4,0,0,1-.376.94,3.341,3.341,0,0,1-1.41.846,10.958,10.958,0,0,1-3.714.564,8.65,8.65,0,0,1-3.479-.658A8.279,8.279,0,0,1,11.57,17a1.21,1.21,0,0,1-.47-1.034Q11.1,14.481,11.1,13Z" transform="translate(-5.882 -7.974)"></path>
                    <path id="path-50" data-name="path" className="relief-4" d="M5.094,16.162H3.6c.133-.534.267-1.04.4-1.574a.706.706,0,0,1-.373-.454A.772.772,0,0,1,4,13.281c.08-.027.08-.08.08-.16V11.467c0-.16.08-.267.24-.267a.239.239,0,0,1,.267.267v1.681a.147.147,0,0,0,.08.133.748.748,0,0,1,.08,1.307c-.053.027-.053.08-.027.133.107.454.24.907.347,1.361A.206.206,0,0,0,5.094,16.162Z" transform="translate(-1.184 -6.296)"></path>
                  </g>
                </g>
              </g>
            </g>
            <g id="group-191" transform="translate(2717.19 1491.694)">
              <g id="group-46" transform="translate(34.776 25.869) rotate(161)">
                <g id="ellipse-9" className="relief-3" transform="translate(0 0)">
                  <circle className="relief-23" cx="12.308" cy="12.308" r="12.308"></circle>
                  <circle className="relief-24" cx="12.308" cy="12.308" r="11.808"></circle>
                </g>
                <path id="ellipse-9-2" data-name="ellipse-9" className="relief-13" d="M2.02,0A2.02,2.02,0,1,1,0,2.02,2.02,2.02,0,0,1,2.02,0Z" transform="translate(23.32 23.32)"></path>
              </g>
              <path id="path-84" className="relief-4" d="M0,0,1.032,11.576l4.63,1.285L10.3,11.572,11.338,0ZM9.187,2.748l-.066.719-.028.319H3.661l.131,1.454H8.965l-.034.381L8.6,9.356,8.575,9.6l-2.9.807h0l-.006,0L2.757,9.6l-.2-2.229H3.983l.1,1.132,1.582.428h0L7.246,8.5l.166-1.841H2.491L2.144,2.748,2.11,2.366H9.222Z" transform="translate(13.573 12.41)"></path>
            </g>
            <g id="group-255" transform="translate(2655.742 1440.668)">
              <g id="group-46-2" data-name="group-46" transform="translate(7.783 0) rotate(16)">
                <g id="ellipse-9-3" data-name="ellipse-9" className="relief-3">
                  <circle className="relief-23" cx="12.308" cy="12.308" r="12.308"></circle>
                  <circle className="relief-24" cx="12.308" cy="12.308" r="11.808"></circle>
                </g>
                <g id="ellipse-9-4" data-name="ellipse-9" className="relief-13" transform="translate(22.444 22.444)">
                  <circle className="relief-23" cx="2.896" cy="2.896" r="2.896"></circle>
                  <circle className="relief-24" cx="2.896" cy="2.896" r="2.396"></circle>
                </g>
              </g>
              <g id="group-121" transform="translate(8.642 11.099)">
                <path id="path-51" data-name="path" className="relief-4" d="M.851,0H.3L0,1.561H.445a1.253,1.253,0,0,0,.76-.188A1.022,1.022,0,0,0,1.534.74.675.675,0,0,0,1.472.163.851.851,0,0,0,.851,0Z" transform="translate(10.843 3.038)"></path>
                <path id="path-52" data-name="path" className="relief-4" d="M.851,0H.3L0,1.561H.445a1.253,1.253,0,0,0,.76-.188A1.022,1.022,0,0,0,1.534.74.675.675,0,0,0,1.472.163.851.851,0,0,0,.851,0Z" transform="translate(3.814 3.038)"></path>
                <path id="path-53" data-name="path" className="relief-4" d="M7.841,0C3.51,0,0,1.847,0,4.125S3.51,8.249,7.841,8.249,15.682,6.4,15.682,4.125,12.172,0,7.841,0ZM5.853,4.93a1.694,1.694,0,0,1-.631.379,2.833,2.833,0,0,1-.867.111h-.7l-.2,1.03a.083.083,0,0,1-.084.068h-.9A.084.084,0,0,1,2.4,6.486a.088.088,0,0,1-.018-.07l.8-4.134a.083.083,0,0,1,.084-.068H5a1.524,1.524,0,0,1,1.2.44,1.373,1.373,0,0,1,.236,1.218,1.954,1.954,0,0,1-.209.579A1.915,1.915,0,0,1,5.853,4.93Zm2.591.461a.088.088,0,0,1-.018-.07l.356-1.829c.034-.175.025-.3-.023-.352-.029-.032-.12-.086-.386-.086H7.73l-.447,2.3A.083.083,0,0,1,7.2,5.42H6.3a.084.084,0,0,1-.066-.032.088.088,0,0,1-.018-.07l.8-4.134a.083.083,0,0,1,.084-.068H8a.084.084,0,0,1,.066.032.088.088,0,0,1,.018.07l-.193,1h.692A1.611,1.611,0,0,1,9.679,2.5a.946.946,0,0,1,.2.928L9.5,5.352a.083.083,0,0,1-.084.068H8.51A.089.089,0,0,1,8.444,5.391Zm5.03-1.518a1.891,1.891,0,0,1-.592,1.06,1.694,1.694,0,0,1-.631.379,2.834,2.834,0,0,1-.867.111h-.7l-.2,1.03a.083.083,0,0,1-.084.068H9.5a.084.084,0,0,1-.066-.032.088.088,0,0,1-.018-.07l.8-4.134a.083.083,0,0,1,.084-.068h1.731a1.524,1.524,0,0,1,1.2.44A1.367,1.367,0,0,1,13.474,3.873Z" transform="translate(0 0)"></path>
              </g>
            </g>
            <g id="group-254" transform="translate(2625.023 1439.823)">
              <g id="group-46-3" data-name="group-46" transform="translate(5.462 0) rotate(12)">
                <g id="ellipse-9-5" data-name="ellipse-9" className="relief-3" transform="translate(0 0)">
                  <circle className="relief-23" cx="11.451" cy="11.451" r="11.451"></circle>
                  <circle className="relief-24" cx="11.451" cy="11.451" r="10.951"></circle>
                </g>
                <g id="ellipse-9-6" data-name="ellipse-9" className="relief-13" transform="translate(20.881 20.881)">
                  <circle className="relief-23" cx="2.694" cy="2.694" r="2.694"></circle>
                  <circle className="relief-24" cx="2.694" cy="2.694" r="2.194"></circle>
                </g>
              </g>
              <g id="group-122" transform="translate(8.361 7.81)">
                <path id="path1948_2_" className="relief-4" d="M5.581,0A7.436,7.436,0,0,0,4.257.114C3.087.321,2.87.756,2.87,1.553V2.608H5.643V2.96H1.824A1.744,1.744,0,0,0,.085,4.368a5.1,5.1,0,0,0,0,2.815c.2.818.673,1.408,1.47,1.408h.952V7.328A1.775,1.775,0,0,1,4.246,5.6H7.02A1.392,1.392,0,0,0,8.407,4.192V1.553A1.539,1.539,0,0,0,7.02.114,8.344,8.344,0,0,0,5.581,0Zm-1.5.849a.521.521,0,0,1,.518.528.528.528,0,0,1-.518.528.528.528,0,0,1,0-1.056Z" transform="translate(0.113)"></path>
                <path id="path1950_2_" className="relief-4" d="M34.589,28.7v1.232a1.781,1.781,0,0,1-1.739,1.76H30.087A1.419,1.419,0,0,0,28.7,33.1v2.639c0,.756.652,1.19,1.387,1.408a4.684,4.684,0,0,0,2.774,0c.7-.207,1.387-.611,1.387-1.408V34.683H31.474v-.352h4.161c.807,0,1.107-.559,1.387-1.408a4.73,4.73,0,0,0,0-2.815c-.2-.807-.58-1.408-1.387-1.408Zm-1.553,6.686a.521.521,0,0,1,.518.528.528.528,0,0,1-.518.528.521.521,0,0,1-.518-.528A.515.515,0,0,1,33.037,35.386Z" transform="translate(-25.718 -25.729)"></path>
              </g>
            </g>
            <g id="group-253" transform="translate(2608.242 1467.378)">
              <g id="group-46-4" data-name="group-46" transform="matrix(0.891, -0.454, 0.454, 0.891, 0, 12.819)">
                <g id="ellipse-9-7" data-name="ellipse-9" className="relief-3">
                  <circle className="relief-23" cx="12.308" cy="12.308" r="12.308"></circle>
                  <circle className="relief-24" cx="12.308" cy="12.308" r="11.808"></circle>
                </g>
                <g id="ellipse-9-8" data-name="ellipse-9" className="relief-13" transform="translate(22.444 22.444)">
                  <circle className="relief-23" cx="2.896" cy="2.896" r="2.896"></circle>
                  <circle className="relief-24" cx="2.896" cy="2.896" r="2.396"></circle>
                </g>
              </g>
              <g id="group-202" transform="translate(11.387 12.646)">
                <path id="path-54" data-name="path" className="relief-4" d="M10.6,6.83l-.053.125A4.776,4.776,0,0,1,6.163,9.611a6.224,6.224,0,0,1-2.514-.5A6.553,6.553,0,0,1,.618,6.259,4.75,4.75,0,0,1,1.4.5c.036,0-.214.374-.2.374l-.053.143c-1.3,4.065,2.71,7.56,6.6,7.15A5.048,5.048,0,0,0,10.6,6.83" transform="translate(0.008 1.158)"></path>
                <path id="path-55" data-name="path" className="relief-4" d="M22.95-.105a3.738,3.738,0,0,0,2.6-1.088C25.036.144,23.5,1,21.452.733A4.456,4.456,0,0,1,17.9-2.976,3.7,3.7,0,0,1,18.938-5.9a3.523,3.523,0,0,0-.374,1.088A4.485,4.485,0,0,0,22.95-.105" transform="translate(-14.693 6.417)"></path>
                <path id="path-56" data-name="path" className="relief-4" d="M38.36-5.894a3.028,3.028,0,0,1-1.409,1.088,2.7,2.7,0,0,1-3.031-1.23c-.071-.125-.214-.464-.25-.553a2.256,2.256,0,0,1,.5-2.211,2.692,2.692,0,0,0-.107,1.48,3.264,3.264,0,0,0,.41.892,2.289,2.289,0,0,0,.909.8,1.942,1.942,0,0,0,.464.214,2.563,2.563,0,0,0,2.514-.481" transform="translate(-27.589 8.8)"></path>
              </g>
            </g>
            <g id="group-252" transform="translate(2611.251 1500.795)">
              <g id="group-46-5" data-name="group-46" transform="translate(0 8.725) rotate(-18)">
                <g id="ellipse-9-9" data-name="ellipse-9" className="relief-3" transform="translate(0 0)">
                  <circle className="relief-23" cx="12.308" cy="12.308" r="12.308"></circle>
                  <circle className="relief-24" cx="12.308" cy="12.308" r="11.808"></circle>
                </g>
                <g id="ellipse-9-10" data-name="ellipse-9" className="relief-13" transform="translate(22.444 22.444)">
                  <circle className="relief-23" cx="2.896" cy="2.896" r="2.896"></circle>
                  <circle className="relief-24" cx="2.896" cy="2.896" r="2.396"></circle>
                </g>
              </g>
              <g id="group-210" transform="translate(9.826 11.037)">
                <path id="path-57" data-name="path" className="relief-4" d="M5.612,10.952A5.543,5.543,0,0,1,1.584,9.413,5.23,5.23,0,0,1,0,5.506,5.313,5.313,0,0,1,1.614,1.569,5.476,5.476,0,0,1,5.567,0a5.59,5.59,0,0,1,4.36,1.991L8.463,3.666A3.428,3.428,0,0,0,5.672,2.278a3.216,3.216,0,0,0-2.278.875,3.036,3.036,0,0,0-.95,2.353,3.2,3.2,0,0,0,.89,2.368,2.925,2.925,0,0,0,2.172.89A3.487,3.487,0,0,0,8.358,7.392L9.866,8.946A5.545,5.545,0,0,1,5.612,10.952Z" transform="translate(0)"></path>
                <path id="path-58" data-name="path" className="relief-4" d="M64.92,28.446h-.715l-.143.915h.83l-.2.973h-.8l-.172,1.087H62.632l.2-1.087h-.658L62,31.421H60.915l.172-1.087H60.4l.2-.973h.658l.172-.915h-.772l.2-.973h.715l.2-1.173H62.86l-.2,1.173h.629l.2-1.173h1.087l-.2,1.173h.744Z" transform="translate(-50.202 -23.418)"></path>
                <path id="path-124" className="relief-7" d="M178.222-222.482l.283-1.29h.83l-.277,1.29Z" transform="translate(-166.196 228.436)"></path>
              </g>
            </g>
            <g id="group-10" data-name="group" transform="translate(2706.646 1453.446)">
              <g id="group-11" data-name="group">
                <path id="path-59" data-name="path" className="relief-7" d="M4.692,28.159.1,28v1.482l4.579,5.135v-1.6l-3.017-3.4,3.03.146v-1.6" transform="translate(-0.1 -28)"></path>
                <path id="path-60" data-name="path" className="relief-7" d="M44.973,34.535,42.3,33v1.442l1.257.728L43.531,41.3l1.416.82.026-7.583" transform="translate(-36.716 -32.338)"></path>
                <path id="path-61" data-name="path" className="relief-7" d="M75.426,64.831a2.213,2.213,0,0,1-.966-1.257,5.9,5.9,0,0,1-.3-2.011,3.285,3.285,0,0,1,.318-1.654c.212-.344.529-.384.979-.132a2.213,2.213,0,0,1,.966,1.257,5.9,5.9,0,0,1,.3,2.011,3.285,3.285,0,0,1-.318,1.654c-.212.344-.543.384-.979.132m.013-6.445c-.913-.529-1.588-.569-2.051-.119a3.462,3.462,0,0,0-.688,2.461,7.6,7.6,0,0,0,.675,3.242,5.019,5.019,0,0,0,2.038,2.25c.913.529,1.6.569,2.051.119a3.462,3.462,0,0,0,.688-2.461,7.6,7.6,0,0,0-.675-3.242,4.811,4.811,0,0,0-2.038-2.25" transform="translate(-63.093 -53.992)"></path>
                <path id="path-62" data-name="path" className="relief-7" d="M125.02,87.168l-1.337-.768L119.7,94.049l1.337.768,3.983-7.649" transform="translate(-103.873 -78.672)"></path>
                <path id="path-63" data-name="path" className="relief-7" d="M166.573,104.748l-2.66-1.548-.013,1.442,1.257.728-.013,6.14,1.416.807.013-7.569" transform="translate(-142.224 -93.248)"></path>
                <path id="path-64" data-name="path" className="relief-7" d="M197.113,135.044a2.213,2.213,0,0,1-.966-1.257,5.9,5.9,0,0,1-.3-2.011,3.285,3.285,0,0,1,.318-1.654c.212-.344.529-.384.979-.132a2.213,2.213,0,0,1,.966,1.257,5.9,5.9,0,0,1,.3,2.011,3.285,3.285,0,0,1-.318,1.654c-.212.344-.529.384-.979.132m.026-6.458c-.913-.529-1.6-.569-2.051-.119a3.462,3.462,0,0,0-.688,2.461,7.6,7.6,0,0,0,.675,3.242,4.952,4.952,0,0,0,2.038,2.25c.913.529,1.588.569,2.051.119a3.462,3.462,0,0,0,.688-2.461,7.6,7.6,0,0,0-.675-3.242,4.945,4.945,0,0,0-2.038-2.25" transform="translate(-168.688 -114.903)"></path>
                <path id="path-65" data-name="path" className="relief-7" d="M244.813,150.6v1.588l3.017,3.348-3.03-.093v1.6l4.592.159v-1.482l-4.579-5.121" transform="translate(-212.418 -134.376)"></path>
              </g>
            </g>
          </g>
          <g id="Group_260" data-name="Group 260" transform="translate(-2497 -978)">
            <path id="path-66" data-name="path" className="relief-2" d="M176.174,406.977V323.7L120,356.165v83.277Z" transform="translate(2449.218 1154.79)"></path>
            <path id="path-67" data-name="path" className="relief-3" d="M1,335.3l49.152,27.674v83.277L1,418.578Z" transform="translate(2519.066 1147.981)"></path>
            <path id="path-68" data-name="path" className="relief-2" d="M106.325,284.374,57.173,256.7,1,289.165l49.152,27.674Z" transform="translate(2519.066 1194.116)"></path>
            <path id="path-69" data-name="path" className="relief-4" d="M189.4,422.3,154,442.415v8.261l35.4-20.28Z" transform="translate(2428.537 1095.106)"></path>
            <path id="path-70" data-name="path" className="relief-4" d="M189.4,466.4,154,486.515v8.261l35.4-20.28Z" transform="translate(2429.261 1071.031)"></path>
            <path id="path-71" data-name="path" className="relief-16" d="M12.1,508.6l35.4,20.115v8.3l-35.4-20.28Z" transform="translate(2512.551 1046.261)"></path>
            <path id="path-72" data-name="path" className="relief-5" d="M106.614,363,50.441,395.424,1,366.883" transform="translate(2519.066 1119.415)"></path>
            <g id="group-258" transform="translate(2533.411 1454.677)">
              <g id="group-60">
                <path id="path-73" data-name="path" className="relief-3" d="M400.27,276.227l20.94,11.643,13.7-7.911V264.137L414.359,252.78v15.689Z" transform="translate(-400.27 -252.78)"></path>
                <line id="line" className="relief-3" x2="20.553" y2="11.49" transform="translate(14.089 15.689)"></line>
                <path id="path-74" data-name="path" className="relief-4" d="M417.27,301.4l6.851-3.829,16.3,9.128-6.75,3.865Z" transform="translate(-410.419 -279.519)"></path>
              </g>
              <g id="group-12" data-name="group" transform="translate(17.937 3.482)">
                <g id="group-13" data-name="group" className="relief-17">
                  <g id="group-14" data-name="group" transform="translate(0 0.893)">
                    <g id="group-15" data-name="group">
                      <g id="group-16" data-name="group">
                        <path id="path-75" data-name="path" d="M1.656,3.5.1,3.551v.587L1.656,5.974V5.311l-.918-1.1.918-.051V3.5" transform="translate(-0.074 -3.5)"></path>
                        <path id="path-76" data-name="path" d="M43.3,62.6v.689l.918,1.1-.918.026v.663l1.556-.051v-.561L43.3,62.6" transform="translate(-32.255 -47.525)"></path>
                        <path id="path-77-2" data-name="path" d="M12.65,20.744.1,13.5v.612l12.55,7.27v-.638" transform="translate(-0.074 -10.949)"></path>
                        <path id="path-78-2" data-name="path" d="M12.65,26.644.1,19.4v.612l12.55,7.244v-.612" transform="translate(-0.074 -15.344)"></path>
                        <path id="path-79" data-name="path" d="M12.65,32.544.1,25.3v.612l12.55,7.244v-.612" transform="translate(-0.074 -19.739)"></path>
                        <path id="path-80" data-name="path" d="M12.575,38.444,0,31.2v.612l12.575,7.244v-.612" transform="translate(0 -24.134)"></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
            <g id="group-256" transform="translate(2562.735 1439.809)">
              <g id="group-60-2" data-name="group-60" transform="translate(0 0)">
                <path id="path-81-3" data-name="path" className="relief-3" d="M400.27,276.227l20.94,11.643,13.7-7.911V264.137L414.359,252.78v15.689Z" transform="translate(-400.27 -252.78)"></path>
                <path id="line-2" data-name="line" className="relief-3" d="M0,0,12.846,7.181l7.707,4.309" transform="translate(14.089 15.689)"></path>
                <path id="path-82" data-name="path" className="relief-4" d="M417.27,301.4l6.851-3.829,16.3,9.128-6.75,3.865Z" transform="translate(-410.419 -279.519)"></path>
              </g>
              <g id="group-123" transform="translate(16.461 4.589)">
                <path id="path-34-2" data-name="path-34" className="relief-4" d="M3688.191,5221.893l15.431,8.4-.208,5.3-15.223-8.493Z" transform="translate(-3687.983 -5221.893)"></path>
                <path id="path-34-3" data-name="path-34" className="relief-4" d="M3690.192,5218.893l6.468,3.556v3.081l-6.468-3.567Z" transform="translate(-3690.192 -5211.994)"></path>
                <path id="path-34-4" data-name="path-34" className="relief-4" d="M3690.192,5217.893l6.677,3.765v3.29l-6.677-3.776Z" transform="translate(-3681.853 -5206.407)"></path>
              </g>
            </g>
            <g id="group-156" transform="translate(2569.756 1459.634)">
              <g id="group-60-3" data-name="group-60" transform="translate(0 0)">
                <path id="path-83" data-name="path" className="relief-3" d="M400.27,276.227l20.94,11.643,13.7-7.911V264.137L414.359,252.78v15.689Z" transform="translate(-400.27 -252.78)"></path>
                <path id="line-3" data-name="line" className="relief-3" d="M0,0,20.553,11.49" transform="translate(14.089 15.689)"></path>
                <path id="path-84-2" data-name="path" className="relief-4" d="M417.27,301.4l6.851-3.829,16.3,9.128-6.75,3.865Z" transform="translate(-410.419 -279.519)"></path>
              </g>
              <path id="path-89" className="relief-18" d="M829.6,8318.419l4.318-9.921,6.952,3.957v12.605Z" transform="translate(-807.855 -8300.291)"></path>
              <g id="group-105" transform="matrix(0.999, 0.035, -0.035, 0.999, 20.912, 10.255)">
                <g id="group-17" data-name="group" transform="translate(0 0)">
                  <g id="group-18" data-name="group" transform="translate(0)">
                    <g id="group-19" data-name="group" transform="translate(1.172 1.077)">
                      <path id="path-85" data-name="path" d="M1.23.026,1.335,0,.1.327,0,.353Z" transform="translate(0 0)"></path>
                    </g>
                    <g id="group-20" data-name="group" transform="translate(0 0.042)">
                      <path id="path-86" data-name="path" className="relief-19" d="M2.121,0,2.055,1.073.825,1.4,1.95,2.88,1.885,3.953,0,1.466.052.55Z" transform="translate(0 0)"></path>
                    </g>
                    <g id="group-21" data-name="group" transform="translate(2.191 0)">
                      <path id="path-87" data-name="path" className="relief-19" d="M2.3.223.458,5.2,0,4.974,1.846,0Z" transform="translate(0)"></path>
                    </g>
                  </g>
                  <g id="group-22" data-name="group" transform="translate(4.34 1.392)">
                    <g id="group-23" data-name="group" transform="translate(0.052 2.185)">
                      <path id="path-88" data-name="path" d="M0,.34.1.314,1.335,0,1.244.026Z" transform="translate(0 0)"></path>
                    </g>
                    <g id="group-24-3" data-name="group" transform="translate(0 3.036)">
                      <path id="path-89-2" data-name="path" d="M2.068.026,2.16,0,.092.55,0,.576Z" transform="translate(0 0)"></path>
                    </g>
                    <g id="group-25" data-name="group" transform="translate(0.186 0)">
                      <path id="path-90" data-name="path" className="relief-19" d="M.223,0l1.9,2.487L2.068,3.4,0,3.953.052,2.867,1.3,2.553.17,1.073Z" transform="translate(0)"></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
            <g id="group-26-3" data-name="group" transform="translate(2531.25 1507.959)">
              <g id="group-27" data-name="group" className="relief-20">
                <g id="group-28" data-name="group" transform="translate(0.039 5.27)">
                  <g id="group-29" data-name="group">
                    <g id="group-30" data-name="group">
                      <path id="path-91" data-name="path" className="relief-4" d="M19.7,82.884l-1.9-1.1L15.106,88.65l1.9,1.1L19.7,82.884m-7.21-13.709c-.179-.1-.359-.2-.538-.3L9.488,75.19l2.13,1.233a2.949,2.949,0,0,1,1.506,3.363l-3.043,7.787L7.545,86.114l3.269-8.341-1.9-1.1L5.649,85.006,3.113,83.547l3.839-9.816h0l2.31-5.907C4.229,66.53.515,69.705.5,76.01.484,82.5,4.385,90.195,9.644,94.673l2.239-5.727h0l3.948-10.1L20.5,81.542a2.96,2.96,0,0,1,1.514,3.371l-2.676,6.835a.955.955,0,0,1-1.443.328l-3.363-1.943-2.466,6.3c.117.07.234.14.359.211,6.6,3.815,11.977.749,11.992-6.835S19.1,72.982,12.492,69.175" transform="translate(-0.499 -67.539)"></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g id="Group_261" data-name="Group 261" transform="translate(-2434 -1016)">
            <path id="path-92" data-name="path" className="relief-2" d="M176.174,406.977V323.7L120,356.165v83.277Z" transform="translate(2613.218 1182.79)"></path>
            <path id="path-93" data-name="path" className="relief-3" d="M1,335.3l49.152,27.674v83.277L1,418.578Z" transform="translate(2683.066 1175.981)"></path>
            <path id="path-94" data-name="path" className="relief-2" d="M106.325,284.374,57.173,256.7,1,289.165l49.152,27.674Z" transform="translate(2683.066 1222.116)"></path>
            <path id="path-95" data-name="path" className="relief-4" d="M189.4,422.3,154,442.415v8.261l35.4-20.28Z" transform="translate(2592.537 1123.106)"></path>
            <path id="path-96" data-name="path" className="relief-4" d="M189.4,466.4,154,486.515v8.261l35.4-20.28Z" transform="translate(2593.261 1099.031)"></path>
            <path id="path-97" data-name="path" className="relief-16" d="M12.1,508.6l35.4,20.115v8.3l-35.4-20.28Z" transform="translate(2676.551 1074.261)"></path>
            <path id="path-98" data-name="path" className="relief-5" d="M106.614,363,50.441,395.424,1,366.883" transform="translate(2683.066 1147.415)"></path>
            <g id="group-31" data-name="group" transform="translate(2684.99 1529.028)">
              <g id="group-32" data-name="group">
                <g id="group-33" data-name="group" className="relief-21">
                  <g id="group-34" data-name="group" transform="translate(0.023 1.334)">
                    <g id="group-35" data-name="group">
                      <g id="group-36" data-name="group">
                        <path id="path-99" data-name="path" className="relief-22" d="M3.027,11.761a4.134,4.134,0,0,1-1.86-3.222c0-1.18.839-1.656,1.86-1.066l3.2,1.838a4.134,4.134,0,0,1,1.86,3.222c0,1.18-.839,1.656-1.86,1.066l-3.2-1.838M6.316,8.2,2.981,6.271C1.393,5.363.1,6.089.1,7.927A6.261,6.261,0,0,0,2.981,12.9l3.358,1.928c1.588.908,2.881.182,2.881-1.656A6.477,6.477,0,0,0,6.316,8.2m8.326,4.81L9.788,10.218l-.023,6.625,1.112.635.023-5.445,3.653,2.11a2.037,2.037,0,0,1,.93,1.611c0,.59-.408.839-.93.545l-3.131-1.815,3.29,5.218,1.611.93L14.1,17.206l.5.295c1.066.613,1.951.113,1.951-1.112a4.218,4.218,0,0,0-1.906-3.38m16.608,9.6-3.97-2.291c-1.588-.908-2.881-.182-2.881,1.656a6.261,6.261,0,0,0,2.881,4.969l3.426,1.974.658-.794L27.371,25.8a4.134,4.134,0,0,1-1.86-3.222c0-1.18.839-1.656,1.883-1.066L30.638,23.4l.613-.794m1.7.976-1.112-.635-.023,5.99a1.338,1.338,0,0,0,.159.545,1.248,1.248,0,0,0,.408.431l5.059,2.9.658-.794-5.15-2.972V23.581m11.775,6.806L40.757,28.1c-1.588-.908-2.881-.182-2.881,1.656a6.26,6.26,0,0,0,2.881,4.969L44.183,36.7l.658-.794L40.8,33.587A4.181,4.181,0,0,1,39.01,31l4.742,2.723.658-.794-5.377-3.108c.227-.771.953-1.021,1.792-.522l3.244,1.883.658-.794M20.791,16.5c-.272-.159-.545-.159-.681.023l-3.517,4.243,1.316.749,2.881-3.562,1.543,3.789-2.949-1.7.658,1.543,2.995,1.724.613,1.52,1.316.749-3.494-8.3a1.889,1.889,0,0,0-.681-.771" transform="translate(-0.1 -5.88)"></path>
                        <path id="path-100" data-name="path" className="relief-22" d="M199.267,116.81v-.25l.045.023a1.394,1.394,0,0,0,.136.091.167.167,0,0,1,.045.136.083.083,0,0,1-.023.068c-.023.023-.068,0-.113-.023l-.091-.045m.045-.34-.182-.113v.839l.136.091v-.318l.091.068.136.408.159.091-.159-.454h.023a.1.1,0,0,0,.091-.091v-.068a.551.551,0,0,0-.136-.318c-.023-.068-.068-.091-.159-.136m.045,1.044a1.115,1.115,0,0,1-.5-.885c0-.318.227-.454.5-.295a1.151,1.151,0,0,1,.5.885c.023.34-.2.476-.5.295m0-1.316c-.363-.2-.658-.045-.658.363a1.452,1.452,0,0,0,.635,1.112c.363.2.658.045.658-.363a1.335,1.335,0,0,0-.635-1.112" transform="translate(-153.642 -91.1)"></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
            <g id="group-258-2" data-name="group-258" transform="translate(2697.411 1482.677)">
              <g id="group-60-4" data-name="group-60">
                <path id="path-101" data-name="path" className="relief-3" d="M400.27,276.227l20.94,11.643,13.7-7.911V264.137L414.359,252.78v15.689Z" transform="translate(-400.27 -252.78)"></path>
                <line id="line-4" data-name="line" className="relief-3" x2="20.553" y2="11.49" transform="translate(14.089 15.689)"></line>
                <path id="path-102" data-name="path" className="relief-4" d="M417.27,301.4l6.851-3.829,16.3,9.128-6.75,3.865Z" transform="translate(-410.419 -279.519)"></path>
              </g>
              <g id="group-37" data-name="group" transform="translate(17.937 3.482)">
                <g id="group-38" data-name="group" className="relief-17">
                  <g id="group-39" data-name="group" transform="translate(0 0.893)">
                    <g id="group-40" data-name="group">
                      <g id="group-41" data-name="group">
                        <path id="path-103" data-name="path" d="M1.656,3.5.1,3.551v.587L1.656,5.974V5.311l-.918-1.1.918-.051V3.5" transform="translate(-0.074 -3.5)"></path>
                        <path id="path-104" data-name="path" d="M43.3,62.6v.689l.918,1.1-.918.026v.663l1.556-.051v-.561L43.3,62.6" transform="translate(-32.255 -47.525)"></path>
                        <path id="path-105" data-name="path" d="M12.65,20.744.1,13.5v.612l12.55,7.27v-.638" transform="translate(-0.074 -10.949)"></path>
                        <path id="path-106" data-name="path" d="M12.65,26.644.1,19.4v.612l12.55,7.244v-.612" transform="translate(-0.074 -15.344)"></path>
                        <path id="path-107" data-name="path" d="M12.65,32.544.1,25.3v.612l12.55,7.244v-.612" transform="translate(-0.074 -19.739)"></path>
                        <path id="path-108" data-name="path" d="M12.575,38.444,0,31.2v.612l12.575,7.244v-.612" transform="translate(0 -24.134)"></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
            <g id="group-256-2" data-name="group-256" transform="translate(2726.735 1467.809)">
              <g id="group-60-5" data-name="group-60" transform="translate(0 0)">
                <path id="path-109" data-name="path" className="relief-3" d="M400.27,276.227l20.94,11.643,13.7-7.911V264.137L414.359,252.78v15.689Z" transform="translate(-400.27 -252.78)"></path>
                <path id="line-5" data-name="line" className="relief-3" d="M0,0,12.846,7.181l7.707,4.309" transform="translate(14.089 15.689)"></path>
                <path id="path-110" data-name="path" className="relief-4" d="M417.27,301.4l6.851-3.829,16.3,9.128-6.75,3.865Z" transform="translate(-410.419 -279.519)"></path>
              </g>
              <g id="group-123-2" data-name="group-123" transform="translate(16.461 4.589)">
                <path id="path-34-5" data-name="path-34" className="relief-4" d="M3688.191,5221.893l15.431,8.4-.208,5.3-15.223-8.493Z" transform="translate(-3687.983 -5221.893)"></path>
                <path id="path-34-6" data-name="path-34" className="relief-4" d="M3690.192,5218.893l6.468,3.556v3.081l-6.468-3.567Z" transform="translate(-3690.192 -5211.994)"></path>
                <path id="path-34-7" data-name="path-34" className="relief-4" d="M3690.192,5217.893l6.677,3.765v3.29l-6.677-3.776Z" transform="translate(-3681.853 -5206.407)"></path>
              </g>
            </g>
            <g id="group-156-2" data-name="group-156" transform="translate(2733.756 1487.634)">
              <g id="group-60-6" data-name="group-60" transform="translate(0 0)">
                <path id="path-111" data-name="path" className="relief-3" d="M400.27,276.227l20.94,11.643,13.7-7.911V264.137L414.359,252.78v15.689Z" transform="translate(-400.27 -252.78)"></path>
                <path id="line-6" data-name="line" className="relief-3" d="M0,0,20.553,11.49" transform="translate(14.089 15.689)"></path>
                <path id="path-112" data-name="path" className="relief-4" d="M417.27,301.4l6.851-3.829,16.3,9.128-6.75,3.865Z" transform="translate(-410.419 -279.519)"></path>
              </g>
              <path id="path-89-3" data-name="path-89" className="relief-18" d="M829.6,8318.419l4.318-9.921,6.952,3.957v12.605Z" transform="translate(-807.855 -8300.291)"></path>
              <g id="group-105-2" data-name="group-105" transform="matrix(0.999, 0.035, -0.035, 0.999, 20.912, 10.255)">
                <g id="group-42" data-name="group" transform="translate(0 0)">
                  <g id="group-43" data-name="group" transform="translate(0)">
                    <g id="group-44" data-name="group" transform="translate(1.172 1.077)">
                      <path id="path-113" data-name="path" d="M1.23.026,1.335,0,.1.327,0,.353Z" transform="translate(0 0)"></path>
                    </g>
                    <g id="group-45" data-name="group" transform="translate(0 0.042)">
                      <path id="path-114" data-name="path" className="relief-19" d="M2.121,0,2.055,1.073.825,1.4,1.95,2.88,1.885,3.953,0,1.466.052.55Z" transform="translate(0 0)"></path>
                    </g>
                    <g id="group-46-6" data-name="group" transform="translate(2.191 0)">
                      <path id="path-115" data-name="path" className="relief-19" d="M2.3.223.458,5.2,0,4.974,1.846,0Z" transform="translate(0)"></path>
                    </g>
                  </g>
                  <g id="group-47-2" data-name="group" transform="translate(4.34 1.392)">
                    <g id="group-48-2" data-name="group" transform="translate(0.052 2.185)">
                      <path id="path-116" data-name="path" d="M0,.34.1.314,1.335,0,1.244.026Z" transform="translate(0 0)"></path>
                    </g>
                    <g id="group-49-2" data-name="group" transform="translate(0 3.036)">
                      <path id="path-117" data-name="path" d="M2.068.026,2.16,0,.092.55,0,.576Z" transform="translate(0 0)"></path>
                    </g>
                    <g id="group-50" data-name="group" transform="translate(0.186 0)">
                      <path id="path-118" data-name="path" className="relief-19" d="M.223,0l1.9,2.487L2.068,3.4,0,3.953.052,2.867,1.3,2.553.17,1.073Z" transform="translate(0)"></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
}
export default MobileImgReliefSvg