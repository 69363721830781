import React,{useState,useEffect} from 'react'
import RightImage from '../assets/images/home-section5/right-image'
import KeepReading from '../assets/images/keep-reading'
import MobileImgReliefSvg from '../assets/images/mobile_img_relief'
const HomeSection5 =({fullpageApi}) =>{ 
    return(
             <div className={`section section5 `} id="section5">
               <div className="left-col-box">
                <div className="text-table relief-section en-text">
                    <h2 className="hdst2">Relief</h2>
                    <p><span className="green_marked line_un_1"><span className="text1">Poland</span></span> <span className="text2">is an extremely fast growing economy. Growth creates</span> <span className="border_underline green_br line_un_2">motivated people</span>, <span className="border_underline green_br line_un_3">opportunities</span>, <span className="border_underline green_br line_un_4">speed</span>, <span className="border_underline green_br line_un_5">accuracy</span> &amp; <span className="border_underline green_br line_un_6">hard working attitudes</span>.</p>
                    <p className="text3">The Polish Universities are rated as one of the best in Europe and every year they are delivering top notch ICT developers. Successful companies like Google, Oracle, IBM, etc. all know this for many years and are all located in Poland for that same reason.</p>
                </div>
                </div>
                <div className="right-col-box">
                <div className="img-section-relief hidden-m">
                <RightImage/>
                </div>
                <div className="img-section-relief visible-m">
                  <MobileImgReliefSvg/>
                </div>
                </div>
                <a to="#" className="get-start-btn-2 en-text" onClick={() => fullpageApi.moveSectionDown()}>
                 {/* <KeepReading/> */}
                 <span>Keep reading</span>
                </a>
        </div>
    )
}
export default HomeSection5;