import React from 'react'
import './style.scss'

const Lastwords =()=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" viewBox="51.99 13347.23 849.021 845.538">
        <defs>
         
          <clipPath id="last-word-clip">
            <use xlink="#fill"></use>
          </clipPath>
          <clipPath id="last-word-clip-2">
            <use xlink="#fill-2"></use>
          </clipPath>
        </defs>
        <g id="group-239" transform="translate(0 13212)">
          <g id="ellipse-23" className="clsltw-1" transform="translate(51.99 135.23)">
            <ellipse className="clsltw-34" cx="424.51" cy="422.769" rx="424.51" ry="422.769"></ellipse>
            <ellipse className="clsltw-35" cx="424.51" cy="422.769" rx="423.01" ry="421.269"></ellipse>
          </g>
          <g id="group-171" transform="translate(239.225 163.104)">
            <line id="line-19" className="clsltw-2" y1="94.185" transform="translate(339.311 66.841)"></line>
            <g id="group-89" transform="translate(113.875 0)">
              <g id="group-88" transform="translate(0 0)">
                <path id="path-63" className="clsltw-3" d="M169.5,12473.944v-93.85l112.138-66.526,113.2,66.526v93.85l-113.2-66.7Z" transform="translate(-168.892 -12312.919)"></path>
                <path id="path" className="clsltw-4" d="M112.736,303.014,224.8,238.774,113.2,171.7,1,238.379Z" transform="translate(0.36 -77.244)"></path>
                <line id="line-18" className="clsltw-2" y1="94.185" transform="translate(113.022)"></line>
                <line id="line-19-2" data-name="line-19" className="clsltw-2" y1="94.185" transform="translate(0.608 66.841)"></line>
                <line id="line-20" className="clsltw-2" y1="68.056" x2="113.022"></line>
                <line id="line-21" className="clsltw-2" x1="114.845" y1="66.841" transform="translate(111.806)"></line>
              </g>
              <g id="group-87" transform="translate(61.194 76.664)">
                <g id="group-64" transform="translate(0 0)">
                  <g id="group" transform="translate(0 4.609)">
                    <path id="path-2" data-name="path" className="clsltw-5" d="M309.956,157.6,268.9,181.328l71.848,41.478L381.8,199.078Z" transform="translate(-268.598 -157.563)"></path>
                    <line id="line" className="clsltw-6" y2="41.78" transform="translate(72.15 65.244)"></line>
                    <path id="line-2" data-name="line" className="clsltw-6" d="M0,0V42.626" transform="translate(113.717 41.516)"></path>
                    <path id="path-3" data-name="path" className="clsltw-7" d="M338.1,217.1" transform="translate(-296.018 -181.139)"></path>
                    <path id="path-4" data-name="path" className="clsltw-8" d="M343.1,270.005s.06-15.532,0-18.43a6.453,6.453,0,0,0-2.536-4.649,2.628,2.628,0,0,0-.423-.362c-1.751-1.208-18.415-10.264-18.415-10.264l-.617,24.067,14.939-9.011,4.374,2.047-4.586,10.228.984,12.516s-.362,3.079,2.475,3.079l12.275-6.918S352.762,265.658,343.1,270.005Z" transform="translate(-289.186 -188.747)"></path>
                    <line id="line-3" data-name="line" className="clsltw-6" y2="23.667" transform="translate(30.792 83.598)"></line>
                    <line id="line-4" data-name="line" className="clsltw-6" x1="0.06" y2="23.788" transform="translate(0 65.727)"></line>
                    <line id="line-5" data-name="line" className="clsltw-8" y2="23.426" transform="translate(51.38 71.945)"></line>
                    <g id="group-60" transform="translate(38.924 0)">
                      <path id="path-5" data-name="path" className="clsltw-9" d="M400.27,278.277l22.771,12.661,14.9-8.6V265.13l-22.35-12.35v17.061Z" transform="translate(-400.27 -252.78)"></path>
                      <line id="line-6" data-name="line" className="clsltw-10" x2="22.35" y2="12.494" transform="translate(15.321 17.061)"></line>
                      <path id="path-6" data-name="path" className="clsltw-11" d="M417.27,301.733l7.45-4.163,17.727,9.926-7.341,4.2Z" transform="translate(-409.82 -277.941)"></path>
                    </g>
                    <path id="path-7" data-name="path" className="clsltw-8" d="M298.128,182.507c6.1-.483,8.016-5.555,8.016-11.773,0-6.521-2.461-11.834-8.982-11.834a11.828,11.828,0,0,0-8.211,20.347,7.649,7.649,0,0,0,.785.664,10.01,10.01,0,0,1-1.691.3,7.266,7.266,0,0,0-7.245,7.245V222.6a7.266,7.266,0,0,0,7.245,7.245h15.4c3.985,0,4.83-3.26,4.83-7.245V207.02c3.321,1.328,7.426-.845,7.426-.845l13.1-7.909a2.885,2.885,0,0,0,1.691-.6l2.717-2.053c1.328-1.026,2.355-2.657,1.389-4.045-1.026-1.328-2.294-1.087-3.622-.06l-4.166,1.268a3.593,3.593,0,0,0-1.026,1.328h-.06l-11.23,5.494c-3.924,1.63-5.615-1.147-6.339-3.623a14.727,14.727,0,0,0-.543-3.26h0c-.725-2.536-2.053-4.77-4.226-5.736l-3.864-2.536C299.577,184.318,297.162,182.93,298.128,182.507Z" transform="translate(-273.313 -158.078)"></path>
                    <g id="group-2" data-name="group" transform="translate(0.06 23.886)">
                      <path id="path-8" data-name="path" className="clsltw-12" d="M319.5,283.834,340.028,272.3v-6.7l-20.166,11.834" transform="translate(-288.708 -224.242)"></path>
                      <path id="path-9" data-name="path" className="clsltw-5" d="M268.5,238.941V197.1l7.185,4.045L300.2,215.092l-.906,41.6Z" transform="translate(-268.5 -197.1)"></path>
                    </g>
                    <path id="path-10" data-name="path" className="clsltw-13" d="M325.512,249s.587,3.788,3.968,3.064-3,9.253-3,9.253l-1.917-2.927Z" transform="translate(-291.428 -193.779)"></path>
                    <path id="path-11" data-name="path" className="clsltw-13" d="M321.025,272.461l2.906-1.722L325.1,267.1h-3.14" transform="translate(-289.116 -201.462)"></path>
                    <path id="path-44" className="clsltw-11" d="M54.338,99.091l-.607,9.563,15.236-9.046,4.665,2.241v7.679l-4.665,2.114,1.021,10.388,6.175-3.456V99.608L75.1,96.386,64.39,89.574l-7.141,2.093" transform="translate(-22.279 -37.104)"></path>
                  </g>
                  <path id="path-38" className="clsltw-11" d="M533.631,8209.686c5.451-2.42,10.229-3.88,8.206-4.974s.776-6.524.776-6.524,6.14-.081,3.941-2.266,3.558-4.285,3.558-4.285a15.067,15.067,0,0,0-9.4-7.823c-7.029-2.095-9.395,3.952-13.215,5.022s-1.766,12.446-1.766,12.446S528.18,8212.1,533.631,8209.686Z" transform="translate(-514.518 -8183.376)"></path>
                  <path id="path-39" className="clsltw-14" d="M534.259,8236.49s3.8,4.8,9.883,3.553" transform="translate(-518.951 -8209.83)"></path>
                  <path id="path-42" className="clsltw-11" d="M608.329,8350.8l-.444,3.9,2.186-4.693Z" transform="translate(-554.909 -8264.757)"></path>
                  <path id="path-43" className="clsltw-11" d="M572.6,8288.334s-.065,8.244.537,8.66,7.156-3.759,8.305-3.669S572.6,8288.334,572.6,8288.334Z" transform="translate(-537.673 -8234.637)"></path>
                </g>
                <path id="path-45" className="clsltw-14" d="M152.529,8102.924a2.721,2.721,0,0,0-1.169,3.8" transform="translate(-122.243 -8086.904)"></path>
              </g>
            </g>
            <g id="group-89-2" data-name="group-89" transform="translate(227.505 158.595)">
              <g id="group-88-2" data-name="group-88" transform="translate(0 0)">
                <path id="path-63-2" data-name="path-63" className="clsltw-15" d="M169.5,12473.944v-93.85l112.138-66.526,113.2,66.526v93.85l-113.2-66.7Z" transform="translate(-168.892 -12312.919)"></path>
                <path id="path-12" data-name="path" className="clsltw-15" d="M112.736,303.014,224.8,238.774,113.2,171.7,1,238.379Z" transform="translate(0.36 -77.244)"></path>
                <line id="line-18-2" data-name="line-18" className="clsltw-2" y1="94.185" transform="translate(113.022)"></line>
                <line id="line-19-3" data-name="line-19" className="clsltw-2" y1="94.185" transform="translate(0.608 66.841)"></line>
                <line id="line-20-2" data-name="line-20" className="clsltw-2" y1="68.056" x2="113.022"></line>
                <line id="line-21-2" data-name="line-21" className="clsltw-2" x1="114.845" y1="66.841" transform="translate(111.806)"></line>
              </g>
              <g id="group-87-2" data-name="group-87" transform="translate(61.194 75.534)">
                <g id="group-64-2" data-name="group-64" transform="translate(0 0)">
                  <g id="group-3" data-name="group" transform="translate(0 5.739)">
                    <path id="path-13" data-name="path" className="clsltw-5" d="M309.956,157.6,268.9,181.328l71.848,41.478L381.8,199.078Z" transform="translate(-268.598 -157.563)"></path>
                    <line id="line-7" data-name="line" className="clsltw-6" y2="41.78" transform="translate(72.15 65.244)"></line>
                    <path id="line-8" data-name="line" className="clsltw-6" d="M0,0V42.626" transform="translate(113.717 41.516)"></path>
                    <path id="path-14" data-name="path" className="clsltw-7" d="M338.1,217.1" transform="translate(-296.018 -181.139)"></path>
                    <path id="path-15" data-name="path" className="clsltw-8" d="M343.1,270.005s.06-15.532,0-18.43a6.453,6.453,0,0,0-2.536-4.649,2.628,2.628,0,0,0-.423-.362c-1.751-1.208-18.415-10.264-18.415-10.264l-.617,24.067,14.939-9.011,4.374,2.047-4.586,10.228.984,12.516s-.362,3.079,2.475,3.079l12.275-6.918S352.762,265.658,343.1,270.005Z" transform="translate(-289.186 -188.747)"></path>
                    <line id="line-9" data-name="line" className="clsltw-6" y2="23.667" transform="translate(30.792 83.598)"></line>
                    <line id="line-10" data-name="line" className="clsltw-6" x1="0.06" y2="23.788" transform="translate(0 65.727)"></line>
                    <line id="line-11" data-name="line" className="clsltw-8" y2="23.426" transform="translate(51.38 71.945)"></line>
                    <g id="group-60-2" data-name="group-60" transform="translate(38.924 0)">
                      <path id="path-16" data-name="path" className="clsltw-9" d="M400.27,278.277l22.771,12.661,14.9-8.6V265.13l-22.35-12.35v17.061Z" transform="translate(-400.27 -252.78)"></path>
                      <line id="line-12" data-name="line" className="clsltw-10" x2="22.35" y2="12.494" transform="translate(15.321 17.061)"></line>
                      <path id="path-17" data-name="path" className="clsltw-11" d="M417.27,301.733l7.45-4.163,17.727,9.926-7.341,4.2Z" transform="translate(-409.82 -277.941)"></path>
                    </g>
                    <path id="path-18" data-name="path" className="clsltw-8" d="M298.128,182.507c6.1-.483,8.016-5.555,8.016-11.773,0-6.521-2.461-11.834-8.982-11.834a11.828,11.828,0,0,0-8.211,20.347,7.649,7.649,0,0,0,.785.664,10.01,10.01,0,0,1-1.691.3,7.266,7.266,0,0,0-7.245,7.245V222.6a7.266,7.266,0,0,0,7.245,7.245h15.4c3.985,0,4.83-3.26,4.83-7.245V207.02c3.321,1.328,7.426-.845,7.426-.845l13.1-7.909a2.885,2.885,0,0,0,1.691-.6l2.717-2.053c1.328-1.026,2.355-2.657,1.389-4.045-1.026-1.328-2.294-1.087-3.622-.06l-4.166,1.268a3.593,3.593,0,0,0-1.026,1.328h-.06l-11.23,5.494c-3.924,1.63-5.615-1.147-6.339-3.623a14.727,14.727,0,0,0-.543-3.26h0c-.725-2.536-2.053-4.77-4.226-5.736l-3.864-2.536C299.577,184.318,297.162,182.93,298.128,182.507Z" transform="translate(-273.313 -158.078)"></path>
                    <g id="group-4" data-name="group" transform="translate(0.06 23.886)">
                      <path id="path-19" data-name="path" className="clsltw-12" d="M319.5,283.834,340.028,272.3v-6.7l-20.166,11.834" transform="translate(-288.708 -224.242)"></path>
                      <path id="path-20" data-name="path" className="clsltw-5" d="M268.5,238.941V197.1l7.185,4.045L300.2,215.092l-.906,41.6Z" transform="translate(-268.5 -197.1)"></path>
                    </g>
                    <path id="path-21" data-name="path" className="clsltw-13" d="M325.512,249s.587,3.788,3.968,3.064-3,9.253-3,9.253l-1.917-2.927Z" transform="translate(-291.428 -193.779)"></path>
                    <path id="path-22" data-name="path" className="clsltw-13" d="M321.025,272.461l2.906-1.722L325.1,267.1h-3.14" transform="translate(-289.116 -201.462)"></path>
                    <path id="path-44-2" data-name="path-44" className="clsltw-11" d="M54.338,99.091l-.607,9.563,15.236-9.046,4.665,2.241v7.679l-4.665,2.114,1.021,10.388,6.175-3.456V99.608L75.1,96.386,64.39,89.574l-7.141,2.093" transform="translate(-22.279 -37.104)"></path>
                  </g>
                  <path id="path-39-2" data-name="path-39" className="clsltw-14" d="M534.259,8236.49s3.8,4.8,9.883,3.553" transform="translate(-518.951 -8208.699)"></path>
                  <path id="path-42-2" data-name="path-42" className="clsltw-11" d="M608.329,8350.8l-.444,3.9,2.186-4.693Z" transform="translate(-554.909 -8263.626)"></path>
                  <path id="path-43-2" data-name="path-43" className="clsltw-11" d="M572.6,8288.334s-.065,8.244.537,8.66,7.156-3.759,8.305-3.669S572.6,8288.334,572.6,8288.334Z" transform="translate(-537.673 -8233.507)"></path>
                  <path id="path-46" className="clsltw-11" d="M325.745,8324.194c-1.945-.65-3.311-8.326-2.048-9.058s-.551-7.479,2.936-6.938,2.467-5.778,5.443-4.865,4.963-1.643,7.513,0,7.633,2.369,7.093,4.865,3.527,6.849-.476,6.294-9.528,6.562-7.587,7.405,1.956,5.6,0,5.6-3.515,1.435-4.62,2.647-2.1.325-4.3-1.135S327.691,8324.845,325.745,8324.194Z" transform="translate(-315.292 -8302.769)"></path>
                </g>
                <path id="path-45-2" data-name="path-45" className="clsltw-14" d="M152.529,8102.924a2.721,2.721,0,0,0-1.169,3.8" transform="translate(-122.243 -8085.774)"></path>
              </g>
            </g>
            <g id="group-17" transform="translate(381.016 190.81)">
              <path id="path-10-2" data-name="path-10" className="clsltw-3" d="M0,12.9A7.223,7.223,0,0,1,.4,10.53l-.016-.008.087-.188A7.2,7.2,0,0,1,.9,9.412L4.364,1.935A2.7,2.7,0,0,1,6.7,0h.8A2.824,2.824,0,0,1,9.946,2.247l3.938,7.763-.018,0A7.234,7.234,0,1,1,0,12.9Z" transform="translate(0 39.605)"></path>
              <g id="rectangle-6" className="clsltw-16" transform="translate(4.822 36.168)">
                <rect className="clsltw-34" width="4.822" height="5.626" rx="2.411"></rect>
                <rect className="clsltw-35" x="1.5" y="1.5" width="1.822" height="2.626" rx="0.911"></rect>
              </g>
              <g id="rectangle-7" className="clsltw-14" transform="translate(6.43 0)">
                <rect id="fill" className="clsltw-34" width="1.607" height="39.115" rx="0.804"></rect>
                <path className="clsltw-36" d="M0,1h1.6074522733688354M1,0v39.114540100097656M1.6074522733688354,38.114540100097656h-1.6074522733688354M0.6074522733688354,39.114540100097656v-39.114540100097656"></path>
              </g>
            </g>
            <g id="group-89-3" data-name="group-89" transform="translate(0 159.81)">
              <g id="group-88-3" data-name="group-88" transform="translate(0 0)">
                <path id="path-63-3" data-name="path-63" className="clsltw-11" d="M169.5,12473.944v-93.85l112.138-66.526,113.2,66.526v93.85l-113.2-66.7Z" transform="translate(-168.892 -12312.919)"></path>
                <path id="path-23" data-name="path" className="clsltw-17" d="M112.736,303.014,224.8,238.774,113.2,171.7,1,238.379Z" transform="translate(0.36 -77.244)"></path>
                <line id="line-18-3" data-name="line-18" className="clsltw-2" y1="94.185" transform="translate(113.022)"></line>
                <line id="line-19-4" data-name="line-19" className="clsltw-2" y1="94.185" transform="translate(0.608 66.841)"></line>
                <line id="line-20-3" data-name="line-20" className="clsltw-2" y1="68.056" x2="113.022"></line>
                <line id="line-21-3" data-name="line-21" className="clsltw-2" x1="114.845" y1="66.841" transform="translate(111.806)"></line>
              </g>
              <g id="group-87-3" data-name="group-87" transform="translate(61.194 76.664)">
                <g id="group-64-3" data-name="group-64" transform="translate(0 0)">
                  <g id="group-5" data-name="group" transform="translate(0 4.609)">
                    <path id="path-24" data-name="path" className="clsltw-5" d="M309.956,157.6,268.9,181.328l71.848,41.478L381.8,199.078Z" transform="translate(-268.598 -157.563)"></path>
                    <line id="line-13" data-name="line" className="clsltw-18" y2="41.78" transform="translate(72.15 65.244)"></line>
                    <path id="line-14" data-name="line" className="clsltw-18" d="M0,0V42.626" transform="translate(112.256 42.246)"></path>
                    <path id="path-25" data-name="path" className="clsltw-7" d="M338.1,217.1" transform="translate(-296.018 -181.139)"></path>
                    <path id="path-26" data-name="path" className="clsltw-19" d="M343.1,270.005s.06-15.532,0-18.43a6.453,6.453,0,0,0-2.536-4.649,2.628,2.628,0,0,0-.423-.362c-1.751-1.208-18.415-10.264-18.415-10.264l-.617,24.067,14.939-9.011,4.374,2.047-4.586,10.228.984,12.516s-.362,3.079,2.475,3.079l12.275-6.918S352.762,265.658,343.1,270.005Z" transform="translate(-289.186 -188.747)"></path>
                    <line id="line-15" data-name="line" className="clsltw-18" y2="23.667" transform="translate(30.792 83.598)"></line>
                    <line id="line-16" data-name="line" className="clsltw-18" x1="0.06" y2="23.788" transform="translate(0 65.727)"></line>
                    <line id="line-17" data-name="line" className="clsltw-6" y2="23.426" transform="translate(51.38 71.945)"></line>
                    <g id="group-60-3" data-name="group-60" transform="translate(38.924 0)">
                      <path data-name="path" className="clsltw-20" d="M400.27,278.277l22.771,12.661,14.9-8.6V265.13l-22.35-12.35v17.061Z" transform="translate(-400.27 -252.78)"></path>
                      <line id="line-18-4" data-name="line" className="clsltw-10" x2="22.35" y2="12.494" transform="translate(15.321 17.061)"></line>
                      <path id="path-28" data-name="path" className="clsltw-11" d="M417.27,301.733l7.45-4.163,17.727,9.926-7.341,4.2Z" transform="translate(-409.82 -277.941)"></path>
                    </g>
                    <path id="path-29" data-name="path" className="clsltw-8" d="M298.128,182.507c6.1-.483,8.016-5.555,8.016-11.773,0-6.521-2.461-11.834-8.982-11.834a11.828,11.828,0,0,0-8.211,20.347,7.649,7.649,0,0,0,.785.664,10.01,10.01,0,0,1-1.691.3,7.266,7.266,0,0,0-7.245,7.245V222.6a7.266,7.266,0,0,0,7.245,7.245h15.4c3.985,0,4.83-3.26,4.83-7.245V207.02c3.321,1.328,7.426-.845,7.426-.845l13.1-7.909a2.885,2.885,0,0,0,1.691-.6l2.717-2.053c1.328-1.026,2.355-2.657,1.389-4.045-1.026-1.328-2.294-1.087-3.622-.06l-4.166,1.268a3.593,3.593,0,0,0-1.026,1.328h-.06l-11.23,5.494c-3.924,1.63-5.615-1.147-6.339-3.623a14.727,14.727,0,0,0-.543-3.26h0c-.725-2.536-2.053-4.77-4.226-5.736l-3.864-2.536C299.577,184.318,297.162,182.93,298.128,182.507Z" transform="translate(-273.313 -158.078)"></path>
                    <g id="group-6" data-name="group" transform="translate(0.06 23.886)">
                      <path id="path-30" data-name="path" className="clsltw-12" d="M319.5,283.834,340.028,272.3v-6.7l-20.166,11.834" transform="translate(-288.708 -224.242)"></path>
                      <path id="path-31" data-name="path" className="clsltw-5" d="M268.5,238.941V197.1l7.185,4.045L300.2,215.092l-.906,41.6Z" transform="translate(-268.5 -197.1)"></path>
                    </g>
                    <path id="path-32" data-name="path" className="clsltw-13" d="M325.512,249s.587,3.788,3.968,3.064-3,9.253-3,9.253l-1.917-2.927Z" transform="translate(-291.428 -193.779)"></path>
                    <path id="path-33" data-name="path" className="clsltw-13" d="M321.025,272.461l2.906-1.722L325.1,267.1h-3.14" transform="translate(-289.116 -201.462)"></path>
                    <path id="path-44-3" data-name="path-44" className="clsltw-11" d="M54.338,99.091l-.607,9.563,15.236-9.046,4.665,2.241v7.679l-4.665,2.114,1.021,10.388,6.175-3.456V99.608L75.1,96.386,64.39,89.574l-7.141,2.093" transform="translate(-22.279 -37.104)"></path>
                  </g>
                  <path id="path-38-2" data-name="path-38" className="clsltw-21" d="M533.631,8209.686c5.451-2.42,10.229-3.88,8.206-4.974s.776-6.524.776-6.524,6.14-.081,3.941-2.266,3.558-4.285,3.558-4.285a15.067,15.067,0,0,0-9.4-7.823c-7.029-2.095-9.395,3.952-13.215,5.022s-1.766,12.446-1.766,12.446S528.18,8212.1,533.631,8209.686Z" transform="translate(-514.518 -8183.376)"></path>
                  <path id="path-39-3" data-name="path-39" className="clsltw-14" d="M534.259,8236.49s3.8,4.8,9.883,3.553" transform="translate(-518.951 -8209.83)"></path>
                  <path id="path-42-3" data-name="path-42" className="clsltw-11" d="M608.329,8350.8l-.444,3.9,2.186-4.693Z" transform="translate(-554.909 -8264.757)"></path>
                  <path id="path-43-3" data-name="path-43" className="clsltw-11" d="M572.6,8288.334s-.065,8.244.537,8.66,7.156-3.759,8.305-3.669S572.6,8288.334,572.6,8288.334Z" transform="translate(-537.673 -8234.637)"></path>
                </g>
                <path id="path-45-3" data-name="path-45" className="clsltw-14" d="M152.529,8102.924a2.721,2.721,0,0,0-1.169,3.8" transform="translate(-122.243 -8086.904)"></path>
              </g>
            </g>
            <g id="group-90" transform="translate(144.561 26.432)">
              <g id="group-46" transform="translate(0 0)">
                <g id="ellipse-9" className="clsltw-8">
                  <ellipse className="clsltw-34" cx="20.66" cy="20.66" rx="20.66" ry="20.66"></ellipse>
                  <ellipse className="clsltw-35" cx="20.66" cy="20.66" rx="19.66" ry="19.66"></ellipse>
                </g>
                <g id="ellipse-9-2" data-name="ellipse-9" className="clsltw-8" transform="translate(37.674 37.674)">
                  <ellipse className="clsltw-34" cx="4.861" cy="4.861" rx="4.861" ry="4.861"></ellipse>
                  <ellipse className="clsltw-35" cx="4.861" cy="4.861" rx="3.861" ry="3.861"></ellipse>
                </g>
                <g id="group-44" transform="translate(15.191 17.014)">
                  <ellipse id="ellipse-10" className="clsltw-11" cx="1.367" cy="1.367" rx="1.367" ry="1.367"></ellipse>
                  <ellipse id="ellipse-10-2" data-name="ellipse-10" className="clsltw-11" cx="1.367" cy="1.367" rx="1.367" ry="1.367" transform="translate(8.203)"></ellipse>
                </g>
              </g>
              <path id="path-64" className="clsltw-14" d="M245.93,12355s4.278,4.665,8.279,0" transform="translate(-229.566 -12331.3)"></path>
            </g>
            <path id="path-65" className="clsltw-22" d="M398.661,12626.021v33.162l21.833-13v-33.611Z" transform="translate(-152.326 -12387.904)"></path>
            <g id="group-129" transform="translate(345.102 245.074)">
              <g id="group-7" data-name="group" transform="translate(0 2.852)">
                <g id="group-8" data-name="group">
                  <g id="group-9" data-name="group">
                    <path id="path-34" data-name="path" className="clsltw-13" d="M2.245,7.771A2.025,2.025,0,0,1,1.592,7L2.9,7.746a.546.546,0,0,1-.653.024M1.592,6.247c-.024-.024-.073-.048-.1-.1-.121-.121-.169-.29-.1-.387a.126.126,0,0,1,.193-.024l.1.1a.414.414,0,0,1,.1.387.146.146,0,0,1-.193.024M2.874,7c-.024-.024-.073-.048-.1-.1a.414.414,0,0,1-.1-.387.126.126,0,0,1,.193-.024c.024.024.073.048.1.1a.414.414,0,0,1,.1.387A.142.142,0,0,1,2.874,7M2.221,4.748a1.134,1.134,0,0,0-.532-.218C.795,4.362.311,5.184.6,6.392a4.247,4.247,0,0,0,.653,1.378C.5,7.65-.027,8.133-.1,9.028l4.594,2.659a4.622,4.622,0,0,0-1.33-2.8c.629-.121.919-.87.677-1.862a3.659,3.659,0,0,0-1.62-2.273" transform="translate(0.1 -4.509)"></path>
                  </g>
                </g>
              </g>
              <line id="line-28" className="clsltw-14" y2="15.982" transform="translate(6.345 0)"></line>
              <g id="group-126" transform="translate(7.727 4.828)">
                <g id="group-10" data-name="group" transform="translate(0 0)">
                  <g id="group-11" data-name="group">
                    <g id="group-12" data-name="group">
                      <g id="group-13" data-name="group">
                        <path id="path-35" data-name="path" className="clsltw-23" d="M3.448,4.606,8.04,5.865,3.448,3.217c-.352-.2-.648-.037-.648.37A1.413,1.413,0,0,0,3.429,4.7L8.021,7.346c.352.2.648.037.648-.37A1.365,1.365,0,0,0,8.04,5.865" transform="translate(-2.356 -3.131)"></path>
                        <path id="path-36" data-name="path" className="clsltw-13" d="M24.065,25.7l-4.592-2.648c-.315-.185-.574-.037-.574.333a1.22,1.22,0,0,0,.574.981l4.592,2.648c.315.185.574.037.574-.333a1.248,1.248,0,0,0-.574-.981" transform="translate(-15.475 -18.614)"></path>
                        <path id="path-37" data-name="path" className="clsltw-23" d="M5.565,24.9.974,22.249C.659,22.064.4,22.212.4,22.582a1.22,1.22,0,0,0,.574.981l4.592,2.648c.315.185.574.037.574-.333a1.277,1.277,0,0,0-.574-.981" transform="translate(-0.4 -16.939)"></path>
                        <path id="path-38-3" data-name="path" className="clsltw-13" d="M25.765,46.4l-4.592-2.648c-.315-.185-.574-.037-.574.333a1.282,1.282,0,0,0,.574,1l4.592,2.648c.315.185.574.037.574-.333a1.282,1.282,0,0,0-.574-1" transform="translate(-16.86 -34.118)"></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
            <path id="path-65-2" data-name="path-65" className="clsltw-14" d="M398.661,12626.021v33.162l21.833-13v-33.611Z" transform="translate(-117.256 -12411.283)"></path>
            <line id="line-22" className="clsltw-14" y1="8.767" x2="13.882" transform="translate(285.671 212.927)"></line>
            <line id="line-22-2" data-name="line-22" className="clsltw-14" y1="8.767" x2="13.882" transform="translate(285.671 218.042)"></line>
            <line id="line-22-3" data-name="line-22" className="clsltw-14" y1="8.767" x2="13.882" transform="translate(285.671 223.155)"></line>
            <path id="path-65-3" data-name="path-65" className="clsltw-22" d="M398.661,12616.768v10.335l6.806-4.049v-10.478Z" transform="translate(-147.004 -12374.788)"></path>
            <line id="line-23" className="clsltw-22" y1="7.306" x2="12.42" transform="translate(251.332 250.188)"></line>
            <line id="line-23-2" data-name="line-23" className="clsltw-22" y1="7.306" x2="12.42" transform="translate(251.332 253.842)"></line>
            <path id="path-66" className="clsltw-24" d="M387.091,12398.8v-49.943l82.068,47.546v50.786Z" transform="translate(-145.556 -12320.516)"></path>
            <path id="path-66-2" data-name="path-66" className="clsltw-14" d="M387.091,12361v-12.146l19.955,11.563v12.347Z" transform="translate(-136.789 -12304.134)"></path>
            <path id="path-66-3" data-name="path-66" className="clsltw-14" d="M387.091,12361v-12.146l19.955,11.563v12.347Z" transform="translate(-111.512 -12289.197)"></path>
            <line id="line-25" className="clsltw-14" x2="55.527" y2="32.878" transform="translate(249.14 67.535)"></line>
            <line id="line-25-2" data-name="line-25" className="clsltw-14" x2="55.527" y2="32.878" transform="translate(249.14 72.649)"></line>
            <path id="path-69" className="clsltw-13" d="M535.623,12658.538v-9.224l15.344,8.416v9.764Z" transform="translate(-417.173 -12402.914)"></path>
            <g id="ellipse-19" className="clsltw-9" transform="translate(311.545 247.196)">
              <ellipse className="clsltw-34" cx="4.415" cy="4.967" rx="4.415" ry="4.967"></ellipse>
              <ellipse className="clsltw-35" cx="4.415" cy="4.967" rx="3.415" ry="3.967"></ellipse>
            </g>
            <path id="path-85" className="clsltw-9" d="M547.29,8419.6c-8.6,2.479,2.59-3.347,2.59-3.347s8.878-2.741,12.682,11.91-3.751.411-3.7,1.468S555.889,8417.116,547.29,8419.6Z" transform="translate(-245.718 -8180.771)"></path>
            <g id="group-129-2" data-name="group-129" transform="translate(231.857 87.262)">
              <g id="group-14" data-name="group" transform="translate(0 2.852)">
                <g id="group-15" data-name="group">
                  <g id="group-16" data-name="group">
                    <path id="path-39-4" data-name="path" className="clsltw-13" d="M2.245,7.771A2.025,2.025,0,0,1,1.592,7L2.9,7.746a.546.546,0,0,1-.653.024M1.592,6.247c-.024-.024-.073-.048-.1-.1-.121-.121-.169-.29-.1-.387a.126.126,0,0,1,.193-.024l.1.1a.414.414,0,0,1,.1.387.146.146,0,0,1-.193.024M2.874,7c-.024-.024-.073-.048-.1-.1a.414.414,0,0,1-.1-.387.126.126,0,0,1,.193-.024c.024.024.073.048.1.1a.414.414,0,0,1,.1.387A.142.142,0,0,1,2.874,7M2.221,4.748a1.134,1.134,0,0,0-.532-.218C.795,4.362.311,5.184.6,6.392a4.247,4.247,0,0,0,.653,1.378C.5,7.65-.027,8.133-.1,9.028l4.594,2.659a4.622,4.622,0,0,0-1.33-2.8c.629-.121.919-.87.677-1.862a3.659,3.659,0,0,0-1.62-2.273" transform="translate(0.1 -4.509)"></path>
                  </g>
                </g>
              </g>
              <line id="line-28-2" data-name="line-28" className="clsltw-14" y2="15.982" transform="translate(6.345 0)"></line>
              <g id="group-126-2" data-name="group-126" transform="translate(7.727 4.828)">
                <g id="group-17-2" data-name="group" transform="translate(0 0)">
                  <g id="group-18" data-name="group">
                    <g id="group-19" data-name="group">
                      <g id="group-20" data-name="group">
                        <path id="path-40" data-name="path" className="clsltw-23" d="M3.448,4.606,8.04,5.865,3.448,3.217c-.352-.2-.648-.037-.648.37A1.413,1.413,0,0,0,3.429,4.7L8.021,7.346c.352.2.648.037.648-.37A1.365,1.365,0,0,0,8.04,5.865" transform="translate(-2.356 -3.131)"></path>
                        <path id="path-41" data-name="path" className="clsltw-13" d="M24.065,25.7l-4.592-2.648c-.315-.185-.574-.037-.574.333a1.22,1.22,0,0,0,.574.981l4.592,2.648c.315.185.574.037.574-.333a1.248,1.248,0,0,0-.574-.981" transform="translate(-15.475 -18.614)"></path>
                        <path id="path-42-4" data-name="path" className="clsltw-23" d="M5.565,24.9.974,22.249C.659,22.064.4,22.212.4,22.582a1.22,1.22,0,0,0,.574.981l4.592,2.648c.315.185.574.037.574-.333a1.277,1.277,0,0,0-.574-.981" transform="translate(-0.4 -16.939)"></path>
                        <path id="path-43-4" data-name="path" className="clsltw-13" d="M25.765,46.4l-4.592-2.648c-.315-.185-.574-.037-.574.333a1.282,1.282,0,0,0,.574,1l4.592,2.648c.315.185.574.037.574-.333a1.282,1.282,0,0,0-.574-1" transform="translate(-16.86 -34.118)"></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
            <path id="path-84" className="clsltw-13" d="M0,0,2.082,23.363l9.344,2.593,9.369-2.6L22.883,0ZM18.542,5.546,18.41,7l-.057.644H7.388l.265,2.934H18.094l-.069.77-.675,7.539-.044.486L11.445,21h0L11.432,21,5.565,19.375l-.4-4.5H8.038l.2,2.284,3.192.864h0l3.192-.864.334-3.716H5.028l-.7-7.9-.069-.77H18.612Z" transform="translate(195.44 39.406)"></path>
            <g id="group-164" transform="translate(24.7 179.682) rotate(13)">
              <g id="group-46-2" data-name="group-46" transform="matrix(0.891, -0.454, 0.454, 0.891, 0, 21.184)">
                <g id="ellipse-9-3" data-name="ellipse-9" className="clsltw-8" transform="translate(0 0)">
                  <ellipse className="clsltw-34" cx="20.339" cy="20.339" rx="20.339" ry="20.339"></ellipse>
                  <ellipse className="clsltw-35" cx="20.339" cy="20.339" rx="19.339" ry="19.339"></ellipse>
                </g>
                <g id="ellipse-9-4" data-name="ellipse-9" className="clsltw-8" transform="translate(37.089 37.089)">
                  <ellipse className="clsltw-34" cx="4.786" cy="4.786" rx="4.786" ry="4.786"></ellipse>
                  <ellipse className="clsltw-35" cx="4.786" cy="4.786" rx="3.786" ry="3.786"></ellipse>
                </g>
              </g>
            </g>
          </g>
          
          <g id="group-17-3" data-name="group-17" transform="translate(1229.193 514.502) rotate(180)">
            <path id="path-10-3" data-name="path-10" className="clsltw-29" d="M0,12.15A6.8,6.8,0,0,1,.376,9.914L.361,9.906l.083-.178a6.78,6.78,0,0,1,.4-.866L4.112,1.815A2.539,2.539,0,0,1,6.306,0h.756a2.658,2.658,0,0,1,2.3,2.114l3.708,7.311-.017,0A6.811,6.811,0,1,1,0,12.15Z" transform="translate(939 127.116)"></path>
            <rect id="rectangle-6-2" data-name="rectangle-6" className="clsltw-30" width="4.54" height="5.297" rx="2.27" transform="translate(943.54 123.88)"></rect>
            <rect id="rectangle-7-2" data-name="rectangle-7" className="clsltw-30" width="1.513" height="3.654" rx="0.757" transform="translate(945.054 123)"></rect>
          </g>
          <g id="group-172" transform="translate(241.306 531.361)">
            <g id="group-89-4" data-name="group-89" transform="translate(227.505 159.447)">
              <g id="group-88-4" data-name="group-88" transform="translate(0 0)">
                <path id="path-63-4" data-name="path-63" className="clsltw-31" d="M169.5,12473.944v-93.85l112.138-66.526,113.2,66.526v93.85l-113.2-66.7Z" transform="translate(-169.5 -12312.919)"></path>
                <path id="path-46-2" data-name="path" className="clsltw-31" d="M112.736,303.014,224.8,238.774,113.2,171.7,1,238.379Z" transform="translate(-0.248 -77.244)"></path>
                <line id="line-18-5" data-name="line-18" className="clsltw-32" y1="94.185" transform="translate(112.414)"></line>
                <line id="line-21-5" data-name="line-21" className="clsltw-32" x1="114.845" y1="66.841" transform="translate(111.199)"></line>
              </g>
              <g id="group-87-4" data-name="group-87" transform="translate(60.586 75.534)">
                <g id="group-64-4" data-name="group-64" transform="translate(0 0)">
                  <g id="group-22" data-name="group" transform="translate(0 5.739)">
                    <path id="path-47" data-name="path" className="clsltw-5" d="M309.956,157.6,268.9,181.328l71.848,41.478L381.8,199.078Z" transform="translate(-268.598 -157.563)"></path>
                    <line id="line-24" data-name="line" className="clsltw-6" y2="41.78" transform="translate(72.15 65.244)"></line>
                    <path id="line-25-3" data-name="line" className="clsltw-6" d="M0,0V42.626" transform="translate(113.717 41.516)"></path>
                    <path id="path-48" data-name="path" className="clsltw-7" d="M338.1,217.1" transform="translate(-296.018 -181.139)"></path>
                    <path id="path-49" data-name="path" className="clsltw-8" d="M343.1,270.005s.06-15.532,0-18.43a6.453,6.453,0,0,0-2.536-4.649,2.628,2.628,0,0,0-.423-.362c-1.751-1.208-18.415-10.264-18.415-10.264l-.617,24.067,14.939-9.011,4.374,2.047-4.586,10.228.984,12.516s-.362,3.079,2.475,3.079l12.275-6.918S352.762,265.658,343.1,270.005Z" transform="translate(-289.186 -188.747)"></path>
                    <line id="line-26" data-name="line" className="clsltw-6" y2="23.667" transform="translate(30.792 83.598)"></line>
                    <line id="line-27" data-name="line" className="clsltw-6" x1="0.06" y2="23.788" transform="translate(0 65.727)"></line>
                    <line id="line-28-3" data-name="line" className="clsltw-8" y2="23.426" transform="translate(51.38 71.945)"></line>
                    <g id="group-60-4" data-name="group-60" transform="translate(38.924 0)">
                      <path id="path-50" data-name="path" className="clsltw-9" d="M400.27,278.277l22.771,12.661,14.9-8.6V265.13l-22.35-12.35v17.061Z" transform="translate(-400.27 -252.78)"></path>
                      <line id="line-29" data-name="line" className="clsltw-10" x2="22.35" y2="12.494" transform="translate(15.321 17.061)"></line>
                      <path id="path-51" data-name="path" className="clsltw-11" d="M417.27,301.733l7.45-4.163,17.727,9.926-7.341,4.2Z" transform="translate(-409.82 -277.941)"></path>
                    </g>
                    <path id="path-52" data-name="path" className="clsltw-8" d="M298.128,182.507c6.1-.483,8.016-5.555,8.016-11.773,0-6.521-2.461-11.834-8.982-11.834a11.828,11.828,0,0,0-8.211,20.347,7.649,7.649,0,0,0,.785.664,10.01,10.01,0,0,1-1.691.3,7.266,7.266,0,0,0-7.245,7.245V222.6a7.266,7.266,0,0,0,7.245,7.245h15.4c3.985,0,4.83-3.26,4.83-7.245V207.02c3.321,1.328,7.426-.845,7.426-.845l13.1-7.909a2.885,2.885,0,0,0,1.691-.6l2.717-2.053c1.328-1.026,2.355-2.657,1.389-4.045-1.026-1.328-2.294-1.087-3.622-.06l-4.166,1.268a3.593,3.593,0,0,0-1.026,1.328h-.06l-11.23,5.494c-3.924,1.63-5.615-1.147-6.339-3.623a14.727,14.727,0,0,0-.543-3.26h0c-.725-2.536-2.053-4.77-4.226-5.736l-3.864-2.536C299.577,184.318,297.162,182.93,298.128,182.507Z" transform="translate(-273.313 -158.078)"></path>
                    <g id="group-23" data-name="group" transform="translate(0.06 23.886)">
                      <path id="path-53" data-name="path" className="clsltw-12" d="M319.5,283.834,340.028,272.3v-6.7l-20.166,11.834" transform="translate(-288.708 -224.242)"></path>
                      <path id="path-54" data-name="path" className="clsltw-5" d="M268.5,238.941V197.1l7.185,4.045L300.2,215.092l-.906,41.6Z" transform="translate(-268.5 -197.1)"></path>
                    </g>
                    <path id="path-55" data-name="path" className="clsltw-13" d="M325.512,249s.587,3.788,3.968,3.064-3,9.253-3,9.253l-1.917-2.927Z" transform="translate(-291.428 -193.779)"></path>
                    <path id="path-56" data-name="path" className="clsltw-13" d="M321.025,272.461l2.906-1.722L325.1,267.1h-3.14" transform="translate(-289.116 -201.462)"></path>
                    <path id="path-44-5" data-name="path-44" className="clsltw-11" d="M54.338,99.091l-.607,9.563,15.236-9.046,4.665,2.241v7.679l-4.665,2.114,1.021,10.388,6.175-3.456V99.608L75.1,96.386,64.39,89.574l-7.141,2.093" transform="translate(-22.279 -37.104)"></path>
                  </g>
                  <path id="path-39-5" data-name="path-39" className="clsltw-14" d="M534.259,8236.49s3.8,4.8,9.883,3.553" transform="translate(-518.951 -8208.699)"></path>
                  <path id="path-42-5" data-name="path-42" className="clsltw-11" d="M608.329,8350.8l-.444,3.9,2.186-4.693Z" transform="translate(-554.909 -8263.626)"></path>
                  <path id="path-43-5" data-name="path-43" className="clsltw-11" d="M572.6,8288.334s-.065,8.244.537,8.66,7.156-3.759,8.305-3.669S572.6,8288.334,572.6,8288.334Z" transform="translate(-537.673 -8233.507)"></path>
                  <path id="path-46-3" data-name="path-46" className="clsltw-11" d="M325.745,8324.194c-1.945-.65-3.311-8.326-2.048-9.058s-.551-7.479,2.936-6.938,2.467-5.778,5.443-4.865,4.963-1.643,7.513,0,7.633,2.369,7.093,4.865,3.527,6.849-.476,6.294-9.528,6.562-7.587,7.405,1.956,5.6,0,5.6-3.515,1.435-4.62,2.647-2.1.325-4.3-1.135S327.691,8324.845,325.745,8324.194Z" transform="translate(-315.292 -8302.769)"></path>
                </g>
                <path id="path-45-5" data-name="path-45" className="clsltw-14" d="M152.529,8102.924a2.721,2.721,0,0,0-1.169,3.8" transform="translate(-122.243 -8085.774)"></path>
              </g>
            </g>
            <g id="group-89-5" data-name="group-89" transform="translate(0 160.662)">
              <g id="group-88-5" data-name="group-88" transform="translate(0 0)">
                <line id="line-21-6" data-name="line-21" className="clsltw-2" x1="114.845" y1="66.841" transform="translate(111.199)"></line>
                <path id="path-63-5" data-name="path-63" className="clsltw-31" d="M169.5,12473.944v-93.85l112.138-66.526,113.2,66.526v93.85l-113.2-66.7Z" transform="translate(-169.5 -12312.919)"></path>
                <path id="path-57" data-name="path" className="clsltw-31" d="M112.736,303.014,224.8,238.774,113.2,171.7,1,238.379Z" transform="translate(-0.248 -77.244)"></path>
                <line id="line-18-6" data-name="line-18" className="clsltw-32" y1="94.185" transform="translate(112.414)"></line>
              </g>
              <g id="group-87-5" data-name="group-87" transform="translate(60.586 76.664)">
                <g id="group-64-5" data-name="group-64" transform="translate(0 0)">
                  <g id="group-24" data-name="group" transform="translate(0 4.609)">
                    <path id="path-58" data-name="path" className="clsltw-5" d="M309.956,157.6,268.9,181.328l71.848,41.478L381.8,199.078Z" transform="translate(-268.598 -157.563)"></path>
                    <line id="line-30" data-name="line" className="clsltw-18" y2="41.78" transform="translate(72.15 65.244)"></line>
                    <path id="line-31" data-name="line" className="clsltw-18" d="M0,0V42.626" transform="translate(112.256 42.246)"></path>
                    <path id="path-59" data-name="path" className="clsltw-7" d="M338.1,217.1" transform="translate(-296.018 -181.139)"></path>
                    <path id="path-60" data-name="path" className="clsltw-19" d="M343.1,270.005s.06-15.532,0-18.43a6.453,6.453,0,0,0-2.536-4.649,2.628,2.628,0,0,0-.423-.362c-1.751-1.208-18.415-10.264-18.415-10.264l-.617,24.067,14.939-9.011,4.374,2.047-4.586,10.228.984,12.516s-.362,3.079,2.475,3.079l12.275-6.918S352.762,265.658,343.1,270.005Z" transform="translate(-289.186 -188.747)"></path>
                    <line id="line-32" data-name="line" className="clsltw-18" y2="23.667" transform="translate(30.792 83.598)"></line>
                    <line id="line-33" data-name="line" className="clsltw-18" x1="0.06" y2="23.788" transform="translate(0 65.727)"></line>
                    <line id="line-34" data-name="line" className="clsltw-6" y2="23.426" transform="translate(51.38 71.945)"></line>
                    <g id="group-60-5" data-name="group-60" transform="translate(38.924 0)">
                      <path id="path-61" data-name="path" className="clsltw-20" d="M400.27,278.277l22.771,12.661,14.9-8.6V265.13l-22.35-12.35v17.061Z" transform="translate(-400.27 -252.78)"></path>
                      <line id="line-35" data-name="line" className="clsltw-10" x2="22.35" y2="12.494" transform="translate(15.321 17.061)"></line>
                      <path id="path-62" data-name="path" className="clsltw-11" d="M417.27,301.733l7.45-4.163,17.727,9.926-7.341,4.2Z" transform="translate(-409.82 -277.941)"></path>
                    </g>
                    <path id="path-63-6" data-name="path" className="clsltw-8" d="M298.128,182.507c6.1-.483,8.016-5.555,8.016-11.773,0-6.521-2.461-11.834-8.982-11.834a11.828,11.828,0,0,0-8.211,20.347,7.649,7.649,0,0,0,.785.664,10.01,10.01,0,0,1-1.691.3,7.266,7.266,0,0,0-7.245,7.245V222.6a7.266,7.266,0,0,0,7.245,7.245h15.4c3.985,0,4.83-3.26,4.83-7.245V207.02c3.321,1.328,7.426-.845,7.426-.845l13.1-7.909a2.885,2.885,0,0,0,1.691-.6l2.717-2.053c1.328-1.026,2.355-2.657,1.389-4.045-1.026-1.328-2.294-1.087-3.622-.06l-4.166,1.268a3.593,3.593,0,0,0-1.026,1.328h-.06l-11.23,5.494c-3.924,1.63-5.615-1.147-6.339-3.623a14.727,14.727,0,0,0-.543-3.26h0c-.725-2.536-2.053-4.77-4.226-5.736l-3.864-2.536C299.577,184.318,297.162,182.93,298.128,182.507Z" transform="translate(-273.313 -158.078)"></path>
                    <g id="group-25" data-name="group" transform="translate(0.06 23.886)">
                      <path id="path-64-2" data-name="path" className="clsltw-12" d="M319.5,283.834,340.028,272.3v-6.7l-20.166,11.834" transform="translate(-288.708 -224.242)"></path>
                      <path id="path-65-4" data-name="path" className="clsltw-5" d="M268.5,238.941V197.1l7.185,4.045L300.2,215.092l-.906,41.6Z" transform="translate(-268.5 -197.1)"></path>
                    </g>
                    <path id="path-66-4" data-name="path" className="clsltw-13" d="M325.512,249s.587,3.788,3.968,3.064-3,9.253-3,9.253l-1.917-2.927Z" transform="translate(-291.428 -193.779)"></path>
                    <path id="path-67" data-name="path" className="clsltw-13" d="M321.025,272.461l2.906-1.722L325.1,267.1h-3.14" transform="translate(-289.116 -201.462)"></path>
                    <path id="path-44-6" data-name="path-44" className="clsltw-11" d="M54.338,99.091l-.607,9.563,15.236-9.046,4.665,2.241v7.679l-4.665,2.114,1.021,10.388,6.175-3.456V99.608L75.1,96.386,64.39,89.574l-7.141,2.093" transform="translate(-22.279 -37.104)"></path>
                  </g>
                  <path id="path-38-4" data-name="path-38" className="clsltw-21" d="M533.631,8209.686c5.451-2.42,10.229-3.88,8.206-4.974s.776-6.524.776-6.524,6.14-.081,3.941-2.266,3.558-4.285,3.558-4.285a15.067,15.067,0,0,0-9.4-7.823c-7.029-2.095-9.395,3.952-13.215,5.022s-1.766,12.446-1.766,12.446S528.18,8212.1,533.631,8209.686Z" transform="translate(-514.518 -8183.376)"></path>
                  <path id="path-39-6" data-name="path-39" className="clsltw-14" d="M534.259,8236.49s3.8,4.8,9.883,3.553" transform="translate(-518.951 -8209.83)"></path>
                  <path id="path-42-6" data-name="path-42" className="clsltw-11" d="M608.329,8350.8l-.444,3.9,2.186-4.693Z" transform="translate(-554.909 -8264.757)"></path>
                  <path id="path-43-6" data-name="path-43" className="clsltw-11" d="M572.6,8288.334s-.065,8.244.537,8.66,7.156-3.759,8.305-3.669S572.6,8288.334,572.6,8288.334Z" transform="translate(-537.673 -8234.637)"></path>
                </g>
                <path id="path-45-6" data-name="path-45" className="clsltw-14" d="M152.529,8102.924a2.721,2.721,0,0,0-1.169,3.8" transform="translate(-122.243 -8086.904)"></path>
              </g>
            </g>
            <line id="line-19-6" data-name="line-19" className="clsltw-2" y1="94.185" transform="translate(338.704 67.693)"></line>
            <g id="group-89-6" data-name="group-89" transform="translate(113.268 0.852)">
              <g id="group-88-6" data-name="group-88" transform="translate(0 0)">
                <path id="path-68" data-name="path" className="clsltw-33" d="M112.736,303.014,224.8,238.774,113.2,171.7,1,238.379Z" transform="translate(0.36 -77.244)"></path>
                <line id="line-18-7" data-name="line-18" className="clsltw-2" y1="94.185" transform="translate(113.022)"></line>
                <line id="line-19-7" data-name="line-19" className="clsltw-2" y1="94.185" transform="translate(0.608 66.841)"></line>
                <line id="line-20-5" data-name="line-20" className="clsltw-2" y1="68.056" x2="113.022"></line>
                <line id="line-21-7" data-name="line-21" className="clsltw-2" x1="114.845" y1="66.841" transform="translate(111.806)"></line>
                <path id="path-63-7" data-name="path-63" className="clsltw-6" d="M169.5,12473.944v-93.85l112.138-66.526,113.2,66.526v93.85l-113.2-66.7Z" transform="translate(-168.892 -12312.919)"></path>
              </g>
              <g id="group-87-6" data-name="group-87" transform="translate(61.194 76.664)">
                <g id="group-64-6" data-name="group-64" transform="translate(0 0)">
                  <g id="group-26" data-name="group" transform="translate(0 4.609)">
                    <path id="path-69-2" data-name="path" className="clsltw-5" d="M309.956,157.6,268.9,181.328l71.848,41.478L381.8,199.078Z" transform="translate(-268.598 -157.563)"></path>
                    <line id="line-36" data-name="line" className="clsltw-6" y2="41.78" transform="translate(72.15 65.244)"></line>
                    <path id="line-37" data-name="line" className="clsltw-6" d="M0,0V42.626" transform="translate(113.717 41.516)"></path>
                    <path id="path-70" data-name="path" className="clsltw-7" d="M338.1,217.1" transform="translate(-296.018 -181.139)"></path>
                    <path id="path-71" data-name="path" className="clsltw-8" d="M343.1,270.005s.06-15.532,0-18.43a6.453,6.453,0,0,0-2.536-4.649,2.628,2.628,0,0,0-.423-.362c-1.751-1.208-18.415-10.264-18.415-10.264l-.617,24.067,14.939-9.011,4.374,2.047-4.586,10.228.984,12.516s-.362,3.079,2.475,3.079l12.275-6.918S352.762,265.658,343.1,270.005Z" transform="translate(-289.186 -188.747)"></path>
                    <line id="line-38" data-name="line" className="clsltw-6" y2="23.667" transform="translate(30.792 83.598)"></line>
                    <line id="line-39" data-name="line" className="clsltw-6" x1="0.06" y2="23.788" transform="translate(0 65.727)"></line>
                    <line id="line-40" data-name="line" className="clsltw-8" y2="23.426" transform="translate(51.38 71.945)"></line>
                    <g id="group-60-6" data-name="group-60" transform="translate(38.924 0)">
                      <path id="path-72" data-name="path" className="clsltw-9" d="M400.27,278.277l22.771,12.661,14.9-8.6V265.13l-22.35-12.35v17.061Z" transform="translate(-400.27 -252.78)"></path>
                      <line id="line-41" data-name="line" className="clsltw-10" x2="22.35" y2="12.494" transform="translate(15.321 17.061)"></line>
                      <path id="path-73" data-name="path" className="clsltw-11" d="M417.27,301.733l7.45-4.163,17.727,9.926-7.341,4.2Z" transform="translate(-409.82 -277.941)"></path>
                    </g>
                    <path id="path-74" data-name="path" className="clsltw-8" d="M298.128,182.507c6.1-.483,8.016-5.555,8.016-11.773,0-6.521-2.461-11.834-8.982-11.834a11.828,11.828,0,0,0-8.211,20.347,7.649,7.649,0,0,0,.785.664,10.01,10.01,0,0,1-1.691.3,7.266,7.266,0,0,0-7.245,7.245V222.6a7.266,7.266,0,0,0,7.245,7.245h15.4c3.985,0,4.83-3.26,4.83-7.245V207.02c3.321,1.328,7.426-.845,7.426-.845l13.1-7.909a2.885,2.885,0,0,0,1.691-.6l2.717-2.053c1.328-1.026,2.355-2.657,1.389-4.045-1.026-1.328-2.294-1.087-3.622-.06l-4.166,1.268a3.593,3.593,0,0,0-1.026,1.328h-.06l-11.23,5.494c-3.924,1.63-5.615-1.147-6.339-3.623a14.727,14.727,0,0,0-.543-3.26h0c-.725-2.536-2.053-4.77-4.226-5.736l-3.864-2.536C299.577,184.318,297.162,182.93,298.128,182.507Z" transform="translate(-273.313 -158.078)"></path>
                    <g id="group-27" data-name="group" transform="translate(0.06 23.886)">
                      <path id="path-75" data-name="path" className="clsltw-12" d="M319.5,283.834,340.028,272.3v-6.7l-20.166,11.834" transform="translate(-288.708 -224.242)"></path>
                      <path id="path-76" data-name="path" className="clsltw-5" d="M268.5,238.941V197.1l7.185,4.045L300.2,215.092l-.906,41.6Z" transform="translate(-268.5 -197.1)"></path>
                    </g>
                    <path id="path-77" data-name="path" className="clsltw-13" d="M325.512,249s.587,3.788,3.968,3.064-3,9.253-3,9.253l-1.917-2.927Z" transform="translate(-291.428 -193.779)"></path>
                    <path id="path-78" data-name="path" className="clsltw-13" d="M321.025,272.461l2.906-1.722L325.1,267.1h-3.14" transform="translate(-289.116 -201.462)"></path>
                    <path id="path-44-7" data-name="path-44" className="clsltw-11" d="M54.338,99.091l-.607,9.563,15.236-9.046,4.665,2.241v7.679l-4.665,2.114,1.021,10.388,6.175-3.456V99.608L75.1,96.386,64.39,89.574l-7.141,2.093" transform="translate(-22.279 -37.104)"></path>
                  </g>
                  <path id="path-38-5" data-name="path-38" className="clsltw-11" d="M533.631,8209.686c5.451-2.42,10.229-3.88,8.206-4.974s.776-6.524.776-6.524,6.14-.081,3.941-2.266,3.558-4.285,3.558-4.285a15.067,15.067,0,0,0-9.4-7.823c-7.029-2.095-9.395,3.952-13.215,5.022s-1.766,12.446-1.766,12.446S528.18,8212.1,533.631,8209.686Z" transform="translate(-514.518 -8183.376)"></path>
                  <path id="path-39-7" data-name="path-39" className="clsltw-14" d="M534.259,8236.49s3.8,4.8,9.883,3.553" transform="translate(-518.951 -8209.83)"></path>
                  <path id="path-42-7" data-name="path-42" className="clsltw-11" d="M608.329,8350.8l-.444,3.9,2.186-4.693Z" transform="translate(-554.909 -8264.757)"></path>
                  <path id="path-43-7" data-name="path-43" className="clsltw-11" d="M572.6,8288.334s-.065,8.244.537,8.66,7.156-3.759,8.305-3.669S572.6,8288.334,572.6,8288.334Z" transform="translate(-537.673 -8234.637)"></path>
                </g>
                <path id="path-45-7" data-name="path-45" className="clsltw-14" d="M152.529,8102.924a2.721,2.721,0,0,0-1.169,3.8" transform="translate(-122.243 -8086.904)"></path>
              </g>
            </g>
            <g id="group-89-7" data-name="group-89" transform="translate(339.142 0)">
              <g id="group-88-7" data-name="group-88" transform="translate(0 0)">
                <path id="path-79" data-name="path" className="clsltw-33" d="M112.736,303.014,224.8,238.774,113.2,171.7,1,238.379Z" transform="translate(0.36 -77.244)"></path>
                <line id="line-18-8" data-name="line-18" className="clsltw-2" y1="94.185" transform="translate(113.022)"></line>
                <line id="line-19-8" data-name="line-19" className="clsltw-2" y1="94.185" transform="translate(0.608 66.841)"></line>
                <line id="line-20-6" data-name="line-20" className="clsltw-2" y1="68.056" x2="113.022"></line>
                <line id="line-21-8" data-name="line-21" className="clsltw-2" x1="114.845" y1="66.841" transform="translate(111.806)"></line>
                <path id="path-63-8" data-name="path-63" className="clsltw-6" d="M169.5,12473.944v-93.85l112.138-66.526,113.2,66.526v93.85l-113.2-66.7Z" transform="translate(-168.892 -12312.919)"></path>
              </g>
              <g id="group-87-7" data-name="group-87" transform="translate(61.194 76.664)">
                <g id="group-64-7" data-name="group-64" transform="translate(0 0)">
                  <g id="group-28" data-name="group" transform="translate(0 4.609)">
                    <path id="path-80" data-name="path" className="clsltw-5" d="M309.956,157.6,268.9,181.328l71.848,41.478L381.8,199.078Z" transform="translate(-268.598 -157.563)"></path>
                    <line id="line-42" data-name="line" className="clsltw-6" y2="41.78" transform="translate(72.15 65.244)"></line>
                    <path id="line-43" data-name="line" className="clsltw-6" d="M0,0V42.626" transform="translate(113.717 41.516)"></path>
                    <path id="path-81" data-name="path" className="clsltw-7" d="M338.1,217.1" transform="translate(-296.018 -181.139)"></path>
                    <path id="path-82" data-name="path" className="clsltw-8" d="M343.1,270.005s.06-15.532,0-18.43a6.453,6.453,0,0,0-2.536-4.649,2.628,2.628,0,0,0-.423-.362c-1.751-1.208-18.415-10.264-18.415-10.264l-.617,24.067,14.939-9.011,4.374,2.047-4.586,10.228.984,12.516s-.362,3.079,2.475,3.079l12.275-6.918S352.762,265.658,343.1,270.005Z" transform="translate(-289.186 -188.747)"></path>
                    <line id="line-44" data-name="line" className="clsltw-6" y2="23.667" transform="translate(30.792 83.598)"></line>
                    <line id="line-45" data-name="line" className="clsltw-6" x1="0.06" y2="23.788" transform="translate(0 65.727)"></line>
                    <line id="line-46" data-name="line" className="clsltw-8" y2="23.426" transform="translate(51.38 71.945)"></line>
                    <g id="group-60-7" data-name="group-60" transform="translate(38.924 0)">
                      <path id="path-83" data-name="path" className="clsltw-9" d="M400.27,278.277l22.771,12.661,14.9-8.6V265.13l-22.35-12.35v17.061Z" transform="translate(-400.27 -252.78)"></path>
                      <line id="line-47" data-name="line" className="clsltw-10" x2="22.35" y2="12.494" transform="translate(15.321 17.061)"></line>
                      <path id="path-84-2" data-name="path" className="clsltw-11" d="M417.27,301.733l7.45-4.163,17.727,9.926-7.341,4.2Z" transform="translate(-409.82 -277.941)"></path>
                    </g>
                    <path id="path-85-2" data-name="path" className="clsltw-8" d="M298.128,182.507c6.1-.483,8.016-5.555,8.016-11.773,0-6.521-2.461-11.834-8.982-11.834a11.828,11.828,0,0,0-8.211,20.347,7.649,7.649,0,0,0,.785.664,10.01,10.01,0,0,1-1.691.3,7.266,7.266,0,0,0-7.245,7.245V222.6a7.266,7.266,0,0,0,7.245,7.245h15.4c3.985,0,4.83-3.26,4.83-7.245V207.02c3.321,1.328,7.426-.845,7.426-.845l13.1-7.909a2.885,2.885,0,0,0,1.691-.6l2.717-2.053c1.328-1.026,2.355-2.657,1.389-4.045-1.026-1.328-2.294-1.087-3.622-.06l-4.166,1.268a3.593,3.593,0,0,0-1.026,1.328h-.06l-11.23,5.494c-3.924,1.63-5.615-1.147-6.339-3.623a14.727,14.727,0,0,0-.543-3.26h0c-.725-2.536-2.053-4.77-4.226-5.736l-3.864-2.536C299.577,184.318,297.162,182.93,298.128,182.507Z" transform="translate(-273.313 -158.078)"></path>
                    <g id="group-29" data-name="group" transform="translate(0.06 23.886)">
                      <path id="path-86" data-name="path" className="clsltw-12" d="M319.5,283.834,340.028,272.3v-6.7l-20.166,11.834" transform="translate(-288.708 -224.242)"></path>
                      <path id="path-87" data-name="path" className="clsltw-5" d="M268.5,238.941V197.1l7.185,4.045L300.2,215.092l-.906,41.6Z" transform="translate(-268.5 -197.1)"></path>
                    </g>
                    <path id="path-88" data-name="path" className="clsltw-13" d="M325.512,249s.587,3.788,3.968,3.064-3,9.253-3,9.253l-1.917-2.927Z" transform="translate(-291.428 -193.779)"></path>
                    <path id="path-89" data-name="path" className="clsltw-13" d="M321.025,272.461l2.906-1.722L325.1,267.1h-3.14" transform="translate(-289.116 -201.462)"></path>
                    <path id="path-44-8" data-name="path-44" className="clsltw-11" d="M54.338,99.091l-.607,9.563,15.236-9.046,4.665,2.241v7.679l-4.665,2.114,1.021,10.388,6.175-3.456V99.608L75.1,96.386,64.39,89.574l-7.141,2.093" transform="translate(-22.279 -37.104)"></path>
                  </g>
                  <path id="path-38-6" data-name="path-38" className="clsltw-11" d="M533.631,8209.686c5.451-2.42,10.229-3.88,8.206-4.974s.776-6.524.776-6.524,6.14-.081,3.941-2.266,3.558-4.285,3.558-4.285a15.067,15.067,0,0,0-9.4-7.823c-7.029-2.095-9.395,3.952-13.215,5.022s-1.766,12.446-1.766,12.446S528.18,8212.1,533.631,8209.686Z" transform="translate(-514.518 -8183.376)"></path>
                  <path id="path-39-8" data-name="path-39" className="clsltw-14" d="M534.259,8236.49s3.8,4.8,9.883,3.553" transform="translate(-518.951 -8209.83)"></path>
                  <path id="path-42-8" data-name="path-42" className="clsltw-11" d="M608.329,8350.8l-.444,3.9,2.186-4.693Z" transform="translate(-554.909 -8264.757)"></path>
                  <path id="path-43-8" data-name="path-43" className="clsltw-11" d="M572.6,8288.334s-.065,8.244.537,8.66,7.156-3.759,8.305-3.669S572.6,8288.334,572.6,8288.334Z" transform="translate(-537.673 -8234.637)"></path>
                </g>
                <path id="path-45-8" data-name="path-45" className="clsltw-14" d="M152.529,8102.924a2.721,2.721,0,0,0-1.169,3.8" transform="translate(-122.243 -8086.904)"></path>
              </g>
            </g>
            <line id="line-23-4" data-name="line-23" className="clsltw-22" y1="7.306" x2="12.42" transform="translate(250.724 254.694)"></line>
            <g id="group-90-2" data-name="group-90" transform="translate(143.954 27.285)">
              <g id="group-46-3" data-name="group-46" transform="translate(0 0)">
                <g id="ellipse-9-5" data-name="ellipse-9" className="clsltw-8">
                  <ellipse className="clsltw-34" cx="20.66" cy="20.66" rx="20.66" ry="20.66"></ellipse>
                  <ellipse className="clsltw-35" cx="20.66" cy="20.66" rx="19.66" ry="19.66"></ellipse>
                </g>
                <g id="ellipse-9-6" data-name="ellipse-9" className="clsltw-8" transform="translate(37.674 37.674)">
                  <ellipse className="clsltw-34" cx="4.861" cy="4.861" rx="4.861" ry="4.861"></ellipse>
                  <ellipse className="clsltw-35" cx="4.861" cy="4.861" rx="3.861" ry="3.861"></ellipse>
                </g>
                <g id="group-44-2" data-name="group-44" transform="translate(15.191 17.014)">
                  <ellipse id="ellipse-10-3" data-name="ellipse-10" className="clsltw-11" cx="1.367" cy="1.367" rx="1.367" ry="1.367"></ellipse>
                  <ellipse id="ellipse-10-4" data-name="ellipse-10" className="clsltw-11" cx="1.367" cy="1.367" rx="1.367" ry="1.367" transform="translate(8.203)"></ellipse>
                </g>
              </g>
              <path id="path-64-3" data-name="path-64" className="clsltw-14" d="M245.93,12355s4.278,4.665,8.279,0" transform="translate(-229.566 -12331.3)"></path>
            </g>
            <path id="path-65-5" data-name="path-65" className="clsltw-22" d="M398.661,12626.021v33.162l21.833-13v-33.611Z" transform="translate(-152.934 -12387.052)"></path>
            <path id="path-65-6" data-name="path-65" className="clsltw-14" d="M398.661,12626.021v33.162l21.833-13v-33.611Z" transform="translate(-117.864 -12410.431)"></path>
            <line id="line-22-5" data-name="line-22" className="clsltw-14" y1="8.767" x2="13.882" transform="translate(285.063 213.779)"></line>
            <line id="line-22-6" data-name="line-22" className="clsltw-14" y1="8.767" x2="13.882" transform="translate(285.063 218.894)"></line>
            <line id="line-22-7" data-name="line-22" className="clsltw-14" y1="8.767" x2="13.882" transform="translate(285.063 224.008)"></line>
            <path id="path-65-7" data-name="path-65" className="clsltw-22" d="M398.661,12616.768v10.335l6.806-4.049v-10.478Z" transform="translate(-147.612 -12373.937)"></path>
            <line id="line-23-5" data-name="line-23" className="clsltw-22" y1="7.306" x2="12.42" transform="translate(250.724 251.041)"></line>
            <path id="path-66-5" data-name="path-66" className="clsltw-24" d="M387.091,12398.8v-49.943l82.068,47.546v50.786Z" transform="translate(-146.164 -12319.663)"></path>
            <path id="path-66-6" data-name="path-66" className="clsltw-14" d="M387.091,12361v-12.146l19.955,11.563v12.347Z" transform="translate(-137.396 -12303.282)"></path>
            <path id="path-66-7" data-name="path-66" className="clsltw-14" d="M387.091,12361v-12.146l19.955,11.563v12.347Z" transform="translate(-112.12 -12288.346)"></path>
            <line id="line-25-4" data-name="line-25" className="clsltw-14" x2="55.527" y2="32.878" transform="translate(248.532 68.387)"></line>
            <line id="line-25-5" data-name="line-25" className="clsltw-14" x2="55.527" y2="32.878" transform="translate(248.532 73.502)"></line>
            <g id="group-129-3" data-name="group-129" transform="translate(344.494 245.926)">
              <g id="group-30" data-name="group" transform="translate(0 2.852)">
                <g id="group-31" data-name="group">
                  <g id="group-32" data-name="group">
                    <path id="path-90" data-name="path" className="clsltw-13" d="M2.245,7.771A2.025,2.025,0,0,1,1.592,7L2.9,7.746a.546.546,0,0,1-.653.024M1.592,6.247c-.024-.024-.073-.048-.1-.1-.121-.121-.169-.29-.1-.387a.126.126,0,0,1,.193-.024l.1.1a.414.414,0,0,1,.1.387.146.146,0,0,1-.193.024M2.874,7c-.024-.024-.073-.048-.1-.1a.414.414,0,0,1-.1-.387.126.126,0,0,1,.193-.024c.024.024.073.048.1.1a.414.414,0,0,1,.1.387A.142.142,0,0,1,2.874,7M2.221,4.748a1.134,1.134,0,0,0-.532-.218C.795,4.362.311,5.184.6,6.392a4.247,4.247,0,0,0,.653,1.378C.5,7.65-.027,8.133-.1,9.028l4.594,2.659a4.622,4.622,0,0,0-1.33-2.8c.629-.121.919-.87.677-1.862a3.659,3.659,0,0,0-1.62-2.273" transform="translate(0.1 -4.509)"></path>
                  </g>
                </g>
              </g>
              <line id="line-28-4" data-name="line-28" className="clsltw-14" y2="15.982" transform="translate(6.345 0)"></line>
              <g id="group-126-3" data-name="group-126" transform="translate(7.727 4.828)">
                <g id="group-33" data-name="group" transform="translate(0 0)">
                  <g id="group-34" data-name="group">
                    <g id="group-35" data-name="group">
                      <g id="group-36" data-name="group">
                        <path id="path-91" data-name="path" className="clsltw-23" d="M3.448,4.606,8.04,5.865,3.448,3.217c-.352-.2-.648-.037-.648.37A1.413,1.413,0,0,0,3.429,4.7L8.021,7.346c.352.2.648.037.648-.37A1.365,1.365,0,0,0,8.04,5.865" transform="translate(-2.356 -3.131)"></path>
                        <path id="path-92" data-name="path" className="clsltw-13" d="M24.065,25.7l-4.592-2.648c-.315-.185-.574-.037-.574.333a1.22,1.22,0,0,0,.574.981l4.592,2.648c.315.185.574.037.574-.333a1.248,1.248,0,0,0-.574-.981" transform="translate(-15.475 -18.614)"></path>
                        <path id="path-93" data-name="path" className="clsltw-23" d="M5.565,24.9.974,22.249C.659,22.064.4,22.212.4,22.582a1.22,1.22,0,0,0,.574.981l4.592,2.648c.315.185.574.037.574-.333a1.277,1.277,0,0,0-.574-.981" transform="translate(-0.4 -16.939)"></path>
                        <path id="path-94" data-name="path" className="clsltw-13" d="M25.765,46.4l-4.592-2.648c-.315-.185-.574-.037-.574.333a1.282,1.282,0,0,0,.574,1l4.592,2.648c.315.185.574.037.574-.333a1.282,1.282,0,0,0-.574-1" transform="translate(-16.86 -34.118)"></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
            <g id="group-17-4" data-name="group-17" transform="translate(380.408 191.663)">
              <path id="path-10-4" data-name="path-10" className="clsltw-3" d="M0,12.9A7.223,7.223,0,0,1,.4,10.53l-.016-.008.087-.188A7.2,7.2,0,0,1,.9,9.412L4.364,1.935A2.7,2.7,0,0,1,6.7,0h.8A2.824,2.824,0,0,1,9.946,2.247l3.938,7.763-.018,0A7.234,7.234,0,1,1,0,12.9Z" transform="translate(0 39.605)"></path>
              <g id="rectangle-6-3" data-name="rectangle-6" className="clsltw-16" transform="translate(4.822 36.168)">
                <rect className="clsltw-34" width="4.822" height="5.626" rx="2.411"></rect>
                <rect className="clsltw-35" x="1.5" y="1.5" width="1.822" height="2.626" rx="0.911"></rect>
              </g>
              <g id="rectangle-7-3" data-name="rectangle-7" className="clsltw-14" transform="translate(6.43 0)">
                <rect id="fill-2" className="clsltw-34" width="1.607" height="39.115" rx="0.804"></rect>
                <path className="clsltw-37" d="M0,1h1.6074522733688354M1,0v39.114540100097656M1.6074522733688354,38.114540100097656h-1.6074522733688354M0.6074522733688354,39.114540100097656v-39.114540100097656"></path>
              </g>
            </g>
            <g id="group-164-2" data-name="group-164" transform="translate(24.093 180.534) rotate(13)">
              <g id="group-46-4" data-name="group-46" transform="matrix(0.891, -0.454, 0.454, 0.891, 0, 21.184)">
                <g id="ellipse-9-7" data-name="ellipse-9" className="clsltw-8" transform="translate(0 0)">
                  <ellipse className="clsltw-34" cx="20.339" cy="20.339" rx="20.339" ry="20.339"></ellipse>
                  <ellipse className="clsltw-35" cx="20.339" cy="20.339" rx="19.339" ry="19.339"></ellipse>
                </g>
                <g id="ellipse-9-8" data-name="ellipse-9" className="clsltw-8" transform="translate(37.089 37.089)">
                  <ellipse className="clsltw-34" cx="4.786" cy="4.786" rx="4.786" ry="4.786"></ellipse>
                  <ellipse className="clsltw-35" cx="4.786" cy="4.786" rx="3.786" ry="3.786"></ellipse>
                </g>
              </g>
            </g>
            <path id="path-69-3" data-name="path-69" className="clsltw-13" d="M535.623,12658.538v-9.224l15.344,8.416v9.764Z" transform="translate(-417.781 -12402.062)"></path>
            <g id="group-92" transform="translate(307.176 68.885)">
              <path id="ellipse-17" className="clsltw-29" d="M11.113,0C17.463.119,22.785,8,23,17.6S18.244,34.9,11.895,34.78s-11.672-8-11.888-17.6S4.763-.116,11.113,0Z" transform="matrix(0.995, -0.105, 0.105, 0.995, 0, 2.405)"></path>
              <path id="ellipse-17-2" data-name="ellipse-17" className="clsltw-8" d="M7.8,0c4.484.083,8.266,6.688,8.448,14.752S12.939,29.287,8.454,29.2.188,22.516.007,14.452,3.314-.082,7.8,0Z" transform="matrix(0.995, -0.105, 0.105, 0.995, 2.523, 4.825)"></path>
              <path id="path-71-2" data-name="path-71" className="clsltw-14" d="M655.813,12648.4v7.729l4.8,2.325" transform="translate(-644.495 -12636.551)"></path>
            </g>
            <g id="ellipse-19-2" data-name="ellipse-19" className="clsltw-9" transform="translate(310.938 248.048)">
              <ellipse className="clsltw-34" cx="4.415" cy="4.967" rx="4.415" ry="4.967"></ellipse>
              <ellipse className="clsltw-35" cx="4.415" cy="4.967" rx="3.415" ry="3.967"></ellipse>
            </g>
            <path id="path-85-3" data-name="path-85" className="clsltw-9" d="M547.29,8419.6c-8.6,2.479,2.59-3.347,2.59-3.347s8.878-2.741,12.682,11.91-3.751.411-3.7,1.468S555.889,8417.116,547.29,8419.6Z" transform="translate(-246.325 -8179.918)"></path>
            <g id="group-129-4" data-name="group-129" transform="translate(231.249 88.114)">
              <g id="group-37" data-name="group" transform="translate(0 2.852)">
                <g id="group-38" data-name="group">
                  <g id="group-39" data-name="group">
                    <path id="path-95" data-name="path" className="clsltw-13" d="M2.245,7.771A2.025,2.025,0,0,1,1.592,7L2.9,7.746a.546.546,0,0,1-.653.024M1.592,6.247c-.024-.024-.073-.048-.1-.1-.121-.121-.169-.29-.1-.387a.126.126,0,0,1,.193-.024l.1.1a.414.414,0,0,1,.1.387.146.146,0,0,1-.193.024M2.874,7c-.024-.024-.073-.048-.1-.1a.414.414,0,0,1-.1-.387.126.126,0,0,1,.193-.024c.024.024.073.048.1.1a.414.414,0,0,1,.1.387A.142.142,0,0,1,2.874,7M2.221,4.748a1.134,1.134,0,0,0-.532-.218C.795,4.362.311,5.184.6,6.392a4.247,4.247,0,0,0,.653,1.378C.5,7.65-.027,8.133-.1,9.028l4.594,2.659a4.622,4.622,0,0,0-1.33-2.8c.629-.121.919-.87.677-1.862a3.659,3.659,0,0,0-1.62-2.273" transform="translate(0.1 -4.509)"></path>
                  </g>
                </g>
              </g>
              <line id="line-28-5" data-name="line-28" className="clsltw-14" y2="15.982" transform="translate(6.345 0)"></line>
              <g id="group-126-4" data-name="group-126" transform="translate(7.727 4.828)">
                <g id="group-40" data-name="group" transform="translate(0 0)">
                  <g id="group-41" data-name="group">
                    <g id="group-42" data-name="group">
                      <g id="group-43" data-name="group">
                        <path id="path-96" data-name="path" className="clsltw-23" d="M3.448,4.606,8.04,5.865,3.448,3.217c-.352-.2-.648-.037-.648.37A1.413,1.413,0,0,0,3.429,4.7L8.021,7.346c.352.2.648.037.648-.37A1.365,1.365,0,0,0,8.04,5.865" transform="translate(-2.356 -3.131)"></path>
                        <path id="path-97" data-name="path" className="clsltw-13" d="M24.065,25.7l-4.592-2.648c-.315-.185-.574-.037-.574.333a1.22,1.22,0,0,0,.574.981l4.592,2.648c.315.185.574.037.574-.333a1.248,1.248,0,0,0-.574-.981" transform="translate(-15.475 -18.614)"></path>
                        <path id="path-98" data-name="path" className="clsltw-23" d="M5.565,24.9.974,22.249C.659,22.064.4,22.212.4,22.582a1.22,1.22,0,0,0,.574.981l4.592,2.648c.315.185.574.037.574-.333a1.277,1.277,0,0,0-.574-.981" transform="translate(-0.4 -16.939)"></path>
                        <path id="path-99" data-name="path" className="clsltw-13" d="M25.765,46.4l-4.592-2.648c-.315-.185-.574-.037-.574.333a1.282,1.282,0,0,0,.574,1l4.592,2.648c.315.185.574.037.574-.333a1.282,1.282,0,0,0-.574-1" transform="translate(-16.86 -34.118)"></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
            <path id="path-84-3" data-name="path-84" className="clsltw-13" d="M0,0,2.082,23.363l9.344,2.593,9.369-2.6L22.883,0ZM18.542,5.546,18.41,7l-.057.644H7.388l.265,2.934H18.094l-.069.77-.675,7.539-.044.486L11.445,21h0L11.432,21,5.565,19.375l-.4-4.5H8.038l.2,2.284,3.192.864h0l3.192-.864.334-3.716H5.028l-.7-7.9-.069-.77H18.612Z" transform="translate(194.832 40.259)"></path>
          </g>
          <g id="group-17-5" data-name="group-17" transform="translate(1231.193 882.502) rotate(180)">
            <path id="path-10-5" data-name="path-10" className="clsltw-29" d="M0,12.15A6.8,6.8,0,0,1,.376,9.914L.361,9.906l.083-.178a6.78,6.78,0,0,1,.4-.866L4.112,1.815A2.539,2.539,0,0,1,6.306,0h.756a2.658,2.658,0,0,1,2.3,2.114l3.708,7.311-.017,0A6.811,6.811,0,1,1,0,12.15Z" transform="translate(939 127.116)"></path>
            <rect id="rectangle-6-4" data-name="rectangle-6" className="clsltw-30" width="4.54" height="5.297" rx="2.27" transform="translate(943.54 123.88)"></rect>
            <rect id="rectangle-7-4" data-name="rectangle-7" className="clsltw-30" width="1.513" height="3.654" rx="0.757" transform="translate(945.054 123)"></rect>
          </g>
          <path id="ellipse-17-3" data-name="ellipse-17" className="clsltw-29" d="M11.113,0C17.463.119,22.785,8,23,17.6S18.244,34.9,11.895,34.78s-11.672-8-11.888-17.6S4.763-.116,11.113,0Z" transform="matrix(0.995, -0.105, 0.105, 0.995, 547.009, 234.541)"></path>
          <path id="ellipse-17-4" data-name="ellipse-17" className="clsltw-8" d="M7.8,0c4.484.083,8.266,6.688,8.448,14.752S12.939,29.287,8.454,29.2.188,22.516.007,14.452,3.314-.082,7.8,0Z" transform="matrix(0.995, -0.105, 0.105, 0.995, 548.531, 236.962)"></path>
          <path id="path-71-3" data-name="path-71" className="clsltw-14" d="M655.813,12648.4v7.729l4.8,2.325" transform="translate(-98.486 -12404.414)"></path>
          <g id="group-219" transform="translate(126.2 417.7)">
            <g id="group-21" data-name="group">
              <line id="line-19-5" data-name="line" className="clsltw-25" y1="1.9" x2="3" transform="translate(0.8 292)"></line>
              <line id="line-20-4" data-name="line" className="clsltw-26" y1="193.4" x2="316.3" transform="translate(13.9 92.4)"></line>
              <path id="path-44-4" data-name="path" className="clsltw-25" d="M335.3,89.3l3-1.8,3,1.7"></path>
              <line id="line-21-4" data-name="line" className="clsltw-27" x2="91.5" y2="51.3" transform="translate(351.5 94.9)"></line>
              <path id="path-45-4" data-name="path" className="clsltw-25" d="M448.1,149.1l3,1.7,3.1-1.8"></path>
              <line id="line-22-4" data-name="line" className="clsltw-28" y1="137.1" x2="239.9" transform="translate(464.5 6)"></line>
              <line id="line-23-3" data-name="line" className="clsltw-25" y1="1.7" x2="3" transform="translate(709.6 1.3)"></line>
            </g>
          </g>
        </g>
      </svg>
    )
}
export default Lastwords;