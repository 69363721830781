import React from 'react'
import './style.scss'

const Unrevealtable =()=>{
    return(
<svg xmlns="http://www.w3.org/2000/svg" viewBox="64.916 12238.139 934.996 831.221">
                     
                      <g id="group-238" transform="translate(0 12132)">
                        <line id="line-19" className="clsur-1" y1="128.912" transform="translate(533.918 282.55)"></line>
                        <g id="group-89" transform="translate(225.362 191.064)">
                          <g id="group-88" transform="translate(0 0)">
                            <path id="path-63" className="clsur-2" d="M169.5,12533.076v-128.45l153.485-91.058,154.935,91.058v128.45l-154.935-91.292Z" transform="translate(-168.668 -12312.681)"></path>
                            <path id="path" className="clsur-3" d="M153.934,351.432,307.32,263.506,154.566,171.7,1,262.964Z" transform="translate(0.861 -42.417)"></path>
                            <line id="line-18" className="clsur-1" y1="128.912" transform="translate(154.694)"></line>
                            <line id="line-19-2" data-name="line-19" className="clsur-1" y1="128.912" transform="translate(0.832 91.486)"></line>
                            <line id="line-20" className="clsur-1" y1="93.149" x2="154.694"></line>
                            <line id="line-21" className="clsur-1" x1="157.189" y1="91.486" transform="translate(153.031)"></line>
                          </g>
                          <g id="group-87" transform="translate(83.757 104.931)">
                            <g id="group-64" transform="translate(0 0)">
                              <g id="group" transform="translate(0 6.308)">
                                <path id="path-2" data-name="path" className="clsur-4" d="M325.094,157.6,268.9,190.077l98.339,56.772,56.194-32.477Z" transform="translate(-268.487 -157.549)"></path>
                                <line id="line" className="clsur-5" y2="57.185" transform="translate(98.752 89.3)"></line>
                                <path id="line-2" data-name="line" className="clsur-5" d="M0,0V58.342" transform="translate(155.646 56.823)"></path>
                                <path id="path-3" data-name="path" className="clsur-6" d="M338.1,217.1" transform="translate(-280.501 -167.879)"></path>
                                <path id="path-4" data-name="path" className="clsur-7" d="M351.21,282.433s.083-21.259,0-25.225a8.833,8.833,0,0,0-3.471-6.363,3.6,3.6,0,0,0-.578-.5c-2.4-1.653-25.2-14.048-25.2-14.048l-.845,32.941,20.448-12.334,5.986,2.8-6.277,14,1.347,17.131s-.5,4.215,3.388,4.215l16.8-9.469S364.432,276.483,351.21,282.433Z" transform="translate(-277.415 -171.213)"></path>
                                <line id="line-3" data-name="line" className="clsur-5" y2="32.394" transform="translate(42.145 114.422)"></line>
                                <line id="line-4" data-name="line" className="clsur-5" x1="0.083" y2="32.559" transform="translate(0 89.961)"></line>
                                <line id="line-5" data-name="line" className="clsur-7" y2="32.063" transform="translate(70.325 98.473)"></line>
                                <g id="group-60" transform="translate(53.276 0)">
                                  <path id="path-5" data-name="path" className="clsur-8" d="M400.27,287.678l31.167,17.329,20.394-11.775V269.683l-30.591-16.9v23.351Z" transform="translate(-400.27 -252.78)"></path>
                                  <line id="line-6" data-name="line" className="clsur-9" x2="30.591" y2="17.101" transform="translate(20.97 23.351)"></line>
                                  <path id="path-6" data-name="path" className="clsur-10" d="M417.27,303.268l10.2-5.7,24.263,13.586-10.047,5.752Z" transform="translate(-407.073 -270.704)"></path>
                                </g>
                                <path id="path-7" data-name="path" className="clsur-7" d="M304.517,191.211c8.346-.661,10.971-7.6,10.971-16.114,0-8.925-3.369-16.2-12.293-16.2a16.189,16.189,0,0,0-11.239,27.849,10.475,10.475,0,0,0,1.074.909,13.7,13.7,0,0,1-2.314.413,9.946,9.946,0,0,0-9.917,9.917v48.1A9.946,9.946,0,0,0,290.716,256h21.073c5.454,0,6.611-4.462,6.611-9.916V224.762c4.545,1.818,10.164-1.157,10.164-1.157L346.5,212.78a3.948,3.948,0,0,0,2.314-.826l3.719-2.81c1.818-1.4,3.223-3.636,1.9-5.537-1.4-1.818-3.14-1.487-4.958-.083l-5.7,1.735a4.918,4.918,0,0,0-1.4,1.818h-.083l-15.371,7.52c-5.371,2.231-7.685-1.57-8.677-4.958a20.153,20.153,0,0,0-.744-4.462h0c-.992-3.471-2.81-6.528-5.785-7.851l-5.289-3.471C306.5,193.691,303.195,191.79,304.517,191.211Z" transform="translate(-270.553 -157.775)"></path>
                                <g id="group-2" data-name="group" transform="translate(0.083 32.693)">
                                  <path id="path-8" data-name="path" className="clsur-11" d="M319.5,290.557l28.1-15.784V265.6L320,281.8" transform="translate(-277.355 -208.993)"></path>
                                  <path id="path-9" data-name="path" className="clsur-4" d="M268.5,254.368V197.1l9.834,5.537,33.551,19.089-1.24,56.937Z" transform="translate(-268.5 -197.1)"></path>
                                </g>
                                <path id="path-10" data-name="path" className="clsur-12" d="M325.863,249s.8,5.185,5.431,4.193-4.109,12.665-4.109,12.665l-2.624-4.007Z" transform="translate(-279.212 -173.418)"></path>
                                <path id="path-11" data-name="path" className="clsur-12" d="M321.025,274.438,325,272.081l1.6-4.981h-4.3" transform="translate(-277.351 -177.261)"></path>
                                <path id="path-44" className="clsur-10" d="M54.562,102.6l-.831,13.089,20.854-12.382,6.386,3.067v10.511l-6.386,2.893L75.982,134l8.452-4.73v-25.96l-1.46-4.41L68.32,89.574l-9.774,2.865" transform="translate(-10.683 -17.758)"></path>
                              </g>
                              <path id="path-38" className="clsur-10" d="M536.746,8219.386c7.461-3.312,14-5.31,11.232-6.807s1.062-8.93,1.062-8.93,8.4-.109,5.394-3.1,4.869-5.864,4.869-5.864a20.619,20.619,0,0,0-12.861-10.706c-9.62-2.868-12.859,5.407-18.087,6.873s-2.417,17.036-2.417,17.036S529.285,8222.7,536.746,8219.386Z" transform="translate(-510.586 -8183.376)"></path>
                              <path id="path-39" className="clsur-13" d="M534.259,8236.489s5.207,6.566,13.527,4.864" transform="translate(-513.306 -8199.998)"></path>
                              <path id="path-42" className="clsur-10" d="M608.493,8351.1l-.607,5.332,2.991-6.424Z" transform="translate(-535.376 -8233.323)"></path>
                              <path id="path-43" className="clsur-10" d="M572.6,8288.334s-.089,11.285.735,11.853,9.794-5.145,11.367-5.022S572.6,8288.334,572.6,8288.334Z" transform="translate(-524.797 -8214.838)"></path>
                            </g>
                            <path id="path-45" className="clsur-13" d="M153.082,8102.923s-3.216,1.445-1.6,5.2" transform="translate(-111.629 -8080.998)"></path>
                          </g>
                        </g>
                        <g id="group-89-2" data-name="group-89" transform="translate(380.888 408.135)">
                          <g id="group-88-2" data-name="group-88" transform="translate(0 0)">
                            <path id="path-63-2" data-name="path-63" className="clsur-14" d="M169.5,12533.076v-128.45l153.485-91.058,154.935,91.058v128.45l-154.935-91.292Z" transform="translate(-168.668 -12312.681)"></path>
                            <path id="path-12" data-name="path" className="clsur-14" d="M153.934,351.432,307.32,263.506,154.566,171.7,1,262.964Z" transform="translate(0.861 -42.417)"></path>
                            <line id="line-18-2" data-name="line-18" className="clsur-1" y1="128.912" transform="translate(154.694)"></line>
                            <line id="line-19-3" data-name="line-19" className="clsur-1" y1="128.912" transform="translate(0.832 91.486)"></line>
                            <line id="line-20-2" data-name="line-20" className="clsur-1" y1="93.149" x2="154.694"></line>
                            <line id="line-21-2" data-name="line-21" className="clsur-1" x1="157.189" y1="91.486" transform="translate(153.031)"></line>
                          </g>
                          <g id="group-87-2" data-name="group-87" transform="translate(83.757 104.931)">
                            <g id="group-64-2" data-name="group-64" transform="translate(0 0)">
                              <g id="group-3" data-name="group" transform="translate(0 6.308)">
                                <path id="path-13" data-name="path" className="clsur-4" d="M325.094,157.6,268.9,190.077l98.339,56.772,56.194-32.477Z" transform="translate(-268.487 -157.549)"></path>
                                <line id="line-7" data-name="line" className="clsur-5" y2="57.185" transform="translate(98.752 89.3)"></line>
                                <path id="line-8" data-name="line" className="clsur-5" d="M0,0V58.342" transform="translate(155.646 56.823)"></path>
                                <path id="path-14" data-name="path" className="clsur-6" d="M338.1,217.1" transform="translate(-280.501 -167.879)"></path>
                                <path id="path-15" data-name="path" className="clsur-7" d="M351.21,282.433s.083-21.259,0-25.225a8.833,8.833,0,0,0-3.471-6.363,3.6,3.6,0,0,0-.578-.5c-2.4-1.653-25.2-14.048-25.2-14.048l-.845,32.941,20.448-12.334,5.986,2.8-6.277,14,1.347,17.131s-.5,4.215,3.388,4.215l16.8-9.469S364.432,276.483,351.21,282.433Z" transform="translate(-277.415 -171.213)"></path>
                                <line id="line-9" data-name="line" className="clsur-5" y2="32.394" transform="translate(42.145 114.422)"></line>
                                <line id="line-10" data-name="line" className="clsur-5" x1="0.083" y2="32.559" transform="translate(0 89.961)"></line>
                                <line id="line-11" data-name="line" className="clsur-7" y2="32.063" transform="translate(70.325 98.473)"></line>
                                <g id="group-60-2" data-name="group-60" transform="translate(53.276 0)">
                                  <path id="path-16" data-name="path" className="clsur-8" d="M400.27,287.678l31.167,17.329,20.394-11.775V269.683l-30.591-16.9v23.351Z" transform="translate(-400.27 -252.78)"></path>
                                  <line id="line-12" data-name="line" className="clsur-9" x2="30.591" y2="17.101" transform="translate(20.97 23.351)"></line>
                                  <path id="path-17" data-name="path" className="clsur-10" d="M417.27,303.268l10.2-5.7,24.263,13.586-10.047,5.752Z" transform="translate(-407.073 -270.704)"></path>
                                </g>
                                <path id="path-18" data-name="path" className="clsur-7" d="M304.517,191.211c8.346-.661,10.971-7.6,10.971-16.114,0-8.925-3.369-16.2-12.293-16.2a16.189,16.189,0,0,0-11.239,27.849,10.475,10.475,0,0,0,1.074.909,13.7,13.7,0,0,1-2.314.413,9.946,9.946,0,0,0-9.917,9.917v48.1A9.946,9.946,0,0,0,290.716,256h21.073c5.454,0,6.611-4.462,6.611-9.916V224.762c4.545,1.818,10.164-1.157,10.164-1.157L346.5,212.78a3.948,3.948,0,0,0,2.314-.826l3.719-2.81c1.818-1.4,3.223-3.636,1.9-5.537-1.4-1.818-3.14-1.487-4.958-.083l-5.7,1.735a4.918,4.918,0,0,0-1.4,1.818h-.083l-15.371,7.52c-5.371,2.231-7.685-1.57-8.677-4.958a20.153,20.153,0,0,0-.744-4.462h0c-.992-3.471-2.81-6.528-5.785-7.851l-5.289-3.471C306.5,193.691,303.195,191.79,304.517,191.211Z" transform="translate(-270.553 -157.775)"></path>
                                <g id="group-4" data-name="group" transform="translate(0.083 32.693)">
                                  <path id="path-19" data-name="path" className="clsur-11" d="M319.5,290.557l28.1-15.784V265.6L320,281.8" transform="translate(-277.355 -208.993)"></path>
                                  <path id="path-20" data-name="path" className="clsur-4" d="M268.5,254.368V197.1l9.834,5.537,33.551,19.089-1.24,56.937Z" transform="translate(-268.5 -197.1)"></path>
                                </g>
                                <path id="path-21" data-name="path" className="clsur-12" d="M325.863,249s.8,5.185,5.431,4.193-4.109,12.665-4.109,12.665l-2.624-4.007Z" transform="translate(-279.212 -173.418)"></path>
                                <path id="path-22" data-name="path" className="clsur-12" d="M321.025,274.438,325,272.081l1.6-4.981h-4.3" transform="translate(-277.351 -177.261)"></path>
                                <path id="path-44-2" data-name="path-44" className="clsur-10" d="M54.562,102.6l-.831,13.089,20.854-12.382,6.386,3.067v10.511l-6.386,2.893L75.982,134l8.452-4.73v-25.96l-1.46-4.41L68.32,89.574l-9.774,2.865" transform="translate(-10.683 -17.758)"></path>
                              </g>
                              <path id="path-39-2" data-name="path-39" className="clsur-13" d="M534.259,8236.489s5.207,6.566,13.527,4.864" transform="translate(-513.306 -8199.998)"></path>
                              <path id="path-42-2" data-name="path-42" className="clsur-10" d="M608.493,8351.1l-.607,5.332,2.991-6.424Z" transform="translate(-535.376 -8233.323)"></path>
                              <path id="path-43-2" data-name="path-43" className="clsur-10" d="M572.6,8288.334s-.089,11.285.735,11.853,9.794-5.145,11.367-5.022S572.6,8288.334,572.6,8288.334Z" transform="translate(-524.797 -8214.838)"></path>
                              <path id="path-46" className="clsur-10" d="M326.681,8332.1c-2.663-.891-4.532-11.4-2.8-12.4s-.755-10.238,4.019-9.5,3.376-7.907,7.449-6.656,6.793-2.25,10.283,0,10.448,3.24,9.708,6.656,4.828,9.375-.651,8.615-13.041,8.98-10.385,10.137,2.677,7.662,0,7.662-4.811,1.964-6.324,3.625-2.871.444-5.891-1.555S329.343,8332.984,326.681,8332.1Z" transform="translate(-312.373 -8304.316)"></path>
                            </g>
                            <path id="path-45-2" data-name="path-45" className="clsur-13" d="M153.082,8102.923s-3.216,1.445-1.6,5.2" transform="translate(-111.629 -8080.998)"></path>
                          </g>
                        </g>
                        <g id="group-89-3" data-name="group-89" transform="translate(687.781 408.966)">
                          <g id="group-88-3" data-name="group-88" transform="translate(0 0)">
                            <path id="path-63-3" data-name="path-63" className="clsur-15" d="M169.5,12533.076v-128.45l153.485-91.058,154.935,91.058v128.45l-154.935-91.292Z" transform="translate(-168.668 -12312.681)"></path>
                            <path id="path-23" data-name="path" className="clsur-15" d="M153.934,351.432,307.32,263.506,154.566,171.7,1,262.964Z" transform="translate(0.861 -42.417)"></path>
                            <line id="line-18-3" data-name="line-18" className="clsur-1" y1="128.912" transform="translate(154.694)"></line>
                            <line id="line-19-4" data-name="line-19" className="clsur-1" y1="128.912" transform="translate(0.832 91.486)"></line>
                            <line id="line-20-3" data-name="line-20" className="clsur-1" y1="93.149" x2="154.694"></line>
                            <line id="line-21-3" data-name="line-21" className="clsur-1" x1="157.189" y1="91.486" transform="translate(153.031)"></line>
                          </g>
                          <g id="group-87-3" data-name="group-87" transform="translate(83.757 104.931)">
                            <g id="group-64-3" data-name="group-64" transform="translate(0 0)">
                              <g id="group-5" data-name="group" transform="translate(0 6.308)">
                                <path id="path-24" data-name="path" className="clsur-5" d="M325.094,157.6,268.9,190.077l98.339,56.772,56.194-32.477Z" transform="translate(-268.487 -157.549)"></path>
                                <line id="line-13" data-name="line" className="clsur-5" y2="57.185" transform="translate(98.752 89.3)"></line>
                                <path id="line-14" data-name="line" className="clsur-5" d="M0,0V58.342" transform="translate(155.646 56.823)"></path>
                                <path id="path-25" data-name="path" className="clsur-6" d="M338.1,217.1" transform="translate(-280.501 -167.879)"></path>
                                <path id="path-26" data-name="path" className="clsur-7" d="M351.21,282.433s.083-21.259,0-25.225a8.833,8.833,0,0,0-3.471-6.363,3.6,3.6,0,0,0-.578-.5c-2.4-1.653-25.2-14.048-25.2-14.048l-.845,32.941,20.448-12.334,5.986,2.8-6.277,14,1.347,17.131s-.5,4.215,3.388,4.215l16.8-9.469S364.432,276.483,351.21,282.433Z" transform="translate(-277.415 -171.213)"></path>
                                <line id="line-15" data-name="line" className="clsur-5" y2="32.394" transform="translate(42.145 114.422)"></line>
                                <line id="line-16" data-name="line" className="clsur-5" x1="0.083" y2="32.559" transform="translate(0 89.961)"></line>
                                <line id="line-17" data-name="line" className="clsur-7" y2="32.063" transform="translate(70.325 98.473)"></line>
                                <g id="group-60-3" data-name="group-60" transform="translate(53.276 0)">
                                  <path id="path-27" data-name="path" className="clsur-7" d="M400.27,287.678l31.167,17.329,20.394-11.775V269.683l-30.591-16.9v23.351Z" transform="translate(-400.27 -252.78)"></path>
                                  <line id="line-18-4" data-name="line" className="clsur-9" x2="30.591" y2="17.101" transform="translate(20.97 23.351)"></line>
                                  <path id="path-28" data-name="path" className="clsur-10" d="M417.27,303.268l10.2-5.7,24.263,13.586-10.047,5.752Z" transform="translate(-407.073 -270.704)"></path>
                                </g>
                                <path id="path-29" data-name="path" className="clsur-7" d="M304.517,191.211c8.346-.661,10.971-7.6,10.971-16.114,0-8.925-3.369-16.2-12.293-16.2a16.189,16.189,0,0,0-11.239,27.849,10.475,10.475,0,0,0,1.074.909,13.7,13.7,0,0,1-2.314.413,9.946,9.946,0,0,0-9.917,9.917v48.1A9.946,9.946,0,0,0,290.716,256h21.073c5.454,0,6.611-4.462,6.611-9.916V224.762c4.545,1.818,10.164-1.157,10.164-1.157L346.5,212.78a3.948,3.948,0,0,0,2.314-.826l3.719-2.81c1.818-1.4,3.223-3.636,1.9-5.537-1.4-1.818-3.14-1.487-4.958-.083l-5.7,1.735a4.918,4.918,0,0,0-1.4,1.818h-.083l-15.371,7.52c-5.371,2.231-7.685-1.57-8.677-4.958a20.153,20.153,0,0,0-.744-4.462h0c-.992-3.471-2.81-6.528-5.785-7.851l-5.289-3.471C306.5,193.691,303.195,191.79,304.517,191.211Z" transform="translate(-270.553 -157.775)"></path>
                                <g id="group-6" data-name="group" transform="translate(0.083 32.693)">
                                  <path id="path-30" data-name="path" className="clsur-7" d="M319.5,290.557l28.1-15.784V265.6L320,281.8" transform="translate(-277.355 -208.993)"></path>
                                  <path id="path-31" data-name="path" className="clsur-5" d="M268.5,254.368V197.1l9.834,5.537,33.551,19.089-1.24,56.937Z" transform="translate(-268.5 -197.1)"></path>
                                </g>
                                <path id="path-32" data-name="path" className="clsur-12" d="M325.863,249s.8,5.185,5.431,4.193-4.109,12.665-4.109,12.665l-2.624-4.007Z" transform="translate(-279.212 -173.418)"></path>
                                <path id="path-33" data-name="path" className="clsur-12" d="M321.025,274.438,325,272.081l1.6-4.981h-4.3" transform="translate(-277.351 -177.261)"></path>
                                <path id="path-44-3" data-name="path-44" className="clsur-10" d="M54.562,102.6l-.831,13.089,20.854-12.382,6.386,3.067v10.511l-6.386,2.893L75.982,134l8.452-4.73v-25.96l-1.46-4.41L68.32,89.574l-9.774,2.865" transform="translate(-10.683 -17.758)"></path>
                              </g>
                              <path id="path-38-2" data-name="path-38" className="clsur-10" d="M535.134,8229.817c7.461-3.312,14.681-14.391,11.913-15.888s0-11.741,0-11.741,14.886,2.994,11.876,0a66.457,66.457,0,0,1-5.567-6.412s4.242-3.27-5.378-6.138-12.1.416-17.332,1.882-8.057,10.668-8.057,10.668S527.673,8233.128,535.134,8229.817Z" transform="translate(-510.586 -8183.376)"></path>
                              <path id="path-39-3" data-name="path-39" className="clsur-13" d="M534.259,8236.489s5.207,6.566,13.527,4.864" transform="translate(-513.306 -8199.998)"></path>
                              <path id="path-42-3" data-name="path-42" className="clsur-10" d="M608.493,8351.1l-.607,5.332,2.991-6.424Z" transform="translate(-535.376 -8233.323)"></path>
                              <path id="path-43-3" data-name="path-43" className="clsur-10" d="M572.6,8288.334s-.089,11.285.735,11.853,9.794-5.145,11.367-5.022S572.6,8288.334,572.6,8288.334Z" transform="translate(-524.797 -8214.838)"></path>
                            </g>
                            <path id="path-45-3" data-name="path-45" className="clsur-13" d="M153.082,8102.923s-3.216,1.445-1.6,5.2" transform="translate(-111.629 -8080.998)"></path>
                          </g>
                        </g>
                        <g id="group-89-4" data-name="group-89" transform="translate(229.521 626.037)">
                          <g id="group-88-4" data-name="group-88" transform="translate(0 0)">
                            <path id="path-63-4" data-name="path-63" className="clsur-15" d="M169.5,12533.076v-128.45l153.485-91.058,154.935,91.058v128.45l-154.935-91.292Z" transform="translate(-168.668 -12312.681)"></path>
                            <path id="path-34" data-name="path" className="clsur-15" d="M153.934,351.432,307.32,263.506,154.566,171.7,1,262.964Z" transform="translate(0.861 -42.417)"></path>
                            <line id="line-18-5" data-name="line-18" className="clsur-1" y1="128.912" transform="translate(154.694)"></line>
                            <line id="line-19-5" data-name="line-19" className="clsur-1" y1="128.912" transform="translate(0.832 91.486)"></line>
                            <line id="line-20-4" data-name="line-20" className="clsur-1" y1="93.149" x2="154.694"></line>
                            <line id="line-21-4" data-name="line-21" className="clsur-1" x1="157.189" y1="91.486" transform="translate(153.031)"></line>
                          </g>
                          <g id="group-87-4" data-name="group-87" transform="translate(83.757 104.931)">
                            <g id="group-64-4" data-name="group-64" transform="translate(0 0)">
                              <g id="group-7" data-name="group" transform="translate(0 6.308)">
                                <path id="path-35" data-name="path" className="clsur-16" d="M325.094,157.6,268.9,190.077l98.339,56.772,56.194-32.477Z" transform="translate(-268.487 -157.549)"></path>
                                <line id="line-19-6" data-name="line" className="clsur-5" y2="57.185" transform="translate(98.752 89.3)"></line>
                                <path id="line-20-5" data-name="line" className="clsur-5" d="M0,0V58.342" transform="translate(155.646 56.823)"></path>
                                <path id="path-36" data-name="path" className="clsur-6" d="M338.1,217.1" transform="translate(-280.501 -167.879)"></path>
                                <path id="path-37" data-name="path" className="clsur-7" d="M351.21,282.433s.083-21.259,0-25.225a8.833,8.833,0,0,0-3.471-6.363,3.6,3.6,0,0,0-.578-.5c-2.4-1.653-25.2-14.048-25.2-14.048l-.845,32.941,20.448-12.334,5.986,2.8-6.277,14,1.347,17.131s-.5,4.215,3.388,4.215l16.8-9.469S364.432,276.483,351.21,282.433Z" transform="translate(-277.415 -171.213)"></path>
                                <line id="line-21-5" data-name="line" className="clsur-5" y2="32.394" transform="translate(42.145 114.422)"></line>
                                <line id="line-22" data-name="line" className="clsur-5" x1="0.083" y2="32.559" transform="translate(0 89.961)"></line>
                                <line id="line-23" data-name="line" className="clsur-7" y2="32.063" transform="translate(70.325 98.473)"></line>
                                <g id="group-60-4" data-name="group-60" transform="translate(53.276 0)">
                                  <path id="path-38-3" data-name="path" className="clsur-7" d="M400.27,287.678l31.167,17.329,20.394-11.775V269.683l-30.591-16.9v23.351Z" transform="translate(-400.27 -252.78)"></path>
                                  <line id="line-24" data-name="line" className="clsur-9" x2="30.591" y2="17.101" transform="translate(20.97 23.351)"></line>
                                  <path id="path-39-4" data-name="path" className="clsur-10" d="M417.27,303.268l10.2-5.7,24.263,13.586-10.047,5.752Z" transform="translate(-407.073 -270.704)"></path>
                                </g>
                                <path id="path-40" data-name="path" className="clsur-7" d="M304.517,191.211c8.346-.661,10.971-7.6,10.971-16.114,0-8.925-3.369-16.2-12.293-16.2a16.189,16.189,0,0,0-11.239,27.849,10.475,10.475,0,0,0,1.074.909,13.7,13.7,0,0,1-2.314.413,9.946,9.946,0,0,0-9.917,9.917v48.1A9.946,9.946,0,0,0,290.716,256h21.073c5.454,0,6.611-4.462,6.611-9.916V224.762c4.545,1.818,10.164-1.157,10.164-1.157L346.5,212.78a3.948,3.948,0,0,0,2.314-.826l3.719-2.81c1.818-1.4,3.223-3.636,1.9-5.537-1.4-1.818-3.14-1.487-4.958-.083l-5.7,1.735a4.918,4.918,0,0,0-1.4,1.818h-.083l-15.371,7.52c-5.371,2.231-7.685-1.57-8.677-4.958a20.153,20.153,0,0,0-.744-4.462h0c-.992-3.471-2.81-6.528-5.785-7.851l-5.289-3.471C306.5,193.691,303.195,191.79,304.517,191.211Z" transform="translate(-270.553 -157.775)"></path>
                                <g id="group-8" data-name="group" transform="translate(0.083 32.693)">
                                  <path id="path-41" data-name="path" className="clsur-17" d="M319.5,290.557l28.1-15.784V265.6L320,281.8" transform="translate(-277.355 -208.993)"></path>
                                  <path id="path-42-4" data-name="path" className="clsur-16" d="M268.5,254.368V197.1l9.834,5.537,33.551,19.089-1.24,56.937Z" transform="translate(-268.5 -197.1)"></path>
                                </g>
                                <path id="path-43-4" data-name="path" className="clsur-12" d="M325.863,249s.8,5.185,5.431,4.193-4.109,12.665-4.109,12.665l-2.624-4.007Z" transform="translate(-279.212 -173.418)"></path>
                                <path id="path-44-4" data-name="path" className="clsur-12" d="M321.025,274.438,325,272.081l1.6-4.981h-4.3" transform="translate(-277.351 -177.261)"></path>
                                <path id="path-44-5" data-name="path-44" className="clsur-10" d="M54.562,102.6l-.831,13.089,20.854-12.382,6.386,3.067v10.511l-6.386,2.893L75.982,134l8.452-4.73v-25.96l-1.46-4.41L68.32,89.574l-9.774,2.865" transform="translate(-10.683 -17.758)"></path>
                              </g>
                              <path id="path-38-4" data-name="path-38" className="clsur-10" d="M536.746,8219.386c7.461-3.312,14-5.31,11.232-6.807s1.062-8.93,1.062-8.93,8.4-.109,5.394-3.1,4.869-5.864,4.869-5.864a20.619,20.619,0,0,0-12.861-10.706c-9.62-2.868-12.859,5.407-18.087,6.873s-2.417,17.036-2.417,17.036S529.285,8222.7,536.746,8219.386Z" transform="translate(-510.586 -8183.376)"></path>
                              <path id="path-39-5" data-name="path-39" className="clsur-13" d="M534.259,8236.489s5.207,6.566,13.527,4.864" transform="translate(-513.306 -8199.998)"></path>
                              <path id="path-42-5" data-name="path-42" className="clsur-10" d="M608.493,8351.1l-.607,5.332,2.991-6.424Z" transform="translate(-535.376 -8233.323)"></path>
                              <path id="path-43-5" data-name="path-43" className="clsur-10" d="M572.6,8288.334s-.089,11.285.735,11.853,9.794-5.145,11.367-5.022S572.6,8288.334,572.6,8288.334Z" transform="translate(-524.797 -8214.838)"></path>
                            </g>
                            <path id="path-45-4" data-name="path-45" className="clsur-13" d="M153.082,8102.923s-3.216,1.445-1.6,5.2" transform="translate(-111.629 -8080.998)"></path>
                          </g>
                        </g>
                        <g id="group-89-5" data-name="group-89" transform="translate(533.918 191.064)">
                          <g id="group-88-5" data-name="group-88" transform="translate(0 0)">
                            <path id="path-63-5" data-name="path-63" className="clsur-18" d="M169.5,12533.076v-128.45l153.485-91.058,154.935,91.058v128.45l-154.935-91.292Z" transform="translate(-168.668 -12312.681)"></path>
                            <path id="path-45-5" data-name="path" className="clsur-18" d="M153.934,351.432,307.32,263.506,154.566,171.7,1,262.964Z" transform="translate(0.861 -42.417)"></path>
                            <line id="line-18-6" data-name="line-18" className="clsur-1" y1="128.912" transform="translate(154.694)"></line>
                            <line id="line-19-7" data-name="line-19" className="clsur-1" y1="128.912" transform="translate(0.832 91.486)"></line>
                            <line id="line-20-6" data-name="line-20" className="clsur-1" y1="93.149" x2="154.694"></line>
                            <line id="line-21-6" data-name="line-21" className="clsur-1" x1="157.189" y1="91.486" transform="translate(153.031)"></line>
                          </g>
                          <g id="group-87-5" data-name="group-87" transform="translate(83.757 104.931)">
                            <g id="group-64-5" data-name="group-64" transform="translate(0 0)">
                              <g id="group-9" data-name="group" transform="translate(0 6.308)">
                                <path id="path-46-2" data-name="path" className="clsur-4" d="M325.094,157.6,268.9,190.077l98.339,56.772,56.194-32.477Z" transform="translate(-268.487 -157.549)"></path>
                                <line id="line-25" data-name="line" className="clsur-5" y2="57.185" transform="translate(98.752 89.3)"></line>
                                <path id="line-26" data-name="line" className="clsur-5" d="M0,0V58.342" transform="translate(155.646 56.823)"></path>
                                <path id="path-47" data-name="path" className="clsur-6" d="M338.1,217.1" transform="translate(-280.501 -167.879)"></path>
                                <path id="path-48" data-name="path" className="clsur-7" d="M351.21,282.433s.083-21.259,0-25.225a8.833,8.833,0,0,0-3.471-6.363,3.6,3.6,0,0,0-.578-.5c-2.4-1.653-25.2-14.048-25.2-14.048l-.845,32.941,20.448-12.334,5.986,2.8-6.277,14,1.347,17.131s-.5,4.215,3.388,4.215l16.8-9.469S364.432,276.483,351.21,282.433Z" transform="translate(-277.415 -171.213)"></path>
                                <line id="line-27" data-name="line" className="clsur-5" y2="32.394" transform="translate(42.145 114.422)"></line>
                                <line id="line-28" data-name="line" className="clsur-5" x1="0.083" y2="32.559" transform="translate(0 89.961)"></line>
                                <line id="line-29" data-name="line" className="clsur-7" y2="32.063" transform="translate(70.325 98.473)"></line>
                                <g id="group-60-5" data-name="group-60" transform="translate(53.276 0)">
                                  <path id="path-49" data-name="path" className="clsur-19" d="M400.27,287.678l31.167,17.329,20.394-11.775V269.683l-30.591-16.9v23.351Z" transform="translate(-400.27 -252.78)"></path>
                                  <line id="line-30" data-name="line" className="clsur-9" x2="30.591" y2="17.101" transform="translate(20.97 23.351)"></line>
                                  <path id="path-50" data-name="path" className="clsur-10" d="M417.27,303.268l10.2-5.7,24.263,13.586-10.047,5.752Z" transform="translate(-407.073 -270.704)"></path>
                                </g>
                                <path id="path-51" data-name="path" className="clsur-7" d="M304.517,191.211c8.346-.661,10.971-7.6,10.971-16.114,0-8.925-3.369-16.2-12.293-16.2a16.189,16.189,0,0,0-11.239,27.849,10.475,10.475,0,0,0,1.074.909,13.7,13.7,0,0,1-2.314.413,9.946,9.946,0,0,0-9.917,9.917v48.1A9.946,9.946,0,0,0,290.716,256h21.073c5.454,0,6.611-4.462,6.611-9.916V224.762c4.545,1.818,10.164-1.157,10.164-1.157L346.5,212.78a3.948,3.948,0,0,0,2.314-.826l3.719-2.81c1.818-1.4,3.223-3.636,1.9-5.537-1.4-1.818-3.14-1.487-4.958-.083l-5.7,1.735a4.918,4.918,0,0,0-1.4,1.818h-.083l-15.371,7.52c-5.371,2.231-7.685-1.57-8.677-4.958a20.153,20.153,0,0,0-.744-4.462h0c-.992-3.471-2.81-6.528-5.785-7.851l-5.289-3.471C306.5,193.691,303.195,191.79,304.517,191.211Z" transform="translate(-270.553 -157.775)"></path>
                                <g id="group-10" data-name="group" transform="translate(0.083 32.693)">
                                  <path id="path-52" data-name="path" className="clsur-11" d="M319.5,290.557l28.1-15.784V265.6L320,281.8" transform="translate(-277.355 -208.993)"></path>
                                  <path id="path-53" data-name="path" className="clsur-4" d="M268.5,254.368V197.1l9.834,5.537,33.551,19.089-1.24,56.937Z" transform="translate(-268.5 -197.1)"></path>
                                </g>
                                <path id="path-54" data-name="path" className="clsur-12" d="M325.863,249s.8,5.185,5.431,4.193-4.109,12.665-4.109,12.665l-2.624-4.007Z" transform="translate(-279.212 -173.418)"></path>
                                <path id="path-55" data-name="path" className="clsur-12" d="M321.025,274.438,325,272.081l1.6-4.981h-4.3" transform="translate(-277.351 -177.261)"></path>
                                <path id="path-44-6" data-name="path-44" className="clsur-10" d="M54.562,102.6l-.831,13.089,20.854-12.382,6.386,3.067v10.511l-6.386,2.893L75.982,134l8.452-4.73v-25.96l-1.46-4.41L68.32,89.574l-9.774,2.865" transform="translate(-10.683 -17.758)"></path>
                              </g>
                              <path id="path-39-6" data-name="path-39" className="clsur-13" d="M534.259,8236.489s5.207,6.566,13.527,4.864" transform="translate(-513.306 -8199.998)"></path>
                              <path id="path-42-6" data-name="path-42" className="clsur-10" d="M608.493,8351.1l-.607,5.332,2.991-6.424Z" transform="translate(-535.376 -8233.323)"></path>
                              <path id="path-43-6" data-name="path-43" className="clsur-10" d="M572.6,8288.334s-.089,11.285.735,11.853,9.794-5.145,11.367-5.022S572.6,8288.334,572.6,8288.334Z" transform="translate(-524.797 -8214.838)"></path>
                            </g>
                            <path id="path-45-6" data-name="path-45" className="clsur-13" d="M153.082,8102.923s-3.216,1.445-1.6,5.2" transform="translate(-111.629 -8080.998)"></path>
                          </g>
                        </g>
                        <g id="group-89-6" data-name="group-89" transform="translate(69.5 409.798)">
                          <g id="group-88-6" data-name="group-88" transform="translate(0 0)">
                            <path id="path-63-6" data-name="path-63" className="clsur-10" d="M169.5,12533.076v-128.45l153.485-91.058,154.935,91.058v128.45l-154.935-91.292Z" transform="translate(-168.668 -12312.681)"></path>
                            <path id="path-56" data-name="path" className="clsur-20" d="M153.934,351.432,307.32,263.506,154.566,171.7,1,262.964Z" transform="translate(0.861 -42.417)"></path>
                            <line id="line-18-7" data-name="line-18" className="clsur-1" y1="128.912" transform="translate(154.694)"></line>
                            <line id="line-19-8" data-name="line-19" className="clsur-1" y1="128.912" transform="translate(0.832 91.486)"></line>
                            <line id="line-20-7" data-name="line-20" className="clsur-1" y1="93.149" x2="154.694"></line>
                            <line id="line-21-7" data-name="line-21" className="clsur-1" x1="157.189" y1="91.486" transform="translate(153.031)"></line>
                          </g>
                          <g id="group-87-6" data-name="group-87" transform="translate(83.757 104.931)">
                            <g id="group-64-6" data-name="group-64" transform="translate(0 0)">
                              <g id="group-11" data-name="group" transform="translate(0 6.308)">
                                <path id="path-57" data-name="path" className="clsur-4" d="M325.094,157.6,268.9,190.077l98.339,56.772,56.194-32.477Z" transform="translate(-268.487 -157.549)"></path>
                                <line id="line-31" data-name="line" className="clsur-21" y2="57.185" transform="translate(98.752 89.3)"></line>
                                <path id="line-32" data-name="line" className="clsur-21" d="M0,0V58.342" transform="translate(153.646 57.823)"></path>
                                <path id="path-58" data-name="path" className="clsur-6" d="M338.1,217.1" transform="translate(-280.501 -167.879)"></path>
                                <path id="path-59" data-name="path" className="clsur-22" d="M351.21,282.433s.083-21.259,0-25.225a8.833,8.833,0,0,0-3.471-6.363,3.6,3.6,0,0,0-.578-.5c-2.4-1.653-25.2-14.048-25.2-14.048l-.845,32.941,20.448-12.334,5.986,2.8-6.277,14,1.347,17.131s-.5,4.215,3.388,4.215l16.8-9.469S364.432,276.483,351.21,282.433Z" transform="translate(-277.415 -171.213)"></path>
                                <line id="line-33" data-name="line" className="clsur-21" y2="32.394" transform="translate(42.145 114.422)"></line>
                                <line id="line-34" data-name="line" className="clsur-21" x1="0.083" y2="32.559" transform="translate(0 89.961)"></line>
                                <line id="line-35" data-name="line" className="clsur-5" y2="32.063" transform="translate(70.325 98.473)"></line>
                                <g id="group-60-6" data-name="group-60" transform="translate(53.276 0)">
                                  <path id="path-60" data-name="path" className="clsur-17" d="M400.27,287.678l31.167,17.329,20.394-11.775V269.683l-30.591-16.9v23.351Z" transform="translate(-400.27 -252.78)"></path>
                                  <line id="line-36" data-name="line" className="clsur-9" x2="30.591" y2="17.101" transform="translate(20.97 23.351)"></line>
                                  <path id="path-61" data-name="path" className="clsur-10" d="M417.27,303.268l10.2-5.7,24.263,13.586-10.047,5.752Z" transform="translate(-407.073 -270.704)"></path>
                                </g>
                                <path id="path-62" data-name="path" className="clsur-7" d="M304.517,191.211c8.346-.661,10.971-7.6,10.971-16.114,0-8.925-3.369-16.2-12.293-16.2a16.189,16.189,0,0,0-11.239,27.849,10.475,10.475,0,0,0,1.074.909,13.7,13.7,0,0,1-2.314.413,9.946,9.946,0,0,0-9.917,9.917v48.1A9.946,9.946,0,0,0,290.716,256h21.073c5.454,0,6.611-4.462,6.611-9.916V224.762c4.545,1.818,10.164-1.157,10.164-1.157L346.5,212.78a3.948,3.948,0,0,0,2.314-.826l3.719-2.81c1.818-1.4,3.223-3.636,1.9-5.537-1.4-1.818-3.14-1.487-4.958-.083l-5.7,1.735a4.918,4.918,0,0,0-1.4,1.818h-.083l-15.371,7.52c-5.371,2.231-7.685-1.57-8.677-4.958a20.153,20.153,0,0,0-.744-4.462h0c-.992-3.471-2.81-6.528-5.785-7.851l-5.289-3.471C306.5,193.691,303.195,191.79,304.517,191.211Z" transform="translate(-270.553 -157.775)"></path>
                                <g id="group-12" data-name="group" transform="translate(0.083 32.693)">
                                  <path id="path-63-7" data-name="path" className="clsur-11" d="M319.5,290.557l28.1-15.784V265.6L320,281.8" transform="translate(-277.355 -208.993)"></path>
                                  <path id="path-64" data-name="path" className="clsur-4" d="M268.5,254.368V197.1l9.834,5.537,33.551,19.089-1.24,56.937Z" transform="translate(-268.5 -197.1)"></path>
                                </g>
                                <path id="path-65" data-name="path" className="clsur-12" d="M325.863,249s.8,5.185,5.431,4.193-4.109,12.665-4.109,12.665l-2.624-4.007Z" transform="translate(-279.212 -173.418)"></path>
                                <path id="path-66" data-name="path" className="clsur-12" d="M321.025,274.438,325,272.081l1.6-4.981h-4.3" transform="translate(-277.351 -177.261)"></path>
                                <path id="path-44-7" data-name="path-44" className="clsur-10" d="M54.562,102.6l-.831,13.089,20.854-12.382,6.386,3.067v10.511l-6.386,2.893L75.982,134l8.452-4.73v-25.96l-1.46-4.41L68.32,89.574l-9.774,2.865" transform="translate(-10.683 -17.758)"></path>
                              </g>
                              <path id="path-38-5" data-name="path-38" className="clsur-23" d="M536.746,8219.386c7.461-3.312,14-5.31,11.232-6.807s1.062-8.93,1.062-8.93,8.4-.109,5.394-3.1,4.869-5.864,4.869-5.864a20.619,20.619,0,0,0-12.861-10.706c-9.62-2.868-12.859,5.407-18.087,6.873s-2.417,17.036-2.417,17.036S529.285,8222.7,536.746,8219.386Z" transform="translate(-510.586 -8183.376)"></path>
                              <path id="path-39-7" data-name="path-39" className="clsur-13" d="M534.259,8236.489s5.207,6.566,13.527,4.864" transform="translate(-513.306 -8199.998)"></path>
                              <path id="path-42-7" data-name="path-42" className="clsur-10" d="M608.493,8351.1l-.607,5.332,2.991-6.424Z" transform="translate(-535.376 -8233.323)"></path>
                              <path id="path-43-7" data-name="path-43" className="clsur-10" d="M572.6,8288.334s-.089,11.285.735,11.853,9.794-5.145,11.367-5.022S572.6,8288.334,572.6,8288.334Z" transform="translate(-524.797 -8214.838)"></path>
                            </g>
                            <path id="path-45-7" data-name="path-45" className="clsur-13" d="M153.082,8102.923s-3.216,1.445-1.6,5.2" transform="translate(-111.629 -8080.998)"></path>
                          </g>
                        </g>
                        <g id="group-90" transform="translate(267.362 227.242)">
                          <g id="group-46" transform="translate(0 0)">
                            <g id="ellipse-9" className="clsur-7">
                              <ellipse className="clsur-35" cx="28.277" cy="28.277" rx="28.277" ry="28.277"></ellipse>
                              <ellipse className="clsur-36" cx="28.277" cy="28.277" rx="27.277" ry="27.277"></ellipse>
                            </g>
                            <g id="ellipse-9-2" data-name="ellipse-9" className="clsur-7" transform="translate(51.565 51.565)">
                              <ellipse className="clsur-35" cx="6.654" cy="6.654" rx="6.654" ry="6.654"></ellipse>
                              <ellipse className="clsur-36" cx="6.654" cy="6.654" rx="5.654" ry="5.654"></ellipse>
                            </g>
                            <g id="group-44" transform="translate(20.792 23.287)">
                              <ellipse id="ellipse-10" className="clsur-10" cx="1.871" cy="1.871" rx="1.871" ry="1.871"></ellipse>
                              <ellipse id="ellipse-10-2" data-name="ellipse-10" className="clsur-10" cx="1.871" cy="1.871" rx="1.871" ry="1.871" transform="translate(11.228)"></ellipse>
                            </g>
                          </g>
                          <path id="path-64-2" data-name="path-64" className="clsur-13" d="M245.93,12355s5.856,6.385,11.332,0" transform="translate(-223.533 -12322.563)"></path>
                        </g>
                        <g id="ellipse-9-3" data-name="ellipse-9" className="clsur-7" transform="translate(664.077 222.252)">
                          <ellipse className="clsur-35" cx="28.277" cy="28.277" rx="28.277" ry="28.277"></ellipse>
                          <ellipse className="clsur-36" cx="28.277" cy="28.277" rx="27.277" ry="27.277"></ellipse>
                        </g>
                        <g id="ellipse-9-4" data-name="ellipse-9" className="clsur-7" transform="translate(657.424 273.817)">
                          <ellipse className="clsur-35" cx="6.654" cy="6.654" rx="6.654" ry="6.654"></ellipse>
                          <ellipse className="clsur-36" cx="6.654" cy="6.654" rx="5.654" ry="5.654"></ellipse>
                        </g>
                        <g id="group-44-2" data-name="group-44" transform="translate(684.87 245.54)">
                          <ellipse id="ellipse-10-3" data-name="ellipse-10" className="clsur-10" cx="1.871" cy="1.871" rx="1.871" ry="1.871"></ellipse>
                          <ellipse id="ellipse-10-4" data-name="ellipse-10" className="clsur-10" cx="1.871" cy="1.871" rx="1.871" ry="1.871" transform="translate(11.228)"></ellipse>
                        </g>
                        <path id="path-64-3" data-name="path-64" className="clsur-13" d="M245.93,12355s5.856,6.385,11.332,0" transform="translate(440.545 -12100.312)"></path>
                        <path id="path-65-2" data-name="path-65" className="clsur-24" d="M398.661,12630.979v45.388l29.882-17.784v-46.007Z" transform="translate(8 -12114)"></path>
                        <path id="path-65-3" data-name="path-65" className="clsur-13" d="M398.661,12630.979v45.388l29.882-17.784v-46.007Z" transform="translate(56 -12146)"></path>
                        <line id="line-22-2" data-name="line-22" className="clsur-13" y1="12" x2="19" transform="translate(460.5 482.5)"></line>
                        <line id="line-22-3" data-name="line-22" className="clsur-13" y1="12" x2="19" transform="translate(460.5 489.5)"></line>
                        <line id="line-22-4" data-name="line-22" className="clsur-13" y1="12" x2="19" transform="translate(460.5 496.5)"></line>
                        <path id="path-65-4" data-name="path-65" className="clsur-24" d="M398.661,12618.313v14.148l9.315-5.543v-14.343Z" transform="translate(15.283 -12096.048)"></path>
                        <line id="line-23-2" data-name="line-23" className="clsur-24" y1="10" x2="17" transform="translate(413.5 533.5)"></line>
                        <line id="line-23-3" data-name="line-23" className="clsur-24" y1="10" x2="17" transform="translate(413.5 538.5)"></line>
                        <path id="path-66-2" data-name="path-66" className="clsur-25" d="M387.091,12417.219v-68.359l112.327,65.075v69.514Z" transform="translate(13 -12119)"></path>
                        <path id="path-66-3" data-name="path-66" className="clsur-13" d="M387.091,12365.481v-16.622l27.312,15.823v16.9Z" transform="translate(25 -12096.58)"></path>
                        <path id="path-66-4" data-name="path-66" className="clsur-13" d="M387.091,12365.481v-16.622l27.312,15.823v16.9Z" transform="translate(59.596 -12076.137)"></path>
                        <line id="line-25-2" data-name="line-25" className="clsur-13" x2="76" y2="45" transform="translate(410.5 283.5)"></line>
                        <line id="line-25-3" data-name="line-25" className="clsur-13" x2="76" y2="45" transform="translate(410.5 290.5)"></line>
                        <g id="group-17" transform="translate(-348 459.229)">
                          <path id="path-10-2" data-name="path-10" className="clsur-2" d="M0,17.663a9.886,9.886,0,0,1,.546-3.25L.524,14.4l.119-.258a9.857,9.857,0,0,1,.585-1.262L5.973,2.649A3.7,3.7,0,0,1,9.167,0h1.1c1.49,0,2.772,1.262,3.346,3.076L19,13.7l-.025,0A9.9,9.9,0,1,1,0,17.663Z" transform="translate(939 47.208)"></path>
                          <g id="rectangle-6" className="clsur-26" transform="translate(945.6 42.503)">
                            <rect className="clsur-35" width="6.6" height="7.7" rx="3.3"></rect>
                            <rect className="clsur-36" x="1.5" y="1.5" width="3.6" height="4.7" rx="1.8"></rect>
                          </g>
                          <g id="rectangle-7" className="clsur-13" transform="translate(947.801 -7)">
                            <rect className="clsur-35" width="2.2" height="53.537" rx="1.1"></rect>
                            <rect className="clsur-36" x="1" y="1" width="0.2" height="51.537" rx="0.1"></rect>
                          </g>
                        </g>
                        <path id="path-69" className="clsur-12" d="M535.623,12661.939v-12.624l21,11.517v13.364Z" transform="translate(-304 -12121)"></path>
                        <path id="path-69-2" data-name="path-69" className="clsur-27" d="M535.623,12661.939v-12.624l21.436,11.517v13.364Z" transform="translate(314.565 -12121)"></path>
                        <path id="path-70" className="clsur-19" d="M64.343,45.66a1.141,1.141,0,0,0-.107-.5L61.7,39.087v-12a6.973,6.973,0,0,1,.5-3.036c2-5.108,1.143-13.825,1.107-14.182A1.325,1.325,0,0,0,61.95,8.688a1.3,1.3,0,0,0-1.215,1.322A22.355,22.355,0,0,1,57.413,20.3a1.286,1.286,0,0,1-1.286.572c-.071,0-28.078-4.5-28.078-4.5L20.583,7.08c.071-.107.143-.25.214-.357a5,5,0,0,0-.143-5,1.272,1.272,0,0,0-1.107-.607,5.031,5.031,0,0,0-1.929.393A5.378,5.378,0,0,0,17.261.9,1.27,1.27,0,0,0,16.225.4a4.817,4.817,0,0,0-4.037,2.751,5.931,5.931,0,0,0-.429,1.5l-4,3.215H1.686A1.281,1.281,0,0,0,.4,9.152,9.306,9.306,0,0,0,8.938,18.44l4.215,13.539a11.946,11.946,0,0,0,6.5,7.216v11.86a3.474,3.474,0,0,0-2.322,5.716,3.484,3.484,0,0,0,2.643,1.215h2.786A4.006,4.006,0,0,0,25.8,56.591h.965a3.994,3.994,0,0,0,4-4V39.48l14.36-1.893,7.43,9.324v2.715a3.474,3.474,0,0,0-2.322,5.716,3.484,3.484,0,0,0,2.643,1.215h1.929c.036.071.107.107.143.179a3.484,3.484,0,0,0,2.643,1.215h2.751a3.994,3.994,0,0,0,4-4h0C64.343,54.019,64.343,45.66,64.343,45.66Z" transform="translate(694.093 394.215)"></path>
                        <g id="group-92" transform="translate(-151.469 -216)">
                          <path id="ellipse-17" className="clsur-19" d="M15.541.2c8.691.161,15.976,10.948,16.271,24.093S25.3,47.959,16.61,47.8.635,36.85.339,23.7,6.85.034,15.541.2Z" transform="matrix(0.995, -0.105, 0.105, 0.995, 641.887, 503.315)"></path>
                          <path id="ellipse-17-2" data-name="ellipse-17" className="clsur-7" d="M10.949.138c6.138.114,11.314,9.154,11.562,20.191S17.985,40.222,11.848,40.108.533,30.954.285,19.917,4.812.024,10.949.138Z" transform="matrix(0.995, -0.105, 0.105, 0.995, 645.399, 506.679)"></path>
                          <path id="path-71" className="clsur-13" d="M655.813,12648.4v10.58l6.566,3.184" transform="translate(1.913 -12132)"></path>
                        </g>
                        <g id="group-92-2" data-name="group-92" transform="translate(-151.469 223)">
                          <path id="ellipse-17-3" data-name="ellipse-17" className="clsur-8" d="M15.541.2c8.691.161,15.976,10.948,16.271,24.093S25.3,47.959,16.61,47.8.635,36.85.339,23.7,6.85.034,15.541.2Z" transform="matrix(0.995, -0.105, 0.105, 0.995, 641.887, 503.315)"></path>
                          <path id="ellipse-17-4" data-name="ellipse-17" className="clsur-7" d="M10.949.138c6.138.114,11.314,9.154,11.562,20.191S17.985,40.222,11.848,40.108.533,30.954.285,19.917,4.812.024,10.949.138Z" transform="matrix(0.995, -0.105, 0.105, 0.995, 645.399, 506.679)"></path>
                          <path id="path-71-2" data-name="path-71" className="clsur-13" d="M655.813,12648.4v10.58l6.566,3.184" transform="translate(1.913 -12132)"></path>
                        </g>
                        <path id="path-46-3" data-name="path-46" className="clsur-10" d="M326.681,8332.1c-2.663-.891-4.532-11.4-2.8-12.4s-.755-10.238,4.019-9.5,3.376-7.907,7.449-6.656,6.793-2.25,10.283,0,10.448,3.24,9.708,6.656,4.828,9.375-.651,8.615-13.041,8.98-10.385,10.137,2.677,7.662,0,7.662-4.811,1.964-6.324,3.625-2.871.444-5.891-1.555S329.343,8332.984,326.681,8332.1Z" transform="translate(305.271 -8006.251)"></path>
                        <g id="ellipse-19" className="clsur-8" transform="translate(495.915 529.404)">
                          <ellipse className="clsur-35" cx="6.043" cy="6.798" rx="6.043" ry="6.798"></ellipse>
                          <ellipse className="clsur-36" cx="6.043" cy="6.798" rx="5.043" ry="5.798"></ellipse>
                        </g>
                        <path id="path-85" className="clsur-8" d="M548.488,8420.905c-11.769,3.393,3.545-4.581,3.545-4.581s12.151-3.753,17.358,16.3-5.134.563-5.059,2.008S560.257,8417.512,548.488,8420.905Z" transform="translate(-66.223 -7902.958)"></path>
                        <g id="group-129" transform="translate(-234.584 -289)">
                          <g id="group-13" data-name="group" transform="translate(621.428 603.404)">
                            <g id="group-14" data-name="group">
                              <g id="group-15" data-name="group">
                                <path id="path-67" data-name="path" className="clsur-12" d="M3.11,8.973a2.772,2.772,0,0,1-.893-1.059L4,8.94a.747.747,0,0,1-.893.033M2.216,6.888c-.033-.033-.1-.066-.132-.132-.165-.165-.232-.4-.132-.529a.172.172,0,0,1,.265-.033l.132.132a.567.567,0,0,1,.132.529.2.2,0,0,1-.265.033M3.97,7.914c-.033-.033-.1-.066-.132-.132a.567.567,0,0,1-.132-.529.172.172,0,0,1,.265-.033c.033.033.1.066.132.132a.567.567,0,0,1,.132.529.194.194,0,0,1-.265.033M3.077,4.837a1.552,1.552,0,0,0-.728-.3C1.124,4.307.463,5.432.86,7.087a5.813,5.813,0,0,0,.893,1.886C.727,8.808,0,9.47-.1,10.694l6.287,3.64A6.327,6.327,0,0,0,4.367,10.5c.86-.165,1.257-1.191.927-2.548A5.008,5.008,0,0,0,3.077,4.837" transform="translate(0.1 -4.509)"></path>
                              </g>
                            </g>
                          </g>
                          <line id="line-28-2" data-name="line-28" className="clsur-13" y2="21.874" transform="translate(630.112 599.5)"></line>
                          <g id="group-126" transform="translate(632.003 606.108)">
                            <g id="group-16" data-name="group" transform="translate(0 0)">
                              <g id="group-17-2" data-name="group">
                                <g id="group-18" data-name="group">
                                  <g id="group-19" data-name="group">
                                    <path id="path-68" data-name="path" className="clsur-28" d="M3.687,5.15,9.971,6.873,3.687,3.249C3.205,2.971,2.8,3.2,2.8,3.756a1.934,1.934,0,0,0,.862,1.52L9.946,8.9c.481.279.887.051.887-.507a1.869,1.869,0,0,0-.862-1.52" transform="translate(-2.192 -3.131)"></path>
                                    <path id="path-69-3" data-name="path" className="clsur-12" d="M25.97,26.7l-6.284-3.624c-.431-.253-.786-.051-.786.456a1.67,1.67,0,0,0,.786,1.343L25.97,28.5c.431.253.786.051.786-.456A1.708,1.708,0,0,0,25.97,26.7" transform="translate(-14.212 -17.009)"></path>
                                    <path id="path-70-2" data-name="path" className="clsur-28" d="M7.47,25.9,1.186,22.279C.755,22.025.4,22.228.4,22.735a1.67,1.67,0,0,0,.786,1.343L7.47,27.7c.431.253.786.051.786-.456A1.748,1.748,0,0,0,7.47,25.9" transform="translate(-0.4 -15.011)"></path>
                                    <path id="path-71-3" data-name="path" className="clsur-12" d="M27.67,47.4l-6.284-3.624c-.431-.253-.786-.051-.786.456a1.754,1.754,0,0,0,.786,1.368l6.284,3.624c.431.253.786.051.786-.456A1.754,1.754,0,0,0,27.67,47.4" transform="translate(-15.481 -30.596)"></path>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                        <g id="group-129-2" data-name="group-129" transform="translate(-79.584 -73)">
                          <g id="group-20" data-name="group" transform="translate(621.428 603.404)">
                            <g id="group-21" data-name="group">
                              <g id="group-22" data-name="group">
                                <path id="path-72" data-name="path" className="clsur-12" d="M3.11,8.973a2.772,2.772,0,0,1-.893-1.059L4,8.94a.747.747,0,0,1-.893.033M2.216,6.888c-.033-.033-.1-.066-.132-.132-.165-.165-.232-.4-.132-.529a.172.172,0,0,1,.265-.033l.132.132a.567.567,0,0,1,.132.529.2.2,0,0,1-.265.033M3.97,7.914c-.033-.033-.1-.066-.132-.132a.567.567,0,0,1-.132-.529.172.172,0,0,1,.265-.033c.033.033.1.066.132.132a.567.567,0,0,1,.132.529.194.194,0,0,1-.265.033M3.077,4.837a1.552,1.552,0,0,0-.728-.3C1.124,4.307.463,5.432.86,7.087a5.813,5.813,0,0,0,.893,1.886C.727,8.808,0,9.47-.1,10.694l6.287,3.64A6.327,6.327,0,0,0,4.367,10.5c.86-.165,1.257-1.191.927-2.548A5.008,5.008,0,0,0,3.077,4.837" transform="translate(0.1 -4.509)"></path>
                              </g>
                            </g>
                          </g>
                          <line id="line-28-3" data-name="line-28" className="clsur-13" y2="21.874" transform="translate(630.112 599.5)"></line>
                          <g id="group-126-2" data-name="group-126" transform="translate(632.003 606.108)">
                            <g id="group-23" data-name="group" transform="translate(0 0)">
                              <g id="group-24" data-name="group">
                                <g id="group-25" data-name="group">
                                  <g id="group-26" data-name="group">
                                    <path id="path-73" data-name="path" className="clsur-28" d="M3.687,5.15,9.971,6.873,3.687,3.249C3.205,2.971,2.8,3.2,2.8,3.756a1.934,1.934,0,0,0,.862,1.52L9.946,8.9c.481.279.887.051.887-.507a1.869,1.869,0,0,0-.862-1.52" transform="translate(-2.192 -3.131)"></path>
                                    <path id="path-74" data-name="path" className="clsur-12" d="M25.97,26.7l-6.284-3.624c-.431-.253-.786-.051-.786.456a1.67,1.67,0,0,0,.786,1.343L25.97,28.5c.431.253.786.051.786-.456A1.708,1.708,0,0,0,25.97,26.7" transform="translate(-14.212 -17.009)"></path>
                                    <path id="path-75" data-name="path" className="clsur-28" d="M7.47,25.9,1.186,22.279C.755,22.025.4,22.228.4,22.735a1.67,1.67,0,0,0,.786,1.343L7.47,27.7c.431.253.786.051.786-.456A1.748,1.748,0,0,0,7.47,25.9" transform="translate(-0.4 -15.011)"></path>
                                    <path id="path-76" data-name="path" className="clsur-12" d="M27.67,47.4l-6.284-3.624c-.431-.253-.786-.051-.786.456a1.754,1.754,0,0,0,.786,1.368l6.284,3.624c.431.253.786.051.786-.456A1.754,1.754,0,0,0,27.67,47.4" transform="translate(-15.481 -30.596)"></path>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                        <g id="group-161" transform="translate(-429.671 253.057)">
                          <path id="path-89" className="clsur-29" d="M829.6,8323.455l6.51-14.958,10.482,5.968v19Z" transform="translate(-1.546 -7812)"></path>
                          <g id="group-105" transform="matrix(0.999, 0.035, -0.035, 0.999, 826.796, 499.586)">
                            <g id="group-27" data-name="group" transform="translate(0 0)">
                              <g id="group-28" data-name="group" transform="translate(0)">
                                <g id="group-29" data-name="group" transform="translate(1.767 1.624)">
                                  <path id="path-77" data-name="path" d="M1.855.039,2.013,0,.158.493,0,.533Z"></path>
                                </g>
                                <g id="group-30" data-name="group" transform="translate(0 0.063)">
                                  <path id="path-78" data-name="path" className="clsur-30" d="M3.2,0,3.1,1.618l-1.855.493,1.7,2.23-.1,1.618L0,2.21.079.829Z"></path>
                                </g>
                                <g id="group-31" data-name="group" transform="translate(3.304 0)">
                                  <path id="path-79" data-name="path" className="clsur-30" d="M3.474.336.691,7.835,0,7.5,2.783,0Z"></path>
                                </g>
                              </g>
                              <g id="group-32" data-name="group" transform="translate(6.543 2.099)">
                                <g id="group-33" data-name="group" transform="translate(0.079 3.294)">
                                  <path id="path-80" data-name="path" d="M0,.513.158.474,2.013,0,1.875.039Z"></path>
                                </g>
                                <g id="group-34" data-name="group" transform="translate(0 4.577)">
                                  <path id="path-81" data-name="path" d="M3.118.039,3.256,0,.138.829,0,.868Z"></path>
                                </g>
                                <g id="group-35" data-name="group" transform="translate(0.28 0)">
                                  <path id="path-82" data-name="path" className="clsur-30" d="M.336,0,3.2,3.75,3.118,5.131,0,5.96.079,4.322l1.875-.474-1.7-2.23Z"></path>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                        <g id="group-123" transform="translate(-264 46.773)">
                          <path id="path-34-2" data-name="path-34" className="clsur-10" d="M3688.191,5221.893l21.792,11.867-.294,7.482-21.5-11.994Z" transform="translate(-2727.706 -4959)"></path>
                          <animate xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" xlink="#group-123" attributeName="d" attributeType="XML" from="M3688.191,5221.893l21.792,11.867-.294,7.482-21.5-11.994Z" to="M3688.191,5221.893l21.792,0.867-.294,7.482-21.5-11.994Z" dur="60s" fill="freeze"></animate>
                          <path id="path-34-3" data-name="path-34" className="clsur-10" d="M3690.191,5218.893l9.134,5.022v4.351l-9.134-5.038Z" transform="translate(-2730 -4946.257)"></path>
                          <path id="path-34-4" data-name="path-34" className="clsur-10" d="M3690.191,5217.893l9.428,5.316v4.646l-9.428-5.333Z" transform="translate(-2718.224 -4938.78)"></path>
                        </g>
                        <g id="group-163" transform="translate(-419 232)">
                          <g id="group-162" transform="translate(820.012 444.078)">
                            <g id="group-46-2" data-name="group-46" transform="translate(64.91 3.232) rotate(93)">
                              <g id="ellipse-9-5" data-name="ellipse-9" className="clsur-7" transform="translate(0 0)">
                                <ellipse className="clsur-35" cx="26.922" cy="26.922" rx="26.922" ry="26.922"></ellipse>
                                <ellipse className="clsur-36" cx="26.922" cy="26.922" rx="25.922" ry="25.922"></ellipse>
                              </g>
                              <g id="ellipse-9-6" data-name="ellipse-9" className="clsur-7" transform="translate(49.092 49.093)">
                                <ellipse className="clsur-35" cx="6.334" cy="6.335" rx="6.334" ry="6.335"></ellipse>
                                <ellipse className="clsur-36" cx="6.334" cy="6.335" rx="5.334" ry="5.335"></ellipse>
                              </g>
                            </g>
                          </g>
                          <text id="_" data-name="</>" className="clsur-31" transform="translate(842.173 478.072)"><tspan x="0" y="0">&lt;/&gt;</tspan></text>
                        </g>
                        <g id="group-164" transform="translate(-1125.295 -236.178) rotate(13)">
                          <g id="group-46-3" data-name="group-46" transform="matrix(0.891, -0.454, 0.454, 0.891, 1348.545, 408.54)">
                            <g id="ellipse-9-7" data-name="ellipse-9" className="clsur-7" transform="translate(0 0)">
                              <circle className="clsur-35" cx="27.839" cy="27.839" r="27.839"></circle>
                              <circle className="clsur-36" cx="27.839" cy="27.839" r="26.839"></circle>
                            </g>
                            <g id="ellipse-9-8" data-name="ellipse-9" className="clsur-7" transform="translate(50.765 50.765)">
                              <circle className="clsur-35" cx="6.55" cy="6.55" r="6.55"></circle>
                              <circle className="clsur-36" cx="6.55" cy="6.55" r="5.55"></circle>
                            </g>
                          </g>
                          <text id="_2" data-name="</>" className="clsur-32" transform="matrix(0.978, -0.208, 0.208, 0.978, 1381.505, 429.197)"><tspan x="0" y="0"></tspan></text>
                        </g>
                        <g id="group-90-2" data-name="group-90" transform="translate(274.016 661.384)">
                          <g id="group-46-4" data-name="group-46" transform="translate(0 0)">
                            <g id="ellipse-9-9" data-name="ellipse-9" className="clsur-7">
                              <ellipse className="clsur-35" cx="28.277" cy="28.277" rx="28.277" ry="28.277"></ellipse>
                              <ellipse className="clsur-36" cx="28.277" cy="28.277" rx="27.277" ry="27.277"></ellipse>
                            </g>
                            <g id="ellipse-9-10" data-name="ellipse-9" className="clsur-7" transform="translate(51.565 51.565)">
                              <ellipse className="clsur-35" cx="6.654" cy="6.654" rx="6.654" ry="6.654"></ellipse>
                              <ellipse className="clsur-36" cx="6.654" cy="6.654" rx="5.654" ry="5.654"></ellipse>
                            </g>
                            <g id="group-44-3" data-name="group-44" transform="translate(20.792 23.287)">
                              <ellipse id="ellipse-10-5" data-name="ellipse-10" className="clsur-10" cx="1.871" cy="1.871" rx="1.871" ry="1.871"></ellipse>
                              <ellipse id="ellipse-10-6" data-name="ellipse-10" className="clsur-10" cx="1.871" cy="1.871" rx="1.871" ry="1.871" transform="translate(11.228)"></ellipse>
                            </g>
                          </g>
                          <path id="path-64-4" data-name="path-64" className="clsur-13" d="M245.93,12355s5.856,6.385,11.332,0" transform="translate(-223.533 -12322.563)"></path>
                        </g>
                        <path id="path-84" className="clsur-12" d="M0,0,2.85,31.977l12.789,3.549,12.823-3.558L31.32,0ZM25.379,7.59,25.2,9.577l-.078.881H10.112l.363,4.015H24.766l-.095,1.054-.924,10.319-.06.665-8.022,2.228h0l-.017.009L7.616,26.519l-.553-6.157H11l.276,3.126,4.369,1.183h0l4.369-1.183.458-5.086H6.882L5.924,7.59,5.829,6.537H25.474Z" transform="translate(247 325)"></path>
                        <g id="group-120" transform="translate(789.5 291.262)">
                          <path id="path-83" data-name="path" className="clsur-12" d="M70.43,58.644h3.492L70.45,51.4,67.4,58.644Z" transform="translate(-53.874 -41.085)"></path>
                          <path id="path-84-2" data-name="path" className="clsur-12" d="M16.6,0,0,5.84,2.629,27.573l13.987,7.686,14.068-7.786L33.313,5.739ZM23.62,25.827,21.372,20.61H12.181l-2.067,5.137-3.833.08L16.556,2.99h0l10.736,22.9Z"></path>
                        </g>
                        <path id="path-99" className="clsur-24" d="M181.266,12628.5s-105.354-245.479,126.891-191.464" transform="translate(0 -12132)"></path>
                        <path id="path-101" className="clsur-24" d="M644.555,12409.813s101.371-504.7,151.488,221.91" transform="translate(0 -12132)"></path>
                        <path id="path-102" className="clsur-24" d="M418.6,12927.844s467.191,82.4,138.449-215.785" transform="translate(0 -12132)"></path>
                        <path id="path-104" className="clsur-24" d="M158.047,12666.078s-290.437,197.293,160.348,218.688" transform="translate(0 -12132)"></path>
                        <path id="path-105" className="clsur-24" d="M565.394,12702.1s107.319,67.058,205.168-35.276" transform="translate(0 -12130)"></path>
                        <g id="group-122" transform="translate(933.113 511)">
                          <path id="path1948_2_" className="clsur-12" d="M19.937,0a26.182,26.182,0,0,0-4.665.4c-4.118.729-4.884,2.26-4.884,5.066V9.184h9.767v1.239H6.707A6.142,6.142,0,0,0,.584,15.38a17.969,17.969,0,0,0,0,9.913c.692,2.879,2.369,4.956,5.175,4.956H9.112V25.8a6.251,6.251,0,0,1,6.123-6.086H25a4.9,4.9,0,0,0,4.884-4.956V5.467C29.886,2.806,27.663.838,25,.4A29.38,29.38,0,0,0,19.937,0ZM14.652,2.988a1.836,1.836,0,0,1,1.822,1.859,1.859,1.859,0,0,1-1.822,1.859,1.859,1.859,0,0,1,0-3.717Z" transform="translate(0)"></path>
                          <path id="path1950_2_" className="clsur-12" d="M49.437,28.7v4.337a6.272,6.272,0,0,1-6.123,6.2H33.584A5,5,0,0,0,28.7,44.189v9.293c0,2.66,2.3,4.191,4.884,4.956a16.492,16.492,0,0,0,9.767,0c2.478-.729,4.884-2.15,4.884-4.956V49.765H38.467V48.526H53.118c2.843,0,3.9-1.968,4.884-4.956,1.02-3.061.984-6.013,0-9.913-.692-2.843-2.041-4.956-4.884-4.956ZM43.97,52.243A1.836,1.836,0,0,1,45.793,54.1a1.859,1.859,0,0,1-1.822,1.859A1.836,1.836,0,0,1,42.148,54.1,1.813,1.813,0,0,1,43.97,52.243Z" transform="translate(-18.312 -18.24)"></path>
                        </g>
                        <g id="group-216" transform="translate(622 508.53)">
                          <text id="C" className="clsur-33" transform="translate(0 44)"><tspan x="0" y="0">C</tspan></text>
                          <text id="_3" data-name="#" className="clsur-34" transform="translate(31.788 35.161)"><tspan x="0" y="0">#</tspan></text>
                        </g>
                        <g id="group-217" transform="translate(243.03 747.987)">
                          <path id="path-85-2" data-name="path" className="clsur-12" d="M16.34,56s-2.1,1.2,1.5,1.654A30.351,30.351,0,0,0,29.117,57.2a23.371,23.371,0,0,0,3.006,1.428C21.451,63.14,8,58.33,16.34,56" transform="translate(-3.42 -13.91)"></path>
                          <path id="path-86" data-name="path" className="clsur-12" d="M14.528,48.1s-2.33,1.729,1.2,2.1a44.583,44.583,0,0,0,14.506-.676,6.355,6.355,0,0,0,2.255,1.353c-12.852,3.758-27.208.225-17.963-2.781" transform="translate(-2.885 -11.948)"></path>
                          <path id="path-87" data-name="path" className="clsur-12" d="M29.3,29.682c2.631,3.006-.676,5.712-.676,5.712s6.614-3.382,3.533-7.666c-2.856-3.983-5.036-6.013,6.84-12.928,0,0-18.64,4.66-9.7,14.882" transform="translate(-6.682 -3.676)"></path>
                          <path id="path-88" data-name="path" className="clsur-12" d="M36.671,61.75s1.5,1.278-1.729,2.255c-6.163,1.879-25.63,2.405-30.966.075-1.954-.827,1.729-2.029,2.856-2.255A11.37,11.37,0,0,1,8.711,61.6c-2.18-1.5-13.98,3.006-6.013,4.284C24.42,69.341,42.308,64.23,36.671,61.75" transform="translate(0 -15.226)"></path>
                          <path id="path-89-2" data-name="path" className="clsur-12" d="M15.335,39.9s-9.921,2.33-3.533,3.232a70.151,70.151,0,0,0,13.078-.15c4.059-.376,8.192-1.052,8.192-1.052a15.116,15.116,0,0,0-2.48,1.353C20.521,45.909,1.13,44.706,6.767,42a17.686,17.686,0,0,1,8.568-2.1" transform="translate(-1.438 -9.907)"></path>
                          <path id="path-90" data-name="path" className="clsur-12" d="M42.025,49.653c10.222-5.336,5.487-10.372,2.18-9.7a6.972,6.972,0,0,0-1.2.3,2,2,0,0,1,.9-.676c6.539-2.255,11.5,6.764-2.1,10.372.075-.075.225-.225.225-.3" transform="translate(-10.391 -9.743)"></path>
                          <path id="path-91" data-name="path" className="clsur-12" d="M29.753,0s5.637,5.637-5.336,14.356c-8.869,6.99-2.029,10.973,0,15.483-5.186-4.66-8.944-8.719-6.389-12.552C21.711,11.725,32.083,9.019,29.753,0" transform="translate(-4.282)"></path>
                          <path id="path-92" data-name="path" className="clsur-12" d="M17.341,70.561c9.771.6,24.878-.376,25.179-4.961,0,0-.676,1.729-8.117,3.157a79.116,79.116,0,0,1-24.8.376s1.278.977,7.741,1.428" transform="translate(-2.392 -16.295)"></path>
                        </g>
                        <g id="group-218" transform="translate(311 516.404)">
                          <g id="group-36" data-name="group">
                            <path id="path-93" data-name="path" className="clsur-12" d="M44.517,9.371c-.336.4-.6.806-.941,1.21-.067,0-.134,0-.134-.067a8,8,0,0,0-8.2-1.68C29.4,10.782,25.9,14.881,24.358,20.794a26.034,26.034,0,0,0-.336,9.407,50.324,50.324,0,0,0,3.225,12.5c0,.067.067.134.067.269H6.685c0-1.478-.067-2.889,0-4.368a56.168,56.168,0,0,1,7.056-22.107,29.393,29.393,0,0,1,6.316-8.332A17.942,17.942,0,0,1,31.413,3.592a15.338,15.338,0,0,1,7.66,1.814A25.36,25.36,0,0,1,44.517,9.17Z" transform="translate(-2.183 -1.173)"></path>
                            <path id="path-94" data-name="path" className="clsur-12" d="M4.5,47.036c-.2,1.344-.47,2.688-.672,4.032-1.277-.134-2.486-.336-3.83-.47.134-1.277.269-2.553.47-3.9C1.814,46.767,3.091,46.9,4.5,47.036Z" transform="translate(0 -15.32)"></path>
                            <path id="path-95" data-name="path" className="clsur-12" d="M9.5,28.611c-.4,1.075-.739,2.016-1.075,3.024-1.21-.4-2.419-.739-3.629-1.142.4-1.075.739-2.15,1.142-3.293C7.085,27.67,8.227,28.141,9.5,28.611Z" transform="translate(-1.575 -8.923)"></path>
                            <path id="path-96" data-name="path" className="clsur-12" d="M40.105,55.424q-2.016-.1-3.83-.2a.446.446,0,0,1-.336-.269c-.269-.806-.47-1.613-.739-2.553h3.158c.134,0,.336.134.336.269A26.39,26.39,0,0,1,40.105,55.424Z" transform="translate(-11.547 -17.189)"></path>
                            <path id="path-97" data-name="path" className="clsur-12" d="M15.617,11.9c.941.6,1.814,1.21,2.688,1.814-.672.874-1.411,1.68-2.083,2.486-.941-.6-1.814-1.21-2.822-1.881C14.206,13.513,14.878,12.706,15.617,11.9Z" transform="translate(-4.396 -3.904)"></path>
                            <path id="path-98" data-name="path" className="clsur-12" d="M36.925,42.066c-1.075-.47-2.016-.874-3.024-1.277a.37.37,0,0,1-.2-.336c0-.806.067-1.613.134-2.553,1.008.538,1.881,1.008,2.755,1.478a.571.571,0,0,1,.2.336C36.858,40.521,36.858,41.26,36.925,42.066Z" transform="translate(-11.055 -12.433)"></path>
                            <path id="path-99-2" data-name="path" className="clsur-12" d="M30.335,4.035c-.874.538-1.68,1.008-2.553,1.478-.6-.739-1.21-1.478-1.881-2.285.806-.4,1.545-.806,2.285-1.142a.552.552,0,0,1,.47.067C29.193,2.758,29.73,3.363,30.335,4.035Z" transform="translate(-8.496 -0.675)"></path>
                            <path id="path-100" data-name="path" className="clsur-12" d="M36.621,31.7c-.941-.806-1.747-1.478-2.621-2.285L35.008,27.4c.672.538,1.344,1.008,2.016,1.545.067.067.2.269.134.4C37.024,30.088,36.822,30.827,36.621,31.7Z" transform="translate(-11.153 -8.988)"></path>
                            <path id="path-101-2" data-name="path" className="clsur-12" d="M39.87,2.15C39.736,1.478,39.6.806,39.4,0c1.075.067,2.083.067,3.158.2.134,0,.269.269.269.4.067.47.134,1.008.269,1.613C41.953,2.15,40.945,2.15,39.87,2.15Z" transform="translate(-12.925)"></path>
                            <path id="path-102-2" data-name="path" className="clsur-12" d="M39.917,21.665c-.806-.874-1.478-1.68-2.217-2.486.538-.47,1.075-.941,1.613-1.478.47.739.941,1.411,1.411,2.15a.423.423,0,0,1,0,.4A7.561,7.561,0,0,1,39.917,21.665Z" transform="translate(-12.367 -5.806)"></path>
                            <path id="path-103" data-name="path" className="clsur-12" d="M46.95,13.3c.47,1.478.672,1.008-1.142,2.352L44.8,13.838C45.606,13.636,46.278,13.434,46.95,13.3Z" transform="translate(-14.696 -4.363)"></path>
                            <path id="path-104-2" data-name="path" className="clsur-12" d="M55.467,3.6c.874.672,1.68,1.21,2.553,1.881a2.413,2.413,0,0,1-.47.672c-.672-.336-1.277-.672-1.881-1.008-.134-.067-.2-.4-.269-.6C55.4,4.272,55.467,4,55.467,3.6Z" transform="translate(-18.174 -1.181)"></path>
                            <path id="path-105-2" data-name="path" className="clsur-12" d="M56.916,13.944l-2.016-.2V12.6c.672.134,1.344.336,2.016.47Z" transform="translate(-18.01 -4.133)"></path>
                          </g>
                        </g>
                      </g>
                    </svg>
    )
}
export default Unrevealtable;