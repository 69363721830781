import React,{useState,useEffect} from 'react'
import LeftImage from '../assets/images/home-section4/left_image'
import KeepReadingBlue from '../assets/images/keep-reading-blue'
const HomeSection4 =({fullpageApi}) =>{    
 
    return(
        <div 
        className={`section section4`} 
        id={`section4`} style={{backgroundColor:'rgb(79, 216, 153)'}}>

       <div className="left-col-box">
            <div className="answer-table">
                <LeftImage/>
            </div>
        </div>

        <div className="right-col-box">
            <div className="text-table en-text">
                     <h2 className="hdst2">Answer</h2>
                    <p className="text1">Are you searching for the best, smartest &amp; most motivated ICT programming guys? Then please stop searching in the small boundaries of Belgium.</p>
                    <p>
                    <span className="white_highlight text2">Poland can be your answer.</span>{' '}
                    <span className="text3">The Belgian economy is stable, that’s a fact. But let’s face it: stable is boring and boring creates mediocrity and mediocrity doesn’t stimulate the motivation to move forward.</span>
                    </p>
                    <p className="white-txt">
                        <span className="text4">If you want to deliver products and services that exceed expectations, start thinking outside the box and outside Belgium.</span> 
                        <br/> 
                        <span className="keep_reading_arrow_wraper">
                      </span>
                    </p>
            </div>
            </div>
            <a to="#" className="get-start-btn-3 en-text" onClick={() => fullpageApi.moveSectionDown()}>
                {/* <KeepReadingBlue/> */}
                <span>Keep reading</span>
            </a>
        </div>
    )
}
export default HomeSection4;