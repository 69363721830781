import React,{useState,useEffect,useRef} from 'react'
import {navigate} from 'gatsby'
import {getLocationData} from '../location_data'
import next from '../assets/images/arrow_contact-1.svg'
import { isMobile } from 'react-device-detect'

const HomeSection9 =({fullpageApi,activeSection}) =>{
    const windowGlobal = typeof window !== 'undefined' && window
    const [aq,setAq] = useState(0)  
    const [errormessage,setErrormessage] = useState(null) 
    const [invalid,setInvalid] = useState(``)
    const [qdata, setQdata] = useState({q1:'',q2:'',q3:''})
    const wr = useRef(null);
    const wr1 = useRef(null);
    const wr2 = useRef(null);
    const setInvalidd=()=>{
        setInvalid(`invalid`)
        setTimeout(() => {
            setInvalid(``)
        }, 450);
    }
    const encode = (data) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");
      }
      const handleSubmit=(e)=>{
        const userrole = []
        const lifecycledata = []
        let expert = null
        if(windowGlobal){
            if(windowGlobal.localStorage.getItem('roleData')){
                const t = JSON.parse(windowGlobal.localStorage.getItem('roleData'))
                t.forEach(el=>{
                    if(el.checked === true){
                        userrole.push(el.role)
                    }
                })
            }
             if(windowGlobal.localStorage.getItem('lifecycledata')){
                const s = JSON.parse(windowGlobal.localStorage.getItem('lifecycledata'))
                s.forEach(el=>{
                    if(el.checked === true){
                        lifecycledata.push(el.name)
                    }
                })
            }
             if(windowGlobal.localStorage.getItem('expert_name')){
              expert = windowGlobal.localStorage.getItem('expert_name')
            }
        }
            setAq(aq+1)
            fetch("/", {
                method: "post",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                body: encode({ "form-name": 'contact_us_form', 
                                ...qdata,
                                "User_roles":userrole.length !== 0 ? `${userrole.join(", ")}` : null,
                                "Company_lifecycle":lifecycledata.length !== 0 ? `${lifecycledata.join(", ")}` : null,
                                "Expertise":expert,
                                "Language":getLocationData('languages') !== null ? getLocationData('languages').split(',')[0] : null,
                                "Country":getLocationData('country_name'),
                                "Zip":getLocationData('zipcode'),
                                "User_country":getLocationData('country_name'),
                                "City":getLocationData('city'),
                                "Latitude":getLocationData('latitude'),
                                "Longitude":getLocationData('longitude'),
                                "State":getLocationData('state_prov'),
                             })
              })
                .then(() =>{
                    if(windowGlobal){
                        windowGlobal.localStorage.setItem('name',qdata.q1)
                        windowGlobal.localStorage.setItem('email',qdata.q2)
                        if(qdata.q3 !== ''){
                        windowGlobal.localStorage.setItem('number',qdata.q3)
                        }
                    }
                    setTimeout(() => {
                    navigate('/thank-you-page')
                }, 500);})
                .catch(error => alert(error));
                if(!e.keyCode){
                        e.preventDefault();
                    }
         
      }
    const handleQuestion = (e) =>{
    e.preventDefault()       
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(aq === 0 && qdata.q1 !==''){
            setAq(aq+1)
            if(windowGlobal.innerWidth > 1023 && !isMobile){
                setTimeout(() => {
                    wr1.current.focus()
                  }, 10);
            }
            
        }else if(aq === 0 && qdata.q1 ===''){
            setErrormessage(`Please enter your valid Name!`)
            setInvalidd()
        }else if(aq === 1 && !re.test(qdata.q2.toLowerCase())){
            setErrormessage(`Please enter your valid Email!`)
            setInvalidd()
        }else if(aq === 1 && re.test(qdata.q2.toLowerCase())){
                setAq(aq+1)
                if(windowGlobal.innerWidth > 1023 && !isMobile){
                    setTimeout(() => {
                        wr2.current.focus()
                      }, 10);
                }
               
        }else if(aq === 2 && e.keyCode === 13){
            setAq(aq+1)
           handleSubmit(e)
           
        }
      
      
    }
    const handleInputChnage = (e)=>{        
        setErrormessage(``)
        setQdata({...qdata,[e.target.name]:e.target.value})
    }
    const checkKeyPress = (e) =>{
       if(e.keyCode === 13){
        handleQuestion(e)
       }
    }
    useEffect(()=>{

       if(activeSection === 'contact'){
        setInvalid(``)
        if(windowGlobal.innerWidth > 1023 && !isMobile){
            setTimeout(() => {
                wr.current.focus()
              }, 1000);
        }    
       }
    },[activeSection])
 
    return(
             <div className={`section section9`} id="section9" style={{backgroundColor:'rgb(11, 27, 52)'}}>
    
             <div className="contact-section mid-text-section ">
                    <h2 className="hd-st en-text" >Contact us</h2>
                    <p className="contact-tp-pg en-text" >Contact us now and we will come to you to explain how we can help out</p>
        
                    <form 
                    id="theForm" 
                    className={`simform ${invalid}`}
                    name="contact_us_form"
                    method="POST"
                    netlify-honeypot="bot-field"
                    data-netlify="true"
                    onSubmit={handleSubmit}
                    style={{display: aq === 3 ? `none` : ``}}
                    > 
                    <input type="hidden" name="form-name" value="contact_us_form" />
                    <p hidden>
                    <label>
                        Don’t fill this out: <input name="bot-field" onChange={handleInputChnage} />
                    </label>
                    </p>                   
                    <div className="simform-inner">
                        <ol className="questions">
                            <li className={aq === 0 ? `current` : ``}>
                                <input id="q1" name="q1" placeholder="Hi, whats your name?"
                                  ref={wr}
                                  type="text" 
                                  onChange={handleInputChnage} onKeyDown={checkKeyPress}/>
                            </li>
                            <li className={aq === 1 ? `current` : ``}>
                                <input 
                                id="q2" 
                                name="q2" 
                                ref={wr1}
                                placeholder="What’s your e-mail address?" 
                                type="email"
                                onChange={handleInputChnage}
                                onKeyDown={checkKeyPress}
                                />
                            </li>
                            <li className={aq === 2 ? `current` : ``}> 
                                <input 
                                id="q3" 
                                name="q3"
                                ref={wr2}
                                placeholder="Give us your number ( optional )" 
                                type="number"
                                onChange={handleInputChnage}
                                onKeyDown={checkKeyPress}
                                />
                            </li>
                        </ol>
                        <p hidden>
                        <input name="User_roles" />
                        <input name="Company_lifecycle" />                
                        <input name="Expertise" />                   
                        <input name="Language" />
                        <input name="Country" />
                        <input name="Zip" />
                        <input name="User_country" />
                        <input name="City" />
                        <input name="Latitude" />
                        <input name="Longitude" />
                        <input name="State" />
                        </p>
                        <div className="controls">
                          
                            {aq >= 2 
                            ?
                            <button
                            className="next show final"
                            type={`submit`}
                            style={{
                                backgroundImage:`url('${next}')`,
                                backgroundRepeat:'no-repeat', 
                                backgroundPosition:'center'
                                }}></button>
                            :
                            <button
                            className="next show"
                            type={`button`}
                            onClick={handleQuestion}
                            style={{
                                backgroundImage:`url('${next}')`,
                                backgroundRepeat:'no-repeat', 
                                backgroundPosition:'center'
                                }}></button>}
                           
                               
                                
                            <div className="progress" style={{width:`${aq*33.33333}%`}}></div>
                            <span className="number">
                                <span className="number-current"></span>
                                <span className="number-total"></span>
                            </span>
                            <span 
                            className={`error-message ${errormessage !== null ? `show`:``}`}
                            >{errormessage}</span>
                        </div>
                    </div>
                    </form>
                    <span className={`final-message ${aq === 3 ? `show`:`hide`}`}>Thank you, wsd! Someone from our team will contact you within 24hours</span>
               </div>
                <p className="contact-pgh en-text">Contact us now and we will come to you in person to explain how we can help you.</p>
                 </div>
    )
}
export default HomeSection9;