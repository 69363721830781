import React,{useState,useEffect} from 'react'
import KeepReading from '../assets/images/keep-reading'
import LeftImage2 from '../assets/images/home-section3/left-img-2'
import RightImage2 from '../assets/images/home-section3/right-img-2'
import LeftImageSvg from '../assets/images/home-section3/left-img'
import RightImageSvg from '../assets/images/home-section3/right-img'
import MobileProblemSvg from '../assets/images/mobile_primary_svg'
const HomeSection3 =({fullpageApi}) =>{    

    return (
        <div 
        className={`section section3`} 
        id={`section3`}>
            <div className="mid-text-section mid-section3 en-text">
                     <h2 className="hdst2">Problem</h2>
    <p><span className="text1">Belgium companies are</span> <span className="border_underline black_br line_un_1">thinking global </span> <span className="text2">in terms of export. But they are still{' '}</span><span className="border_underline red_br line_un_2">thinking</span> <span className="red_marked line_un_3"><span className="text3">extremely local</span></span> <span className="text4">in terms of resources and teams. Pleaseeee!</span><br/> <span className="green_marked line_un_4"><span className="text5">start thinking global</span></span> <span className="text6">for everything</span> </p>
                    <h3><span className="text7">Time to</span> <ins className="text8">GO</ins> <span className="text9">global today.</span> <ins className="text10">Wake up!</ins></h3>
                </div>
                <div className="hidden-m">
                  <div className="left-img-2">
                      
                     <LeftImage2/>
                  </div> 
                  <div className="right-img-2">
                     <RightImage2/> 
                     
                  </div> 
                </div>
                <div className="visible-m problem-img-mobile">
                  <MobileProblemSvg/>
                </div>
                <div className="left-img">
                  <LeftImageSvg/>
                </div>
                <div className="right-img">
                  <RightImageSvg/>
                </div>
                <a to="#" className="get-start-btn-2 en-text" onClick={() => fullpageApi.moveSectionDown()}>
                 {/* <KeepReading/> */}
                 <span>Keep reading</span>
                </a>
        </div>
    )
}

export default HomeSection3;