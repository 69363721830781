import React from 'react'
import './style.scss'
const RightImage = () =>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" viewBox="2499.973 9173.378 417.015 769.438">
                      <defs>
                       
                        <clipPath id="relief-clip-path">
                          <path id="path" d="M0,44.053.131,0,37.619,21.642,37.488,65.7Z"></path>
                        </clipPath>
                        <clipPath id="relief-clip-path-2">
                          <path id="path-2" data-name="path" className="clsrlf-1" d="M41.919,75.866l-.052-.1-.471-2.724c2.1,1.2,8.224-1.886,9.847-2.409l-.21-7.228c-5.238.262-8.643,2.462-13.828.1a9.1,9.1,0,0,1-6.652,3.3,9,9,0,0,1-6.5-3.09c-.052.052-.157.1-.21.157-.157.1-.367.21-.524.314h0c-4.4,2.357-9.847,5.5-12.624,10l4.5,3.929c2.3-1.519,3.509-2.462,5.5-3.719C18.3,82.414,18.347,90.9,18.3,97.133c0,.052.1,2.409.367,5.133H45.376l-.314-4.3C44.59,92.681,44.118,83.619,41.919,75.866Z" transform="translate(-10.7 -63.4)"></path>
                        </clipPath>
                        <clipPath id="relief-clip-path-3">
                          <path id="path-3" data-name="path" d="M0,11.434.039,0,79.26,45.735l-.039,11.434Z"></path>
                        </clipPath>
                        <clipPath id="relief-clip-path-4">
                          <path id="path-4" data-name="path" d="M0,20.655.044,0,21.57,12.419l-.044,20.655Z"></path>
                        </clipPath>
                        <clipPath id="relief-clip-path-5">
                          <path id="path-5" data-name="path" d="M0,46.945.133,0,40.987,23.579l-.133,46.945Z"></path>
                        </clipPath>
                      </defs>
                      <g id="Group_20" data-name="Group 20" transform="translate(1474.863 9051.287)">
                        <g id="group-263" transform="translate(1137.406 286.916)">
                          <g id="group">
                            <path id="path-7" data-name="path" className="clsrlf-2" d="M110.487,572.764V256.7L1,319.977V636.041Z" transform="translate(-1 -256.7)"></path>
                            <path id="path-8" data-name="path" className="clsrlf-3" d="M229.487,639.764V323.7L120,386.977V703.041Z" transform="translate(-24.199 -269.761)"></path>
                            <path id="path-9" data-name="path" className="clsrlf-4" d="M1,335.3l95.8,53.939V705.3L1,651.364Z" transform="translate(-1 -272.023)"></path>
                            <path id="path-10" data-name="path" className="clsrlf-3" d="M206.288,310.639l-95.8-53.939L1,319.977l95.8,53.939Z" transform="translate(-1 -256.7)"></path>
                            <g id="group-2" data-name="group" transform="translate(121.762 129.789)">
                              <path id="path-11" data-name="path" className="clsrlf-5" d="M222.993,422.3,154,461.506v16.1l68.993-39.528Z" transform="translate(-154 -422.3)"></path>
                              <path id="path-12" data-name="path" className="clsrlf-5" d="M222.993,466.4,154,505.606v16.1l68.993-39.528Z" transform="translate(-152.589 -427.369)"></path>
                              <path id="path-13" data-name="path" className="clsrlf-5" d="M222.993,510.5,154,549.706v16.1l68.993-39.528Z" transform="translate(-152.589 -435.966)"></path>
                              <path id="path-14" data-name="path" className="clsrlf-5" d="M222.993,554.6,154,593.806v16.1l68.993-39.528Z" transform="translate(-152.589 -444.564)"></path>
                              <path id="path-15" data-name="path" className="clsrlf-5" d="M222.993,598.7,154,637.906v16.1l68.993-39.528Z" transform="translate(-152.589 -453.161)"></path>
                              <path id="path-16" data-name="path" className="clsrlf-5" d="M222.993,642.8,154,682.006v16.1l68.993-39.528Z" transform="translate(-152.589 -461.758)"></path>
                            </g>
                            <g id="group-3" data-name="group" transform="translate(8.936 202.793)">
                              <path id="path-17" data-name="path" className="clsrlf-5" d="M12.1,508.6l68.993,39.206v16.182L12.1,524.46Z" transform="translate(-12.1 -508.6)"></path>
                              <path id="path-18" data-name="path" className="clsrlf-5" d="M12.1,552.8l68.993,39.126v16.182L12.1,568.579Z" transform="translate(-12.1 -517.216)"></path>
                              <path id="path-19" data-name="path" className="clsrlf-5" d="M12.1,596.9l68.993,39.125v16.182L12.1,612.679Z" transform="translate(-12.1 -525.814)"></path>
                              <path id="path-20" data-name="path" className="clsrlf-5" d="M12.1,641l68.993,39.126v16.182L12.1,656.779Z" transform="translate(-12.1 -534.411)"></path>
                            </g>
                            <path id="path-21" data-name="path" className="clsrlf-6" d="M206.852,363,97.365,426.2,1,370.568" transform="translate(-1 -301.412)"></path>
                          </g>
                          <g id="group-192" transform="translate(24.148 112.209)">
                            <g id="group-4" data-name="group">
                              <g id="group-5" data-name="group">
                                <g id="group-6" data-name="group" className="clsrlf-7">
                                  <g id="group-7" data-name="group" transform="translate(0.061 8.43)">
                                    <g id="group-8" data-name="group">
                                      <g id="group-9" data-name="group">
                                        <path id="path-22" data-name="path" className="clsrlf-8" d="M30.018,93.239c-3.486-2-6.957-4.023-10.443-6.028v.015c3.486,2,6.957,4.023,10.443,6.013h0M8.739,80.777h0c.058.247.131.508.2.755-.073-.247-.131-.494-.189-.741-.015,0-.015,0-.015-.015m23.7-6.914c-.6.392-1.249.726-1.874,1.089-1.177.668-2.353,1.351-3.53,2.019a24.019,24.019,0,0,0-4.125-5.185,18.007,18.007,0,0,0-3.254-2.426,11.74,11.74,0,0,0-2.368-1.075,6.861,6.861,0,0,0-5.737.349,6.629,6.629,0,0,0-2.789,3.573h0a6.648,6.648,0,0,1,2.789-3.559,6.8,6.8,0,0,1,5.737-.349,12.808,12.808,0,0,1,2.382,1.089,18.719,18.719,0,0,1,3.254,2.426A23.333,23.333,0,0,1,27.055,77c1.176-.668,2.353-1.336,3.529-2.019.625-.378,1.264-.712,1.859-1.118.015.015.015,0,0,0M12.632,58.206A9.758,9.758,0,0,0,2.494,62.985,14.376,14.376,0,0,0,.809,67.56c-.857,4.3-.29,9.732,1.612,15.207A45.77,45.77,0,0,0,7.606,93.123a41.9,41.9,0,0,0,6.957,8.061,30.5,30.5,0,0,0,5.026,3.646,24.45,24.45,0,0,0,4.779,2.135,10.756,10.756,0,0,0,7.858-.16,9.806,9.806,0,0,0,4.88-6.333,24.589,24.589,0,0,0,.8-8.6,25.248,25.248,0,0,1-.8,8.584,9.77,9.77,0,0,1-4.88,6.333v-.015a10.757,10.757,0,0,1-7.858.16,24.183,24.183,0,0,1-4.793-2.135,30.5,30.5,0,0,1-5.026-3.646,42.42,42.42,0,0,1-6.943-8.061A45.916,45.916,0,0,1,2.435,82.753H2.45C.533,77.291-.034,71.845.823,67.545A14.375,14.375,0,0,1,2.508,62.97a9.761,9.761,0,0,1,10.124-4.764" transform="translate(-0.419 -58.095)"></path>
                                        <path id="path-23" data-name="path" className="clsrlf-9" d="M31.983,60.69a20.213,20.213,0,0,0-6.3-2.44c-.261-.044-.508-.087-.755-.116A9.793,9.793,0,0,0,14.8,62.912c2.092,3.079,4.169,6.144,6.26,9.223a6.629,6.629,0,0,1,2.789-3.573,6.861,6.861,0,0,1,5.737-.349,13.6,13.6,0,0,1,2.368,1.075,18.186,18.186,0,0,1,3.254,2.426A23.671,23.671,0,0,1,39.332,76.9c1.177-.668,2.353-1.336,3.529-2.019.625-.363,1.264-.683,1.874-1.089h0a42.842,42.842,0,0,0-6.158-7.96,32.618,32.618,0,0,0-6.594-5.142" transform="translate(-12.711 -58.037)"></path>
                                        <path id="path-24" data-name="path" className="clsrlf-10" d="M2.6,91.6A14.507,14.507,0,0,0,.913,96.175c-.857,4.3-.29,9.732,1.641,15.193,2.092-.654,4.183-1.307,6.275-1.975h.015a14.971,14.971,0,0,1,.029-8.57h0C6.767,97.744,4.675,94.679,2.6,91.6" transform="translate(-0.51 -86.725)"></path>
                                        <path id="path-25" data-name="path" className="clsrlf-11" d="M150.354,209.714q-9.02-5.207-18.025-10.414c-.015,2.861-.015,5.737-.029,8.584h.015c3.486,2,6.957,4.023,10.443,6.028v.015c-.407,2.5-1.83,4.183-3.849,4.59q3.028,4.466,6.071,8.933h0v.015a9.806,9.806,0,0,0,4.88-6.333,24.889,24.889,0,0,0,.8-8.584c-.073-.915-.16-1.874-.305-2.832" transform="translate(-113.145 -178.782)"></path>
                                        <path id="path-26" data-name="path" className="clsrlf-12" d="M20.589,214.2c-2.092.668-4.183,1.322-6.275,1.975H14.3a45.916,45.916,0,0,0,5.171,10.342,41.47,41.47,0,0,0,6.957,8.061,30.5,30.5,0,0,0,5.026,3.646,24.182,24.182,0,0,0,4.793,2.135,10.684,10.684,0,0,0,7.858-.16h0q-3.028-4.466-6.071-8.933a.014.014,0,0,0-.015-.015,7.885,7.885,0,0,1-4.227-.523,15.7,15.7,0,0,1-2.353-1.118,20.234,20.234,0,0,1-2.222-1.525,23.857,23.857,0,0,1-4.256-4.575,28.173,28.173,0,0,1-4.169-8.526c-.073-.276-.131-.523-.2-.784h0" transform="translate(-12.284 -191.518)"></path>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                            <path id="path" className="green-1" d="M76.251,276.472,41.134,256.7,1,279.9l35.117,19.772Z"></path>
                            <path id="path" className="green-2" d="M76.251,276.472,41.134,256.7,1,279.9l35.117,19.772Z"></path>
                            <path id="path" className="yellow-1" d="M76.251,276.472,41.134,256.7,1,279.9l35.117,19.772Z"></path>
                            <path id="path" className="red-1" d="M76.251,276.472,41.134,256.7,1,279.9l35.117,19.772Z"></path>
                        </g>
                        <g id="group-153" transform="translate(1172.362 202.748)">
                          <g id="group-26" transform="translate(0 7.384)">
                            <g id="group-24" transform="translate(0 4.854)">
                              <path id="path-27" data-name="path" className="clsrlf-1" d="M-281.125,546.018c-1.411-8.824-8.971-16.2-16.447-19.248-.577-.808.084-1.658.588-2.13q.22-.173.435-.362h0a14.647,14.647,0,0,0,4.764-10.22,4.661,4.661,0,0,0,1.049-1.374c.829-1.6.661-3.331-.367-3.861a1.607,1.607,0,0,0-1.243-.058c-1.537-5.551-5.944-9.564-11.143-9.564s-9.658,4.055-11.169,9.648a1.7,1.7,0,0,0-1.474-.026c-1.049.525-1.2,2.261-.367,3.861a4.5,4.5,0,0,0,1.327,1.574,14.513,14.513,0,0,0,5.152,10.346h0l.11.084c.446.357,1.836,1.658-.089,2.854l-.525.294h0c-7.219,3.9-14.3,10.9-14.5,20.088-.157,6.993,1.3,20.3,4.879,25.985h0l.131.215a29.521,29.521,0,0,1,.976,2.954h0a6.253,6.253,0,0,0,1.285,2.324c1.931,2.224,4.974,2.749,6.794,1.165s1.726-4.664-.2-6.888a5.708,5.708,0,0,0-4.423-2.1c-2.5-3.247-3.588-14.165-3.489-18.693.115-5.2.645-11.542,5.975-14.878-2.418,8.032-2.335,16.494-2.418,22.732,0,.052.252,5,.818,8.709a3.455,3.455,0,0,0,.414.2l.839-3.788h20.56a10.449,10.449,0,0,1-.892.771l2.182,4.585c.178-.084,1.422-4,3.148-5.362l-.294-4.318a124.152,124.152,0,0,0-3.672-22.034l-.026-.095-1.012-2.749c5.031,2.954,6.3,10.188,7.1,15.062.666,4.16,1.049,13.519-.525,17.638a5.7,5.7,0,0,0-4.407,2.14c-1.9,2.256-1.941,5.341-.1,6.894s4.879.986,6.773-1.27a6.206,6.206,0,0,0,1.3-2.544h0a5.41,5.41,0,0,0,.131-.834,15.962,15.962,0,0,1,.761-2.865v-.031C-279.656,565.046-280.023,552.9-281.125,546.018Z" transform="translate(325.032 -499.2)"></path>
                              <path id="path-28" data-name="path" className="clsrlf-8" d="M-301.48,733.74" transform="translate(313.836 -610.694)"></path>
                              <path id="path-29" data-name="path" className="clsrlf-1" d="M-289.623,564.861l-.026-.094-1.018-2.754a11.688,11.688,0,0,1,4.548,5.393l5.5-2.723a29.185,29.185,0,0,0-13.2-12.2,9.078,9.078,0,0,1-6.689,3.284A8.961,8.961,0,0,1-307,552.69c-.068.047-.131.1-.21.147l-.525.294h0A30.653,30.653,0,0,0-319.28,563.55l4.753,4.659a11.153,11.153,0,0,1,4.228-4.863c-2.424,8.048-2.34,16.531-2.424,22.784,0,.037.121,2.424.383,5.12h26.756l-.294-4.307A132.987,132.987,0,0,0-289.623,564.861Z" transform="translate(322.297 -524.528)"></path>
                              <path id="path-30" data-name="path" className="clsrlf-13" d="M24.973,180.479H31.74l1.228-42.794h-.1l2.744,43.035,6.044.488,3.777-54.162-26.8-.126Z" transform="translate(-8.845 -60.34)"></path>
                              <path id="path-31" data-name="path" className="clsrlf-13" d="M-268.205,727.74h-6.558l-.467,7.98s16.625,2.177,14.752-.483S-268.205,727.74-268.205,727.74Z" transform="translate(301.357 -607.842)"></path>
                              <path id="path-32" data-name="path" className="clsrlf-13" d="M-258.88,732.81s16.295,6.322,1.952,6.154" transform="translate(293.585 -610.252)"></path>
                              <rect id="rectangle" className="clsrlf-8" width="0.986" height="4.533" rx="0.493" transform="translate(35.033 119.765) rotate(60)"></rect>
                              <rect id="rectangle-2" data-name="rectangle" className="clsrlf-8" width="0.986" height="4.533" rx="0.493" transform="translate(36.618 120.823) rotate(60)"></rect>
                              <rect id="rectangle-3" data-name="rectangle" className="clsrlf-8" width="0.986" height="4.533" rx="0.493" transform="translate(38.11 121.859) rotate(60)"></rect>
                              <path id="path-81" className="clsrlf-13" d="M5.916,8.8,5.9,8.814C-8.046,8.977,6.994,3,7.82,2.674,9.535,1.169,11,0,11,0h6.752l.483,7.979a110.894,110.894,0,0,1-11.163.842C6.666,8.821,6.28,8.814,5.916,8.8Z" transform="translate(5.131 120.139)"></path>
                              <rect id="rectangle-4" data-name="rectangle" className="clsrlf-8" width="0.986" height="4.533" rx="0.493" transform="translate(10.408 123.437) rotate(-60)"></rect>
                              <rect id="rectangle-5" data-name="rectangle" className="clsrlf-8" width="0.986" height="4.533" rx="0.493" transform="translate(11.993 122.375) rotate(-60)"></rect>
                              <rect id="rectangle-6" data-name="rectangle" className="clsrlf-8" width="0.986" height="4.533" rx="0.493" transform="translate(13.482 121.343) rotate(-60)"></rect>
                            </g>
                            <path id="path-17-2" data-name="path-17" className="clsrlf-5" d="M-305.786,503.722s-1.726-4.982.6-8.728c0,0,1.292,2.2,3.1,0s3.488-9.689,15.761-4c0,0-2.455,2.842-1.034,4,0,0,5.932,3.462,3.55,8.646,0,0-4.5-9.751-10.227-7.323S-297.471,492.942-305.786,503.722Z" transform="translate(316.163 -488.902)"></path>
                          </g>
                          <g id="group-150" transform="translate(0.559)">
                            <path id="path-33" data-name="path" className="clsrlf-14" d="M0,6.78l3.3-1.1C8.888,3.848,14.477,1.924,20.066.092A1.559,1.559,0,0,1,20.708,0L40.5,6.6a1.548,1.548,0,0,1,.458.183c-1.283.458-2.474.825-3.665,1.191C31.886,9.8,26.48,11.545,21.074,13.377a1.565,1.565,0,0,1-1.283,0c-3.3-1.191-6.689-2.291-9.987-3.39A1.548,1.548,0,0,1,9.346,9.8c1.283-.458,2.474-.825,3.665-1.283,2.474-.825,4.948-1.741,7.513-2.566a.973.973,0,0,0,.458-1.649.729.729,0,0,0-.916-.183c-1.649.55-3.207,1.191-4.856,1.741C12.553,6.872,9.8,7.788,7.147,8.8a.578.578,0,0,1-.641.092C4.4,8.246,2.2,7.513,0,6.78Z" transform="translate(0 0)"></path>
                            <path id="path-34" data-name="path" className="clsrlf-1" d="M11.1,13c1.191.367,2.291.733,3.39,1.1l6.6,2.2a.783.783,0,0,0,.641,0c3.207-1.1,6.414-2.107,9.621-3.207A.337.337,0,0,0,31.624,13v5.772a2.724,2.724,0,0,1-.733,1.833,6.511,6.511,0,0,1-2.749,1.649,21.358,21.358,0,0,1-7.238,1.1,16.86,16.86,0,0,1-6.78-1.283,16.136,16.136,0,0,1-2.107-1.283,2.359,2.359,0,0,1-.916-2.016Q11.1,15.886,11.1,13Z" transform="translate(-0.46 -3.439)"></path>
                            <path id="path-35" data-name="path" className="clsrlf-5" d="M6.512,20.872H3.6c.26-1.04.52-2.028.78-3.068a1.375,1.375,0,0,1-.728-.884,1.5,1.5,0,0,1,.728-1.664c.156-.052.156-.156.156-.312V11.72c0-.312.156-.52.468-.52a.466.466,0,0,1,.52.52V15a.286.286,0,0,0,.156.26A1.457,1.457,0,0,1,5.836,17.8c-.1.052-.1.156-.052.26.208.884.468,1.768.676,2.652A.4.4,0,0,0,6.512,20.872Z" transform="translate(1.579 -1.878)"></path>
                          </g>
                        </g>
                        <g id="group-152" transform="translate(1251.199 197.341)">
                          <g id="group-26-2" data-name="group-26" transform="translate(0 10.657)">
                            <g id="group-24-2" data-name="group-24" transform="translate(0 4.854)">
                              <path id="path-36" data-name="path" className="clsrlf-1" d="M-281.125,546.018c-1.411-8.824-8.971-16.2-16.447-19.248-.577-.808.084-1.658.588-2.13q.22-.173.435-.362h0a14.647,14.647,0,0,0,4.764-10.22,4.661,4.661,0,0,0,1.049-1.374c.829-1.6.661-3.331-.367-3.861a1.607,1.607,0,0,0-1.243-.058c-1.537-5.551-5.944-9.564-11.143-9.564s-9.658,4.055-11.169,9.648a1.7,1.7,0,0,0-1.474-.026c-1.049.525-1.2,2.261-.367,3.861a4.5,4.5,0,0,0,1.327,1.574,14.513,14.513,0,0,0,5.152,10.346h0l.11.084c.446.357,1.836,1.658-.089,2.854l-.525.294h0c-7.219,3.9-14.3,10.9-14.5,20.088-.157,6.993,1.3,20.3,4.879,25.985h0l.131.215a29.521,29.521,0,0,1,.976,2.954h0a6.253,6.253,0,0,0,1.285,2.324c1.931,2.224,4.974,2.749,6.794,1.165s1.726-4.664-.2-6.888a5.708,5.708,0,0,0-4.423-2.1c-2.5-3.247-3.588-14.165-3.489-18.693.115-5.2.645-11.542,5.975-14.878-2.418,8.032-2.335,16.494-2.418,22.732,0,.052.252,5,.818,8.709a3.455,3.455,0,0,0,.414.2l.839-3.788h20.56a10.449,10.449,0,0,1-.892.771l2.182,4.585c.178-.084,1.422-4,3.148-5.362l-.294-4.318a124.152,124.152,0,0,0-3.672-22.034l-.026-.095-1.012-2.749c5.031,2.954,6.3,10.188,7.1,15.062.666,4.16,1.049,13.519-.525,17.638a5.7,5.7,0,0,0-4.407,2.14c-1.9,2.256-1.941,5.341-.1,6.894s4.879.986,6.773-1.27a6.206,6.206,0,0,0,1.3-2.544h0a5.41,5.41,0,0,0,.131-.834,15.962,15.962,0,0,1,.761-2.865v-.031C-279.656,565.046-280.023,552.9-281.125,546.018Z" transform="translate(325.032 -499.2)"></path>
                              <path id="path-37" data-name="path" className="clsrlf-8" d="M-301.48,733.74" transform="translate(313.836 -610.694)"></path>
                              <path id="path-38" data-name="path" className="clsrlf-1" d="M-289.623,564.861l-.026-.094-1.018-2.754a11.688,11.688,0,0,1,4.548,5.393l5.5-2.723a29.185,29.185,0,0,0-13.2-12.2,9.078,9.078,0,0,1-6.689,3.284A8.961,8.961,0,0,1-307,552.69c-.068.047-.131.1-.21.147l-.525.294h0A30.653,30.653,0,0,0-319.28,563.55l4.753,4.659a11.153,11.153,0,0,1,4.228-4.863c-2.424,8.048-2.34,16.531-2.424,22.784,0,.037.121,2.424.383,5.12h26.756l-.294-4.307A132.987,132.987,0,0,0-289.623,564.861Z" transform="translate(322.297 -524.528)"></path>
                              <path id="path-39" data-name="path" className="clsrlf-13" d="M24.973,180.479H31.74l1.228-42.794h-.1l2.744,43.035,6.044.488,3.777-54.162-26.8-.126Z" transform="translate(-8.845 -60.34)"></path>
                              <path id="path-40" data-name="path" className="clsrlf-13" d="M-268.205,727.74h-6.558l-.467,7.98s16.625,2.177,14.752-.483S-268.205,727.74-268.205,727.74Z" transform="translate(301.357 -607.842)"></path>
                              <path id="path-41" data-name="path" className="clsrlf-13" d="M-258.88,732.81s16.295,6.322,1.952,6.154" transform="translate(293.585 -610.252)"></path>
                              <rect id="rectangle-7" data-name="rectangle" className="clsrlf-8" width="0.986" height="4.533" rx="0.493" transform="translate(35.033 119.765) rotate(60)"></rect>
                              <rect id="rectangle-8" data-name="rectangle" className="clsrlf-8" width="0.986" height="4.533" rx="0.493" transform="translate(36.618 120.823) rotate(60)"></rect>
                              <rect id="rectangle-9" data-name="rectangle" className="clsrlf-8" width="0.986" height="4.533" rx="0.493" transform="translate(38.11 121.859) rotate(60)"></rect>
                              <path id="path-81-2" data-name="path-81" className="clsrlf-13" d="M5.916,8.8,5.9,8.814C-8.046,8.977,6.994,3,7.82,2.674,9.535,1.169,11,0,11,0h6.752l.483,7.979a110.894,110.894,0,0,1-11.163.842C6.666,8.821,6.28,8.814,5.916,8.8Z" transform="translate(5.131 120.139)"></path>
                              <rect id="rectangle-10" data-name="rectangle" className="clsrlf-8" width="0.986" height="4.533" rx="0.493" transform="translate(10.408 123.437) rotate(-60)"></rect>
                              <rect id="rectangle-11" data-name="rectangle" className="clsrlf-8" width="0.986" height="4.533" rx="0.493" transform="translate(11.993 122.375) rotate(-60)"></rect>
                              <rect id="rectangle-12" data-name="rectangle" className="clsrlf-8" width="0.986" height="4.533" rx="0.493" transform="translate(13.482 121.343) rotate(-60)"></rect>
                            </g>
                            <path id="path-17-3" data-name="path-17" className="clsrlf-5" d="M-305.786,503.722s-1.726-4.982.6-8.728c0,0,1.292,2.2,3.1,0s3.488-9.689,15.761-4c0,0-2.455,2.842-1.034,4,0,0,5.932,3.462,3.55,8.646,0,0-4.5-9.751-10.227-7.323S-297.471,492.942-305.786,503.722Z" transform="translate(316.163 -488.902)"></path>
                          </g>
                          <g id="group-150-2" data-name="group-150" transform="translate(0.135)">
                            <path id="path-42" data-name="path" className="clsrlf-14" d="M0,6.78l3.3-1.1C8.888,3.848,14.477,1.924,20.066.092A1.559,1.559,0,0,1,20.708,0L40.5,6.6a1.548,1.548,0,0,1,.458.183c-1.283.458-2.474.825-3.665,1.191C31.886,9.8,26.48,11.545,21.074,13.377a1.565,1.565,0,0,1-1.283,0c-3.3-1.191-6.689-2.291-9.987-3.39A1.548,1.548,0,0,1,9.346,9.8c1.283-.458,2.474-.825,3.665-1.283,2.474-.825,4.948-1.741,7.513-2.566a.973.973,0,0,0,.458-1.649.729.729,0,0,0-.916-.183c-1.649.55-3.207,1.191-4.856,1.741C12.553,6.872,9.8,7.788,7.147,8.8a.578.578,0,0,1-.641.092C4.4,8.246,2.2,7.513,0,6.78Z" transform="translate(0 0)"></path>
                            <path id="path-43" data-name="path" className="clsrlf-1" d="M11.1,13c1.191.367,2.291.733,3.39,1.1l6.6,2.2a.783.783,0,0,0,.641,0c3.207-1.1,6.414-2.107,9.621-3.207A.337.337,0,0,0,31.624,13v5.772a2.724,2.724,0,0,1-.733,1.833,6.511,6.511,0,0,1-2.749,1.649,21.358,21.358,0,0,1-7.238,1.1,16.86,16.86,0,0,1-6.78-1.283,16.136,16.136,0,0,1-2.107-1.283,2.359,2.359,0,0,1-.916-2.016Q11.1,15.886,11.1,13Z" transform="translate(-0.46 -3.439)"></path>
                            <path id="path-44" data-name="path" className="clsrlf-5" d="M6.512,20.872H3.6c.26-1.04.52-2.028.78-3.068a1.375,1.375,0,0,1-.728-.884,1.5,1.5,0,0,1,.728-1.664c.156-.052.156-.156.156-.312V11.72c0-.312.156-.52.468-.52a.466.466,0,0,1,.52.52V15a.286.286,0,0,0,.156.26A1.457,1.457,0,0,1,5.836,17.8c-.1.052-.1.156-.052.26.208.884.468,1.768.676,2.652A.4.4,0,0,0,6.512,20.872Z" transform="translate(1.579 -1.878)"></path>
                          </g>
                        </g>
                        <g id="group-146" transform="translate(1207.97 125.5)">
                          <g id="group-145">
                            <g id="group-143" transform="translate(64.551 0)">
                              <line id="line-30" className="clsrlf-15" y1="59.054" transform="translate(0.039 70.088)"></line>
                              <g id="group-134" transform="translate(0)">
                                <path id="path-45" data-name="path" className="clsrlf-16" d="M67.615,36.947c-8-4.629-16.2-7.575-24.83-10.521s-17.465-6.1-26.3-11.152A90.014,90.014,0,0,1,.91,3.7L.7,77.138c12.2,2.946,24.2,8.627,35.982,15.571,6.944,4,13.888,8.417,20.832,12.836s14.1,9.048,21.463,13.046a188.08,188.08,0,0,0,20.411,10.521c-6.734-12.415-4.629-24.62-2.946-35.562.842-5.261,1.683-10.311,1.473-14.73a61.688,61.688,0,0,1,.421-10.942c.21-1.894.21-1.894-2.1-4.419-1.683-1.683-4.208-4.419-8-9.048A76.641,76.641,0,0,0,67.615,36.947" transform="translate(-0.7 -3.7)"></path>
                              </g>
                            </g>
                            <g id="group-49" transform="translate(0 113.8)">
                              <g id="group-48" transform="translate(0 0)">
                                <g id="group-47" transform="translate(0 0)">
                                  <path id="path-46" data-name="path" className="clsrlf-1" d="M65.775,15.754a4.877,4.877,0,0,0-5.664,4.008,5.429,5.429,0,0,0,1.176,4.756,21.76,21.76,0,0,1-5.931,9.939c-7.107,6.573-17.58,6.84-21.855,6.626a2.548,2.548,0,0,1-.427-.214h0c-.16-.107-.267-.16-.427-.267h0l-.321-.16c-1.6-.962-.695-2.137-.107-2.779a3.054,3.054,0,0,0,.427-.374h0a15.143,15.143,0,0,0,4.863-10.42,4.6,4.6,0,0,0,1.069-1.389c.855-1.656.695-3.366-.374-3.954a1.519,1.519,0,0,0-1.282-.053C35.478,15.861,30.989,11.8,25.7,11.8c-5.344,0-9.832,4.115-11.382,9.832a1.838,1.838,0,0,0-1.5-.053c-1.069.534-1.229,2.3-.374,3.954a5.085,5.085,0,0,0,1.336,1.6A14.668,14.668,0,0,0,19.02,37.663h0l.107.107c.427.374,1.87,1.71-.107,2.885-.16.107-.374.214-.534.321h0C11.165,44.93.211,53.319,0,62.67-.27,75.281,14.05,83.243,14.691,83.563l.855-3.847H32.165l8.71-.481-.427-5.878A124.3,124.3,0,0,0,36.76,52.2h0l.16-3.74a37.431,37.431,0,0,0,5.13-.481c5.237-.8,12.076-4.114,17.046-8.763,3.527-3.26,5.611-8.229,6.84-13.573a5.339,5.339,0,0,0,3.26-3.847A4.98,4.98,0,0,0,65.775,15.754ZM13.409,76.724c-3.206-2.458-7.321-7.481-7.16-13.947.107-5.29,4.863-10.313,10.26-13.733C13.73,58.342,13.409,71.006,13.409,76.724Z" transform="translate(0.007 -11.8)"></path>
                                  <path id="path-77" className="clsrlf-13" d="M6.016,8.969l-.008.009c-14.588.16,1.977-6.252,1.977-6.252l.962-.855c0,.012.005.023.008.035C10.261.787,11.245,0,11.245,0h6.893l.481,8.122a112.338,112.338,0,0,1-11.4.869C6.8,8.991,6.395,8.984,6.016,8.969Z" transform="translate(9.065 121.565)"></path>
                                  <path id="path-78" className="clsrlf-13" d="M10.741,8.977l0-.011C6.261,8.932,0,8.122,0,8.122L.481,0H7.16S9.424,1.88,11.6,3.949C15.51,5.77,21.04,8.982,11.43,8.983,11.208,8.983,10.979,8.981,10.741,8.977Z" transform="translate(30.462 121.298)"></path>
                                  <path id="path-47" data-name="path" className="clsrlf-8" d="M30.8,244.8" transform="translate(-14.338 -120.296)"></path>
                                  <g id="group-10" data-name="group" transform="translate(15.205 121.698)">
                                    <path id="path-48" data-name="path" className="clsrlf-8" d="M33.24,246.844h0a.509.509,0,0,1-.695.16l-3.153-1.817a.509.509,0,0,1-.16-.695h0a.509.509,0,0,1,.695-.16l3.153,1.817A.509.509,0,0,1,33.24,246.844Z" transform="translate(-29.156 -242.118)"></path>
                                    <path id="path-49" data-name="path" className="clsrlf-8" d="M36.34,244.844h0a.509.509,0,0,1-.695.16l-3.153-1.817a.509.509,0,0,1-.16-.695h0a.509.509,0,0,1,.695-.16l3.153,1.817A.549.549,0,0,1,36.34,244.844Z" transform="translate(-30.599 -241.187)"></path>
                                    <path id="path-50" data-name="path" className="clsrlf-8" d="M39.14,242.844h0a.509.509,0,0,1-.695.16l-3.153-1.817a.509.509,0,0,1-.16-.695h0a.509.509,0,0,1,.695-.16l3.153,1.817A.509.509,0,0,1,39.14,242.844Z" transform="translate(-31.903 -240.256)"></path>
                                  </g>
                                  <g id="group-11" data-name="group" transform="translate(35.09 121.484)">
                                    <path id="path-51" data-name="path" className="clsrlf-8" d="M69.04,240.093h0a.509.509,0,0,0-.695-.16l-3.153,1.817a.509.509,0,0,0-.16.695h0a.509.509,0,0,0,.695.16l3.153-1.817A.509.509,0,0,0,69.04,240.093Z" transform="translate(-64.956 -239.856)"></path>
                                    <path id="path-52" data-name="path" className="clsrlf-8" d="M72.14,242.093h0a.509.509,0,0,0-.695-.16l-3.153,1.817a.509.509,0,0,0-.16.695h0a.509.509,0,0,0,.695.16l3.153-1.817A.549.549,0,0,0,72.14,242.093Z" transform="translate(-66.399 -240.787)"></path>
                                    <path id="path-53" data-name="path" className="clsrlf-8" d="M74.94,244.093h0a.509.509,0,0,0-.695-.16l-3.153,1.817a.509.509,0,0,0-.16.695h0a.509.509,0,0,0,.695.16l3.153-1.817A.509.509,0,0,0,74.94,244.093Z" transform="translate(-67.703 -241.718)"></path>
                                  </g>
                                  <g id="group-12" data-name="group" transform="translate(5.721 27.573)">
                                    <path id="path-54" data-name="path" className="clsrlf-1" d="M42.547,76.118l-.053-.107-.481-2.779c2.137,1.229,8.389-1.924,10.046-2.458L51.845,63.4c-5.344.267-8.817,2.511-14.107.107a9.283,9.283,0,0,1-6.786,3.366,9.181,9.181,0,0,1-6.626-3.153c-.053.053-.16.107-.214.16-.16.107-.374.214-.534.321h0c-4.489,2.4-10.046,5.611-12.878,10.206l4.6,4.008c2.351-1.55,3.58-2.511,5.611-3.794-2.458,8.176-2.4,16.832-2.458,23.191,0,.053.107,2.458.374,5.237H46.074l-.321-4.382C45.272,93.27,44.792,84.026,42.547,76.118Z" transform="translate(-10.7 -63.4)"></path>
                                  </g>
                                  <path id="path-55" data-name="path" className="clsrlf-13" d="M32.366,191.757h6.893l1.229-43.6h-.107l2.779,43.87,6.2-.652,3.847-54.012L25.9,137.2Z" transform="translate(-12.057 -70.192)"></path>
                                </g>
                              </g>
                            </g>
                          </g>
                          <g id="group-144" transform="translate(5.099 100.521)">
                            <g id="group-14" data-name="group" transform="translate(0 0)">
                              <g id="group-149">
                                <path id="path-56" data-name="path" className="clsrlf-14" d="M0,6.78l3.3-1.1C8.888,3.848,14.477,1.924,20.066.092A1.559,1.559,0,0,1,20.708,0L40.5,6.6a1.548,1.548,0,0,1,.458.183c-1.283.458-2.474.825-3.665,1.191C31.886,9.8,26.48,11.545,21.074,13.377a1.565,1.565,0,0,1-1.283,0c-3.3-1.191-6.689-2.291-9.987-3.39A1.548,1.548,0,0,1,9.346,9.8c1.283-.458,2.474-.825,3.665-1.283,2.474-.825,4.948-1.741,7.513-2.566a.973.973,0,0,0,.458-1.649.729.729,0,0,0-.916-.183c-1.649.55-3.207,1.191-4.856,1.741C12.553,6.872,9.8,7.788,7.147,8.8a.578.578,0,0,1-.641.092C4.4,8.246,2.2,7.513,0,6.78Z"></path>
                                <path id="path-57" data-name="path" className="clsrlf-1" d="M11.1,13c1.191.367,2.291.733,3.39,1.1l6.6,2.2a.783.783,0,0,0,.641,0c3.207-1.1,6.414-2.107,9.621-3.207A.337.337,0,0,0,31.624,13v5.772a2.724,2.724,0,0,1-.733,1.833,6.511,6.511,0,0,1-2.749,1.649,21.358,21.358,0,0,1-7.238,1.1,16.86,16.86,0,0,1-6.78-1.283,16.136,16.136,0,0,1-2.107-1.283,2.359,2.359,0,0,1-.916-2.016Q11.1,15.886,11.1,13Z" transform="translate(-0.929 -3.203)"></path>
                                <path id="path-58" data-name="path" className="clsrlf-5" d="M6.512,20.872H3.6c.26-1.04.52-2.028.78-3.068a1.375,1.375,0,0,1-.728-.884,1.5,1.5,0,0,1,.728-1.664c.156-.052.156-.156.156-.312V11.72c0-.312.156-.52.468-.52a.466.466,0,0,1,.52.52V15a.286.286,0,0,0,.156.26A1.457,1.457,0,0,1,5.836,17.8c-.1.052-.1.156-.052.26.208.884.468,1.768.676,2.652A.4.4,0,0,0,6.512,20.872Z" transform="translate(1.11 -1.641)"></path>
                              </g>
                            </g>
                          </g>
               <g id="group-61" data-name="group" transform="translate(1283.017 163.289)">
                          <g id="group-62" data-name="group">
                            <path id="path-138" data-name="path" className="clsrlf-8" d="M9.05,28.31.1,28v2.889L9.024,40.9V37.776L3.144,31.147l5.907.284V28.31" transform="translate(-0.1 -28)"></path>
                            <path id="path-139" data-name="path" className="clsrlf-8" d="M47.51,35.992,42.3,33v2.811l2.45,1.419L44.7,49.172l2.76,1.6.052-14.779" transform="translate(-31.415 -31.71)"></path>
                            <path id="path-140" data-name="path" className="clsrlf-8" d="M78.013,71.355A4.313,4.313,0,0,1,76.13,68.9a11.494,11.494,0,0,1-.593-3.921,6.4,6.4,0,0,1,.619-3.224c.413-.671,1.032-.748,1.909-.258a4.313,4.313,0,0,1,1.883,2.45,11.494,11.494,0,0,1,.593,3.921,6.4,6.4,0,0,1-.619,3.224c-.413.671-1.058.748-1.909.258m.026-12.561c-1.78-1.032-3.1-1.109-4-.232-.877.877-1.341,2.476-1.341,4.8a14.817,14.817,0,0,0,1.315,6.319,9.782,9.782,0,0,0,3.972,4.385c1.78,1.032,3.121,1.109,4,.232s1.341-2.476,1.341-4.8a14.817,14.817,0,0,0-1.315-6.319,9.377,9.377,0,0,0-3.972-4.385" transform="translate(-53.974 -50.23)"></path>
                            <path id="path-141" data-name="path" className="clsrlf-8" d="M130.069,87.9l-2.605-1.5L119.7,101.308l2.605,1.5L130.069,87.9" transform="translate(-88.851 -71.337)"></path>
                            <path id="path-142" data-name="path" className="clsrlf-8" d="M169.11,106.218l-5.184-3.018-.026,2.811,2.45,1.419-.026,11.968,2.76,1.573.026-14.754" transform="translate(-121.651 -83.804)"></path>
                            <path id="path-143" data-name="path" className="clsrlf-8" d="M199.688,141.58a4.313,4.313,0,0,1-1.883-2.45,11.494,11.494,0,0,1-.593-3.921,6.4,6.4,0,0,1,.619-3.224c.413-.671,1.032-.748,1.909-.258a4.313,4.313,0,0,1,1.883,2.45,11.494,11.494,0,0,1,.593,3.921,6.4,6.4,0,0,1-.619,3.224c-.413.671-1.032.748-1.909.258m.052-12.587c-1.78-1.032-3.121-1.109-4-.232s-1.341,2.476-1.341,4.8a14.817,14.817,0,0,0,1.315,6.319,9.651,9.651,0,0,0,3.972,4.385c1.78,1.032,3.1,1.109,4,.232.877-.877,1.341-2.476,1.341-4.8a14.817,14.817,0,0,0-1.315-6.319,9.639,9.639,0,0,0-3.972-4.385" transform="translate(-144.284 -102.323)"></path>
                            <path id="path-144" data-name="path" className="clsrlf-8" d="M244.826,150.6v3.1l5.881,6.526-5.907-.181v3.121l8.95.31v-2.889l-8.924-9.982" transform="translate(-181.684 -118.978)"></path>
                          </g>
                        </g>
                        </g>
                        <g id="group-262" transform="translate(1233.809 447.981)">
                          <g id="group-15" data-name="group">
                            <path id="path-59" data-name="path" className="clsrlf-2" d="M110.487,572.764V256.7L1,319.977V636.041Z" transform="translate(-1 -256.7)"></path>
                            <path id="path-60" data-name="path" className="clsrlf-3" d="M229.487,639.764V323.7L120,386.977V703.041Z" transform="translate(-24.199 -269.761)"></path>
                            <path id="path-61" data-name="path" className="clsrlf-18" d="M1,335.3l95.8,53.939V705.3L1,651.364Z" transform="translate(-1 -272.023)"></path>
                            <path id="path-62" data-name="path" className="clsrlf-3" d="M206.288,310.639l-95.8-53.939L1,319.977l95.8,53.939Z" transform="translate(-1 -256.7)"></path>
                            <g id="group-16" data-name="group" transform="translate(118.94 133.317)">
                              <path id="path-63" data-name="path" className="clsrlf-5" d="M222.993,422.3,154,461.506v16.1l68.993-39.528Z" transform="translate(-154 -422.3)"></path>
                              <path id="path-64" data-name="path" className="clsrlf-5" d="M222.993,466.4,154,505.606v16.1l68.993-39.528Z" transform="translate(-154 -430.897)"></path>
                              <path id="path-65" data-name="path" className="clsrlf-5" d="M222.993,510.5,154,549.706v16.1l68.993-39.528Z" transform="translate(-154 -439.494)"></path>
                              <path id="path-66" data-name="path" className="clsrlf-5" d="M222.993,554.6,154,593.806v16.1l68.993-39.528Z" transform="translate(-154 -448.091)"></path>
                              <path id="path-67" data-name="path" className="clsrlf-5" d="M222.993,598.7,154,637.906v16.1l68.993-39.528Z" transform="translate(-154 -456.689)"></path>
                              <path id="path-68" data-name="path" className="clsrlf-5" d="M222.993,642.8,154,682.006v16.1l68.993-39.528Z" transform="translate(-154 -465.286)"></path>
                            </g>
                            <g id="group-17" data-name="group" transform="translate(11.053 168.701)">
                              <path id="path-69" data-name="path" className="clsrlf-19" d="M12.1,464.5l68.993,39.206v16.181L12.1,480.36Z" transform="translate(-12.1 -464.5)"></path>
                              <path id="path-70" data-name="path" className="clsrlf-19" d="M12.1,508.6l68.993,39.206v16.182L12.1,524.46Z" transform="translate(-12.1 -473.097)"></path>
                              <path id="path-71" data-name="path" className="clsrlf-19" d="M12.1,552.8l68.993,39.126v16.182L12.1,568.579Z" transform="translate(-12.1 -481.714)"></path>
                              <path id="path-72" data-name="path" className="clsrlf-19" d="M12.1,596.9l68.993,39.125v16.182L12.1,612.679Z" transform="translate(-12.1 -490.311)"></path>
                              <path id="path-73" data-name="path" className="clsrlf-19" d="M12.1,641l68.993,39.126v16.182L12.1,656.779Z" transform="translate(-12.1 -498.908)"></path>
                            </g>
                            <path id="path-74" data-name="path" className="clsrlf-20" d="M206.852,363,97.365,426.2,1,370.568" transform="translate(-1 -301.412)"></path>
                          </g>
                          <g id="group-195" transform="translate(7.314 107.965)">
                            <g id="group-18" data-name="group">
                              <g id="group-19" data-name="group">
                                <g id="group-20" data-name="group" className="clsrlf-21">
                                  <g id="group-21" data-name="group" transform="translate(0.039 2.279)">
                                    <g id="group-22" data-name="group">
                                      <g id="group-23" data-name="group">
                                        <path id="path-75" data-name="path" className="clsrlf-22" d="M5.1,15.926a7.062,7.062,0,0,1-3.178-5.5C1.922,8.407,3.356,7.593,5.1,8.6l5.465,3.139a7.062,7.062,0,0,1,3.178,5.5c0,2.015-1.434,2.829-3.178,1.822L5.1,15.926m5.62-6.085-5.7-3.294C2.309,5,.1,6.237.1,9.376a10.7,10.7,0,0,0,4.922,8.488l5.736,3.294c2.713,1.55,4.922.31,4.922-2.829A11.065,11.065,0,0,0,10.72,9.841m14.224,8.217L16.65,13.291l-.039,11.317,1.9,1.085.039-9.3,6.24,3.6a3.479,3.479,0,0,1,1.589,2.752c0,1.008-.7,1.434-1.589.93l-5.349-3.1,5.62,8.914,2.752,1.589-3.8-5.852.853.5c1.822,1.046,3.333.194,3.333-1.9a7.206,7.206,0,0,0-3.256-5.775M53.315,34.453l-6.783-3.915c-2.713-1.55-4.922-.31-4.922,2.829a10.7,10.7,0,0,0,4.922,8.488l5.852,3.372,1.124-1.357-6.821-3.953a7.062,7.062,0,0,1-3.178-5.5c0-2.015,1.434-2.829,3.217-1.822l5.542,3.217,1.046-1.357m2.907,1.667-1.9-1.085-.039,10.232a2.285,2.285,0,0,0,.271.93,2.132,2.132,0,0,0,.7.736L63.9,51.894l1.124-1.357-8.8-5.077V36.119M76.337,47.747l-6.783-3.915c-2.713-1.55-4.922-.31-4.922,2.829a10.7,10.7,0,0,0,4.922,8.488l5.852,3.372,1.124-1.357-6.9-3.953a7.143,7.143,0,0,1-3.062-4.418l8.1,4.651,1.124-1.357-9.186-5.31c.388-1.318,1.628-1.744,3.062-.891L75.213,49.1l1.124-1.357m-40.89-23.72c-.465-.271-.93-.271-1.163.039l-6.008,7.248,2.248,1.279,4.922-6.085,2.636,6.473-5.039-2.907,1.124,2.636,5.116,2.946,1.046,2.6,2.248,1.279L36.61,25.345a3.227,3.227,0,0,0-1.163-1.318" transform="translate(-0.1 -5.88)"></path>
                                        <path id="path-76" data-name="path" className="clsrlf-22" d="M199.669,117.307v-.426l.078.039a2.376,2.376,0,0,0,.233.155.285.285,0,0,1,.078.233.142.142,0,0,1-.039.116c-.039.039-.116,0-.194-.039l-.155-.078m.078-.581-.31-.194v1.434l.233.155v-.543l.155.116.233.7.271.155-.271-.775h.039a.163.163,0,0,0,.155-.155V117.5a.941.941,0,0,0-.233-.543c-.039-.116-.116-.155-.271-.233m.078,1.783a1.905,1.905,0,0,1-.853-1.512c0-.543.388-.775.853-.5a1.966,1.966,0,0,1,.853,1.512c.039.581-.349.814-.853.5m0-2.248c-.62-.349-1.124-.078-1.124.62a2.48,2.48,0,0,0,1.085,1.9c.62.349,1.124.078,1.124-.62a2.28,2.28,0,0,0-1.085-1.9" transform="translate(-121.726 -73.386)"></path>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                        <g id="group-258" transform="translate(1267.465 461.39)">
                          <g id="group-60">
                            <path id="path-77-2" data-name="path" className="clsrlf-4" d="M400.27,292.835l35.772,19.89L459.45,299.21V272.181l-35.111-19.4v26.8Z" transform="translate(-400.27 -252.78)"></path>
                            <line id="line" className="clsrlf-4" x2="35.111" y2="19.628" transform="translate(24.069 26.802)"></line>
                            <path id="path-78-2" data-name="path" className="clsrlf-5" d="M417.27,304.11l11.7-6.54,27.848,15.594-11.532,6.6Z" transform="translate(-405.566 -266.734)"></path>
                          </g>
                          <g id="group-24-3" data-name="group" transform="translate(30.642 5.949)">
                            <g id="group-25" data-name="group" className="clsrlf-23">
                              <g id="group-26-3" data-name="group" transform="translate(0 1.525)">
                                <g id="group-27" data-name="group">
                                  <g id="group-28" data-name="group">
                                    <path id="path-79" data-name="path" d="M2.758,3.5.1,3.587v1L2.758,7.727V6.594L1.189,4.72l1.569-.087V3.5" transform="translate(-0.056 -3.5)"></path>
                                    <path id="path-80" data-name="path" d="M43.3,62.6v1.177l1.569,1.874-1.569.044v1.133l2.658-.087v-.959L43.3,62.6" transform="translate(-24.432 -36.847)"></path>
                                    <path id="path-81-3" data-name="path" d="M21.539,25.876.1,13.5v1.046L21.539,26.965V25.876" transform="translate(-0.056 -9.142)"></path>
                                    <path id="path-82" data-name="path" d="M21.539,31.776.1,19.4v1.046L21.539,32.821V31.776" transform="translate(-0.056 -12.471)"></path>
                                    <path id="path-83" data-name="path" d="M21.539,37.676.1,25.3v1.046L21.539,38.721V37.676" transform="translate(-0.056 -15.8)"></path>
                                    <path id="path-84" data-name="path" d="M21.483,43.576,0,31.2v1.046L21.483,44.621V43.576" transform="translate(0 -19.13)"></path>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                        <g id="group-256" transform="translate(1317.561 435.989)">
                          <g id="group-60-2" data-name="group-60" transform="translate(0 0)">
                            <path id="path-85" data-name="path" className="clsrlf-4" d="M400.27,292.835l35.772,19.89L459.45,299.21V272.181l-35.111-19.4v26.8Z" transform="translate(-400.27 -252.78)"></path>
                            <line id="line-2" data-name="line" className="clsrlf-4" x2="35.111" y2="19.628" transform="translate(24.069 26.802)"></line>
                            <path id="path-86" data-name="path" className="clsrlf-5" d="M417.27,304.11l11.7-6.54,27.848,15.594-11.532,6.6Z" transform="translate(-405.566 -266.734)"></path>
                          </g>
                          <g id="group-123" transform="translate(28.121 7.84)">
                            <path id="path-34-2" data-name="path-34" className="clsrlf-5" d="M3688.191,5221.893l26.362,14.355-.356,9.051-26.006-14.509Z" transform="translate(-3687.835 -5221.893)"></path>
                            <path id="path-34-3" data-name="path-34" className="clsrlf-5" d="M3690.192,5218.893l11.049,6.075v5.263l-11.049-6.094Z" transform="translate(-3690.192 -5207.106)"></path>
                            <path id="path-34-4" data-name="path-34" className="clsrlf-5" d="M3690.192,5217.893l11.405,6.432v5.62l-11.405-6.451Z" transform="translate(-3675.947 -5198.271)"></path>
                          </g>
                        </g>
                        <g id="group-156" transform="translate(1329.555 469.857)">
                          <g id="group-60-3" data-name="group-60" transform="translate(0 0)">
                            <path id="path-87" data-name="path" className="clsrlf-4" d="M400.27,292.835l35.772,19.89L459.45,299.21V272.181l-35.111-19.4v26.8Z" transform="translate(-400.27 -252.78)"></path>
                            <line id="line-3" data-name="line" className="clsrlf-4" x2="35.111" y2="19.628" transform="translate(24.069 26.802)"></line>
                            <path id="path-88" data-name="path" className="clsrlf-5" d="M417.27,304.11l11.7-6.54,27.848,15.594-11.532,6.6Z" transform="translate(-405.566 -266.734)"></path>
                          </g>
                          <path id="path-89" className="clsrlf-24" d="M829.6,8325.445l7.376-16.948,11.876,6.762v21.534Z" transform="translate(-792.451 -8294.478)"></path>
                          <g id="group-105" transform="matrix(0.999, 0.035, -0.035, 0.999, 35.724, 17.519)">
                            <g id="group-29" data-name="group" transform="translate(0 0)">
                              <g id="group-30" data-name="group">
                                <g id="group-31" data-name="group" transform="translate(2.002 1.84)">
                                  <path id="path-89-2" data-name="path" d="M2.1.045,2.281,0,.179.559,0,.6Z"></path>
                                </g>
                                <g id="group-32" data-name="group" transform="translate(0 0.071)">
                                  <path id="path-90" data-name="path" className="clsrlf-25" d="M3.623,0,3.511,1.834l-2.1.559L3.332,4.92,3.22,6.753,0,2.5.089.939Z"></path>
                                </g>
                                <g id="group-33" data-name="group" transform="translate(3.744 0)">
                                  <path id="path-91" data-name="path" className="clsrlf-25" d="M3.936.38.783,8.878,0,8.5,3.153,0Z"></path>
                                </g>
                              </g>
                              <g id="group-34" data-name="group" transform="translate(7.414 2.378)">
                                <g id="group-35" data-name="group" transform="translate(0.089 3.733)">
                                  <path id="path-92" data-name="path" d="M0,.581.179.537,2.281,0,2.124.045Z"></path>
                                </g>
                                <g id="group-36" data-name="group" transform="translate(0 5.186)">
                                  <path id="path-93" data-name="path" d="M3.533.045,3.69,0,.157.939,0,.984Z" transform="translate(0 0)"></path>
                                </g>
                                <g id="group-37" data-name="group" transform="translate(0.317 0)">
                                  <path id="path-94" data-name="path" className="clsrlf-25" d="M.38,0,3.623,4.249,3.533,5.814,0,6.753.089,4.9l2.124-.537L.291,1.834Z"></path>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                        <g id="group-191" transform="translate(1301.398 236.223)">
                          <g id="group-46" transform="translate(69.954 52.036) rotate(161)">
                            <g id="ellipse-9" className="clsrlf-1" transform="translate(0)">
                              <circle className="clsrlf-29" cx="23.989" cy="23.989" r="23.989"></circle>
                              <circle className="clsrlf-30" cx="23.989" cy="23.989" r="22.989"></circle>
                            </g>
                            <g id="ellipse-9-2" data-name="ellipse-9" className="clsrlf-26" transform="translate(43.745 43.745)">
                              <circle className="clsrlf-29" cx="5.645" cy="5.645" r="5.645"></circle>
                              <circle className="clsrlf-30" cx="5.645" cy="5.645" r="4.645"></circle>
                            </g>
                          </g>
                          <path id="path-84-2" data-name="path-84" className="clsrlf-5" d="M0,0,2.011,22.562l9.023,2.5,9.048-2.51L22.1,0ZM17.907,5.356l-.128,1.4-.055.621H7.135l.256,2.833H17.474l-.067.743-.652,7.281-.043.469-5.66,1.572h0l-.012.006L5.374,18.711l-.39-4.344H7.762l.195,2.206,3.083.835h0l3.083-.835.323-3.589H4.856L4.18,5.356l-.067-.743H17.974Z" transform="translate(28.627 25.804)"></path>
                        </g>
                        <g id="group-255" transform="translate(1183.803 138.385)">
                          <g id="group-46-2" data-name="group-46" transform="translate(15.17 0) rotate(16)">
                            <g id="ellipse-9-3" data-name="ellipse-9" className="clsrlf-1">
                              <circle className="clsrlf-29" cx="23.989" cy="23.989" r="23.989"></circle>
                              <circle className="clsrlf-30" cx="23.989" cy="23.989" r="22.989"></circle>
                            </g>
                            <g id="ellipse-9-4" data-name="ellipse-9" className="clsrlf-26" transform="translate(43.745 43.745)">
                              <circle className="clsrlf-29" cx="5.645" cy="5.645" r="5.645"></circle>
                              <circle className="clsrlf-30" cx="5.645" cy="5.645" r="4.645"></circle>
                            </g>
                          </g>
                          <g id="group-121" transform="translate(16.843 21.633)">
                            <path id="path-95" data-name="path" className="clsrlf-5" d="M1.658,0H.593L0,3.042H.867a2.442,2.442,0,0,0,1.481-.367,1.992,1.992,0,0,0,.641-1.234A1.315,1.315,0,0,0,2.87.318,1.658,1.658,0,0,0,1.658,0Z" transform="translate(21.133 5.921)"></path>
                            <path id="path-96" data-name="path" className="clsrlf-5" d="M1.658,0H.593L0,3.042H.867a2.442,2.442,0,0,0,1.481-.367,1.993,1.993,0,0,0,.641-1.234A1.315,1.315,0,0,0,2.87.318,1.658,1.658,0,0,0,1.658,0Z" transform="translate(7.434 5.921)"></path>
                            <path id="path-97" data-name="path" className="clsrlf-5" d="M15.283,0C6.841,0,0,3.6,0,8.039s6.841,8.039,15.283,8.039,15.283-3.6,15.283-8.039S23.724,0,15.283,0ZM11.409,9.609a3.3,3.3,0,0,1-1.229.738,5.523,5.523,0,0,1-1.689.217H7.124l-.389,2.008a.162.162,0,0,1-.164.133H4.816a.163.163,0,0,1-.128-.062.172.172,0,0,1-.035-.137L6.217,4.449a.162.162,0,0,1,.164-.133H9.755a2.97,2.97,0,0,1,2.348.858,2.677,2.677,0,0,1,.46,2.375,3.809,3.809,0,0,1-.407,1.128A3.732,3.732,0,0,1,11.409,9.609Zm5.05.9a.172.172,0,0,1-.035-.137l.694-3.564c.066-.341.049-.584-.044-.685-.057-.062-.234-.168-.752-.168H15.066l-.871,4.48a.162.162,0,0,1-.164.133H12.289a.163.163,0,0,1-.128-.062.172.172,0,0,1-.035-.137l1.565-8.057a.162.162,0,0,1,.164-.133H15.6a.163.163,0,0,1,.128.062.172.172,0,0,1,.035.137L15.384,4.32h1.349a3.14,3.14,0,0,1,2.131.553,1.843,1.843,0,0,1,.385,1.809l-.73,3.75a.162.162,0,0,1-.164.133H16.587A.174.174,0,0,1,16.459,10.507Zm9.8-2.958a3.685,3.685,0,0,1-1.154,2.065,3.3,3.3,0,0,1-1.229.738,5.523,5.523,0,0,1-1.689.217H20.824l-.389,2.008a.162.162,0,0,1-.164.133H18.515a.163.163,0,0,1-.128-.062.172.172,0,0,1-.035-.137l1.565-8.057a.162.162,0,0,1,.164-.133h3.374a2.97,2.97,0,0,1,2.348.858A2.664,2.664,0,0,1,26.263,7.548Z" transform="translate(0 0)"></path>
                          </g>
                        </g>
                        <g id="group-261" transform="translate(1028.137 455.65)">
                          <g id="group-135">
                            <g id="group-38" data-name="group" transform="translate(0 0)">
                              <path id="path-98" data-name="path" className="clsrlf-2" d="M110.487,572.764V256.7L1,319.977V636.041Z" transform="translate(-1 -256.7)"></path>
                              <path id="path-99" data-name="path" className="clsrlf-3" d="M229.487,639.764V323.7L120,386.977V703.041Z" transform="translate(-24.199 -269.761)"></path>
                              <path id="path-100" data-name="path" className="clsrlf-18" d="M1,335.3l95.8,53.939V705.3L1,651.364Z" transform="translate(-1 -272.023)"></path>
                              <path id="path-101" data-name="path" className="clsrlf-3" d="M206.288,310.639l-95.8-53.939L1,319.977l95.8,53.939Z" transform="translate(-1 -256.7)"></path>
                              <g id="group-39" data-name="group" transform="translate(119.645 131.2)">
                                <path id="path-102" data-name="path" className="clsrlf-5" d="M222.993,422.3,154,461.506v16.1l68.993-39.528Z" transform="translate(-154 -422.3)"></path>
                                <path id="path-103" data-name="path" className="clsrlf-5" d="M222.993,466.4,154,505.606v16.1l68.993-39.528Z" transform="translate(-154 -430.897)"></path>
                                <path id="path-104" data-name="path" className="clsrlf-5" d="M222.993,510.5,154,549.706v16.1l68.993-39.528Z" transform="translate(-154 -439.494)"></path>
                                <path id="path-105" data-name="path" className="clsrlf-5" d="M222.993,554.6,154,593.806v16.1l68.993-39.528Z" transform="translate(-154 -448.091)"></path>
                                <path id="path-106" data-name="path" className="clsrlf-5" d="M222.993,598.7,154,637.906v16.1l68.993-39.528Z" transform="translate(-154 -456.689)"></path>
                                <path id="path-107" data-name="path" className="clsrlf-5" d="M222.993,642.8,154,682.006v16.1l68.993-39.528Z" transform="translate(-154 -465.286)"></path>
                              </g>
                              <g id="group-40" data-name="group" transform="translate(11.053 167.29)">
                                <path id="path-108" data-name="path" className="clsrlf-19" d="M12.1,464.5l68.993,39.206v16.181L12.1,480.36Z" transform="translate(-12.1 -464.5)"></path>
                                <path id="path-109" data-name="path" className="clsrlf-19" d="M12.1,508.6l68.993,39.206v16.182L12.1,524.46Z" transform="translate(-12.1 -473.097)"></path>
                                <path id="path-110" data-name="path" className="clsrlf-19" d="M12.1,552.8l68.993,39.126v16.182L12.1,568.579Z" transform="translate(-12.1 -481.714)"></path>
                                <path id="path-111" data-name="path" className="clsrlf-19" d="M12.1,596.9l68.993,39.125v16.182L12.1,612.679Z" transform="translate(-12.1 -490.311)"></path>
                                <path id="path-112" data-name="path" className="clsrlf-19" d="M12.1,641l68.993,39.126v16.182L12.1,656.779Z" transform="translate(-12.1 -498.908)"></path>
                              </g>
                              <path id="path-113" data-name="path" className="clsrlf-27" d="M206.852,363,97.365,426.2,1,370.568" transform="translate(-1 -300.001)"></path>
                            </g>
                          </g>
                          <g id="group-194" transform="translate(22.097 105.303)">
                            <g id="group-41" data-name="group">
                              <g id="group-42" data-name="group">
                                <g id="group-43" data-name="group" className="clsrlf-28">
                                  <g id="group-44" data-name="group" transform="translate(0.067 9.002)">
                                    <g id="group-45" data-name="group">
                                      <g id="group-46-3" data-name="group">
                                        <path id="path-114" data-name="path" className="clsrlf-5" d="M33.3,93.752,30.05,91.873l-4.6,11.73,3.252,1.879,4.6-11.73M20.986,70.333c-.307-.173-.613-.347-.92-.507L15.855,80.61l3.639,2.106c2.159,1.253,3.332,3.839,2.573,5.745l-5.2,13.3-4.332-2.493,5.585-14.249-3.252-1.879L9.3,97.378,4.965,94.885l6.558-16.768h0l3.945-10.09C6.871,65.814.526,71.239.5,82.009.473,93.1,7.137,106.242,16.121,113.893l3.825-9.784h0l6.745-17.248,7.971,4.6c2.173,1.253,3.332,3.839,2.586,5.758l-4.572,11.676a1.631,1.631,0,0,1-2.466.56l-5.745-3.319-4.212,10.77c.2.12.4.24.613.36,11.277,6.518,20.46,1.28,20.487-11.676S32.276,76.838,20.986,70.333" transform="translate(-0.499 -67.539)"></path>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                        <g id="group-260" transform="translate(1059.322 471.268)">
                          <g id="group-60-4" data-name="group-60">
                            <path id="path-115" data-name="path" className="clsrlf-4" d="M400.27,292.835l35.772,19.89L459.45,299.21V272.181l-35.111-19.4v26.8Z" transform="translate(-400.27 -252.78)"></path>
                            <line id="line-4" data-name="line" className="clsrlf-4" x2="35.111" y2="19.628" transform="translate(24.069 26.802)"></line>
                            <path id="path-116" data-name="path" className="clsrlf-5" d="M417.27,304.11l11.7-6.54,27.848,15.594-11.532,6.6Z" transform="translate(-405.566 -266.734)"></path>
                          </g>
                          <g id="group-47-2" data-name="group" transform="translate(29.937 5.949)">
                            <g id="group-48-2" data-name="group" className="clsrlf-23">
                              <g id="group-49-2" data-name="group" transform="translate(0 1.525)">
                                <g id="group-50" data-name="group">
                                  <g id="group-51" data-name="group">
                                    <path id="path-117" data-name="path" d="M2.758,3.5.1,3.587v1L2.758,7.727V6.594L1.189,4.72l1.569-.087V3.5" transform="translate(-0.056 -3.5)"></path>
                                    <path id="path-118" data-name="path" d="M43.3,62.6v1.177l1.569,1.874-1.569.044v1.133l2.658-.087v-.959L43.3,62.6" transform="translate(-24.432 -36.847)"></path>
                                    <path id="path-119" data-name="path" d="M21.539,25.876.1,13.5v1.046L21.539,26.965V25.876" transform="translate(-0.056 -9.142)"></path>
                                    <path id="path-120" data-name="path" d="M21.539,31.776.1,19.4v1.046L21.539,32.821V31.776" transform="translate(-0.056 -12.471)"></path>
                                    <path id="path-121" data-name="path" d="M21.539,37.676.1,25.3v1.046L21.539,38.721V37.676" transform="translate(-0.056 -15.8)"></path>
                                    <path id="path-122" data-name="path" d="M21.483,43.576,0,31.2v1.046L21.483,44.621V43.576" transform="translate(0 -19.13)"></path>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                        <g id="group-254" transform="translate(1123.929 136.737)">
                          <g id="group-46-4" data-name="group-46" transform="translate(10.646 0) rotate(12)">
                            <g id="ellipse-9-5" data-name="ellipse-9" className="clsrlf-1" transform="translate(0)">
                              <circle className="clsrlf-29" cx="22.319" cy="22.319" r="22.319"></circle>
                              <circle className="clsrlf-30" cx="22.319" cy="22.319" r="21.319"></circle>
                            </g>
                            <g id="ellipse-9-6" data-name="ellipse-9" className="clsrlf-26" transform="translate(40.699 40.699)">
                              <circle className="clsrlf-29" cx="5.252" cy="5.252" r="5.252"></circle>
                              <circle className="clsrlf-30" cx="5.252" cy="5.252" r="4.252"></circle>
                            </g>
                          </g>
                          <g id="group-122" transform="translate(16.297 15.222)">
                            <path id="path1948_2_" className="clsrlf-5" d="M10.986,0A14.493,14.493,0,0,0,8.4.222c-2.28.4-2.7,1.251-2.7,2.8V5.084h5.407V5.77H3.662A3.4,3.4,0,0,0,.273,8.513,9.947,9.947,0,0,0,.273,14c.383,1.594,1.311,2.744,2.865,2.744H4.994V14.283a3.46,3.46,0,0,1,3.389-3.369H13.79a2.712,2.712,0,0,0,2.7-2.744V3.026a3,3,0,0,0-2.7-2.8A16.263,16.263,0,0,0,10.986,0ZM8.06,1.654A1.016,1.016,0,0,1,9.069,2.683,1.029,1.029,0,0,1,8.06,3.712a1.029,1.029,0,0,1,0-2.058Z" transform="translate(0.113)"></path>
                            <path id="path1950_2_" className="clsrlf-5" d="M40.179,28.7v2.4a3.472,3.472,0,0,1-3.389,3.43H31.4a2.765,2.765,0,0,0-2.7,2.744v5.144c0,1.473,1.271,2.32,2.7,2.744a9.129,9.129,0,0,0,5.407,0c1.372-.4,2.7-1.19,2.7-2.744V40.361H34.107v-.686h8.11c1.574,0,2.159-1.089,2.7-2.744a9.219,9.219,0,0,0,0-5.487c-.383-1.574-1.13-2.744-2.7-2.744ZM37.153,41.732a1.016,1.016,0,0,1,1.009,1.029,1.029,1.029,0,0,1-1.009,1.029,1.016,1.016,0,0,1-1.009-1.029A1,1,0,0,1,37.153,41.732Z" transform="translate(-22.887 -22.91)"></path>
                          </g>
                        </g>
                        <g id="group-259" transform="translate(1109.417 445.867)">
                          <g id="group-60-5" data-name="group-60" transform="translate(0 0)">
                            <path id="path-123" data-name="path" className="clsrlf-4" d="M400.27,292.835l35.772,19.89L459.45,299.21V272.181l-35.111-19.4v26.8Z" transform="translate(-400.27 -252.78)"></path>
                            <line id="line-5" data-name="line" className="clsrlf-4" x2="35.111" y2="19.628" transform="translate(24.069 26.802)"></line>
                            <path id="path-124" data-name="path" className="clsrlf-5" d="M417.27,304.11l11.7-6.54,27.848,15.594-11.532,6.6Z" transform="translate(-405.566 -266.734)"></path>
                          </g>
                          <g id="group-123-2" data-name="group-123" transform="translate(28.121 7.84)">
                            <path id="path-34-5" data-name="path-34" className="clsrlf-5" d="M3688.191,5221.893l26.362,14.355-.356,9.051-26.006-14.509Z" transform="translate(-3687.835 -5221.893)"></path>
                            <path id="path-34-6" data-name="path-34" className="clsrlf-5" d="M3690.192,5218.893l11.049,6.075v5.263l-11.049-6.094Z" transform="translate(-3690.192 -5207.106)"></path>
                            <path id="path-34-7" data-name="path-34" className="clsrlf-5" d="M3690.192,5217.893l11.405,6.432v5.62l-11.405-6.451Z" transform="translate(-3675.947 -5198.271)"></path>
                          </g>
                        </g>
                        <g id="group-156-2" data-name="group-156" transform="translate(1121.412 479.735)">
                          <g id="group-60-6" data-name="group-60">
                            <path id="path-125" data-name="path" className="clsrlf-4" d="M400.27,292.835l35.772,19.89L459.45,299.21V272.181l-35.111-19.4v26.8Z" transform="translate(-400.27 -252.78)"></path>
                            <line id="line-6" data-name="line" className="clsrlf-4" x2="35.111" y2="19.628" transform="translate(24.069 26.802)"></line>
                            <path id="path-126" data-name="path" className="clsrlf-5" d="M417.27,304.11l11.7-6.54,27.848,15.594-11.532,6.6Z" transform="translate(-405.566 -266.734)"></path>
                          </g>
                          <path id="path-89-3" data-name="path-89" className="clsrlf-24" d="M829.6,8325.445l7.376-16.948,11.876,6.762v21.534Z" transform="translate(-792.451 -8294.478)"></path>
                          <g id="group-105-2" data-name="group-105" transform="matrix(0.999, 0.035, -0.035, 0.999, 35.724, 17.519)">
                            <g id="group-52" data-name="group" transform="translate(0 0)">
                              <g id="group-53" data-name="group">
                                <g id="group-54" data-name="group" transform="translate(2.002 1.84)">
                                  <path id="path-127" data-name="path" d="M2.1.045,2.281,0,.179.559,0,.6Z"></path>
                                </g>
                                <g id="group-55" data-name="group" transform="translate(0 0.071)">
                                  <path id="path-128" data-name="path" className="clsrlf-25" d="M3.623,0,3.511,1.834l-2.1.559L3.332,4.92,3.22,6.753,0,2.5.089.939Z"></path>
                                </g>
                                <g id="group-56" data-name="group" transform="translate(3.744 0)">
                                  <path id="path-129" data-name="path" className="clsrlf-25" d="M3.936.38.783,8.878,0,8.5,3.153,0Z"></path>
                                </g>
                              </g>
                              <g id="group-57" data-name="group" transform="translate(7.414 2.378)">
                                <g id="group-58" data-name="group" transform="translate(0.089 3.733)">
                                  <path id="path-130" data-name="path" d="M0,.581.179.537,2.281,0,2.124.045Z"></path>
                                </g>
                                <g id="group-59" data-name="group" transform="translate(0 5.186)">
                                  <path id="path-131" data-name="path" d="M3.533.045,3.69,0,.157.939,0,.984Z" transform="translate(0 0)"></path>
                                </g>
                                <g id="group-60-7" data-name="group" transform="translate(0.317 0)">
                                  <path id="path-132" data-name="path" className="clsrlf-25" d="M.38,0,3.623,4.249,3.533,5.814,0,6.753.089,4.9l2.124-.537L.291,1.834Z"></path>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                        <g id="group-253" transform="translate(1091.221 190.445)">
                          <g id="group-46-5" data-name="group-46" transform="matrix(0.891, -0.454, 0.454, 0.891, 0, 24.985)">
                            <g id="ellipse-9-7" data-name="ellipse-9" className="clsrlf-1">
                              <circle className="clsrlf-29" cx="23.989" cy="23.989" r="23.989"></circle>
                              <circle className="clsrlf-30" cx="23.989" cy="23.989" r="22.989"></circle>
                            </g>
                            <g id="ellipse-9-8" data-name="ellipse-9" className="clsrlf-26" transform="translate(43.745 43.745)">
                              <circle className="clsrlf-29" cx="5.645" cy="5.645" r="5.645"></circle>
                              <circle className="clsrlf-30" cx="5.645" cy="5.645" r="4.645"></circle>
                            </g>
                          </g>
                          <g id="group-202" transform="translate(22.193 24.649)">
                            <path id="path-133" data-name="path" className="clsrlf-5" d="M20.673,12.837l-.1.243a9.308,9.308,0,0,1-8.549,5.178,12.131,12.131,0,0,1-4.9-.973,12.773,12.773,0,0,1-5.908-5.56C-.491,8.458-.769,3.906,2.741.5c.07,0-.417.73-.382.73l-.1.278C-.283,9.431,7.536,16.243,15.113,15.444a9.84,9.84,0,0,0,5.56-2.606" transform="translate(0.008 2.732)"></path>
                            <path id="path-134" data-name="path" className="clsrlf-5" d="M27.759,5.395a7.285,7.285,0,0,0,5.074-2.12c-1.008,2.606-4,4.275-7.993,3.753A8.685,8.685,0,0,1,17.924-.2c-.243-2.467.626-3.927,2.016-5.7a6.867,6.867,0,0,0-.73,2.12c-.487,5.317,4.309,9.14,8.549,9.175" transform="translate(-11.665 6.908)"></path>
                            <path id="path-135" data-name="path" className="clsrlf-5" d="M42.9-3.135a5.9,5.9,0,0,1-2.745,2.12,5.256,5.256,0,0,1-5.908-2.4c-.139-.243-.417-.9-.487-1.077A4.4,4.4,0,0,1,34.732-8.8a5.248,5.248,0,0,0-.209,2.884,6.362,6.362,0,0,0,.8,1.738,4.462,4.462,0,0,0,1.772,1.564A3.785,3.785,0,0,0,38-2.2c.765.243,2.328.8,4.9-.938" transform="translate(-21.905 8.8)"></path>
                          </g>
                        </g>
                        <g id="group-252" transform="translate(1097.085 255.577)">
                          <g id="group-46-6" data-name="group-46" transform="translate(0 17.007) rotate(-18)">
                            <g id="ellipse-9-9" data-name="ellipse-9" className="clsrlf-1" transform="translate(0 0)">
                              <circle className="clsrlf-29" cx="23.989" cy="23.989" r="23.989"></circle>
                              <circle className="clsrlf-30" cx="23.989" cy="23.989" r="22.989"></circle>
                            </g>
                            <g id="ellipse-9-10" data-name="ellipse-9" className="clsrlf-26" transform="translate(43.745 43.745)">
                              <circle className="clsrlf-29" cx="5.645" cy="5.645" r="5.645"></circle>
                              <circle className="clsrlf-30" cx="5.645" cy="5.645" r="4.645"></circle>
                            </g>
                          </g>
                          <g id="group-210" transform="translate(19.151 21.511)">
                            <path id="path-136" data-name="path" className="clsrlf-5" d="M10.938,21.347a10.8,10.8,0,0,1-7.851-3A10.194,10.194,0,0,1,0,10.732,10.355,10.355,0,0,1,3.146,3.058,10.673,10.673,0,0,1,10.85,0a10.9,10.9,0,0,1,8.5,3.881L16.5,7.145a6.682,6.682,0,0,0-5.44-2.705,6.268,6.268,0,0,0-4.44,1.705,5.917,5.917,0,0,0-1.852,4.587A6.232,6.232,0,0,0,6.5,15.349a5.7,5.7,0,0,0,4.234,1.735,6.8,6.8,0,0,0,5.557-2.676l2.94,3.029A10.808,10.808,0,0,1,10.938,21.347Z" transform="translate(0)"></path>
                            <path id="path-137" data-name="path" className="clsrlf-5" d="M69.21,30.482H67.816l-.279,1.784h1.617l-.39,1.9H67.2l-.335,2.119H64.749l.39-2.119H63.857l-.335,2.119H61.4l.335-2.119H60.4l.39-1.9h1.283l.335-1.784H60.9l.39-1.9h1.394l.39-2.286H65.2l-.39,2.286h1.227l.39-2.286h2.119l-.39,2.286H69.6Z" transform="translate(-40.524 -20.684)"></path>
                            <path id="path-124-2" data-name="path-124" className="clsrlf-8" d="M178.222-221.257l.551-2.515h1.619l-.54,2.515Z" transform="translate(-154.782 232.862)"></path>
                          </g>
                        </g>
    
                      </g>
                    </svg>
    )
}
export default RightImage;