import React,{useState,useEffect} from 'react'
import Full_map from '../assets/images/full-map'
import KeepReading from '../assets/images/keep-reading'
import Section2GrayMapSvg from '../assets/images/home-section2-map'

const HomeSection2 = ({fullpageApi}) =>{
    const [year,setYear] = useState(new Date().getFullYear() - 1993)
    useEffect(()=>{

    },[year])
    return (      
        <div 
        className={`section section1`} 
        id={`section1`}>
           
                <div className="section1-1">
                <div className="map-icons grey-map">
                    <Section2GrayMapSvg/>
                    </div>
                </div>

                <div className="left-col-box">
                <div className="mid-text-section mid-section4 ">
                        <p className="pgh-2 map-slide-3 en-text">So this was</p> 
                         <h2 className="year-2 map-slide-4 en-text">{year}</h2> 
                        <p className="belgium map-slide-5 en-text">Years ago!</p>
                        
                    </div>
                    </div>
                <div className="full-map">
                   <Full_map/>
                </div>
                <a to="#" className="get-start-btn-2 en-text" onClick={() => fullpageApi.moveSectionDown()}>
                 {/* <KeepReading/> */}
                    <span>Keep reading</span>
                </a>
            </div>
     
    )
}

export default HomeSection2;