import React from 'react'
import './style.scss'
const LeftImageSection6 =()=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" viewBox="226 10150 655 655">
                    <defs>
                      <clipPath id="story-clip-path">
                        <path id="path" className="story1" d="M-208.919,599.108l-.1-.356L-212.8,588.38c7.809,4.6,13.08,11.973,16.926,20.311l20.46-10.254a109.119,109.119,0,0,0-49.119-45.956c-6.911,7.765-15.618,12.368-24.891,12.368-9.039,0-17.375-4.307-24.169-11.578-.254.178-.488.375-.781.553l-1.952,1.107h0c-16.438,8.97-32.661,22.326-42.95,39.239l17.687,17.545A41.858,41.858,0,0,1-285.857,593.4c-9.019,30.308-8.707,62.256-9.019,85.807,0,.139.449,9.128,1.425,19.284h99.565l-1.093-16.221C-196.874,662.589-200.7,628.409-208.919,599.108Z" transform="translate(319.28 -552.48)"></path>
                      </clipPath>
                      <clipPath id="clip-path-happy-man">
                        <path id="path" className="happy_man1" d="M103.387,46.849l-.176-.354-1.582-9.37c7.033,4.066,27.605-6.364,33.056-8.132L125.893,0C108.31.884,104.969,13.082,87.738,5.127c-6.154,6.895-14.066,11.138-22.33,11.138-8.088,0-15.649-3.889-21.8-10.431-.176.177-.527.354-.7.53-.527.354-1.231.707-1.758,1.061h0c-15.121,7.779-24.968,0-35.869-2.3L0,34.474C8.967,39.6,23.737,45.965,30.418,41.722c-8.088,27.049-6.154,55.689-6.33,76.727,0,.177.352,8.132,1.231,17.325h89.672l-1.055-14.5C112.53,103.6,110.772,73.014,103.387,46.849Z" transform="translate(0)"></path>
                      </clipPath>
                    </defs>
                    <g id="group-235" transform="translate(0 9972)">
                      <path className="pager_roaled" d="M16.345,7.435,2,33.051l7.172,5.123,12.3,12.3h12.3l6.66,9.222,16.394-6.762L60.4,33.563V15.12L37.349,1.8,26.079,10.663l-8.2,17.367L36.837,39.2l10.246-8.453,6.66-4.867L43.5,10.663l-12.3,9.58,7.172,4.611" transform="translate(227 338.2)"></path>
                      <path className="pager_roaled" d="M16.345,7.435,2,33.051l7.172,5.123,12.3,12.3h12.3l6.66,9.222,16.394-6.762L60.4,33.563V15.12L37.349,1.8,26.079,10.663l-8.2,17.367L36.837,39.2l10.246-8.453,6.66-4.867L43.5,10.663l-12.3,9.58,7.172,4.611" transform="translate(460.396 222.533) rotate(112)"></path>
                      <path className="pager_roaled" d="M16.345,7.435,2,33.051l7.172,5.123,12.3,12.3h12.3l6.66,9.222,16.394-6.762L60.4,33.563V15.12L37.349,1.8,26.079,10.663l-8.2,17.367L36.837,39.2l10.246-8.453,6.66-4.867L43.5,10.663l-12.3,9.58,7.172,4.611" transform="matrix(0.438, 0.899, -0.899, 0.438, 774.157, 499.424)"></path>
                      <path className="pager_roaled" d="M16.345,7.435,2,33.051l7.172,5.123,12.3,12.3h12.3l6.66,9.222,16.394-6.762L60.4,33.563V15.12L37.349,1.8,26.079,10.663l-8.2,17.367L36.837,39.2l10.246-8.453,6.66-4.867L43.5,10.663l-12.3,9.58,7.172,4.611" transform="translate(249.866 612.495) rotate(-87)"></path>

                     
                      <g id="clock" transform="translate(746.089 313.632)">
                        <ellipse id="ellipse-13" className="story5" cx="65.275" cy="65.275" rx="65.275" ry="65.275" transform="translate(0)"></ellipse>
                        <g className="clock_inner story6" transform="translate(9.698 7.46)">
                          <ellipse className="story16" cx="55.577" cy="55.577" rx="55.577" ry="55.577"></ellipse>
                          <ellipse className="story17" cx="55.577" cy="55.577" rx="54.077" ry="54.077"></ellipse>
                        </g>
                        <g id="group-76" transform="translate(61.545 32.451)">
                          <line className="story7 clock_mins" y2="35.432" transform="translate(1.728)"></line>
                          <line className="story7 clock_hours" x1="32.84"></line>
                        </g>
                      </g>
                    
                      <g id="happy_man">
                        <g className="smile-src" transform="translate(358 317)">
                          <path id="path-53" className="happy_man2" d="M158.686,33.166c1.91-12.5.521-6.425,1.91-12.5,1.91-9.2-3.126-18.059-11.287-19.969-8.161-1.736-16.5,4.167-18.406,13.2-1.215,5.9,9.029,11.634,12.5,15.454,3.126,20.316,3.647,51.051-1.042,55.566-22.574,21.879-21.358,10.94-35.25,10.245a8.277,8.277,0,0,1-1.389-.695h0c-.521-.347-.868-.521-1.389-.868h0l-1.042-.521c-5.209-3.126-2.257-6.946-.347-9.029a9.928,9.928,0,0,0,1.389-1.216h0c8.856-7.814,14.933-19.969,15.8-33.86a14.938,14.938,0,0,0,3.473-4.515c2.778-5.383,2.257-10.94-1.215-12.85a4.937,4.937,0,0,0-4.167-.174C113.365,13.2,98.779,0,81.588,0,64.224,0,49.638,13.371,44.6,31.95a5.973,5.973,0,0,0-4.862-.174c-3.473,1.736-3.994,7.467-1.215,12.85a16.524,16.524,0,0,0,4.341,5.209c1.042,14.239,7.467,26.741,17.017,34.208h0l.347.347c1.389,1.216,6.078,5.557-.347,9.377-.521.347-1.216.695-1.736,1.042h0l-.695,5.383c-13.023,1.042-12.329,11.113-34.381-10.245C18.382,85.259,18.9,54.7,22.028,34.381c3.473-3.82,13.891-9.55,12.5-15.454C32.447,9.9,24.286,3.994,16.124,5.9,7.963,7.64,3.1,16.5,5.011,25.526c1.389,6.078,0,0,1.91,12.5C3.449,60.775-.719,73.451.149,86.822c1.389,22.053,13.544,33.34,30.561,35.944a161.215,161.215,0,0,0,16.67,1.563l.521,12.155,8.682-26.915-13.2,111.479,5.383-.174h54l28.3-1.563-1.389-19.1c-1.736-18.406-5.383-45.842-11.981-68.763h0l.521-12.155a121.638,121.638,0,0,0,16.67-1.563c17.017-2.6,29.346-14.065,30.561-35.944C166.326,68.415,162.158,55.913,158.686,33.166Z" transform="translate(-0.033 17.714)"></path>
                          <g id="group-28" transform="translate(27.045)">
                            <g id="group-26" transform="translate(0 0)">
                              <g id="group-24" transform="translate(0 235.687)">
                                <path id="path-2" data-name="path" className="happy_man3" d="M-301.48,733.74" transform="translate(325.318 -547.44)"></path>
                                <path id="path-3" data-name="path" className="happy_man4" d="M39.557,303.631H61.886l4.05-141.192h-.329L74.66,304.427l19.94,1.61,12.463-178.7L18.63,126.92Z" transform="translate(-3.273 -126.92)"></path>
                                <path id="path-4" data-name="path" className="happy_man4" d="M-252.053,727.74H-273.69l-1.541,26.328s54.853,7.183,48.674-1.593S-252.053,727.74-252.053,727.74Z" transform="translate(344.505 -551.825)"></path>
                                <path id="path-5" data-name="path" className="happy_man4" d="M-258.88,732.81s53.763,20.858,6.439,20.3" transform="translate(356.456 -548.119)"></path>
                                <rect id="rectangle" className="happy_man3" width="3.254" height="14.955" rx="0.94" transform="translate(95.495 177.061) rotate(60)"></rect>
                                <rect id="rectangle-2" data-name="rectangle" className="happy_man3" width="3.254" height="14.955" rx="0.94" transform="translate(100.724 180.549) rotate(60)"></rect>
                                <rect id="rectangle-3" data-name="rectangle" className="happy_man3" width="3.254" height="14.955" rx="0.94" transform="translate(105.646 183.967) rotate(60)"></rect>
                                <path id="path-6" data-name="path" className="happy_man4" d="M-282.96,728.2s-19.94,15.942-26.327,24.735,50.2,1.592,50.2,1.592l-1.592-26.327Z" transform="translate(319.244 -551.489)"></path>
                                <path id="path-7" data-name="path" className="happy_man4" d="M-295.775,754.743c-47.307.554,6.439-20.3,6.439-20.3l3.2-2.769c1.419,6.041,12.272,7.806,4.777,7.737" transform="translate(315.252 -548.953)"></path>
                                <rect id="rectangle-4" data-name="rectangle" className="happy_man3" width="3.254" height="14.955" rx="0.94" transform="translate(20.577 187.593) rotate(-60)"></rect>
                                <rect id="rectangle-5" data-name="rectangle" className="happy_man3" width="3.254" height="14.955" rx="0.94" transform="translate(25.806 184.087) rotate(-60)"></rect>
                                <rect id="rectangle-6" data-name="rectangle" className="happy_man3" width="3.254" height="14.955" rx="0.94" transform="translate(30.72 180.685) rotate(-60)"></rect>
                              </g>
                              <path id="path-17" className="happy_man4" d="M-304.369,537.8s-5.695-16.436,1.978-28.8c0,0,4.262,7.246,10.23,0s11.509-31.969,52-13.214c0,0-8.1,9.378-3.41,13.214,0,0,19.574,11.424,11.713,28.525,0,0-14.851-32.173-33.742-24.16S-276.936,502.232-304.369,537.8Z" transform="translate(321.679 -488.902)"></path>
                            </g>
                          </g>
                          <g id="group-73" transform="translate(17.409 103.761)">
                            <path id="path-8" data-name="path" className="happy_man5" d="M105.27,47.465l-.178-.357-1.6-9.448c7.131,4.1,26.438-7.192,31.964-8.975L126.538,1c-17.826.891-19.664,12.417-37.134,4.395-6.239,6.952-14.261,11.231-22.64,11.231-8.2,0-15.866-3.922-22.1-10.518-.178.178-.535.357-.713.535-.535.357-1.248.713-1.783,1.07h0C26.834,15.556,19.95,7.712,8.9,5.395L2,34.987c9.091,5.17,22.516,11.587,29.29,7.309-8.2,27.274-6.239,56.153-6.418,77.367,0,.178.357,8.2,1.248,17.47h90.915l-1.07-14.618C114.54,104.688,112.757,73.848,105.27,47.465Z" transform="translate(-2 -1)"></path>
                          </g>
                          <g id="group-mask-6" className="happy_man6" transform="translate(16.634 102.986)">
                            <g id="group-75" transform="translate(-2.596 -5.443)">
                              <rect id="rectangle-21" className="happy_man4" width="139.582" height="62.388"></rect>
                              <rect id="rectangle-21-2" data-name="rectangle-21" className="happy_man7" width="139.582" height="62.388" transform="translate(0 103.867)"></rect>
                            </g>
                          </g>
                        </g>
                            <g className="smile" transform="translate(-1062 -50)">
                            <g id="Ellipse_5" data-name="Ellipse 5" className="big-smile-1" transform="translate(1478 420)">
                              <circle className="big-smile-3" cx="5.585" cy="5.585" r="5.585"></circle>
                              <circle className="big-smile-4" cx="5.585" cy="5.585" r="5.085"></circle>
                            </g>
                            <g id="Ellipse_6" data-name="Ellipse 6" className="big-smile-1" transform="translate(1512.908 420)">
                              <circle className="big-smile-3" cx="5.585" cy="5.585" r="5.585"></circle>
                              <circle className="big-smile-4" cx="5.585" cy="5.585" r="5.085"></circle>
                            </g>
                            <path id="Path_58" data-name="Path 58" className="big-smile-2" d="M1482,6933.856s17.866,20.962,33.734,0" transform="translate(2.982 -6483.337)"></path>
                          </g>                            
                      </g>
                      <g id="story_man" transform="translate(390 317)">
                        <g id="group-28" transform="translate(0 0)">
                          <g id="group-26" transform="translate(0 0)">
                            <g id="group-24" transform="translate(0 18.42)">
                              <path id="story-2" data-name="path" className="story3" d="M-158.406,676.872c-5.356-33.488-34.045-61.48-62.416-73.048-2.19-3.066.319-6.291,2.23-8.083q.836-.657,1.652-1.374h0a55.589,55.589,0,0,0,18.078-38.784,17.676,17.676,0,0,0,3.982-5.216c3.146-6.072,2.509-12.642-1.394-14.653a6.1,6.1,0,0,0-4.719-.219c-5.833-21.064-22.558-36.295-42.288-36.295s-36.653,15.39-42.387,36.613a6.433,6.433,0,0,0-5.595-.1c-3.982,1.991-4.539,8.581-1.394,14.653a17.063,17.063,0,0,0,5.037,5.973c1.175,16.366,8.66,30.581,19.551,39.261h0l.418.318c1.692,1.354,6.968,6.292-.339,10.831l-1.991,1.115h0c-27.4,14.793-54.253,41.352-55.01,76.233-.6,26.539,4.938,77.05,18.516,98.612h0l.5.816a111.953,111.953,0,0,1,3.7,11.209h0a23.729,23.729,0,0,0,4.878,8.82c7.327,8.442,18.874,10.432,25.783,4.42s6.55-17.7-.777-26.141a21.66,21.66,0,0,0-16.784-7.964c-9.5-12.324-13.618-53.756-13.24-70.938.438-19.73,2.449-43.8,22.677-56.463-9.178,30.481-8.86,62.6-9.178,86.268,0,.2.956,18.974,3.106,33.05a13.227,13.227,0,0,0,1.573.776l3.185-14.374h78.025a39.771,39.771,0,0,1-3.385,2.927l8.282,17.4c.677-.318,5.4-15.191,11.946-20.347l-1.115-16.386c-1.672-19.909-5.555-54.174-13.937-83.62l-.1-.359-3.842-10.432c19.093,11.209,23.891,38.664,26.938,57.16,2.529,15.788,3.982,51.306-1.991,66.936a21.622,21.622,0,0,0-16.724,8.123c-7.207,8.561-7.366,20.268-.378,26.161s18.516,3.743,25.7-4.818a23.554,23.554,0,0,0,4.938-9.656h0a20.563,20.563,0,0,0,.5-3.166,60.517,60.517,0,0,1,2.887-10.871v-.12C-152.831,749.084-154.225,702.993-158.406,676.872Z" transform="translate(325.032 -499.2)"></path>
                              <path id="story-3" data-name="path" className="story9" d="M-301.48,733.74" transform="translate(348.371 -266.783)"></path>
                              <path id="story-4" data-name="path" className="story10" d="M-206.732,599.466l-.1-.358-3.862-10.452c7.964,4.639,13.339,12.065,17.261,20.467l20.865-10.333a110.756,110.756,0,0,0-50.092-46.31c-7.048,7.824-15.928,12.463-25.385,12.463-9.218,0-17.719-4.34-24.648-11.667-.259.179-.5.378-.8.557l-1.991,1.115h0c-16.764,9.039-33.309,22.5-43.8,39.54l18.038,17.68a42.328,42.328,0,0,1,16.047-18.456c-9.2,30.541-8.88,62.735-9.2,86.467,0,.139.458,9.2,1.453,19.432H-191.4l-1.115-16.346C-194.448,663.436-198.35,628.992-206.732,599.466Z" transform="translate(330.732 -446.402)"></path>
                              <path id="story-5" data-name="path" className="story5" d="M42.7,330.176H68.384l4.659-162.4h-.378L83.077,331.092l22.936,1.852L120.348,127.4,18.63,126.92Z" transform="translate(18.505 125.751)"></path>
                              <path id="story-6" data-name="path" className="story5" d="M-248.571,727.74h-24.887l-1.772,30.282s63.093,8.263,55.986-1.832S-248.571,727.74-248.571,727.74Z" transform="translate(374.383 -272.729)"></path>
                              <path id="story-7" data-name="path" className="story5" d="M-258.88,732.81s61.839,23.991,7.406,23.353" transform="translate(390.585 -267.705)"></path>
                              <rect id="rectangle" className="story9" width="3.743" height="17.202" rx="0.94" transform="translate(129.311 456.329) rotate(60)"></rect>
                              <rect id="rectangle-2" data-name="rectangle" className="story9" width="3.743" height="17.202" rx="0.94" transform="translate(135.325 460.342) rotate(60)"></rect>
                              <rect id="rectangle-3" data-name="rectangle" className="story9" width="3.743" height="17.202" rx="0.94" transform="translate(140.988 464.273) rotate(60)"></rect>
                              <path id="story-8" data-name="path" className="story5" d="M-278.93,728.2s-22.936,18.337-30.282,28.451,57.737,1.832,57.737,1.832l-1.832-30.282Z" transform="translate(340.135 -272.273)"></path>
                              <path id="story-9" data-name="path" className="story5" d="M-292.85,758.209c-54.413.637,7.406-23.354,7.406-23.354l3.683-3.185c1.633,6.948,14.116,8.979,5.495,8.9" transform="translate(334.723 -268.834)"></path>
                              <rect id="rectangle-4" data-name="rectangle" className="story9" width="3.743" height="17.202" rx="0.94" transform="translate(43.139 468.444) rotate(-60)"></rect>
                              <rect id="rectangle-5" data-name="rectangle" className="story9" width="3.743" height="17.202" rx="0.94" transform="translate(49.153 464.412) rotate(-60)"></rect>
                              <rect id="rectangle-6" data-name="rectangle" className="story9" width="3.743" height="17.202" rx="0.94" transform="translate(54.806 460.498) rotate(-60)"></rect>
                            </g>
                            <path id="story-17" className="story5" d="M-304.064,545.143s-6.55-18.905,2.275-33.124c0,0,4.9,8.335,11.767,0s13.238-36.771,59.814-15.2c0,0-9.315,10.786-3.922,15.2,0,0,22.514,13.14,13.473,32.81,0,0-17.081-37.006-38.811-27.789S-272.509,504.234-304.064,545.143Z" transform="translate(343.445 -488.902)"></path>
                          </g>
                        </g>
                        <g id="group-mask-4" className="story11" transform="translate(12.878 125.029)">
                          <rect id="rectangle-21" className="story5" width="161.479" height="70.92" transform="translate(-16.49 -12.983)"></rect>
                          <rect id="rectangle-21-2" data-name="rectangle-21" className="story12" width="161.479" height="70.92" transform="translate(-16.49 104.189)"></rect>
                        </g>
                      </g>
                      <g id="story_code_paper">
                        <path className="story3 story_pages" d="M50.535,69.34H0V0L36.2.421,50.535,15.57V69.34Z" transform="translate(607.188 465.606)"></path>
                        <g id="group-157" transform="translate(612 492)">
                            <path id="story-10" data-name="path" d="M4.454,14.224,2.9,12.671C5.619,8.009,7.562,5.937,12.223,2.7c.259.906.518,1.295.777,2.2a24.291,24.291,0,0,0-6.215,5.827A18.712,18.712,0,0,1,14.554,9.3c.259.906.518,1.295.777,2.2C11.187,11.376,8.856,11.894,4.454,14.224Z" transform="translate(-2.9 0.537)"></path>
                            <path id="story-11" data-name="path" d="M23.305.2c1.683.259,2.46.388,4.144.777C21.233,5.25,19.032,8.358,16.831,14.314c-.906.129-1.424.259-2.331.388C15.924,8.487,17.737,5.25,23.305.2Z" transform="translate(0.521 -0.2)"></path>
                            <path id="story-12" data-name="path" d="M36.331,12.671l-1.554,1.554c-4.4-2.331-6.733-2.849-10.877-2.719.259-.906.518-1.295.777-2.2a19.083,19.083,0,0,1,7.769,1.424A22.348,22.348,0,0,0,26.231,4.9c.259-.906.518-1.295.777-2.2C31.669,5.937,33.612,8.009,36.331,12.671Z" transform="translate(3.293 0.537)"></path>
                        </g>
                      </g>
                      <g className="story_arrows">
                        <g transform="translate(203 -113)">
                          <path className="story12 story_arrow_top" d="M111.109,10537.848H132.23l-10.664,17.535Z" transform="translate(4.734 -9972)"></path>
                          <line className="story13 story_arrow_line" y1="36" transform="translate(126.5 530.5)"></line>
                        </g>
                      </g>
                      <g className="story_arrows">
                        <g transform="translate(133 232)">
                          <path className="story12 story_arrow_top" d="M111.109,10537.848H132.23l-10.664,17.535Z" transform="translate(4.734 -9972)"></path>
                          <line className="story13 story_arrow_line" y1="36" transform="translate(126.5 530.5)"></line>
                        </g>
                      </g>
                      <g className="story_arrows">
                        <g transform="translate(591 125)">
                          <path className="story12 story_arrow_top" d="M111.109,10537.848H132.23l-10.664,17.535Z" transform="translate(4.734 -9972)"></path>
                          <line className="story13 story_arrow_line" y1="36" transform="translate(126.5 530.5)"></line>
                        </g>
                      </g>
                      <g className="story_arrows">
                        <g transform="translate(553 -148)">
                          <path className="story12 story_arrow_top" d="M111.109,10537.848H132.23l-10.664,17.535Z" transform="translate(4.734 -9972)"></path>
                          <line className="story13 story_arrow_line" y1="36" transform="translate(126.5 530.5)"></line>
                        </g>
                      </g>
                      <g id="story_page_js">
                        <path className="story3 story_pages" d="M55.582,76.266H0V0L39.816.463,55.582,17.126v59.14Z" transform="translate(314.813 682)"></path>
                        <g transform="translate(330.049 712.254)">
                          <path className="story5" d="M0,18.787l3.535-2.146c.685,1.208,1.3,2.237,2.8,2.237,1.425,0,2.327-.559,2.327-2.723V1.4h4.329V16.208c0,4.491-2.633,6.547-6.475,6.547A6.742,6.742,0,0,1,0,18.787" transform="matrix(0.695, -0.719, 0.719, 0.695, -14.706, 7.211)"></path>
                          <path className="story5" d="M85.1,17.171l3.535-2.038a4.744,4.744,0,0,0,4.275,2.633c1.8,0,2.94-.9,2.94-2.146,0-1.479-1.172-2.02-3.156-2.886l-1.082-.469C88.473,10.93,86.4,9.253,86.4,5.718,86.4,2.471,88.87,0,92.747,0a6.407,6.407,0,0,1,6.168,3.463L95.543,5.627a2.949,2.949,0,0,0-2.8-1.858,1.893,1.893,0,0,0-2.074,1.858c0,1.3.812,1.822,2.669,2.633l1.082.469c3.679,1.587,5.772,3.192,5.772,6.818,0,3.914-3.066,6.042-7.2,6.042-4.04.018-6.637-1.912-7.9-4.419" transform="matrix(0.53, 0.848, -0.848, 0.53, -17.044, -73.496)"></path>
                        </g>
                      </g>
                      <g id="paper_js_double">
                        <path id="path-55-5" data-name="path-55" className="happy_man2" d="M55.582,76.266H0V0L39.816.463,55.582,17.126v59.14Z" transform="translate(301.813 692)"></path>
                        <path id="path-55-6" data-name="path-55" className="happy_man2" d="M55.582,76.266H0V0L39.816.463,55.582,17.126v59.14Z" transform="translate(314.813 682)"></path>
                        <path id="path-95-5" data-name="path-95" className="happy_man2" d="M12.569,0A10.082,10.082,0,0,1,5.942,2.466,10.189,10.189,0,0,1,0,.493,11.216,11.216,0,0,1,1.6,6.657,14.325,14.325,0,0,1,.457,12.081a10.423,10.423,0,0,1,5.485-1.726,10.9,10.9,0,0,1,6.17,1.972,10.836,10.836,0,0,1-1.371-5.671A13.116,13.116,0,0,1,12.569,0Z" transform="matrix(0.966, -0.259, 0.259, 0.966, 360.784, 753.947)"></path>
                        <path id="path-95-6" data-name="path-95" className="happy_man2" d="M20.168,0A16.177,16.177,0,0,1,9.534,3.956,16.349,16.349,0,0,1,0,.791a18,18,0,0,1,2.567,9.89,22.985,22.985,0,0,1-1.833,8.7,16.723,16.723,0,0,1,8.8-2.769,17.483,17.483,0,0,1,9.9,3.165,17.386,17.386,0,0,1-2.2-9.1A21.046,21.046,0,0,1,20.168,0Z" transform="matrix(0.966, -0.259, 0.259, 0.966, 304.784, 676.947)"></path>
                        <g id="group-196" transform="translate(327.049 709.254)">
                          <path id="path-9" data-name="path" className="happy_man4" d="M0,18.787l3.535-2.146c.685,1.208,1.3,2.237,2.8,2.237,1.425,0,2.327-.559,2.327-2.723V1.4h4.329V16.208c0,4.491-2.633,6.547-6.475,6.547A6.742,6.742,0,0,1,0,18.787" transform="translate(0 -1.147)"></path>
                          <path id="path-10-2" data-name="path" className="happy_man4" d="M85.1,17.171l3.535-2.038a4.744,4.744,0,0,0,4.275,2.633c1.8,0,2.94-.9,2.94-2.146,0-1.479-1.172-2.02-3.156-2.886l-1.082-.469C88.473,10.93,86.4,9.253,86.4,5.718,86.4,2.471,88.87,0,92.747,0a6.407,6.407,0,0,1,6.168,3.463L95.543,5.627a2.949,2.949,0,0,0-2.8-1.858,1.893,1.893,0,0,0-2.074,1.858c0,1.3.812,1.822,2.669,2.633l1.082.469c3.679,1.587,5.772,3.192,5.772,6.818,0,3.914-3.066,6.042-7.2,6.042-4.04.018-6.637-1.912-7.9-4.419" transform="translate(-69.751)"></path>
                        </g>
                      </g>
                      <g id="angular_js_page">
                        <path id="path-55-12" data-name="path-55" className="happy_man2" d="M58.708,80.555H0V0L42.055.489l16.654,17.6V80.555Z" transform="translate(732.316 499.945)"></path>
                        <path id="path-95-10" data-name="path-95" className="happy_man2" d="M12.569,0A10.082,10.082,0,0,1,5.942,2.466,10.189,10.189,0,0,1,0,.493,11.216,11.216,0,0,1,1.6,6.657,14.325,14.325,0,0,1,.457,12.081a10.423,10.423,0,0,1,5.485-1.726,10.9,10.9,0,0,1,6.17,1.972,10.836,10.836,0,0,1-1.371-5.671A13.116,13.116,0,0,1,12.569,0Z" transform="matrix(0.966, -0.259, 0.259, 0.966, 779.784, 506.947)"></path>
                        <g id="group-120" transform="translate(745.5 524.262)">
                          <path id="path-11" data-name="path" className="happy_man4" d="M70.43,58.644h3.492L70.45,51.4,67.4,58.644Z" transform="translate(-53.874 -41.085)"></path>
                          <path id="path-12" data-name="path" className="happy_man4" d="M16.6,0,0,5.84,2.629,27.573l13.987,7.686,14.068-7.786L33.313,5.739ZM23.62,25.827,21.372,20.61H12.181l-2.067,5.137-3.833.08L16.556,2.99h0l10.736,22.9Z"></path>
                        </g>
                      </g>
                      <g id="code_page_double">
                        <path id="path-55" className="happy_man2" d="M47.9,65.72H0V0L34.311.4,47.9,14.758V65.72Z" transform="translate(599.101 481.799)"></path>
                        <path id="path-55-2" data-name="path-55" className="happy_man2" d="M47.9,65.72H0V0L34.311.4,47.9,14.758V65.72Z" transform="translate(608.956 466.47)"></path>
                        <text id="_" data-name="</>" className="happy_man10" transform="translate(614 508)"><tspan x="0" y="0">&lt;/&gt;</tspan></text>
                        <path id="path-95" className="happy_man2" d="M20.168,0A16.177,16.177,0,0,1,9.534,3.956,16.349,16.349,0,0,1,0,.791a18,18,0,0,1,2.567,9.89,22.985,22.985,0,0,1-1.833,8.7,16.723,16.723,0,0,1,8.8-2.769,17.483,17.483,0,0,1,9.9,3.165,17.386,17.386,0,0,1-2.2-9.1A21.046,21.046,0,0,1,20.168,0Z" transform="matrix(0.966, -0.259, 0.259, 0.966, 596.784, 459.947)"></path>
                        <path id="path-95-3" data-name="path-95" className="happy_man2" d="M12.569,0A10.082,10.082,0,0,1,5.942,2.466,10.189,10.189,0,0,1,0,.493,11.216,11.216,0,0,1,1.6,6.657,14.325,14.325,0,0,1,.457,12.081a10.423,10.423,0,0,1,5.485-1.726,10.9,10.9,0,0,1,6.17,1.972,10.836,10.836,0,0,1-1.371-5.671A13.116,13.116,0,0,1,12.569,0Z" transform="matrix(0.966, -0.259, 0.259, 0.966, 649.784, 524.947)"></path>
                      </g>
                      <g id="story_page_bulb">
                        <path className="story3 story_pages" d="M60.053,82.4H0V0L43.018.5l17.035,18V82.4Z" transform="translate(619.48 245.502)"></path>
                        <g transform="translate(1604.454 440.107) rotate(180)">
                          <path className="story14" d="M0,29.811a16.681,16.681,0,0,1,.922-5.486l-.037-.018.2-.437a16.635,16.635,0,0,1,.985-2.124L10.089,4.453C11.153,1.794,13.165,0,15.473,0h1.856c2.513,0,4.676,2.13,5.645,5.187l9.1,17.938-.041,0A16.712,16.712,0,1,1,0,29.811Z" transform="translate(939 133.099)"></path>
                          <rect className="story15" width="11.14" height="12.996" rx="5.57" transform="translate(950.14 125.158)"></rect>
                          <rect className="story15" width="3.713" height="8.966" rx="1.857" transform="translate(953.853 123)"></rect>
                        </g>
                      </g>
                      <g id="bulb_page_double">
                        <path id="path-55-3" data-name="path-55" className="happy_man2" d="M60.053,82.4H0V0L43.018.5l17.035,18V82.4Z" transform="translate(608.479 255.502)"></path>
                        <path id="path-55-4" data-name="path-55" className="happy_man2" d="M60.053,82.4H0V0L43.018.5l17.035,18V82.4Z" transform="translate(619.479 245.502)"></path>
                        <g id="group-17" transform="translate(1604.454 440.107) rotate(180)">
                          <path id="path-10" className="happy_man5" d="M0,29.811a16.681,16.681,0,0,1,.922-5.486l-.037-.018.2-.437a16.635,16.635,0,0,1,.985-2.124L10.089,4.453C11.153,1.794,13.165,0,15.473,0h1.856c2.513,0,4.676,2.13,5.645,5.187l9.1,17.938-.041,0A16.712,16.712,0,1,1,0,29.811Z" transform="translate(939 133.099)"></path>
                          <rect id="rectangle-6-2" data-name="rectangle-6" className="happy_man11" width="11.14" height="12.996" rx="5.57" transform="translate(950.14 125.158)"></rect>
                          <rect id="rectangle-7" className="happy_man11" width="3.713" height="8.966" rx="1.857" transform="translate(953.853 123)"></rect>
                        </g>
                        <path id="path-95-2" data-name="path-95" className="happy_man2" d="M20.168,0A16.177,16.177,0,0,1,9.534,3.956,16.349,16.349,0,0,1,0,.791a18,18,0,0,1,2.567,9.89,22.985,22.985,0,0,1-1.833,8.7,16.723,16.723,0,0,1,8.8-2.769,17.483,17.483,0,0,1,9.9,3.165,17.386,17.386,0,0,1-2.2-9.1A21.046,21.046,0,0,1,20.168,0Z" transform="matrix(0.966, -0.259, 0.259, 0.966, 609.784, 233.947)"></path>
                        <path id="path-95-4" data-name="path-95" className="happy_man2" d="M12.569,0A10.082,10.082,0,0,1,5.942,2.466,10.189,10.189,0,0,1,0,.493,11.216,11.216,0,0,1,1.6,6.657,14.325,14.325,0,0,1,.457,12.081a10.423,10.423,0,0,1,5.485-1.726,10.9,10.9,0,0,1,6.17,1.972,10.836,10.836,0,0,1-1.371-5.671A13.116,13.116,0,0,1,12.569,0Z" transform="matrix(0.966, -0.259, 0.259, 0.966, 669.784, 320.947)"></path>
                      </g>
                      <g id="king_page_double">
                        <path id="path-55-10" data-name="path-55" className="happy_man2" d="M58.708,80.555H0V0L42.055.489l16.654,17.6V80.555Z" transform="translate(377.316 209.945)"></path>
                        <path id="path-55-11" data-name="path-55" className="happy_man2" d="M58.708,80.555H0V0L42.055.489l16.654,17.6V80.555Z" transform="translate(391.316 197.945)"></path>
                        <g id="group" transform="translate(403.657 220.077)">
                          <path id="path-13" data-name="path" className="happy_man4" d="M62.4,74.808A19.471,19.471,0,0,1,59.318,71.9l-2.018.356,6.765,14.123s3.679-1.424,3.857-3.56A14.081,14.081,0,0,0,62.4,74.808Z" transform="translate(-40.507 -45.968)"></path>
                          <path id="path-14" data-name="path" className="happy_man4" d="M35,55.141,31.262,49.8,29.6,53.954l4.747,4.095Z" transform="translate(-29.244 -36.982)"></path>
                          <path id="path-15" data-name="path" className="happy_man4" d="M55,61.52l.356,1.84L69.42,60.511,68.055,52.5Z" transform="translate(-39.571 -38.08)"></path>
                          <path id="path-16" data-name="path" className="happy_man4" d="M43.776,53.36l-.237.059-.3.059-.475-2.255,13.174-9.079-.237-.3a15.107,15.107,0,0,0-3.56-2.433l.653.178L63.121,30.93,55.347,28.2,47.989,38.229c-.237,0-.415-.059-.653-.059L53.092,28.2H45.556L42.47,38.941h.119c-.3.119-.593.237-.949.356l.3-.178,1.127-9.257L37.07,32.354l.89,9.376a5.35,5.35,0,0,0-.534.593l.059-.059L35.527,33.9l-4.095,4.095,3.857,7.536a11.492,11.492,0,0,0-1.127,4.51l-.059-.356-4.747-3.026L29,50.987l5.222,1.78.178,1.068-5.1-1.6,1.187,3.5h4.51c.059.178.178.415.237.593l-3.857.534,1.84,2.67L36.3,58.108a12.188,12.188,0,0,0,2.018,2.255l.059.059-1.9,4.391s2.2-1.78,10.859,0l.712-2.433L46.8,59.947Z" transform="translate(-29 -28.2)"></path>
                        </g>
                        <path id="path-95-7" data-name="path-95" className="happy_man2" d="M20.168,0A16.177,16.177,0,0,1,9.534,3.956,16.349,16.349,0,0,1,0,.791a18,18,0,0,1,2.567,9.89,22.985,22.985,0,0,1-1.833,8.7,16.723,16.723,0,0,1,8.8-2.769,17.483,17.483,0,0,1,9.9,3.165,17.386,17.386,0,0,1-2.2-9.1A21.046,21.046,0,0,1,20.168,0Z" transform="matrix(0.966, -0.259, 0.259, 0.966, 381.784, 260.947)"></path>
                      </g>
                      <g id="magento_page_double">
                        <path id="path-55-7" data-name="path-55" className="happy_man2" d="M58.708,80.555H0V0L42.055.489l16.654,17.6V80.555Z" transform="translate(233.316 327.945)"></path>
                        <g id="group-122" transform="translate(248.113 352)">
                          <path id="path1948_2_" className="happy_man4" d="M15.617,0a20.541,20.541,0,0,0-3.66.315C8.726.886,8.126,2.087,8.126,4.289V7.205h7.663v.972H5.238a4.818,4.818,0,0,0-4.8,3.889,14.1,14.1,0,0,0,0,7.777c.543,2.259,1.859,3.889,4.06,3.889H7.125V20.243a4.9,4.9,0,0,1,4.8-4.775h7.663a3.844,3.844,0,0,0,3.831-3.889V4.289A4.251,4.251,0,0,0,19.591.315,23.05,23.05,0,0,0,15.617,0ZM11.471,2.345A1.44,1.44,0,0,1,12.9,3.8a1.459,1.459,0,0,1-1.43,1.458,1.458,1.458,0,0,1,0-2.916Z"></path>
                          <path id="path1950_2_" className="happy_man4" d="M44.969,28.7v3.4a4.921,4.921,0,0,1-4.8,4.861H32.531A3.919,3.919,0,0,0,28.7,40.852v7.291c0,2.087,1.8,3.288,3.831,3.889a12.939,12.939,0,0,0,7.663,0c1.944-.572,3.831-1.687,3.831-3.889V45.226H36.363v-.972H47.857c2.23,0,3.059-1.544,3.831-3.889a13.066,13.066,0,0,0,0-7.777c-.543-2.23-1.6-3.889-3.831-3.889ZM40.68,47.171a1.44,1.44,0,0,1,1.43,1.458,1.459,1.459,0,0,1-1.43,1.458,1.44,1.44,0,0,1-1.43-1.458A1.422,1.422,0,0,1,40.68,47.171Z" transform="translate(-20.574 -20.494)"></path>
                        </g>
                        <path id="path-95-8" data-name="path-95" className="happy_man2" d="M20.168,0A16.177,16.177,0,0,1,9.534,3.956,16.349,16.349,0,0,1,0,.791a18,18,0,0,1,2.567,9.89,22.985,22.985,0,0,1-1.833,8.7,16.723,16.723,0,0,1,8.8-2.769,17.483,17.483,0,0,1,9.9,3.165,17.386,17.386,0,0,1-2.2-9.1A21.046,21.046,0,0,1,20.168,0Z" transform="matrix(0.966, -0.259, 0.259, 0.966, 278.784, 377.947)"></path>
                      </g>
                      <g id="html_page_double">
                        <path id="path-55-8" data-name="path-55" className="happy_man2" d="M58.708,80.555H0V0L42.055.489l16.654,17.6V80.555Z" transform="translate(264.316 551.945)"></path>
                        <path id="path-55-9" data-name="path-55" className="happy_man2" d="M58.708,80.555H0V0L42.055.489l16.654,17.6V80.555Z" transform="translate(252.316 539.945)"></path>
                        <path id="path-114" className="happy_man4" d="M1.872,14.676c.455-2.024.81-3.8,1.215-5.617H24.544c.253-1.164.455-2.227.708-3.492H3.694C4.048,3.644,4.352,1.872,4.706,0H31.628c-1.518,8-3.036,15.941-4.605,24.291-.708,1.366-2.834.86-4.1,1.923-2.986.506-5.567,2.024-8.4,2.885-.607.2-1.164.354-1.923.557C8.5,28.086,4.251,26.416,0,24.746c.4-2.075.759-3.947,1.164-6.073,0,0,.253-.253.557-.506H6.477v2.682c2.429.962,4.757,1.872,7.085,2.783,2.682-.911,5.415-1.822,8.249-2.733,1.265-1.822,1.063-4.048,1.619-6.224Z" transform="translate(266 565.945)"></path>
                        <path id="path-95-9" data-name="path-95" className="happy_man2" d="M20.168,0A16.177,16.177,0,0,1,9.534,3.956,16.349,16.349,0,0,1,0,.791a18,18,0,0,1,2.567,9.89,22.985,22.985,0,0,1-1.833,8.7,16.723,16.723,0,0,1,8.8-2.769,17.483,17.483,0,0,1,9.9,3.165,17.386,17.386,0,0,1-2.2-9.1A21.046,21.046,0,0,1,20.168,0Z" transform="matrix(0.966, -0.259, 0.259, 0.966, 239.784, 594.947)"></path>
                      </g>
                    </g>
                  </svg>
    )
}
export default LeftImageSection6;