import React from 'react'
import './style.scss'
const LeftImage =()=>{
    return(
      <svg xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" viewBox="0 0 975 962.3" xml="preserve">
     
      <g id="group-246">
        <g id="group-95" transform="translate(-30)">
          <path id="path-48" className="answer0" d="M216.2,386.7v119.1l189.7-111V280.5L216.2,386.7z"></path>
          <path id="path-47" className="answer1" d="M184,523.3v430.5h391.5V745.1L184,523.3z"></path>
          <path id="path-16" className="answer2" d="M218.1,386.9l184.4-105.7L218.9,170.8L34.2,280.5L218.1,386.9z"></path>
          <path id="path-17" className="answer3" d="M642.5,307.8V83.9l249,143.3l-0.7,225.2L642.5,307.8z"></path>
          <path id="path-18" className="answer3" d="M571.9,747.1l386.5-221.6L573.5,294.2l-387,230L571.9,747.1z"></path>
          <path id="path-19" className="answer4" transform="translate(0 10)" d="M650,290.3V90l231.9,133.8v200.9L650,290.3z"></path>
          <line id="line-11" className="answer5" x1="279.3" y1="353.2" x2="364" y2="401.4"></line>
          <line id="line-14" className="answer6" x1="32" y1="279.3" x2="32" y2="960.3"></line>
          <line id="line-14-2" className="answer6" x1="960" y1="525.3" x2="960" y2="962.3"></line>
          <line id="line-11-2" className="answer5" x1="338.3" y1="319.2" x2="437" y2="374.3"></line>
        </g>
        <path id="path-88" className="answer7" d="M246.7,350.3l98.8,57.3l61-32.7l-104.1-58.3L246.7,350.3z"></path>
        <g id="group-130" transform="translate(364 469)" className="answer8">
          <g id="group">
            <g id="group-2">
              <g id="XMLID_199_">
                <defs>
                  <polygon id="XMLID_200_" points="-46.5,-115.8 -39.1,-120.2 -22.5,-110.5 -29.9,-106.1            "></polygon>
                </defs>
                <clipPath id="answer-path-1">
                  <use xlinkHref="#XMLID_200_" style={{overflow:'visible'}}></use>
                </clipPath>
                <g id="group-3" className="answer9">
                  <g id="group-4" transform="translate(2.032 1.722)">
                    <g id="group-5">
                      <g id="group-6">
                        <path id="path-20" className="answer10" d="M-35-118.3c-0.3-0.2-0.4-0.3-0.4-0.3c-7-3.7-15.7-0.5-8.2,3.9c0.5,0.3,1,0.5,1.5,0.8
                          c1.9,0.9,4,1.5,6.1,1.6c0.4,0.4,0.8,0.7,1.3,1c0.3,0.2,0.7,0.4,1.1,0.5c4.9,2.3,9,0.1,5.4-2.8c-0.1-0.1-0.2-0.2-0.3-0.3
                          c-0.2-0.1-0.3-0.1-0.3-0.2c-1-0.5-2.1-0.8-3.2-1c-0.4-1.2-1.2-2.2-2.3-2.9C-34.6-118-34.8-118.1-35-118.3"></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <g id="group-130-2" transform="translate(332 465)" className="answer8">
          <g id="group-7">
            <g id="group-8">
              <g id="XMLID_197_">
                <defs>
                  <polygon id="XMLID_198_" points="-46.5,-115.8 -39.1,-120.2 -22.5,-110.5 -29.9,-106.1            "></polygon>
                </defs>
                <clipPath id="XMLID_139_">
                  <use xlinkHref="#XMLID_198_" style={{overflow:'visible'}}></use>
                </clipPath>
                <g id="group-9" className="answer11">
                  <g id="group-10" transform="translate(2.032 1.722)">
                    <g id="group-11">
                      <g id="group-12">
                        <path id="path-21" className="answer10" d="M-35-118.3c-0.3-0.2-0.4-0.3-0.4-0.3c-7-3.7-15.7-0.5-8.2,3.9c0.5,0.3,1,0.5,1.5,0.8
                          c1.9,0.9,4,1.5,6.1,1.6c0.4,0.4,0.8,0.7,1.3,1c0.3,0.2,0.7,0.4,1.1,0.5c4.9,2.3,9,0.1,5.4-2.8c-0.1-0.1-0.2-0.2-0.3-0.3
                          c-0.2-0.1-0.3-0.1-0.3-0.2c-1-0.5-2.1-0.8-3.2-1c-0.4-1.2-1.2-2.2-2.3-2.9C-34.6-118-34.8-118.1-35-118.3"></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <g id="group-130-3" transform="translate(318.88 444.415) rotate(-11)" className="answer8">
          <g id="group-13">
            <g id="group-14">
              <g id="XMLID_195_">
                <defs>
                  <polygon id="XMLID_196_" points="-22.7,-122.4 -15.3,-126.8 1.2,-117.2 -6.2,-112.8             "></polygon>
                </defs>
                <clipPath id="XMLID_140_">
                  <use xlinkHref="#XMLID_196_" style={{overflow:'visible'}}></use>
                </clipPath>
                <g id="group-15" className="answer12">
                  <g id="group-16" transform="translate(2.032 1.722)">
                    <g id="group-17">
                      <g id="group-18">
                        <path id="path-22" className="answer10" d="M-11.2-124.9c-0.3-0.2-0.4-0.3-0.4-0.3c-7-3.7-15.7-0.5-8.2,3.9c0.5,0.3,1,0.5,1.5,0.8
                          c1.9,0.9,4,1.5,6.1,1.6c0.4,0.4,0.8,0.7,1.3,1c0.3,0.2,0.7,0.4,1.1,0.5c4.9,2.3,9,0.1,5.4-2.8c-0.1-0.1-0.2-0.2-0.3-0.3
                          c-0.2-0.1-0.3-0.1-0.3-0.2c-1-0.5-2.1-0.8-3.2-1c-0.4-1.2-1.2-2.2-2.3-2.9C-10.8-124.7-11-124.8-11.2-124.9"></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <g id="group-130-4" transform="translate(286.529 451.574) rotate(-16)" className="answer13">
          <g id="group-19">
            <g id="group-20">
              <g id="XMLID_193_">
                <defs>
                  <polygon id="XMLID_194_" points="-11.6,-123.9 -4.2,-128.3 12.4,-118.7 5,-114.3            "></polygon>
                </defs>
                <clipPath id="XMLID_141_">
                  <use xlinkHref="#XMLID_194_" style={{overflow:'visible'}}></use>
                </clipPath>
                <g id="group-21" className="answer14">
                  <g id="group-22" transform="translate(2.032 1.722)">
                    <g id="group-23">
                      <g id="group-24">
                        <path id="path-23" className="answer10" d="M-0.1-126.4c-0.3-0.2-0.4-0.3-0.4-0.3c-7-3.7-15.7-0.5-8.2,3.9c0.5,0.3,1,0.5,1.5,0.8
                          c1.9,0.9,4,1.5,6.1,1.6c0.4,0.4,0.8,0.7,1.3,1c0.3,0.2,0.7,0.4,1.1,0.5c4.9,2.3,9,0.1,5.4-2.8c-0.1-0.1-0.2-0.2-0.3-0.3
                          c-0.2-0.1-0.3-0.1-0.3-0.2c-1-0.5-2.1-0.8-3.2-1c-0.4-1.2-1.2-2.2-2.3-2.9C0.3-126.2,0.1-126.3-0.1-126.4"></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <g id="group-130-5" transform="matrix(0.883, -0.469, 0.469, 0.883, 254.106, 464.446)" className="answer13">
          <g id="group-25">
            <g id="group-26">
              <g id="XMLID_191_">
                <defs>
                  <polygon id="XMLID_192_" points="15.3,-123.6 22.7,-128 39.3,-118.3 31.9,-113.9            "></polygon>
                </defs>
                <clipPath id="XMLID_142_">
                  <use xlinkHref="#XMLID_192_" style={{overflow:'visible'}}></use>
                </clipPath>
                <g id="group-27" className="answer15">
                  <g id="group-28" transform="translate(2.032 1.722)">
                    <g id="group-29">
                      <g id="group-30">
                        <path id="path-24" className="answer10" d="M26.8-126.1c-0.3-0.2-0.4-0.3-0.4-0.3c-7-3.7-15.7-0.5-8.2,3.9c0.5,0.3,1,0.5,1.5,0.8
                          c1.9,0.9,4,1.5,6.1,1.6c0.4,0.4,0.8,0.7,1.3,1c0.3,0.2,0.7,0.4,1.1,0.5c4.9,2.3,9,0.1,5.4-2.8c-0.1-0.1-0.2-0.2-0.3-0.3
                          c-0.2-0.1-0.3-0.1-0.3-0.2c-1-0.5-2.1-0.8-3.2-1c-0.4-1.2-1.2-2.2-2.3-2.9C27.2-125.8,27-125.9,26.8-126.1"></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <g id="group-130-6" transform="matrix(0.883, -0.469, 0.469, 0.883, 223.106, 455.446)" className="answer13">
          <g id="group-31">
            <g id="group-32">
              <g id="XMLID_189_">
                <defs>
                  <polygon id="XMLID_190_" points="15.3,-123.6 22.7,-128 39.3,-118.3 31.9,-113.9            "></polygon>
                </defs>
                <clipPath id="XMLID_143_">
                  <use xlinkHref="#XMLID_190_" style={{overflow:'visible'}}></use>
                </clipPath>
                <g id="group-33" className="answer16">
                  <g id="group-34" transform="translate(2.032 1.722)">
                    <g id="group-35">
                      <g id="group-36">
                        <path id="path-25" className="answer10" d="M26.8-126.1c-0.3-0.2-0.4-0.3-0.4-0.3c-7-3.7-15.7-0.5-8.2,3.9c0.5,0.3,1,0.5,1.5,0.8
                          c1.9,0.9,4,1.5,6.1,1.6c0.4,0.4,0.8,0.7,1.3,1c0.3,0.2,0.7,0.4,1.1,0.5c4.9,2.3,9,0.1,5.4-2.8c-0.1-0.1-0.2-0.2-0.3-0.3
                          c-0.2-0.1-0.3-0.1-0.3-0.2c-1-0.5-2.1-0.8-3.2-1c-0.4-1.2-1.2-2.2-2.3-2.9C27.2-125.8,27-125.9,26.8-126.1"></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <g id="group-130-7" transform="translate(366 492)" className="answer8">
          <g id="group-37">
            <g id="group-38">
              <g id="XMLID_187_">
                <defs>
                  <polygon id="XMLID_188_" points="-46.5,-115.8 -39.1,-120.2 -22.5,-110.5 -29.9,-106.1            "></polygon>
                </defs>
                <clipPath id="XMLID_144_">
                  <use xlinkHref="#XMLID_188_" style={{overflow:'visible'}}></use>
                </clipPath>
                <g id="group-39" className="answer17">
                  <g id="group-40" transform="translate(2.032 1.722)">
                    <g id="group-41">
                      <g id="group-42">
                        <path id="path-26" className="answer10" d="M-35-118.3c-0.3-0.2-0.4-0.3-0.4-0.3c-7-3.7-15.7-0.5-8.2,3.9c0.5,0.3,1,0.5,1.5,0.8
                          c1.9,0.9,4,1.5,6.1,1.6c0.4,0.4,0.8,0.7,1.3,1c0.3,0.2,0.7,0.4,1.1,0.5c4.9,2.3,9,0.1,5.4-2.8c-0.1-0.1-0.2-0.2-0.3-0.3
                          c-0.2-0.1-0.3-0.1-0.3-0.2c-1-0.5-2.1-0.8-3.2-1c-0.4-1.2-1.2-2.2-2.3-2.9C-34.6-118-34.8-118.1-35-118.3"></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="group-100-2" transform="translate(-30)">
        <path id="answer_board_graph_bottom" className="answer18" d="M670,290 L720,270 L770,310 L862,250"></path>
        <path id="answer_board_graph_arrow" className="st19" d="M844.7,246.8l12.2,20.8l10.2-20.8H844.7z"></path>
      </g>
      <g id="group-244-2">
        <line id="line-27" className="answer19" x1="402.7" y1="511.7" x2="402.7" y2="590.4"></line>
        <path id="path-147" className="answer20" d="M346.1,456.6"></path>
        <path id="path-148" className="answer21" d="M368.4,541.9c0,0,0.1-29.2,0-34.7c-0.1-4.9-4-8.2-4.8-8.8c-0.2-0.3-0.5-0.5-0.8-0.7
          c-3.3-2.3-34.7-19.3-34.7-19.3l-1.2,45.3l28.1-17l8.2,3.9l-8.6,19.3l1.9,23.6c0,0-0.7,5.8,4.7,5.8l23.1-13
          C384.3,546.2,386.5,533.7,368.4,541.9z"></path>
        <line id="line-28-3" className="answer19" x1="324.8" y1="546.3" x2="324.8" y2="590.8"></line>
        <line id="line-29" className="answer19" x1="267" y1="512.6" x2="266.8" y2="557.4"></line>
        <path id="path-149" className="answer22" d="M344.7,388.9l-77.3,44.7l135.3,78.1L480,467L344.7,388.9z"></path>
        <line id="line-30-3" className="answer23" x1="363.6" y1="524.3" x2="363.6" y2="568.4"></line>
        <g id="group-99-2" transform="translate(-30)">
          <path id="path-150" className="answer21" d="M371.2,440.3l34.1,18.8l17.2-10.6l-32.5-18.6L371.2,440.3z"></path>
          <g id="group-99-3" transform="translate(419.945 469.133)">
            <g id="group-100-3">
              <path id="path-151" className="answer21" d="M-27.2-71.9v14.1l15.2,9.1v-14.4"></path>
              <path id="path-152" className="answer24" d="M-46.5-75.5v-44.7l58,33.4v44.7L-46.5-75.5z"></path>
            </g>
          </g>
          <g id="group-101" transform="translate(477.945 502.133)">
            <g id="group-102">
              <path id="path-153" className="answer21" d="M-27.2-71.9v14.1l15.2,9.1v-14.4"></path>
              <path id="path-154" className="answer24" d="M-46.5-75.5v-44.7l58,33.4v44.7L-46.5-75.5z"></path>
            </g>
          </g>
          <path id="path-155" className="answer21" d="M403.7,463.6c0,0,1.3,2.8,8.5-2.2c7.2-5,0.9-8.4,0.9-8.4s0.5,4.5-2.4,5.7
            C407.7,459.9,403.7,463.6,403.7,463.6z"></path>
        </g>
        <path id="line-31" className="answer24" d="M481,467v80.3"></path>
        <path id="path-156" className="answer21" d="M313.6,434.9c11.5-0.9,14.7-10.5,14.7-22.2c0-12.3-4.2-22.3-16.5-22.3s-19.4,10-19.4,22.3
          c0,6.3-0.3,11.9,3.9,16c0.5,0.4,1,0.9,1.5,1.3c-1,0.3-2.1,0.5-3.2,0.6c-7.5,0-13.6,6.1-13.6,13.6v66.2c0,7.5,6.1,13.6,13.6,13.6h29
          c7.5,0,9.1-6.1,9.1-13.6V481c6.3,2.5,14-1.6,14-1.6l24.7-14.9c1.2,0,2.3-0.4,3.2-1.1l5.1-3.9c2.5-1.9,4.4-5,2.6-7.6
          c-1.9-2.5-4.3-2-6.8-0.1l-7.8,2.4c-0.8,0.7-1.5,1.5-1.9,2.5h-0.1L344.4,467c-7.4,3.1-10.6-2.2-11.9-6.8c-0.1-2.1-0.5-4.1-1-6.1l0,0
          c-1.4-4.8-3.9-9-8-10.8l-7.3-4.8C316.3,438.3,311.7,435.7,313.6,434.9z"></path>
        <path id="path-157" className="answer25" d="M331,492.8c0,0,1.1,7.1,7.5,5.8c6.4-1.4-5.7,17.4-5.7,17.4l-3.6-5.5L331,492.8z"></path>
        <path id="path-158" className="answer25" d="M326.9,522.5l5.5-3.2l2.2-6.9h-5.9"></path>
        <path id="path-39-7" className="answer23" d="M295.7,430.4c0,0,7.2,9,18.6,6.7"></path>
        <path id="path-42-7" className="answer26" d="M367.4,542.2l-0.8,7.3l4.1-8.8L367.4,542.2z"></path>
        <path id="path-43-7" className="answer26" d="M332.6,481.3c0,0-0.1,15.5,1,16.3s13.5-7.1,15.6-6.9S332.6,481.3,332.6,481.3z"></path>
        <g id="group-103" transform="translate(313.451 554.002)">
          <path id="path-159" className="answer27" d="M11.5-8l38.7-21.7v-12.6L12.2-20"></path>
          <path id="path-160" className="answer22" d="M-46.5-41.4v-78.8l13.5,7.6l46.2,26.3L11.5-8L-46.5-41.4z"></path>
        </g>
        <path id="path-45-7" className="answer23" d="M320.5,413.5c0,0-4.5,2-2.2,7.2"></path>
        <path id="path-46-3" className="answer26" d="M290.6,423.7c-3.6-1.2-6.1-15.3-3.8-16.6s-1-13.7,5.4-12.7s4.5-10.6,10-8.9s9.1-3,13.8,0
          s14,4.3,13,8.9s6.5,12.5-0.9,11.5s-17.5,12-13.9,13.6s3.6,10.3,0,10.3s-6.4,2.6-8.5,4.9s-3.8,0.6-7.9-2.1S294.2,424.8,290.6,423.7z
          "></path>
        <path id="path-44-7" className="answer26" d="M326.1,505.9l-1.2,18.6l29.6-17.6l9.1,4.3v13.2l-9.1,5.8l2.2,19.4l11.8-5.4v-37.3l-2.1-6.3
          l-20.8-13.2l-13.9,4.1"></path>
        <path id="path-49-4" className="answer23" d="M336.3,467.5l-3.8,13"></path>
        <g id="group-128-2" transform="translate(402 485.611)">
          <g id="group-104">
            <g id="group-105-2" transform="translate(54.764 26.039)">
              <g id="XMLID_123_">
                <defs>
                  <polygon id="XMLID_124_" points="-46.5,-85.1 -46.4,-120.2 -9.8,-99.1 -9.9,-64             "></polygon>
                </defs>
                <clipPath id="XMLID_145_">
                  <use xlinkHref="#XMLID_124_" style={{overflow:'visible'}}></use>
                </clipPath>
                <g id="group-106" className="answer28">
                  <g id="group-107" transform="translate(0 2.592)">
                    <g id="group-108">
                      <g id="group-109">
                        <path id="path-161" className="answer10" d="M-41.9-120.2l-4.5,0.1v1.7l4.5,5.3v-1.9l-2.7-3.2l2.7-0.1V-120.2"></path>
                        <path id="path-162" className="answer10" d="M-14.4-76.4v2l2.7,3.2l-2.7,0.1v1.9l4.5-0.1V-71L-14.4-76.4"></path>
                        <path id="path-163" className="answer10" d="M-10-91.7l-36.4-21v1.8L-10-89.9V-91.7"></path>
                        <path id="path-164" className="answer10" d="M-10-87.4l-36.4-21v1.8l36.4,21V-87.4"></path>
                        <path id="path-165" className="answer10" d="M-10-83l-36.4-21v1.8l36.4,21V-83"></path>
                        <path id="path-166" className="answer10" d="M-10-78.6l-36.5-21v1.8l36.5,21V-78.6"></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
            <g id="group-110" transform="translate(-3.236 -6.961)">
              <g id="XMLID_121_">
                <defs>
                  <polygon id="XMLID_122_" points="-46.5,-85.1 -46.4,-120.2 -9.8,-99.1 -9.9,-64             "></polygon>
                </defs>
                <clipPath id="XMLID_146_">
                  <use xlinkHref="#XMLID_122_" style={{overflow:'visible'}}></use>
                </clipPath>
                <g id="group-111" className="answer29">
                  <g id="group-112" transform="translate(0 2.592)">
                    <g id="group-113">
                      <g id="group-114">
                        <path id="path-167" className="answer10" d="M-41.9-120.2l-4.5,0.1v1.7l4.5,5.3v-1.9l-2.7-3.2l2.7-0.1V-120.2"></path>
                        <path id="path-168" className="answer10" d="M-14.4-76.4v2l2.7,3.2l-2.7,0.1v1.9l4.5-0.1V-71L-14.4-76.4"></path>
                        <path id="path-169" className="answer10" d="M-10-91.7l-36.4-21v1.8L-10-89.9V-91.7"></path>
                        <path id="path-170" className="answer10" d="M-10-87.4l-36.4-21v1.8l36.4,21V-87.4"></path>
                        <path id="path-171" className="answer10" d="M-10-83l-36.4-21v1.8l36.4,21V-83"></path>
                        <path id="path-172" className="answer10" d="M-10-78.6l-36.5-21v1.8l36.5,21V-78.6"></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <g id="group-115" transform="translate(430 578.357)">
          <path id="path-173" className="answer25" d="M-34.1-95.6c-0.4-0.2-0.4-0.6,0.1-1c0.5-0.3,1.1-0.3,1.6-0.1c0.4,0.2,0.4,0.6-0.1,1
            C-33-95.4-33.6-95.4-34.1-95.6 M-39.4-101.9c-0.4-0.1-0.6-0.5-0.5-1c0.1-0.3,0.3-0.4,0.5-0.5l25-14.5c0.8-0.4,1.8-0.4,2.6,0
            l16,9.2c0.4,0.1,0.6,0.5,0.5,1c-0.1,0.3-0.3,0.4-0.5,0.5l-24.9,14.5c-0.8,0.4-1.8,0.4-2.6,0L-39.4-101.9 M5.3-108.8l-16.8-9.7
            c-0.8-0.5-1.9-0.5-2.7,0l-29.5,17.1c-0.7,0.5-0.7,1.1,0,1.6l16.8,9.7c0.8,0.5,1.9,0.5,2.7,0l29.5-17.1
            C6.2-107.7,6-108.4,5.3-108.8"></path>
          <path id="path-174" className="answer10" d="M-43.7-99.8c-0.7-0.5-0.7-1.1,0-1.6l29.5-17.1c0.8-0.5,1.9-0.5,2.7,0l16.8,9.7
            c0.7,0.5,0.7,1.1,0,1.6l-29.5,17.1c-0.8,0.5-1.9,0.5-2.7,0L-43.7-99.8 M6.2-109.3l-16.8-9.7c-1.4-0.7-3-0.7-4.4,0l-29.5,17.1
            c-1.2,0.7-1.2,1.8,0,2.6l16.8,9.7c1.4,0.7,3,0.7,4.4,0l29.5-17.1C7.4-107.4,7.4-108.7,6.2-109.3"></path>
          <path id="path-175" className="answer10" d="M-32.4-96.6c-0.5-0.3-1.1-0.2-1.6,0.1c-0.3,0.1-0.4,0.4-0.4,0.7c0,0.1,0.1,0.2,0.2,0.3
            c0.5,0.3,1.1,0.2,1.6-0.1C-32-96-31.9-96.4-32.4-96.6"></path>
          <path id="path-176" className="answer26" d="M3.8-108.4l-16-9.2c-0.5-0.2-1.2-0.2-1.7,0l-25,14.5c-0.3,0.1-0.4,0.4-0.3,0.6
            c0,0.2,0.2,0.3,0.3,0.3l16,9.2c0.5,0.2,1.2,0.2,1.7,0l25-14.5c0.3-0.1,0.4-0.4,0.3-0.6C4.1-108.2,4-108.4,3.8-108.4"></path>
          <path id="path-177" className="answer10" d="M-38.9-102.2c-0.3-0.1-0.4-0.4-0.3-0.6c0-0.2,0.2-0.3,0.3-0.3l25-14.5c0.5-0.2,1.2-0.2,1.7,0
            l16,9.2c0.3,0.1,0.4,0.4,0.3,0.6c0,0.2-0.2,0.3-0.3,0.3l-25,14.5c-0.5,0.2-1.2,0.2-1.7,0L-38.9-102.2 M4.2-108.7l-16-9.2
            c-0.8-0.4-1.8-0.4-2.6,0l-25,14.5c-0.4,0.1-0.6,0.5-0.5,1c0.1,0.3,0.3,0.4,0.5,0.5l16,9.2c0.8,0.4,1.8,0.4,2.6,0l25-14.5
            c0.4-0.1,0.6-0.5,0.5-1C4.7-108.4,4.5-108.6,4.2-108.7"></path>
        </g>
      </g>
      <g id="group-243-2">
        <g id="ellipse-19-5" transform="translate(523 407)">
          <ellipse id="XMLID_113_" className="answer30" cx="-38.5" cy="-111.2" rx="8" ry="9"></ellipse>
          <ellipse id="XMLID_112_" className="answer23" cx="-38.5" cy="-111.2" rx="7" ry="8"></ellipse>
        </g>
        <g id="group-116" transform="translate(506.964 375.352)">
          <path id="path-178" className="answer22" d="M31.4-97.2l-77.3,44.7L89.4,25.6l77.3-44.7L31.4-97.2z"></path>
          <line id="line-32" className="answer19" x1="89.4" y1="25.6" x2="89.4" y2="104.2"></line>
          <path id="line-33" className="answer24" d="M167.6-19.1v80.3"></path>
          <path id="path-179" className="answer20" d="M32.7-29.6"></path>
          <path id="path-180" className="answer21" d="M55,55.7c0,0,0.1-29.2,0-34.7c-0.1-4.9-4-8.2-4.8-8.8c-0.2-0.3-0.5-0.5-0.8-0.7
            C46.1,9.3,14.8-7.7,14.8-7.7l-1.2,45.3l28.1-17l8.2,3.9l-8.6,19.3l1.9,23.6c0,0-0.7,5.8,4.7,5.8l23.1-13
            C71,60.1,73.2,47.5,55,55.7z"></path>
          <path id="path-181" className="answer21" d="M27.8-45.8l34.1,18.8l17.2-10.6L46.6-56.3L27.8-45.8z"></path>
          <g id="group-117" transform="translate(95.607)">
            <g id="group-118">
              <path id="path-182" className="answer21" d="M-27.2-71.9v14.1l15.2,9.1v-14.4"></path>
              <path id="path-183" className="answer24" d="M-46.5-75.5v-44.7l58,33.4v44.7L-46.5-75.5z"></path>
            </g>
          </g>
          <line id="line-34" className="answer19" x1="11.5" y1="60.1" x2="11.5" y2="104.7"></line>
          <line id="line-35" className="answer19" x1="-46.4" y1="26.5" x2="-46.5" y2="71.3"></line>
          <line id="line-36" className="answer23" x1="50.2" y1="38.2" x2="50.2" y2="82.3"></line>
          <path id="path-184" className="answer21" d="M60.4-22.5c0,0,1.3,2.8,8.5-2.2c7.2-5,0.9-8.4,0.9-8.4s0.5,4.5-2.4,5.7
            C64.3-26.3,60.4-22.5,60.4-22.5z"></path>
          <path id="path-185" className="answer21" d="M0.2-51.3c11.5-0.9,14.7-10.5,14.7-22.2c0-12.3-4.2-22.3-16.5-22.3S-21-85.7-21-73.4
            c0,6.3-0.3,11.9,3.9,16c0.5,0.4,1,0.9,1.5,1.3c-1,0.3-2.1,0.5-3.2,0.6c-7.5,0-13.6,6.1-13.6,13.6v66.2c0,7.5,6.1,13.6,13.6,13.6
            h29c7.5,0,9.1-6.1,9.1-13.6V-5.1c6.3,2.5,14-1.6,14-1.6L58-21.6c1.2,0,2.3-0.4,3.2-1.1l5.1-3.9c2.5-1.9,4.4-5,2.6-7.6
            c-1.9-2.5-4.3-2-6.8-0.1L54.2-32c-0.8,0.7-1.5,1.5-1.9,2.5h-0.1L31-19.1c-7.4,3.1-10.6-2.2-11.9-6.8c-0.1-2.1-0.5-4.1-1-6.1l0,0
            c-1.4-4.8-3.9-9-8-10.8l-7.3-4.8C3-47.9-1.6-50.5,0.2-51.3z"></path>
          <g id="group-119" transform="translate(0.114 67.869)">
            <path id="path-186" className="answer27" d="M11.5-8l38.7-21.7v-12.6L12.2-20"></path>
            <path id="path-187" className="answer22" d="M-46.5-41.4v-78.8l13.5,7.6l46.2,26.3L11.5-8L-46.5-41.4z"></path>
          </g>
          <path id="path-188" className="answer25" d="M17.7,6.7c0,0,1.1,7.1,7.5,5.8s-5.7,17.4-5.7,17.4l-3.6-5.5L17.7,6.7z"></path>
          <path id="path-189" className="answer25" d="M13.6,36.4l5.5-3.2l2.2-6.9h-5.9"></path>
        </g>
        <path id="path-39-8" className="answer23" d="M489.3,319.6c0,0,7.2,9,18.6,6.7"></path>
        <path id="path-42-8" className="answer26" d="M561,431.4l-0.8,7.3l4.1-8.8L561,431.4z"></path>
        <path id="path-43-8" className="answer26" d="M526.2,370.5c0,0-0.1,15.5,1,16.3s13.5-7.1,15.6-6.9S526.2,370.5,526.2,370.5z"></path>
        <path id="path-45-8" className="answer23" d="M511.2,302.7c0,0-4.5,2-2.2,7.2"></path>
        <path id="path-44-8" className="answer26" d="M519.7,395.1l-1.2,18.6l29.6-17.6l9.1,4.4v13.2l-9.1,5.8l2.2,19.4l11.8-5.4v-37.3l-2.1-6.3
          l-20.8-13.2l-13.9,4.1"></path>
        <line id="line-16-4" className="answer23" x1="554" y1="348.3" x2="563" y2="355.3"></line>
        <g transform="translate(-351 125.773)">
          <path id="answer_screen_block1" className="answer26" d="M914.3,142.7l42.2,23l-0.6,14.5L914.3,157L914.3,142.7z"></path>
          <path id="answer_screen_block2" className="answer26" d="M913.7,161.6l17.7,9.7v8.4l-17.7-9.8V161.6z"></path>
          <path id="answer_screen_block3" className="answer26" d="M936.5,174.1l18.3,10.3v9l-18.3-10.3V174.1z"></path>
        </g>
        <path id="path-86-3" className="answer26" d="M486.5,309.9c0,0-11-4.1-7.7-8.2s-4.7-19.3,1.2-19.3s11.4-15.8,20.7-14.8s13.4,13.2,14.3,7
          s10.3,2.8,10.3,2.8s7.7,12.1,1,12.1s-5.9,5.3-5.9,5.3s-9.4-5.8-9.4,0s-7.5,7-7.5,7s-3.8,11.8,0.9,11.8s-8.3,5.3-13.6,3.3
          C487.9,316,486,313,486.5,309.9z"></path>
        <g id="group-120" transform="translate(639.277 459.36)">
          <path id="path-190" className="answer25" d="M-40.2-108.5c-0.2-0.1-0.2-0.3,0.1-0.5c0.2-0.1,0.5-0.1,0.7-0.1c0.2,0.1,0.2,0.3-0.1,0.5
            C-39.7-108.4-40-108.4-40.2-108.5 M-42.7-111.4c-0.2-0.1-0.3-0.3-0.2-0.4c0-0.1,0.1-0.2,0.2-0.2l11.5-6.7c0.4-0.2,0.8-0.2,1.2,0
            l7.3,4.2c0.2,0.1,0.3,0.3,0.2,0.4c0,0.1-0.1,0.2-0.2,0.2l-11.4,6.7c-0.4,0.2-0.8,0.2-1.2,0L-42.7-111.4 M-22.1-114.6l-7.7-4.5
            c-0.4-0.2-0.9-0.2-1.2,0l-13.5,7.8c-0.3,0.2-0.3,0.5,0,0.7l7.7,4.5c0.4,0.2,0.9,0.2,1.2,0l13.5-7.8
            C-21.7-114.1-21.8-114.4-22.1-114.6"></path>
          <path id="path-191" className="answer10" d="M-44.6-110.5c-0.3-0.2-0.3-0.5,0-0.7l13.5-7.8c0.4-0.2,0.9-0.2,1.2,0l7.7,4.5
            c0.3,0.2,0.3,0.5,0,0.7l-13.5,7.8c-0.4,0.2-0.9,0.2-1.2,0L-44.6-110.5 M-21.7-114.8l-7.7-4.5c-0.6-0.3-1.4-0.3-2,0l-13.5,7.8
            c-0.6,0.3-0.6,0.8,0,1.2l7.7,4.5c0.6,0.3,1.4,0.3,2,0l13.5-7.8C-21.2-114-21.2-114.5-21.7-114.8"></path>
          <path id="path-192" className="answer10" d="M-39.4-109c-0.2-0.1-0.5-0.1-0.7,0.1c-0.1,0-0.2,0.2-0.2,0.3c0,0.1,0.1,0.1,0.1,0.1
            c0.2,0.1,0.5,0.1,0.7-0.1C-39.3-108.7-39.2-108.9-39.4-109"></path>
          <path id="path-193" className="answer26" d="M-22.8-114.4l-7.3-4.2c-0.3-0.1-0.5-0.1-0.8,0l-11.5,6.7c-0.1,0-0.2,0.2-0.2,0.3
            c0,0.1,0.1,0.1,0.2,0.2l7.3,4.2c0.3,0.1,0.5,0.1,0.8,0l11.5-6.7c0.1,0,0.2-0.2,0.2-0.3C-22.7-114.3-22.7-114.4-22.8-114.4"></path>
          <path id="path-194" className="answer10" d="M-42.4-111.5c-0.1,0-0.2-0.2-0.2-0.3c0-0.1,0.1-0.1,0.2-0.2l11.5-6.7c0.3-0.1,0.5-0.1,0.8,0
            l7.3,4.2c0.1,0,0.2,0.2,0.2,0.3c0,0.1-0.1,0.1-0.2,0.2l-11.5,6.7c-0.3,0.1-0.5,0.1-0.8,0L-42.4-111.5 M-22.6-114.5l-7.3-4.2
            c-0.4-0.2-0.8-0.2-1.2,0l-11.5,6.7c-0.2,0.1-0.3,0.3-0.2,0.4c0,0.1,0.1,0.2,0.2,0.2l7.3,4.2c0.4,0.2,0.8,0.2,1.2,0l11.5-6.7
            c0.2-0.1,0.3-0.3,0.2-0.4C-22.4-114.4-22.5-114.5-22.6-114.5"></path>
        </g>
        <g id="ellipse-19-6" transform="translate(550 415)">
          <ellipse id="XMLID_104_" className="answer30" cx="-38.5" cy="-111.2" rx="8" ry="9"></ellipse>
          <ellipse id="XMLID_103_" className="answer23" cx="-38.5" cy="-111.2" rx="7" ry="8"></ellipse>
        </g>
        <path id="path-85-4" className="answer31" d="M485.4,282.3c-15.6,4.5,4.7-6.1,4.7-6.1s16.1-5,23,21.6s-6.8,0.7-6.7,2.7
          S501,277.8,485.4,282.3z"></path>
      </g>
      <g id="group-142-2" transform="translate(-30)">
        <g id="group-64-4" transform="translate(713.251 473.61)">
          <g id="group-121" transform="translate(0 0)">
            <path id="path-195" className="answer22" d="M31.4-97.2l-77.3,44.7L89.4,25.6l77.3-44.7L31.4-97.2z"></path>
            <line id="line-37" className="answer19" x1="89.4" y1="25.6" x2="89.4" y2="104.2"></line>
            <path id="line-38" className="answer24" d="M167.6-19.1v80.3"></path>
            <path id="path-196" className="answer20" d="M32.7-29.6"></path>
            <path id="path-197" className="answer21" d="M55,55.7c0,0,0.1-29.2,0-34.7c-0.1-4.9-4-8.2-4.8-8.8c-0.2-0.3-0.5-0.5-0.8-0.7
              C46.1,9.3,14.8-7.7,14.8-7.7l-1.2,45.3l28.1-17l8.2,3.9l-8.6,19.3l1.9,23.6c0,0-0.7,5.8,4.7,5.8l23.1-13
              C71,60.1,73.2,47.5,55,55.7z"></path>
            <path id="path-198" className="answer21" d="M27.8-45.8l34.1,18.8l17.2-10.6L46.6-56.3L27.8-45.8z"></path>
            <g id="group-122" transform="translate(95.607)">
              <g id="group-123-3">
                <path id="path-199" className="answer21" d="M-27.2-71.9v14.1l15.2,9.1v-14.4"></path>
                <path id="path-200" className="answer24" d="M-46.5-75.5v-44.7l58,33.4v44.7L-46.5-75.5z"></path>
              </g>
            </g>
            <line id="line-39" className="answer19" x1="11.5" y1="60.1" x2="11.5" y2="104.7"></line>
            <line id="line-40" className="answer19" x1="-46.4" y1="26.5" x2="-46.5" y2="71.3"></line>
            <line id="line-41" className="answer23" x1="50.2" y1="38.2" x2="50.2" y2="82.3"></line>
            <path id="path-201" className="answer21" d="M60.4-22.5c0,0,1.3,2.8,8.5-2.2c7.2-5,0.9-8.4,0.9-8.4s0.5,4.5-2.4,5.7
              C64.3-26.3,60.4-22.5,60.4-22.5z"></path>
            <path id="path-202" className="answer21" d="M0.2-51.3c11.5-0.9,14.7-10.5,14.7-22.2c0-12.3-4.2-22.3-16.5-22.3
              c-12.3,0-22.3,10-22.3,22.3c0,6.1,2.5,11.9,6.8,16.1c0.5,0.4,1,0.9,1.5,1.3c-1,0.3-2.1,0.5-3.2,0.6c-7.5,0-13.6,6.1-13.6,13.6
              v66.2c0,7.5,6.1,13.6,13.6,13.6h29c7.5,0,9.1-6.1,9.1-13.6V-5.1c6.3,2.5,14-1.6,14-1.6L58-21.6c1.2,0,2.3-0.4,3.2-1.1l5.1-3.9
              c2.5-1.9,4.4-5,2.6-7.6c-1.9-2.5-4.3-2-6.8-0.1L54.2-32c-0.8,0.7-1.5,1.5-1.9,2.5h-0.1L31-19.1c-7.4,3.1-10.6-2.2-11.9-6.8
              c-0.1-2.1-0.5-4.1-1-6.1l0,0c-1.4-4.8-3.9-9-8-10.8l-7.3-4.8C3-47.9-1.6-50.5,0.2-51.3z"></path>
            <g id="group-124" transform="translate(0.114 67.869)">
              <path id="path-203" className="answer27" d="M11.5-8l38.7-21.7v-12.6L12.2-20"></path>
              <path id="path-204" className="answer22" d="M-46.5-41.4v-78.8l13.5,7.6l46.2,26.3L11.5-8L-46.5-41.4z"></path>
            </g>
            <path id="path-205" className="answer25" d="M17.7,6.7c0,0,1.1,7.1,7.5,5.8s-5.7,17.4-5.7,17.4l-3.6-5.5L17.7,6.7z"></path>
            <path id="path-206" className="answer25" d="M13.6,36.4l5.5-3.2l2.2-6.9h-5.9"></path>
          </g>
          <path id="path-38-4" className="answer26" d="M-10.5-56.4C-0.2-61,8.7-63.7,4.9-65.8s1.5-12.3,1.5-12.3s11.6-0.2,7.4-4.3s6.6-8,6.6-8
            c-3.5-7.2-9.9-12.6-17.6-14.8c-13.2-3.9-17.7,7.4-24.9,9.5c-7.2,2-3.3,23.4-3.3,23.4S-20.8-51.9-10.5-56.4z"></path>
          <path id="path-39-9" className="answer23" d="M-17.7-55.8c0,0,7.2,9,18.6,6.7"></path>
          <path id="path-42-9" className="answer26" d="M54.1,56.1l-0.8,7.3l4.1-8.8L54.1,56.1z"></path>
          <path id="path-43-9" className="answer26" d="M19.3-4.8c0,0-0.1,15.5,1,16.3s13.5-7.1,15.6-6.9S19.3-4.8,19.3-4.8z"></path>
        </g>
        <path id="path-45-9" className="answer23" d="M723.3,398c0,0-4.5,2-2.2,7.2"></path>
        <path id="path-44-9" className="answer26" d="M726,493.4l-1.2,18.6l29.6-17.6l9.1,4.3V512l-9.1,5.8l2.2,19.4l11.8-5.4v-37.3l-2.1-6.3
          l-20.8-13.2l-13.9,4.1"></path>
        <path id="path-49-5" className="answer23" d="M736.3,454.5l-3.8,13"></path>
        <path id="path-89-3" className="answer30" d="M783.1,406.3l13-30l21,12v38.1L783.1,406.3z"></path>
        <g id="answer_coding_screen" transform="matrix(0.999, 0.035, -0.035, 0.999, 829.075, 502.774)">
          <g id="group-125" transform="translate(-1.999 -0.02)">
            <g id="group-126-3">
              <g id="group-127" transform="translate(3.54 3.253)">
                <path id="path-207" className="answer10" d="M-47-118.4l0.3-0.1l-3.7,1l-0.3,0.1L-47-118.4z"></path>
              </g>
              <g id="group-128-3" transform="translate(0 0.126)">
                <path id="path-208" className="answer32" d="M-44.3-118.5l-0.2,3.2l-3.7,1l3.4,4.5l-0.2,3.2l-5.7-7.5l0.2-2.8L-44.3-118.5z"></path>
              </g>
              <g id="group-129-2" transform="translate(6.619)">
                <path id="path-209" className="answer32" d="M-43.7-117.8l-5.6,15l-1.4-0.7l5.6-15L-43.7-117.8z"></path>
              </g>
            </g>
            <g id="group-130-8" transform="translate(13.108 4.205)">
              <g id="group-131" transform="translate(0.158 6.6)">
                <path id="path-210" className="answer10" d="M-50.7-117.5l0.3-0.1l3.7-0.9l-0.3,0.1L-50.7-117.5z"></path>
              </g>
              <g id="group-132" transform="translate(0 9.17)">
                <path id="path-211" className="answer10" d="M-44.5-118.4l0.3-0.1l-6.2,1.7l-0.3,0.1L-44.5-118.4z"></path>
              </g>
              <g id="group-133" transform="translate(0.561 0)">
                <path id="path-212" className="answer32" d="M-50-118.5l5.7,7.5l-0.2,2.8l-6.2,1.7l0.2-3.3l3.8-0.9l-3.4-4.5L-50-118.5z"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="group-248-2" className="be">
        <g id="group-98-3" transform="translate(-30)">
          <path id="path-213" className="answer22" d="M210.8,143.1l-67.6,39l118.2,68.3l67.6-39L210.8,143.1z"></path>
        <g className="be-monitor" transform="translate(-152 -103)">
        <g id="group" transform="translate(425.572 249.352)">
          <g id="group-2" data-name="group">
            <path id="path" className="be-monitor-1" d="M370.3,178.2v12.355l13.351,7.971V185.872" transform="translate(-353.409 -135.861)"></path>
            <path id="path-2" data-name="path" className="be-monitor-2" d="M352.5,176.556V137.4l50.813,29.292v39.156Z" transform="translate(-352.5 -137.4)"></path>
          </g>
        </g>
        <g id="group-123" transform="translate(432.25 261.02)">
          <path id="path-34" className="be-monitor-3 be-animate1" d="M3688.191,5221.893l37,20.146-.5,12.7-36.5-20.362Z" transform="translate(-3687.692 -5221.893)"></path>
          <path id="path-34-2" data-name="path-34" className="be-monitor-3 be-animate2" d="M3690.191,5218.893l15.507,8.526v7.386l-15.507-8.552Z" transform="translate(-3690.191 -5202.352)"></path>
          <path id="path-34-3" data-name="path-34" className="be-monitor-3 be-animate3" d="M3690.191,5217.893l16.007,9.026v7.886l-16.007-9.052Z" transform="translate(-3670.2 -5190.356)"></path>
        </g>
      </g>
      <g className="be-monitor" transform="translate(-253 -148)">
        <g id="group" transform="translate(425.572 249.352)">
          <g id="group-2" data-name="group">
            <path id="path" className="be-monitor-1" d="M370.3,178.2v12.355l13.351,7.971V185.872" transform="translate(-353.409 -135.861)"></path>
            <path id="path-2" data-name="path" className="be-monitor-2" d="M352.5,176.556V137.4l50.813,29.292v39.156Z" transform="translate(-352.5 -137.4)"></path>
          </g>
        </g>
        <g id="group-123" transform="translate(432.25 261.02)">
          <path id="path-34" className="be-monitor-3 be-animate" d="M3688.191,5221.893l37,20.146-.5,12.7-36.5-20.362Z" transform="translate(-3687.692 -5221.893)"></path>
          <path id="path-34-2" data-name="path-34" className="be-monitor-3 be-animate" d="M3690.191,5218.893l15.507,8.526v7.386l-15.507-8.552Z" transform="translate(-3690.191 -5202.352)"></path>
          <path id="path-34-3" data-name="path-34" className="be-monitor-3 be-animate be-animate" d="M3690.191,5217.893l16.007,9.026v7.886l-16.007-9.052Z" transform="translate(-3670.2 -5190.356)"></path>
        </g>
      </g>

          <line id="line-42" className="answer19" x1="261.4" y1="250.5" x2="261.4" y2="319.2"></line>
          <path id="line-43" className="answer24" d="M329.9,211.4v70.1"></path>
        </g>
        <path id="path-214" className="answer20" d="M182,202.3"></path>
        <g id="group-60-3" transform="translate(223.265 263.251)">
          <path id="path-215" className="answer31" d="M-46.5-78.2L-9-57.4l24.5-14.2v-28.3l-36.8-20.3v28.1L-46.5-78.2z"></path>
          <line id="line-44" className="answer23" x1="-21.3" y1="-92.1" x2="15.5" y2="-71.5"></line>
          <path id="path-216" className="answer26" d="M-34.2-81l12.3-6.9L7.2-71.5l-12.1,6.9L-34.2-81z"></path>
        </g>
        <g id="group-96-3" transform="translate(-30)">
          <path id="path-217" className="answer21" d="M231.4,276.8c0,0,0.1-25.6,0-30.3c-0.1-4.3-3.5-7.2-4.2-7.7c-0.2-0.2-0.4-0.4-0.7-0.6
            c-2.9-2-30.3-16.9-30.3-16.9l-1,39.6l24.6-14.8l7.2,3.4l-7.5,16.8l1.6,20.6c0,0-0.6,5.1,4.1,5.1l20.2-11.4
            C245.4,280.6,247.3,269.7,231.4,276.8z"></path>
          <path id="path-218" className="answer21" d="M183.5,183.3c10-0.8,13.2-9.1,13.2-19.4c0-10.7-4-19.5-14.8-19.5c-10.8,0-19.5,8.7-19.5,19.5
            c0,5.3,2.1,10.4,6,14c0.4,0.4,0.8,0.8,1.3,1.1c-0.9,0.2-1.8,0.4-2.8,0.5c-6.6,0-11.9,5.3-11.9,11.9v57.8c0,6.6,5.3,11.9,11.9,11.9
            h25.3c6.6,0,7.9-5.4,7.9-11.9v-25.6c5.5,2.2,12.2-1.4,12.2-1.4l21.6-13c1,0,2-0.4,2.8-1l4.5-3.4c2.2-1.7,3.9-4.4,2.3-6.7
            c-1.7-2.2-3.8-1.8-6-0.1l-6.9,2.1c-0.7,0.6-1.3,1.3-1.7,2.2H229l-18.5,9c-6.5,2.7-9.2-1.9-10.4-6c-0.1-1.8-0.4-3.6-0.9-5.4l0,0
            c-1.2-4.2-3.4-7.9-7-9.4l-6.4-4.2C185.9,186.3,182,184,183.5,183.3z"></path>
          <path id="path-44-10" className="answer26" d="M195.5,245.1l-1,15.7l25.1-14.9l7.7,3.7v12.6l-7.7,3.5l1.7,17.1l10.2-5.7v-31.2l-1.8-5.3
            L212,229.4l-11.8,3.4"></path>
          <path id="path-39-10" className="answer23" d="M167.9,179.4c0,0,6.3,7.9,16.3,5.8"></path>
          <path id="path-42-10" className="answer26" d="M230.6,277.1l-0.7,6.4l3.6-7.7L230.6,277.1z"></path>
          <path id="path-43-10" className="answer26" d="M200.2,223.9c0,0-0.1,13.6,0.9,14.3s11.8-6.2,13.7-6S200.2,223.9,200.2,223.9z"></path>
          <line id="line-15-3" className="answer23" x1="205" y1="211.3" x2="200" y2="223.3"></line>
          <path id="path-45-10" className="answer23" d="M190.6,161.9c0,0-3.9,1.7-1.9,6.3"></path>
          <path id="path-87-3" className="answer26" d="M163.9,174.6c1.6-2.7-1.4-2.4-3.5-4.7c-0.1-1.2-0.1-2.4,0-3.6c0.6-3.3,1-6.1,0-8.5
            s1.5-9.9,3.5-9s4.2-10.7,12-8.4s9.3-2.4,10.6,1.5s9.7,0.2,9.9,3s4.8,10.2,2.6,11.3s1.6,3.2-1.4,3.5s-1.9-3.7-5.3-2
            s-8.2,4.9-7.1,8.5s-0.6,8.7-4.6,8.3s-3.5-4.4,0-4.7s-1.4,19.6-4.7,15.7s-8-4.6-8.2-1.5S162,177.8,163.9,174.6z"></path>
        </g>
        <g id="group-97-3" transform="translate(-30)">
          <line id="line-45" className="answer19" x1="193.4" y1="280.7" x2="193.4" y2="319.6"></line>
          <line id="line-46" className="answer19" x1="142.8" y1="251.2" x2="142.7" y2="290.4"></line>
          <line id="line-47" className="answer23" x1="227.3" y1="261.5" x2="227.3" y2="300"></line>
          <g id="group-134-3" transform="translate(189.306 302.56)">
            <path id="path-219" className="answer27" d="M4.2-22.1l33.8-19v-11L4.8-32.6"></path>
            <path id="path-220" className="answer22" d="M-46.5-51.3v-68.9l11.8,6.7l40.3,23L4.2-22.1L-46.5-51.3z"></path>
          </g>
        </g>
        <g id="group-135" transform="translate(269 323)">
          <path id="path-221" className="answer25" d="M-36.2-100.1c-0.3-0.2-0.3-0.5,0.1-0.8c0.4-0.2,0.9-0.3,1.3-0.1c0.3,0.2,0.3,0.5-0.1,0.8
            C-35.3-99.9-35.8-99.8-36.2-100.1 M-40.5-105.2c-0.3-0.1-0.5-0.4-0.4-0.8c0.1-0.2,0.2-0.4,0.4-0.4l20.4-11.8
            c0.7-0.3,1.4-0.3,2.1,0l13,7.5c0.3,0.1,0.5,0.4,0.4,0.8c-0.1,0.2-0.2,0.4-0.4,0.4l-20.3,11.8c-0.7,0.3-1.4,0.3-2.1,0L-40.5-105.2
             M-4.1-110.8l-13.7-7.9c-0.7-0.4-1.5-0.4-2.2,0l-24,13.9c-0.6,0.4-0.6,0.9,0,1.3l13.7,7.9c0.7,0.4,1.5,0.4,2.2,0l24-13.9
            C-3.4-109.9-3.5-110.5-4.1-110.8"></path>
          <path id="path-222" className="answer10" d="M-44-103.5c-0.6-0.4-0.6-0.9,0-1.3l24-13.9c0.7-0.4,1.5-0.4,2.2,0l13.7,7.9
            c0.6,0.4,0.6,0.9,0,1.3l-24,13.9c-0.7,0.4-1.5,0.4-2.2,0L-44-103.5 M-3.4-111.2l-13.7-7.9c-1.1-0.6-2.5-0.6-3.6,0l-24,13.9
            c-1,0.6-1,1.5,0,2.1l13.7,7.9c1.1,0.6,2.5,0.6,3.6,0l24-13.9C-2.4-109.7-2.4-110.7-3.4-111.2"></path>
          <path id="path-223" className="answer10" d="M-34.8-100.9c-0.4-0.2-0.9-0.2-1.3,0.1c-0.2,0.1-0.4,0.3-0.3,0.5c0,0.1,0.1,0.2,0.2,0.3
            c0.4,0.2,0.9,0.2,1.3-0.1C-34.5-100.4-34.4-100.7-34.8-100.9"></path>
          <path id="path-224" className="answer26" d="M-5.3-110.5l-13-7.5c-0.4-0.2-1-0.2-1.4,0l-20.4,11.8c-0.2,0.1-0.3,0.3-0.3,0.5
            c0,0.1,0.1,0.2,0.3,0.3l13,7.5c0.4,0.2,1,0.2,1.4,0l20.4-11.8c0.2-0.1,0.3-0.3,0.3-0.5C-5.1-110.3-5.2-110.4-5.3-110.5"></path>
          <path id="path-225" className="answer10" d="M-40.1-105.4c-0.2-0.1-0.3-0.3-0.3-0.5c0-0.1,0.1-0.2,0.3-0.3l20.4-11.8c0.4-0.2,1-0.2,1.4,0
            l13,7.5c0.2,0.1,0.3,0.3,0.3,0.5c0,0.1-0.1,0.2-0.3,0.3l-20.4,11.8c-0.4,0.2-1,0.2-1.4,0L-40.1-105.4 M-5-110.7l-13-7.5
            c-0.7-0.3-1.4-0.3-2.1,0l-20.4,11.8c-0.3,0.1-0.5,0.4-0.4,0.8c0.1,0.2,0.2,0.4,0.4,0.4l13,7.5c0.7,0.3,1.4,0.3,2.1,0L-5-109.5
            c0.3-0.1,0.5-0.4,0.4-0.8C-4.6-110.4-4.8-110.6-5-110.7"></path>
        </g>
        <g id="group-129-3" transform="translate(-369.584 -327.658)">
          <g id="group-136" transform="translate(621.428 604.43)">
            <g id="group-137">
              <g id="group-138">
                <path id="path-226" className="answer25" d="M-42.4-114.5c-0.5-0.3-0.9-0.8-1.1-1.3l2.3,1.3C-41.6-114.3-42.1-114.3-42.4-114.5
                   M-43.6-117.2c0,0-0.1-0.1-0.2-0.2c-0.2-0.2-0.3-0.5-0.2-0.7c0.1-0.1,0.2-0.1,0.3-0.1c0,0,0,0,0,0l0.2,0.2
                  c0.2,0.2,0.2,0.4,0.2,0.7C-43.3-117.1-43.5-117.1-43.6-117.2 M-41.4-115.9c0,0-0.1-0.1-0.2-0.2c-0.2-0.2-0.2-0.4-0.2-0.7
                  c0.1-0.1,0.2-0.1,0.3-0.1c0,0,0,0,0,0c0,0,0.1,0.1,0.2,0.2c0.2,0.2,0.2,0.4,0.2,0.7C-41.1-115.8-41.3-115.8-41.4-115.9
                   M-42.5-119.8c-0.3-0.2-0.6-0.3-0.9-0.4c-1.5-0.3-2.4,1.1-1.9,3.2c0.2,0.9,0.6,1.7,1.1,2.4c-1.3-0.2-2.2,0.6-2.3,2.2l7.9,4.6
                  c-0.2-1.8-1-3.5-2.3-4.8c1.1-0.2,1.6-1.5,1.2-3.2C-40.1-117.5-41.1-118.9-42.5-119.8"></path>
              </g>
            </g>
          </g>
          <line id="line-28-4" className="answer23" x1="585.9" y1="479.3" x2="585.9" y2="507"></line>
          <g id="group-126-4" transform="translate(634.781 607.844)">
            <g id="group-139" transform="translate(0 0)">
              <g id="group-140">
                <g id="group-141">
                  <g id="group-142-3">
                    <path id="path-227" className="answer33" d="M-36.7-115.4l-7.9-4.6c-0.6-0.4-1.1-0.1-1.1,0.6c0,0.8,0.4,1.5,1.1,1.9l7.9,4.6
                      c0.6,0.4,1.1,0.1,1.1-0.6C-35.6-114.3-36-115-36.7-115.4"></path>
                    <path id="path-228" className="answer25" d="M-31.7-107.9l-7.9-4.6c-0.5-0.3-1-0.1-1,0.6c0,0.7,0.4,1.3,1,1.7l7.9,4.6
                      c0.5,0.3,1,0.1,1-0.6C-30.7-106.9-31.1-107.6-31.7-107.9"></path>
                    <path id="path-229" className="answer33" d="M-37.6-106.4l-7.9-4.6c-0.5-0.3-1-0.1-1,0.6c0,0.7,0.4,1.3,1,1.7l7.9,4.6
                      c0.5,0.3,1,0.1,1-0.6C-36.6-105.4-37-106-37.6-106.4"></path>
                    <path id="path-230" className="answer25" d="M-31.1-98.9l-7.9-4.6c-0.5-0.3-1-0.1-1,0.6c0,0.7,0.4,1.3,1,1.7l7.9,4.6
                      c0.5,0.3,1,0.1,1-0.6C-30.2-97.9-30.5-98.6-31.1-98.9"></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <path id="path-112-3" className="answer34" d="M890.1,184.3"></path>
      <g className="fly">
        <path id="path-116-3" className="answer25" d="M709,52.9c-11.3,0-20.5,9.2-20.5,20.5s9.2,20.5,20.5,20.5c11.3,0,20.5-9.2,20.5-20.5
        C729.4,62.1,720.3,52.9,709,52.9z M714.8,64.6c1-0.1,1.9,0.6,2.1,1.6c0,0.1,0,0.2,0,0.3c0.1,1.1-0.7,2-1.8,2.1c-0.1,0-0.1,0-0.2,0
        c-1,0-1.9-0.7-2-1.8c0-0.1,0-0.2,0-0.3c-0.1-1,0.7-1.9,1.6-1.9C714.7,64.6,714.8,64.6,714.8,64.6z M703,63.8c1.6,0,2.8,1.4,2.8,3
        c0,1.5-1.2,2.7-2.7,2.8c-1.6,0-2.9-1.3-2.9-2.9C700.2,65.1,701.4,63.9,703,63.8L703,63.8z M697.1,72.4c0-1.5,1.2-2.7,2.7-2.8
        c1.5,0,2.7,1.3,2.7,2.8c0,1.4-1.1,2.6-2.5,2.7C698.4,75.1,697.2,73.9,697.1,72.4C697.1,72.4,697.1,72.4,697.1,72.4z M700.9,81.5
        c-1.4-0.1-2.5-1.3-2.5-2.7c-0.1-1.4,1-2.5,2.4-2.6c0.1,0,0.1,0,0.2,0c1.3,0,2.5,1,2.5,2.4c0,0.1,0,0.2,0,0.3
        C703.5,80.2,702.4,81.4,700.9,81.5L700.9,81.5z M705.7,85.6c-1.4-0.2-2.4-1.3-2.3-2.7c0.1-1.4,1.2-2.4,2.6-2.4
        c1.3,0.1,2.3,1.2,2.3,2.6c0,0,0,0.1,0,0.1C708.2,84.5,707.1,85.6,705.7,85.6C705.7,85.6,705.7,85.6,705.7,85.6L705.7,85.6z
         M705.9,64.5c0-1.7,1.4-3.1,3.1-3.2c1.7,0.1,2.9,1.5,2.9,3.1c0.1,1.7-1.2,3.1-2.9,3.2C707.4,67.5,706,66.2,705.9,64.5z M714.5,83.2
        c-0.1,1.3-1.2,2.3-2.5,2.2c-1.3-0.1-2.3-1.2-2.2-2.5c0.1-1.3,1.2-2.3,2.5-2.2C713.6,80.8,714.5,81.9,714.5,83.2L714.5,83.2z
         M716.9,81.1c-1.2-0.1-2.2-1.2-2.1-2.4c0-1.2,1-2.2,2.2-2.2c0,0,0.1,0,0.1,0c1.2,0,2.2,1.1,2.1,2.3c0,0,0,0,0,0
        C719.2,80.1,718.2,81.1,716.9,81.1L716.9,81.1z M718.1,74.5c-1,0.1-2-0.6-2.1-1.7c0-0.1,0-0.2,0-0.3c-0.1-1.1,0.7-2.2,1.8-2.3
        c0.1,0,0.1,0,0.2,0c1.1-0.1,2.1,0.9,2.1,2.3c0,1.1-0.8,1.9-1.8,2C718.2,74.5,718.2,74.5,718.1,74.5z"></path>
      <path id="path-117-3" className="answer26" d="M912,318.8c-13,0-23.5,10.5-23.5,23.5s10.5,23.5,23.5,23.5s23.5-10.5,23.5-23.5
        C935.5,329.4,925,318.8,912,318.8z M912.7,356.6c-3.9,0.1-7.7-1.3-10.5-4c-2.7-2.7-4.2-6.3-4.1-10.1c-0.1-3.8,1.4-7.5,4.2-10.2
        c2.7-2.7,6.4-4.2,10.3-4.1c4.4-0.1,8.5,1.8,11.3,5.2l-3.8,4.3c-1.7-2.3-4.4-3.6-7.2-3.6c-2.2,0-4.3,0.8-5.9,2.3
        c-1.7,1.6-2.6,3.8-2.5,6.1c-0.1,2.3,0.7,4.5,2.3,6.1c1.5,1.5,3.5,2.4,5.6,2.3c2.9,0.1,5.6-1.3,7.4-3.6l3.9,4
        C921,354.7,917,356.6,912.7,356.6z M914.5,343.2v-1.6h2.2v-2.3h1.8v2.3h2.2v1.6h-2.2v2.3h-1.8v-2.3L914.5,343.2z M928.1,343.2h-2.2
        v2.3h-1.7v-2.3h-2.2v-1.6h2.2v-2.3h1.8v2.3h2.2L928.1,343.2L928.1,343.2z"></path>

      
      <g id="group-207-2" transform="translate(853 185)">
        <path id="path-231" className="answer10" d="M-10.6-96.4h0.9c0.1-0.5,0.2-0.9,0.2-1.2h-0.9L-10.6-96.4z"></path>
        <path id="path-232" className="answer26" d="M-23-120.2c-13,0-23.5,10.5-23.5,23.5S-36-73.2-23-73.2S0.5-83.7,0.5-96.7
          C0.5-109.6-10-120.2-23-120.2z M-22.3-82.4c-3.9,0.1-7.7-1.3-10.5-4c-2.7-2.7-4.2-6.3-4.1-10.1c-0.1-3.8,1.4-7.5,4.2-10.2
          c2.7-2.7,6.4-4.2,10.3-4.1c4.4-0.1,8.5,1.8,11.3,5.2l-3.8,4.3c-1.7-2.3-4.4-3.6-7.2-3.6c-2.2,0-4.3,0.8-5.9,2.3
          c-1.7,1.6-2.6,3.8-2.5,6.1c-0.1,2.3,0.7,4.5,2.3,6.1c1.5,1.5,3.5,2.4,5.6,2.3c2.9,0.1,5.6-1.3,7.4-3.6l3.9,4
          C-14-84.3-18-82.4-22.3-82.4z M-7-97.6h-1l-0.2,1.2h1.1L-7.3-95h-1.1l-0.3,1.5h-1.5l0.3-1.5h-0.9l-0.2,1.5h-1.5l0.3-1.5h-0.9
          l0.3-1.4h0.9l0.2-1.2h-1.1l0.3-1.4h1l0.3-1.6h1.5l-0.3,1.6h0.9l0.3-1.6h1.5L-7.8-99h1L-7-97.6z"></path>
      </g>
      <path id="path-119-3" className="answer26" d="M551.3,163.5c-14.7,0-26.7,11.9-26.7,26.7c0,14.7,11.9,26.7,26.7,26.7
        c14.7,0,26.7-11.9,26.7-26.7c0,0,0,0,0,0C578,175.5,566.1,163.5,551.3,163.5z M540,185.2l2.8,4l-0.5,2.2l-3.6-3L540,185.2z
         M556.1,205.9l-5.1-10.6l1.5-0.3c0.7,0.8,1.5,1.5,2.3,2.2c2.1,1.4,3.5,3.6,4.1,6C558.9,204.8,556.1,205.9,556.1,205.9L556.1,205.9z
         M550.3,194.5l-0.3-1.4l9.8-6.8l1,6L550.3,194.5z M556.2,184.1l-0.5-0.1c1,0.5,1.9,1.1,2.7,1.8l0.2,0.2l-9.9,6.8l0.4,1.7l0.2,0
        l0.2,0l2.3,4.9l1,1.9l-0.5,1.8c-6.5-1.3-8.1,0-8.1,0l1.4-3.3l0,0c-0.6-0.5-1.1-1.1-1.5-1.7l-2.3,1.1l-1.4-2l2.9-0.4
        c-0.1-0.1-0.1-0.3-0.2-0.4h-3.4l-0.9-2.6l3.8,1.2l-0.1-0.8l-3.9-1.3l0.2-3.2l3.6,2.3l0,0.3c0.1-1.2,0.4-2.3,0.8-3.4l-2.9-5.7
        l3.1-3.1l1.4,6.3v0c0.1-0.1,0.3-0.3,0.4-0.4l-0.6-7.1l4.5-1.9l-0.8,6.9l-0.2,0.1c0.2-0.1,0.4-0.2,0.7-0.3h-0.1l2.3-8h5.6l-4.3,7.5
        c0.2,0,0.3,0,0.5,0l5.5-7.5l5.8,2L556.2,184.1z"></path>
      <path id="path-120-3" className="answer26" d="M467.5,210.8c-11.6,0-21,9.4-21,21c0,11.6,9.4,21,21,21s21-9.4,21-21
        C488.5,220.2,479.1,210.8,467.5,210.8z M476.6,223.4l-0.2,1.9l-0.1,0.8h-14.4l0.4,3.8H476l-0.1,1l-0.9,9.9l-0.1,0.6l-7.7,2.1l0,0
        l-7.7-2.1l-0.5-5.9h3.8l0.3,3l4.2,1.1l0,0l4.2-1.1l0.4-4.9h-13l-0.9-10.4l-0.1-1h18.8L476.6,223.4z"></path>
      <path id="path-121-3" className="answer26 java" d="M616.7,0c-12.3,0-22.2,10-22.2,22.2s10,22.2,22.2,22.2s22.2-10,22.2-22.2l0,0
        C639,10,629,0,616.7,0z M622.5,21.6c3.2-1.1,5.7,3.3-1,5.1c0,0,0.1-0.1,0.1-0.1c5-2.6,2.7-5.1,1.1-4.8c-0.2,0-0.4,0.1-0.6,0.1
        C622.2,21.8,622.4,21.7,622.5,21.6z M618.6,18.8c1.5,2.1-1.8,3.8-1.8,3.8c0.9-0.6,1-1.9,0.4-2.7c0,0,0-0.1-0.1-0.1
        c-4.4-5,4.8-7.3,4.8-7.3C616.1,15.8,617.2,16.8,618.6,18.8L618.6,18.8z M612.8,15.5c1.9-2.7,6.9-4.1,5.8-8.5c0,0,2.8,2.8-2.6,7.1
        c-4.4,3.4-1,5.4,0,7.6C613.4,19.4,611.5,17.4,612.8,15.5L612.8,15.5z M608.7,22.7c1.3-0.7,2.8-1,4.2-1c0,0-4.9,1.2-1.7,1.6
        c2.1,0.2,4.3,0.2,6.4-0.1c2-0.2,4-0.5,4-0.5c-0.4,0.2-0.8,0.4-1.2,0.7C615.5,24.7,605.9,24,608.7,22.7L608.7,22.7z M613.1,28.5
        c1.8,0.3,3.7,0.2,5.6-0.2c0.5,0.3,1,0.5,1.5,0.7c-5.3,2.3-11.9-0.1-7.7-1.3C612.4,27.7,611.4,28.3,613.1,28.5L613.1,28.5z
         M611.7,24.8c0,0-1.1,0.9,0.6,1c2.4,0.3,4.8,0.2,7.2-0.3c0.3,0.3,0.7,0.5,1.1,0.7C614.2,28,607.2,26.3,611.7,24.8L611.7,24.8z
         M610.3,29.8c-0.3,0-0.6,0-0.9,0.1c-0.6,0.1-2.4,0.7-1.4,1.1c2.7,1.1,12.3,0.9,15.3,0c1.6-0.5,0.9-1.1,0.9-1.1c2.8,1.3-6,3.8-16.7,2
        C603.4,31.3,609.2,29,610.3,29.8L610.3,29.8z M613.4,33.7c-3.2-0.2-3.8-0.7-3.8-0.7c4.1,0.6,8.2,0.5,12.2-0.2c3.7-0.7,4-1.6,4-1.6
        C625.6,33.5,618.2,34,613.4,33.7L613.4,33.7z"></path>
      <path id="path-122-3" className="answer25" d="M878.5,149.9c-13.8,0-25,11.2-25,25s11.2,25,25,25s25-11.2,25-25l0,0
        C903.4,161.1,892.2,149.9,878.5,149.9z M881.1,162.3c-0.4,1.1-0.5,2.3-0.3,3.5c0.2,0.7,0.5,1.4,1,2.1c0.5,0.8,1.3,1.5,2.2,1.9
        c0.3,0.2,0.7,0.4,1.1,0.5c0.9,0.3,2.8,1,5.9-1.1c-0.8,1.2-2,2-3.3,2.5c-2.7,0.7-5.6-0.4-7.1-2.9c-0.2-0.3-0.5-1.1-0.6-1.3
        C879.4,165.7,879.8,163.7,881.1,162.3z M890.4,181.2c-1.9,3.9-5.9,6.3-10.2,6.2c-2,0-4-0.4-5.9-1.2c-3-1.4-5.5-3.7-7.1-6.7
        c-2-3.9-2.4-9.4,1.8-13.4c0.1,0-0.5,0.9-0.5,0.9l-0.2,0.4c-3,9.5,6.3,17.6,15.4,16.7c2.5-0.3,4.8-1.4,6.7-3.1L890.4,181.2z
         M881.5,179c-4.3-0.8-7.6-4.3-8.2-8.7c-0.3-3,0.7-4.7,2.4-6.8c-0.4,0.8-0.7,1.6-0.9,2.5c-0.6,6.4,5.1,10.9,10.2,11
        c2.3,0,4.5-0.9,6.1-2.5C889.9,177.6,886.3,179.7,881.5,179L881.5,179z"></path>
      <g id="group-208-2" transform="translate(552 183.43)">
        <path id="path-233" className="answer25" d="M-31.3-97.1h-1.6l-0.9,4.5h1.3c0.8,0.1,1.5-0.1,2.2-0.5c0.5-0.5,0.9-1.1,0.9-1.8
          c0.2-0.6,0.1-1.2-0.2-1.7C-30-97-30.7-97.2-31.3-97.1z"></path>
        <path id="path-234" className="answer25" d="M-9.6-97.1h-1.6l-0.9,4.5h1.3c0.8,0.1,1.5-0.1,2.2-0.5c0.5-0.5,0.9-1.1,0.9-1.8
          c0.2-0.6,0.1-1.2-0.2-1.7C-8.4-97-9-97.2-9.6-97.1z"></path>
        <path id="path-235" className="answer25" d="M-21.2-120.2c-14,0-25.3,11.3-25.3,25.3s11.3,25.3,25.3,25.3S4.1-80.9,4.1-94.9l0,0
          C4.1-108.8-7.3-120.2-21.2-120.2z M-26.7-93c-0.3,0.5-0.7,1-1.1,1.4c-0.5,0.5-1.1,0.9-1.8,1.1c-0.8,0.2-1.7,0.3-2.5,0.3h-2l-0.6,3
          c0,0.1-0.1,0.2-0.3,0.2h-2.6c-0.1,0-0.1,0-0.2-0.1c-0.1-0.1-0.1-0.1,0-0.2l2.3-11.9c0-0.1,0.1-0.2,0.3-0.2h5
          c1.3-0.1,2.5,0.4,3.5,1.3c0.8,1,1,2.3,0.7,3.5C-26.2-94.1-26.4-93.5-26.7-93z M-16.9-90.2h-2.6c-0.1,0-0.1,0-0.2-0.1
          c-0.1-0.1-0.1-0.1,0-0.2l1-5.3c0.1-0.5,0.1-0.8-0.1-1c-0.1-0.1-0.3-0.3-1.1-0.3h-1.9l-1.3,6.6c0,0.1-0.1,0.2-0.3,0.2h-2.6
          c-0.1,0-0.1,0-0.2-0.1c-0.1-0.1-0.1-0.1,0-0.2l2.3-11.9c0-0.1,0.1-0.2,0.3-0.2h2.6c0.1,0,0.1,0,0.2,0.1c0.1,0.1,0.1,0.1,0,0.2
          l-0.5,2.9h2c1.1-0.1,2.2,0.2,3.2,0.8c0.7,0.7,0.9,1.8,0.5,2.7l-1.1,5.6C-16.7-90.3-16.8-90.2-16.9-90.2L-16.9-90.2z M-4.5-94.7
          c-0.1,0.6-0.3,1.2-0.6,1.7c-0.3,0.5-0.7,1-1.1,1.4c-0.5,0.5-1.1,0.9-1.8,1.1c-0.8,0.2-1.6,0.3-2.5,0.3h-2l-0.6,3
          c0,0.1-0.1,0.2-0.3,0.2h-2.6c-0.1,0-0.1,0-0.2-0.1c-0.1-0.1-0.1-0.1,0-0.2l2.3-11.9c0-0.1,0.1-0.2,0.3-0.2h5
          c1.3-0.1,2.5,0.4,3.5,1.3C-4.4-97.2-4.1-95.9-4.5-94.7L-4.5-94.7z"></path>
      </g>
      <path id="path-123-3" className="answer25" d="M945,419.9c-13.5,0-24.5,11-24.5,24.5s11,24.5,24.5,24.5c13.5,0,24.5-11,24.5-24.5l0,0
        C969.4,430.9,958.5,419.9,945,419.9z M955.5,432.3c0.5,0.4,1,0.7,1.5,1.1c-0.1,0.2-0.2,0.3-0.3,0.4c-0.4-0.2-0.8-0.4-1.1-0.6
        c-0.1,0-0.1-0.2-0.2-0.4C955.5,432.7,955.5,432.5,955.5,432.3z M950.8,431c0.1,0,0.2,0.2,0.2,0.2c0,0.3,0.1,0.6,0.2,1h-1.9
        c-0.1-0.4-0.2-0.8-0.3-1.3C949.6,430.9,950.2,430.9,950.8,431z M944.8,431.7c0.1,0,0.2,0,0.3,0c0.3,0.4,0.7,0.7,1,1.1
        c-0.5,0.3-1,0.6-1.5,0.9c-0.4-0.4-0.7-0.9-1.1-1.4C943.9,432.2,944.3,431.9,944.8,431.7L944.8,431.7z M939.7,435.7
        c0.6,0.4,1.1,0.7,1.6,1.1c-0.4,0.5-0.9,1-1.3,1.5c-0.6-0.4-1.1-0.7-1.7-1.1C938.8,436.7,939.3,436.2,939.7,435.7z M935.5,442
        c0.7,0.3,1.4,0.6,2.2,0.9c-0.2,0.7-0.4,1.2-0.7,1.8c-0.7-0.2-1.5-0.4-2.2-0.7C935.1,443.3,935.3,442.7,935.5,442z M935.2,452.6
        c-0.8-0.1-1.5-0.2-2.3-0.3c0.1-0.8,0.2-1.5,0.3-2.4c0.8,0.1,1.6,0.1,2.4,0.2C935.5,450.9,935.3,451.7,935.2,452.6L935.2,452.6z
         M948.6,443c0,0,0.1,0.2,0.1,0.2c-0.1,0.4-0.2,0.9-0.3,1.4c-0.6-0.5-1.1-0.9-1.6-1.4l0.6-1.2C947.8,442.4,948.2,442.7,948.6,443
        L948.6,443z M948.3,439c0.3-0.3,0.7-0.6,1-0.9c0.3,0.4,0.6,0.9,0.9,1.3c0,0.1,0,0.2,0,0.2c-0.1,0.3-0.3,0.6-0.5,0.9
        C949.1,440,948.7,439.5,948.3,439L948.3,439z M948.4,447.3c0.1,0.1,0.1,0.1,0.1,0.2c0,0.4,0,0.9,0.1,1.4c-0.7-0.3-1.2-0.5-1.8-0.8
        c-0.1,0-0.1-0.1-0.2-0.2c0-0.5,0-1,0.1-1.5C947.3,446.7,947.9,446.9,948.4,447.3L948.4,447.3z M949.2,452.2c0.1,0,0.2,0.1,0.2,0.2
        c0.3,0.5,0.5,1.1,0.8,1.7c-0.8,0-1.6-0.1-2.3-0.1c-0.1,0-0.2-0.1-0.2-0.2c-0.2-0.5-0.3-1-0.4-1.5
        C947.9,452.2,948.5,452.2,949.2,452.2L949.2,452.2z M951.8,437.7l-0.6-1.1c0.4-0.1,0.9-0.2,1.3-0.3
        C952.7,437.2,952.9,436.9,951.8,437.7L951.8,437.7z M956.5,436.8l-1.2-0.1V436c0.4,0.1,0.8,0.2,1.2,0.3V436.8z M958.6,435.9
        c-0.2,0.2-0.4,0.5-0.6,0.7c0,0-0.1,0-0.1,0c-1.3-1.2-3.3-1.6-5-1c-3.3,1-5.9,3.8-6.6,7.3c-0.4,1.9-0.5,3.8-0.2,5.7
        c0.3,2.6,1,5.1,2,7.6c0,0,0,0.1,0,0.2h-12.5c0-0.9,0-1.8,0-2.6c0.5-4.7,1.9-9.3,4.3-13.4c1-1.9,2.3-3.6,3.8-5.1
        c1.9-1.7,4.3-2.7,6.9-2.8c1.6,0,3.2,0.3,4.6,1.1c1.2,0.6,2.3,1.4,3.3,2.3L958.6,435.9z"></path>
      <g id="group-209-2" transform="translate(1247 674.529)">
        <g className="python">
<ellipse id="ellipse-3" className="answer26" cx="-305.6" cy="-440.7" rx="1.2" ry="1.3"></ellipse>
        <ellipse id="ellipse-4" className="answer26" cx="-298.3" cy="-420.1" rx="1.2" ry="1.3"></ellipse>
        <path id="path-236" className="answer26" d="M-302.3-460.2c-16.7,0-30.2,13.5-30.2,30.2c0,16.7,13.5,30.2,30.2,30.2
          c16.7,0,30.2-13.5,30.2-30.2C-272-446.6-285.6-460.2-302.3-460.2z M-309.2-423.8h-2.2c-1.9,0-3-1.4-3.4-3.3c-0.6-2.2-0.6-4.4,0-6.6
          c0.4-1.9,2.1-3.3,4.1-3.3h8.9v-0.8h-6.6v-2.5c0-1.9,0.5-2.9,3.2-3.4c1-0.2,2.1-0.2,3.1-0.3c1.1,0,2.3,0.1,3.4,0.3
          c1.8,0.2,3.1,1.6,3.2,3.4v6.2c0,1.8-1.4,3.3-3.2,3.3h-6.5c-2.2,0-4,1.8-4.1,4L-309.2-423.8L-309.2-423.8z M-288.9-427.1
          c-0.7,2-1.4,3.3-3.2,3.3h-9.8v0.8h6.5v2.5c0,1.9-1.6,2.8-3.2,3.3c-2.1,0.7-4.4,0.7-6.5,0c-1.7-0.5-3.2-1.6-3.2-3.3v-6.1
          c0-1.8,1.4-3.2,3.2-3.3h6.5c2.3,0,4.1-1.9,4.1-4.1v-2.9h2.4c1.9,0,2.8,1.4,3.2,3.3C-288.3-431.6-288.3-429.3-288.9-427.1z"></path>                        
        </g>
      </g>
      </g>
      <g id="group-200-3" transform="translate(70 329.161)">
        <g id="group-199-3" transform="translate(827.722 187.882)">
          <path id="path-237" className="answer25" d="M-14.5-104.3c-3.8-2.1-7.8-3.8-11.9-5c-4.4-1.4-8.6-3.2-12.6-5.3c-2.7-1.6-5.2-3.4-7.4-5.5
            l-0.1,35.1c6.1,1.6,11.9,4.1,17.2,7.4c3.3,1.9,6.6,4,10,6.1c3.3,2.1,6.7,4.3,10.3,6.2c3.1,1.9,6.4,3.6,9.8,5
            c-3.2-5.9-2.2-11.8-1.4-17c0.5-2.3,0.7-4.7,0.7-7c-0.1-1.7,0-3.5,0.2-5.2c0.1-0.9,0.1-0.9-1-2.1c-0.8-0.8-2-2.1-3.8-4.3
            C-7.4-99.3-10.8-102.1-14.5-104.3"></path>
          <g id="XMLID_32_">
            <defs>
              <path id="XMLID_33_" d="M-14.5-104.3c-3.8-2.1-7.8-3.8-11.9-5c-4.4-1.4-8.6-3.2-12.6-5.3c-2.7-1.6-5.2-3.4-7.4-5.5l-0.1,35.1
                c6.1,1.6,11.9,4.1,17.2,7.4c3.3,1.9,6.6,4,10,6.1c3.3,2.1,6.7,4.3,10.3,6.2c3.1,1.9,6.4,3.6,9.8,5c-3.2-5.9-2.2-11.8-1.4-17
                c0.5-2.3,0.7-4.7,0.7-7c-0.1-1.7,0-3.5,0.2-5.2c0.1-0.9,0.1-0.9-1-2.1c-0.8-0.8-2-2.1-3.8-4.3C-7.4-99.3-10.8-102.1-14.5-104.3"></path>
            </defs>
            <clipPath id="XMLID_147_">
              <use xlinkHref="#XMLID_33_" style={{overflow:'visible'}}></use>
            </clipPath>
            <g id="group-mask-10-3" transform="translate(0 0)" className="answer35">
              <path id="path-113-3" className="answer30" d="M4.4-75.5C3.6-77.2-35-95.6-56.5-104.6s14.9,57.3,14.9,57.3L8.6-37.2L4.4-75.5z"></path>
            </g>
          </g>
        </g>
        <g id="group-143-3" transform="translate(827.023 187.183)">
          <line id="line-30-4" className="answer19" x1="-46.1" y1="-57" x2="-46.1" y2="-85.9"></line>
          <g id="group-134-4" transform="translate(0 0)">
            <path id="path-238" className="answer19" d="M-13.8-103.9c-3.9-2.1-7.9-3.8-12.1-5.1c-4.5-1.4-8.8-3.2-12.9-5.5c-2.7-1.6-5.3-3.5-7.6-5.7
              l-0.1,35.9c6.2,1.7,12.1,4.2,17.6,7.6c3.4,2,6.8,4.1,10.2,6.3c3.4,2.2,6.9,4.4,10.5,6.4c3.2,1.9,6.6,3.6,10,5.1
              c-3.3-6.1-2.3-12-1.4-17.4c0.5-2.4,0.7-4.8,0.7-7.2C1-85.2,1-87,1.3-88.8c0.1-0.9,0.1-0.9-1-2.2c-0.8-0.8-2.1-2.2-3.9-4.4
              C-6.5-98.8-9.9-101.7-13.8-103.9"></path>
          </g>
        </g>
      </g>
      <g id="group-200-4" transform="translate(-481 81.161)">
        <g id="group-199-4" transform="translate(827.722 187.882)">
          <path id="path-239" className="answer25" d="M-14.5-104.3c-3.8-2.1-7.8-3.8-11.9-5c-4.4-1.4-8.6-3.2-12.6-5.3c-2.7-1.6-5.2-3.4-7.4-5.5
            l-0.1,35.1c6.1,1.6,11.9,4.1,17.2,7.4c3.3,1.9,6.6,4,10,6.1c3.3,2.1,6.7,4.3,10.3,6.2c3.1,1.9,6.4,3.6,9.8,5
            c-3.2-5.9-2.2-11.8-1.4-17c0.5-2.3,0.7-4.7,0.7-7c-0.1-1.7,0-3.5,0.2-5.2c0.1-0.9,0.1-0.9-1-2.1c-0.8-0.8-2-2.1-3.8-4.3
            C-7.4-99.3-10.8-102.1-14.5-104.3"></path>
          <g id="XMLID_30_">
            <defs>
              <path id="XMLID_31_" d="M-14.5-104.3c-3.8-2.1-7.8-3.8-11.9-5c-4.4-1.4-8.6-3.2-12.6-5.3c-2.7-1.6-5.2-3.4-7.4-5.5l-0.1,35.1
                c6.1,1.6,11.9,4.1,17.2,7.4c3.3,1.9,6.6,4,10,6.1c3.3,2.1,6.7,4.3,10.3,6.2c3.1,1.9,6.4,3.6,9.8,5c-3.2-5.9-2.2-11.8-1.4-17
                c0.5-2.3,0.7-4.7,0.7-7c-0.1-1.7,0-3.5,0.2-5.2c0.1-0.9,0.1-0.9-1-2.1c-0.8-0.8-2-2.1-3.8-4.3C-7.4-99.3-10.8-102.1-14.5-104.3"></path>
            </defs>
            <clipPath id="XMLID_148_">
              <use xlinkHref="#XMLID_31_" style={{overflow:'visible'}}></use>
            </clipPath>
            <g id="group-mask-10-4" transform="translate(0 0)" className="answer36">
              <g id="group-20-3" transform="translate(-652.318 -1667.388) rotate(7)">
                <rect id="rectangle-9-4" x="782.2" y="1461.4" className="answer26" width="22" height="63.7"></rect>
                <rect id="rectangle-9-5" x="804.2" y="1461.4" className="answer7" width="22" height="63.7"></rect>
                <rect id="rectangle-9-6" x="826.1" y="1461.4" className="answer30" width="22" height="63.7"></rect>
              </g>
            </g>
          </g>
        </g>
        <g id="group-143-4" transform="translate(827.023 187.183)">
          <line id="line-30-5" className="answer19" x1="-46.1" y1="-57" x2="-46.1" y2="-85.9"></line>
          <g id="group-134-5" transform="translate(0 0)">
            <path id="path-240" className="answer19" d="M-13.8-103.9c-3.9-2.1-7.9-3.8-12.1-5.1c-4.5-1.4-8.8-3.2-12.9-5.5c-2.7-1.6-5.3-3.5-7.6-5.7
              l-0.1,35.9c6.2,1.7,12.1,4.2,17.6,7.6c3.4,2,6.8,4.1,10.2,6.3c3.4,2.2,6.9,4.4,10.5,6.4c3.2,1.9,6.6,3.6,10,5.1
              c-3.3-6.1-2.3-12-1.4-17.4c0.5-2.4,0.7-4.8,0.7-7.2C1-85.2,1-87,1.3-88.8c0.1-0.9,0.1-0.9-1-2.2c-0.8-0.8-2.1-2.2-3.9-4.4
              C-6.5-98.8-9.9-101.7-13.8-103.9"></path>
          </g>
        </g>
      </g>
      <g id="group-242-2">
        <g id="ellipse-19-7" transform="translate(508 617)">
          <ellipse id="XMLID_29_" className="answer30" cx="-38.5" cy="-111.2" rx="8" ry="9"></ellipse>
          <ellipse id="XMLID_28_" className="answer23" cx="-38.5" cy="-111.2" rx="7" ry="8"></ellipse>
        </g>
        <g id="group-64-5" transform="translate(490.588 585.354)">
          <g id="group-143-5" transform="translate(0)">
            <path id="path-241" className="answer22" d="M31.4-97.2l-77.3,44.7L89.4,25.6l77.3-44.7L31.4-97.2z"></path>
            <line id="line-48" className="answer19" x1="89.4" y1="25.6" x2="89.4" y2="104.2"></line>
            <path id="line-49" className="answer24" d="M167.6-19.1v80.3"></path>
            <path id="path-242" className="answer20" d="M32.7-29.6"></path>
            <path id="path-243" className="answer21" d="M55,55.7c0,0,0.1-29.2,0-34.7c-0.1-4.9-4-8.2-4.8-8.8c-0.2-0.3-0.5-0.5-0.8-0.7
              C46.1,9.3,14.8-7.7,14.8-7.7l-1.2,45.3l28.1-17l8.2,3.9l-8.6,19.3l1.9,23.6c0,0-0.7,5.8,4.7,5.8l23.1-13
              C71,60.1,73.2,47.5,55,55.7z"></path>
            <path id="path-244" className="answer21" d="M27.8-45.8l34.1,18.8l17.2-10.6L46.6-56.3L27.8-45.8z"></path>
            <g id="group-144" transform="translate(95.607)">
              <g id="group-145">
                <path id="path-245" className="answer21" d="M-27.2-71.9v14.1l15.2,9.1v-14.4"></path>
                <path id="path-246" className="answer24" d="M-46.5-75.5v-44.7l58,33.4v44.7L-46.5-75.5z"></path>
              </g>
            </g>
            <line id="line-50" className="answer19" x1="11.5" y1="60.1" x2="11.5" y2="104.7"></line>
            <line id="line-51" className="answer19" x1="-46.4" y1="26.5" x2="-46.5" y2="71.3"></line>
            <line id="line-52" className="answer23" x1="50.2" y1="38.2" x2="50.2" y2="82.3"></line>
            <path id="path-247" className="answer21" d="M60.4-22.5c0,0,1.3,2.8,8.5-2.2c7.2-5,0.9-8.4,0.9-8.4s0.5,4.5-2.4,5.7
              C64.3-26.3,60.4-22.5,60.4-22.5z"></path>
            <path id="path-248" className="answer21" d="M0.2-51.3c11.5-0.9,14.7-10.5,14.7-22.2c0-12.3-4.2-22.3-16.5-22.3
              c-12.3,0-22.3,10-22.3,22.3c0,6.1,2.5,11.9,6.8,16.1c0.5,0.4,1,0.9,1.5,1.3c-1,0.3-2.1,0.5-3.2,0.6c-7.5,0-13.6,6.1-13.6,13.6
              v66.2c0,7.5,6.1,13.6,13.6,13.6h29c7.5,0,9.1-6.1,9.1-13.6V-5.1c6.3,2.5,14-1.6,14-1.6L58-21.6c1.2,0,2.3-0.4,3.2-1.1l5.1-3.9
              c2.5-1.9,4.4-5,2.6-7.6c-1.9-2.5-4.3-2-6.8-0.1L54.2-32c-0.8,0.7-1.5,1.5-1.9,2.5h-0.1L31-19.1c-7.4,3.1-10.6-2.2-11.9-6.8
              c-0.1-2.1-0.5-4.1-1-6.1l0,0c-1.4-4.8-3.9-9-8-10.8l-7.3-4.8C3-47.9-1.6-50.5,0.2-51.3z"></path>
            <g id="group-146" transform="translate(0.114 67.869)">
              <path id="path-249" className="answer27" d="M11.5-8l38.7-21.7v-12.6L12.2-20"></path>
              <path id="path-250" className="answer22" d="M-46.5-41.4v-78.8l13.5,7.6l46.2,26.3L11.5-8L-46.5-41.4z"></path>
            </g>
            <path id="path-251" className="answer25" d="M17.7,6.7c0,0,1.1,7.1,7.5,5.8s-5.7,17.4-5.7,17.4l-3.6-5.5L17.7,6.7z"></path>
            <path id="path-252" className="answer25" d="M13.6,36.4l5.5-3.2l2.2-6.9h-5.9"></path>
          </g>
          <path id="path-38-5" className="answer26" d="M-10.5-57.7c10.3-4.4,19.3-7.1,15.5-9.1s1.5-12,1.5-12s11.6-0.1,7.4-4.2s6.6-7.8,6.6-7.8
            s-4.3-10.5-17.6-14.4s-17.7,7.3-24.9,9.2c-7.2,2-3.3,22.8-3.3,22.8S-20.8-53.2-10.5-57.7z"></path>
          <path id="path-39-11" className="answer23" d="M-17.7-55.8c0,0,7.2,9,18.6,6.7"></path>
          <path id="path-42-11" className="answer26" d="M54.1,56.1l-0.8,7.3l4.1-8.8L54.1,56.1z"></path>
          <path id="path-43-11" className="answer26" d="M19.3-4.8c0,0-0.1,15.5,1,16.3s13.5-7.1,15.6-6.9S19.3-4.8,19.3-4.8z"></path>
          <g id="group-251-2">
            <g id="group-147" transform="translate(100.84 21.821)">
              <g id="group-148">
                <g id="group-149">
                  <path id="path-253" className="answer26" d="M-40.2-111.4c-0.8-0.5-1.4-1.3-1.8-2.1l3.5,2C-38.9-111.1-39.7-111-40.2-111.4
                     M-41.9-115.5c-0.1-0.1-0.2-0.1-0.3-0.3c-0.3-0.3-0.5-0.8-0.3-1c0.1-0.2,0.3-0.2,0.5-0.1c0,0,0,0,0.1,0l0.3,0.3
                    c0.3,0.3,0.4,0.7,0.3,1C-41.6-115.4-41.8-115.4-41.9-115.5 M-38.5-113.5c-0.1-0.1-0.2-0.1-0.3-0.3c-0.3-0.3-0.4-0.7-0.3-1
                    c0.1-0.2,0.3-0.2,0.5-0.1c0,0,0,0,0.1,0c0.1,0.1,0.2,0.1,0.3,0.3c0.3,0.3,0.4,0.7,0.3,1C-38.1-113.4-38.3-113.4-38.5-113.5
                     M-40.3-119.5c-0.4-0.3-0.9-0.5-1.4-0.6c-2.4-0.5-3.7,1.8-2.9,5c0.4,1.3,1,2.6,1.8,3.7c-2-0.3-3.4,1-3.6,3.4l12.4,7.2
                    c-0.3-2.9-1.6-5.5-3.6-7.5c1.7-0.3,2.5-2.3,1.8-5C-36.5-115.9-38.1-118.1-40.3-119.5"></path>
                </g>
              </g>
            </g>
            <line id="line-28-5" className="answer23" x1="71.4" y1="-106" x2="71.4" y2="-63"></line>
            <g id="group-126-5" transform="translate(121.412 26.688)">
              <g id="group-150">
                <g id="group-151" transform="translate(0 1.699)">
                  <g id="group-152">
                    <g id="group-153">
                      <path id="path-254" className="answer37" d="M-29.6-115.2l-13.5-7.8c-1-0.6-1.9-0.1-1.9,1.1c0.1,1.3,0.7,2.5,1.8,3.3l13.5,7.8
                        c1,0.6,1.9,0.1,1.9-1.1C-27.8-113.2-28.5-114.4-29.6-115.2"></path>
                      <path id="path-255" className="answer26" d="M-21.1-102.4l-13.5-7.8c-0.9-0.5-1.7-0.1-1.7,1c0.1,1.2,0.7,2.3,1.7,2.9l13.5,7.8
                        c0.9,0.5,1.7,0.1,1.7-1C-19.5-100.7-20.1-101.8-21.1-102.4"></path>
                      <path id="path-256" className="answer38" d="M-31.1-99.8l-13.5-7.8c-0.9-0.5-1.7-0.1-1.7,1c0.1,1.2,0.7,2.3,1.7,2.9l13.5,7.8
                        c0.9,0.5,1.7,0.1,1.7-1C-29.5-98.1-30.2-99.2-31.1-99.8"></path>
                      <path id="path-257" className="answer26" d="M-20.2-87.2l-13.5-7.8c-0.9-0.5-1.7-0.1-1.7,1c0.1,1.2,0.7,2.3,1.7,2.9l13.5,7.8
                        c0.9,0.5,1.7,0.1,1.7-1C-18.6-85.4-19.2-86.5-20.2-87.2"></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <path id="path-44-11" className="answer26" d="M503.4,605.1l-1.2,18.6l29.6-17.6l9.1,4.3v13.2l-9.1,5.8l2.2,19.4l11.8-5.4v-37.3l-2.1-6.3
          l-20.8-13.2l-13.9,4.1"></path>
        <path id="path-45-11" className="answer23" d="M500.3,509c0,0-4.5,2-2.2,7.2"></path>
        <line id="line-16-5" className="answer23" x1="536" y1="558.3" x2="545" y2="565.3"></line>
        <g id="group-250-2">
          <g id="ellipse-19-8" transform="translate(538 624)">
            <ellipse id="XMLID_23_" className="answer30" cx="-38.5" cy="-111.2" rx="8" ry="9"></ellipse>
            <ellipse id="XMLID_22_" className="answer23" cx="-38.5" cy="-111.2" rx="7" ry="8"></ellipse>
          </g>
          <path id="path-85-5" className="answer31" d="M473.4,490.3c-15.6,4.5,4.7-6.1,4.7-6.1s16.1-5,23,21.6s-6.8,0.7-6.7,2.7
            S489,485.8,473.4,490.3z"></path>
        </g>
        <g id="group-154" transform="translate(633 676)">
          <path id="path-258" className="answer25" d="M-36.2-100.1c-0.3-0.2-0.3-0.5,0.1-0.8c0.4-0.2,0.9-0.3,1.3-0.1c0.3,0.2,0.3,0.5-0.1,0.8
            C-35.3-99.9-35.8-99.8-36.2-100.1 M-40.5-105.2c-0.3-0.1-0.5-0.4-0.4-0.8c0.1-0.2,0.2-0.4,0.4-0.4l20.4-11.8
            c0.7-0.3,1.4-0.3,2.1,0l13,7.5c0.3,0.1,0.5,0.4,0.4,0.8c-0.1,0.2-0.2,0.4-0.4,0.4l-20.3,11.8c-0.7,0.3-1.4,0.3-2.1,0L-40.5-105.2
             M-4.1-110.8l-13.7-7.9c-0.7-0.4-1.5-0.4-2.2,0l-24,13.9c-0.6,0.4-0.6,0.9,0,1.3l13.7,7.9c0.7,0.4,1.5,0.4,2.2,0l24-13.9
            C-3.4-109.9-3.5-110.5-4.1-110.8"></path>
          <path id="path-259" className="answer10" d="M-44-103.5c-0.6-0.4-0.6-0.9,0-1.3l24-13.9c0.7-0.4,1.5-0.4,2.2,0l13.7,7.9
            c0.6,0.4,0.6,0.9,0,1.3l-24,13.9c-0.7,0.4-1.5,0.4-2.2,0L-44-103.5 M-3.4-111.2l-13.7-7.9c-1.1-0.6-2.5-0.6-3.6,0l-24,13.9
            c-1,0.6-1,1.5,0,2.1l13.7,7.9c1.1,0.6,2.5,0.6,3.6,0l24-13.9C-2.4-109.7-2.4-110.7-3.4-111.2"></path>
          <path id="path-260" className="answer10" d="M-34.8-100.9c-0.4-0.2-0.9-0.2-1.3,0.1c-0.2,0.1-0.4,0.3-0.3,0.5c0,0.1,0.1,0.2,0.2,0.3
            c0.4,0.2,0.9,0.2,1.3-0.1C-34.5-100.4-34.4-100.7-34.8-100.9"></path>
          <path id="path-261" className="answer26" d="M-5.3-110.5l-13-7.5c-0.4-0.2-1-0.2-1.4,0l-20.4,11.8c-0.2,0.1-0.3,0.3-0.3,0.5
            c0,0.1,0.1,0.2,0.3,0.3l13,7.5c0.4,0.2,1,0.2,1.4,0l20.4-11.8c0.2-0.1,0.3-0.3,0.3-0.5C-5.1-110.3-5.2-110.4-5.3-110.5"></path>
          <path id="path-262" className="answer10" d="M-40.1-105.4c-0.2-0.1-0.3-0.3-0.3-0.5c0-0.1,0.1-0.2,0.3-0.3l20.4-11.8c0.4-0.2,1-0.2,1.4,0
            l13,7.5c0.2,0.1,0.3,0.3,0.3,0.5c0,0.1-0.1,0.2-0.3,0.3l-20.4,11.8c-0.4,0.2-1,0.2-1.4,0L-40.1-105.4 M-5-110.7l-13-7.5
            c-0.7-0.3-1.4-0.3-2.1,0l-20.4,11.8c-0.3,0.1-0.5,0.4-0.4,0.8c0.1,0.2,0.2,0.4,0.4,0.4l13,7.5c0.7,0.3,1.4,0.3,2.1,0L-5-109.5
            c0.3-0.1,0.5-0.4,0.4-0.8C-4.6-110.4-4.8-110.6-5-110.7"></path>
        </g>
      </g>
      </svg>
    )
}
export default LeftImage;