import React from 'react'
import './style.scss'
const LastwordMobileSvg = () =>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="5121.624 1898.541 267.173 134.552">
                
                    <g id="Group_358" data-name="Group 358" transform="translate(5064 1471)">
                      <line id="line-19" className="last-word-m-1" y1="55.501" transform="translate(190.726 467.863)"></line>
                      <path id="path" className="last-word-m-2" d="M66.844,249.081l66.038-37.855L67.116,171.7,1,210.992Z" transform="translate(57.683 312.436)"></path>
                      <line id="line-18" className="last-word-m-1" y1="55.501" transform="translate(124.483 428.476)"></line>
                      <line id="line-19-2" data-name="line-19" className="last-word-m-1" y1="55.501" transform="translate(58.239 467.863)"></line>
                      <line id="line-20" className="last-word-m-1" y1="40.104" x2="66.601" transform="translate(57.881 428.476)"></line>
                      <line id="line-21" className="last-word-m-1" x1="67.675" y1="39.388" transform="translate(123.767 428.476)"></line>
                      <path id="path-63" className="last-word-m-2" d="M169.5,12408.076v-55.306l66.081-39.2,66.7,39.2v55.306l-66.7-39.306Z" transform="translate(-111.261 -11884.712)"></path>
                      <path id="path-2" data-name="path" className="last-word-m-3" d="M293.093,157.6,268.9,171.582l42.338,24.442,24.193-13.982Z" transform="translate(-174.78 318.79)"></path>
                      <line id="line" className="last-word-m-2" y2="24.62" transform="translate(136.458 514.815)"></line>
                      <path id="line-2" data-name="line" className="last-word-m-2" d="M0,0V25.118" transform="translate(160.953 500.832)"></path>
                      <path id="path-3" data-name="path" className="last-word-m-4" d="M338.1,217.1" transform="translate(-219.36 280.459)"></path>
                      <path id="path-4" data-name="path" className="last-word-m-2" d="M334.07,256.162s.036-9.153,0-10.86a3.8,3.8,0,0,0-1.494-2.74,1.551,1.551,0,0,0-.249-.213c-1.032-.712-10.851-6.048-10.851-6.048l-.364,14.182,8.8-5.31,2.577,1.206-2.7,6.027.58,7.376s-.213,1.814,1.459,1.814l7.233-4.077S339.763,253.6,334.07,256.162Z" transform="translate(-208.357 268.09)"></path>
                      <line id="line-3" data-name="line" className="last-word-m-2" y2="13.947" transform="translate(112.087 525.631)"></line>
                      <line id="line-4" data-name="line" className="last-word-m-2" x1="0.036" y2="14.018" transform="translate(93.942 515.099)"></line>
                      <line id="line-5" data-name="line" className="last-word-m-2" y2="13.804" transform="translate(124.219 518.764)"></line>
                      <path id="path-5" data-name="path" className="last-word-m-5" d="M400.27,267.8l13.419,7.461,8.78-5.069V260.057L409.3,252.78v10.054Z" transform="translate(-283.391 223.588)"></path>
                      <line id="line-6" data-name="line" className="last-word-m-4" x2="13.171" y2="7.363" transform="translate(125.908 486.422)"></line>
                      <path id="path-6" data-name="path" className="last-word-m-6" d="M417.27,300.023l4.39-2.453,10.446,5.849-4.326,2.477Z" transform="translate(-296.001 190.365)"></path>
                      <path id="path-7" data-name="path" className="last-word-m-2" d="M291.011,172.811c3.593-.285,4.724-3.273,4.724-6.938,0-3.842-1.45-6.973-5.293-6.973a6.97,6.97,0,0,0-4.839,11.99,4.507,4.507,0,0,0,.463.391,5.9,5.9,0,0,1-1,.178,4.282,4.282,0,0,0-4.269,4.269v20.707a4.282,4.282,0,0,0,4.269,4.269h9.073c2.348,0,2.846-1.921,2.846-4.269v-9.179c1.957.783,4.376-.5,4.376-.5l7.721-4.661a1.7,1.7,0,0,0,1-.356l1.6-1.21c.783-.6,1.388-1.565.818-2.384-.6-.783-1.352-.64-2.135-.036l-2.455.747a2.117,2.117,0,0,0-.6.783h-.036l-6.618,3.238c-2.313.961-3.309-.676-3.736-2.135a8.678,8.678,0,0,0-.32-1.921h0a4.977,4.977,0,0,0-2.491-3.38l-2.277-1.494C291.865,173.879,290.442,173.06,291.011,172.811Z" transform="translate(-182.446 317.953)"></path>
                      <path id="path-8" data-name="path" className="last-word-m-3" d="M319.5,276.345l12.1-6.8V265.6l-11.883,6.973" transform="translate(-207.378 249.215)"></path>
                      <path id="path-9" data-name="path" className="last-word-m-3" d="M268.5,221.756V197.1l4.234,2.384,14.445,8.219-.534,24.513Z" transform="translate(-174.523 293.344)"></path>
                      <path id="path-10" data-name="path" className="last-word-m-7" d="M325.122,249s.346,2.232,2.338,1.805-1.769,5.453-1.769,5.453l-1.13-1.725Z" transform="translate(-211.095 259.909)"></path>
                      <path id="path-44" className="last-word-m-8" d="M54.089,95.182l-.358,5.635,8.978-5.331,2.749,1.32v4.525l-2.749,1.246.6,6.122,3.639-2.036V95.487l-.629-1.9-6.309-4.014L55.8,90.807" transform="translate(58.745 417.713)"></path>
                      <path id="path-38" className="last-word-m-8" d="M530.162,8198.88c3.212-1.426,6.028-2.285,4.836-2.932s.457-3.845.457-3.845,3.618-.048,2.322-1.335,2.1-2.524,2.1-2.524a8.881,8.881,0,0,0-5.537-4.61c-4.142-1.234-5.536,2.328-7.787,2.961s-1.04,7.332-1.04,7.332S526.95,8200.306,530.162,8198.88Z" transform="translate(-424.957 -7709.724)"></path>
                      <path id="path-39" className="last-word-m-9" d="M534.259,8236.49a6.219,6.219,0,0,0,5.824,2.094" transform="translate(-431.297 -7747.127)"></path>
                      <path id="path-43" className="last-word-m-8" d="M572.594,8288.334s-.038,4.858.316,5.1,4.217-2.217,4.894-2.162S572.594,8288.334,572.594,8288.334Z" transform="translate(-458.073 -7783.039)"></path>
                      <path id="path-45" className="last-word-m-9" d="M151.912,8102.924a1.6,1.6,0,0,0-.689,2.239" transform="translate(-40.124 -7619.833)"></path>
                      <path id="path-11" data-name="path" className="last-word-m-2" d="M66.844,249.081l66.038-37.855L67.116,171.7,1,210.992Z" transform="translate(190.786 311.934)"></path>
                      <line id="line-18-2" data-name="line-18" className="last-word-m-1" y1="55.501" transform="translate(257.586 427.973)"></line>
                      <line id="line-19-3" data-name="line-19" className="last-word-m-1" y1="55.501" transform="translate(191.342 467.361)"></line>
                      <line id="line-20-2" data-name="line-20" className="last-word-m-1" y1="40.104" x2="66.601" transform="translate(190.985 427.973)"></line>
                      <line id="line-21-2" data-name="line-21" className="last-word-m-1" x1="67.675" y1="39.388" transform="translate(256.87 427.973)"></line>
                      <path id="path-63-2" data-name="path-63" className="last-word-m-2" d="M169.5,12408.076v-55.306l66.081-39.2,66.7,39.2v55.306l-66.7-39.306Z" transform="translate(21.842 -11885.214)"></path>
                      <path id="path-12" data-name="path" className="last-word-m-3" d="M293.093,157.6,268.9,171.582l42.338,24.442,24.193-13.982Z" transform="translate(-41.677 318.288)"></path>
                      <line id="line-7" data-name="line" className="last-word-m-2" y2="24.62" transform="translate(269.561 514.312)"></line>
                      <path id="line-8" data-name="line" className="last-word-m-2" d="M0,0V25.118" transform="translate(294.056 500.33)"></path>
                      <path id="path-13" data-name="path" className="last-word-m-4" d="M338.1,217.1" transform="translate(-86.257 279.957)"></path>
                      <path id="path-14" data-name="path" className="last-word-m-2" d="M334.07,256.162s.036-9.153,0-10.86a3.8,3.8,0,0,0-1.494-2.74,1.551,1.551,0,0,0-.249-.213c-1.032-.712-10.851-6.048-10.851-6.048l-.364,14.182,8.8-5.31,2.577,1.206-2.7,6.027.58,7.376s-.213,1.814,1.459,1.814l7.233-4.077S339.763,253.6,334.07,256.162Z" transform="translate(-75.253 267.588)"></path>
                      <line id="line-9" data-name="line" className="last-word-m-2" y2="13.947" transform="translate(245.19 525.128)"></line>
                      <line id="line-10" data-name="line" className="last-word-m-2" x1="0.036" y2="14.018" transform="translate(227.045 514.597)"></line>
                      <line id="line-11" data-name="line" className="last-word-m-2" y2="13.804" transform="translate(257.322 518.262)"></line>
                      <path id="path-15" data-name="path" className="last-word-m-5" d="M400.27,267.8l13.419,7.461,8.78-5.069V260.057L409.3,252.78v10.054Z" transform="translate(-150.287 223.086)"></path>
                      <line id="line-12" data-name="line" className="last-word-m-4" x2="13.171" y2="7.363" transform="translate(259.011 485.919)"></line>
                      <path id="path-16" data-name="path" className="last-word-m-6" d="M417.27,300.023l4.39-2.453,10.446,5.849-4.326,2.477Z" transform="translate(-162.897 189.863)"></path>
                      <path id="path-17" data-name="path" className="last-word-m-2" d="M291.011,172.811c3.593-.285,4.724-3.273,4.724-6.938,0-3.842-1.45-6.973-5.293-6.973a6.97,6.97,0,0,0-4.839,11.99,4.507,4.507,0,0,0,.463.391,5.9,5.9,0,0,1-1,.178,4.282,4.282,0,0,0-4.269,4.269v20.707a4.282,4.282,0,0,0,4.269,4.269h9.073c2.348,0,2.846-1.921,2.846-4.269v-9.179c1.957.783,4.376-.5,4.376-.5l7.721-4.661a1.7,1.7,0,0,0,1-.356l1.6-1.21c.783-.6,1.388-1.565.818-2.384-.6-.783-1.352-.64-2.135-.036l-2.455.747a2.117,2.117,0,0,0-.6.783h-.036l-6.618,3.238c-2.313.961-3.309-.676-3.736-2.135a8.678,8.678,0,0,0-.32-1.921h0a4.977,4.977,0,0,0-2.491-3.38l-2.277-1.494C291.865,173.879,290.442,173.06,291.011,172.811Z" transform="translate(-49.343 317.45)"></path>
                      <path id="path-18" data-name="path" className="last-word-m-3" d="M319.5,276.345l12.1-6.8V265.6l-11.883,6.973" transform="translate(-74.275 248.712)"></path>
                      <path id="path-19" data-name="path" className="last-word-m-3" d="M268.5,221.756V197.1l4.234,2.384,14.445,8.219-.534,24.513Z" transform="translate(-41.419 292.841)"></path>
                      <path id="path-20" data-name="path" className="last-word-m-7" d="M325.122,249s.346,2.232,2.338,1.805-1.769,5.453-1.769,5.453l-1.13-1.725Z" transform="translate(-77.991 259.406)"></path>
                      <path id="path-44-2" data-name="path-44" className="last-word-m-8" d="M54.089,95.182l-.358,5.635,8.978-5.331,2.749,1.32v4.525l-2.749,1.246.6,6.122,3.639-2.036V95.487l-.629-1.9-6.309-4.014L55.8,90.807" transform="translate(191.848 417.211)"></path>
                      <path id="path-38-2" data-name="path-38" className="last-word-m-8" d="M530.162,8198.88c3.212-1.426,6.028-2.285,4.836-2.932s.457-3.845.457-3.845,3.618-.048,2.322-1.335,2.1-2.524,2.1-2.524a8.881,8.881,0,0,0-5.537-4.61c-4.142-1.234-5.536,2.328-7.787,2.961s-1.04,7.332-1.04,7.332S526.95,8200.306,530.162,8198.88Z" transform="translate(-291.854 -7710.226)"></path>
                      <path id="path-39-2" data-name="path-39" className="last-word-m-9" d="M534.259,8236.49a6.219,6.219,0,0,0,5.824,2.094" transform="translate(-298.193 -7747.629)"></path>
                      <path id="path-43-2" data-name="path-43" className="last-word-m-8" d="M572.594,8288.334s-.038,4.858.316,5.1,4.217-2.217,4.894-2.162S572.594,8288.334,572.594,8288.334Z" transform="translate(-324.97 -7783.542)"></path>
                      <path id="path-45-2" data-name="path-45" className="last-word-m-9" d="M151.912,8102.924a1.6,1.6,0,0,0-.689,2.239" transform="translate(92.98 -7620.335)"></path>
                      <g id="ellipse-9" className="last-word-m-2" transform="translate(75.964 444.052)">
                        <ellipse className="last-word-m-12" cx="12.174" cy="12.174" rx="12.174" ry="12.174"></ellipse>
                        <ellipse className="last-word-m-13" cx="12.174" cy="12.174" rx="11.674" ry="11.674"></ellipse>
                      </g>
                      <g id="ellipse-9-2" data-name="ellipse-9" className="last-word-m-2" transform="translate(98.164 466.252)">
                        <ellipse className="last-word-m-12" cx="2.865" cy="2.865" rx="2.865" ry="2.865"></ellipse>
                        <ellipse className="last-word-m-13" cx="2.865" cy="2.865" rx="2.365" ry="2.365"></ellipse>
                      </g>
                      <g id="ellipse-10" className="last-word-m-10" transform="translate(84.916 454.078)">
                        <ellipse className="last-word-m-12" cx="0.806" cy="0.806" rx="0.806" ry="0.806"></ellipse>
                        <ellipse className="last-word-m-13" cx="0.806" cy="0.806" rx="0.306" ry="0.306"></ellipse>
                      </g>
                      <g id="ellipse-10-2" data-name="ellipse-10" className="last-word-m-10" transform="translate(89.75 454.078)">
                        <ellipse className="last-word-m-12" cx="0.806" cy="0.806" rx="0.806" ry="0.806"></ellipse>
                        <ellipse className="last-word-m-13" cx="0.806" cy="0.806" rx="0.306" ry="0.306"></ellipse>
                      </g>
                      <path id="path-64" className="last-word-m-9" d="M245.93,12355s2.521,2.748,4.879,0" transform="translate(-160.323 -11896.983)"></path>
                      <path id="path-66" className="last-word-m-9" d="M387.091,12378.292v-29.43l48.361,28.017v29.926Z" transform="translate(-253.983 -11903.685)"></path>
                      <path id="path-66-2" data-name="path-66" className="last-word-m-9" d="M387.091,12356.019v-7.156l11.759,6.812v7.276Z" transform="translate(-248.816 -11894.031)"></path>
                      <path id="path-66-3" data-name="path-66" className="last-word-m-9" d="M387.091,12356.019v-7.156l11.759,6.812v7.276Z" transform="translate(-233.921 -11885.229)"></path>
                      <line id="line-25" className="last-word-m-9" x2="32.721" y2="19.374" transform="translate(137.59 468.273)"></line>
                      <line id="line-25-2" data-name="line-25" className="last-word-m-9" x2="32.721" y2="19.374" transform="translate(137.59 471.286)"></line>
                      <path id="ellipse-17" className="last-word-m-11" d="M6.549,0c3.742.069,6.878,4.714,7.005,10.373S10.751,20.565,7.009,20.5.131,15.782,0,10.122,2.807-.069,6.549,0Z" transform="matrix(0.995, -0.105, 0.105, 0.995, 172.147, 469.983)"></path>
                      <path id="ellipse-17-2" data-name="ellipse-17" className="last-word-m-2" d="M4.6,0C7.238.049,9.467,3.942,9.573,8.694s-1.949,8.565-4.591,8.516S.111,13.268,0,8.516,1.953-.049,4.6,0Z" transform="matrix(0.995, -0.105, 0.105, 0.995, 173.634, 471.409)"></path>
                      <path id="path-71" className="last-word-m-9" d="M655.813,12648.4v4.554l2.827,1.369" transform="translate(-476.996 -12172.854)"></path>
                      <path id="path-21" data-name="path" className="last-word-m-7" d="M1.282,6.431A1.193,1.193,0,0,1,.9,5.975l.769.442a.322.322,0,0,1-.385.014M.9,5.533C.883,5.519.855,5.5.84,5.476c-.071-.071-.1-.171-.057-.228A.074.074,0,0,1,.9,5.234l.057.057a.244.244,0,0,1,.057.228A.086.086,0,0,1,.9,5.533m.755.442c-.014-.014-.043-.028-.057-.057a.244.244,0,0,1-.057-.228.074.074,0,0,1,.114-.014c.014.014.043.028.057.057a.244.244,0,0,1,.057.228.083.083,0,0,1-.114.014M1.268,4.65a.668.668,0,0,0-.313-.128c-.527-.1-.812.385-.641,1.1A2.5,2.5,0,0,0,.7,6.431c-.442-.071-.755.214-.8.741L2.607,8.739a2.724,2.724,0,0,0-.784-1.653c.37-.071.541-.513.4-1.1A2.156,2.156,0,0,0,1.268,4.65" transform="translate(127.505 477.069)"></path>
                      <line id="line-28" className="last-word-m-9" y2="9.418" transform="translate(131.144 479.897)"></line>
                      <path id="path-22" data-name="path" d="M3.182,4m2.706.742L3.182,3.182C2.975,3.062,2.8,3.16,2.8,3.4a.833.833,0,0,0,.371.655l2.706,1.56c.207.12.382.022.382-.218a.8.8,0,0,0-.371-.655" transform="translate(129.42 479.611)"></path>
                      <path id="path-23" data-name="path" className="last-word-m-7" d="M21.944,24.577l-2.706-1.56c-.185-.109-.338-.022-.338.2a.719.719,0,0,0,.338.578l2.706,1.56c.185.109.338.022.338-.2a.735.735,0,0,0-.338-.578" transform="translate(115.077 462.339)"></path>
                      <path id="path-24" data-name="path" className="last-word-m-7" d="M3.444,23.777.738,22.216c-.185-.109-.338-.022-.338.2a.719.719,0,0,0,.338.578l2.706,1.56c.185.109.338.022.338-.2a.753.753,0,0,0-.338-.578" transform="translate(131.558 463.654)"></path>
                      <path id="path-25" data-name="path" className="last-word-m-7" d="M23.644,45.277l-2.706-1.56c-.185-.109-.338-.022-.338.2a.755.755,0,0,0,.338.589l2.706,1.56c.185.109.338.022.338-.2a.755.755,0,0,0-.338-.589" transform="translate(113.562 444.701)"></path>
                      <path id="path-84" className="last-word-m-8" d="M0,0,1.227,13.767,6.733,15.3l5.521-1.532L13.485,0ZM10.927,3.268l-.078.855-.033.379H4.354L4.51,6.231h6.153l-.041.454-.4,4.443-.026.286-3.454.959h0l-.007,0-3.458-.959L3.041,8.767h1.7l.119,1.346,1.881.509h0l1.881-.509.2-2.19H2.963L2.55,3.268,2.51,2.814h8.458Z" transform="translate(105.945 451.697)"></path>
                    </g>
                  </svg>
    )
}
export default LastwordMobileSvg