import React,{useState,useEffect} from 'react'
import Unrevealtable from '../assets/images/unreveal-table'
import KeepReading from '../assets/images/keep-reading' 
const HomeSection7 =({fullpageApi}) =>{
    return(
             <div 
             className={`section section7`} 
             id="section7"
             style={{backgroundColor:'rgb(79, 216, 153)'}}>

            <div className="left-col-box">
                <div className="unravel-table">
                    <Unrevealtable/>
                </div>
            </div>
                <div className="right-col-box">
                <div className="text-table en-text">
                    <h2 className="hd-std">Unravel</h2>
                    {/* <div className="text-horizontal-box">
                    <div className="text-horizontal"> */}
                    <p><span className="text1">We’ll make it clear by speaking about a hot topic in Belgium that we all know ‐ they call it</span> <span className="border_underline white_br line_un_1">“the new way of work”</span>. <span className="text2">Sounds innovative, but it’s not new at all. For Belgium it is still quite new yes.</span></p>
                    <p><span className="text3">The most</span> <span className="border_underline white_br line_un_2">innovative</span> <span className="text4">and growing companies are promoting and incorporating this “new way of work” phenomenon</span>.</p>
                    <p><span className="text5">Basically it means that we no longer measure the outcome by the performed hours of checking in and out of the office building but we</span> <span className="border_underline white_br line_un_3">measure results</span>. <span className="text6">The</span> <span className="border_underline white_br line_un_4">location</span> <span className="text7">where the results are made is</span> <span className="border_underline white_br line_un_5">not important</span> <span className="text8">anymore</span>.</p>
                    <p className="text9">Summarized ‐ this phenomenon promotes working from home. Why? Because it simply works for everyone , it is modern,  result driven and companies are saving up on expensive office buildings too.</p>
                    <p className="text10">Does it work ‐ yes absolutely. But of course it all depends on the job that needs to be performed. We are talking about ICT developers and this is actually the best job in the whole world that fits in with this “new way of work” model.</p>
                {/* </div>
                </div> */}
                </div>
                </div>
                <a to="#" className="get-start-btn-3 en-text"  onClick={() => fullpageApi.moveSectionDown()}>
                 {/* <KeepReading/> */}
                 <span>Keep reading</span>
                </a>
            </div>
    )
}
export default HomeSection7;