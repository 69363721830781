import React from 'react'
import './style.scss'

const Keep_reading_arrow_nowaday =()=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="654.736 12658.788 167.434 228.144">        
        <g transform="translate(0 11892)">
          <path className="now_a_day_arrow" d="M658.724,12651.011s23.812,212.234,160.458,212.234" transform="translate(-2 -11884)"></path>
          <path className="now_a_day_arrow" d="M860.5,12718.676l13.894,13.895,13.894-13.895" transform="translate(-11913.229 1854.017) rotate(-90)"></path>
        </g>
      </svg>
    )
}
export default Keep_reading_arrow_nowaday;