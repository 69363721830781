import React,{useState,useEffect} from 'react'
import LeftImageSection6 from '../assets/images/left-image-section6/left-image-section6'
import KeepReading from '../assets/images/keep-reading' 
import StoryMobileSvg from '../assets/images/story_mobile_svg'
const HomeSection6 =({fullpageApi}) =>{ 
    return(
            <div className={`section section6`} id="section6">

                <div className="left-col-box">
                    <div className="story-table hidden-m">
                        <LeftImageSection6/>
                    </div>
                    <div className="story-table visible-m">
                        <StoryMobileSvg/>
                    </div>
               </div>
               <div className="right-col-box">
            <div className="text-table en-text">
                     <h2 className="hdst2">Story</h2>
                     {/* <div className="text-horizontal-box">
                    <div className="text-horizontal"> */}
                    <p><span className="text1">We</span> <span className="border_underline black_br line_un_1">started</span> <span className="text2">our  Belgium ICT business in</span> <span className="border_underline black_br line_un_2">2011</span>. <span className="text3">We were looking for</span> <span className="border_underline black_br line_un_3">a senior developer</span> <span className="text4">for 3 months in the region of</span> <span className="border_underline black_br line_un_4">Ghent</span>. <span className="text5">We just couldn’t find the perfect developer. We had prepared</span> <span className="border_underline black_br line_un_5">a test</span> <span className="text6">to measure the skills of the candidates. They had to make a simple CRUD along with some recommended technology choices, provided with CSS files and a DB</span>. <span className="border_underline red_br line_un_6">The best developer that we found was a</span> <span className="red_marked"><span className="text7">6/10</span></span> -&gt; <span className="text8">do you want to start your company with a 6/10 team? I don’t think so! We were looking for the 10/10 team for our company.</span></p>
                    <p className="pgh-4ww"><span className="text9">We found our answer in</span> <span className="green_marked"><span className="text10">Poland</span></span>. <span className="text11">On the same test we found developers who scored a</span> <span className="green_marked"><span className="text12">9/10!</span></span> <span className="text13">Lets Face it, we can never give a developer 10/10.. That’s how our story started in Poland.</span></p>
                    <p><span className="text14">Since 2011 our founder Peter Jouret comes every month to</span> <span className="border_underline black_br line_un_7">Poland</span>. <span className="text15">We established our</span> <span className="border_underline black_br line_un_8">team</span> <span className="text16">there to work on our own products.</span></p>
                    <p><span className="text17">During all these years a lot of people in our network and business friends came to us telling that they face the same problem as we had in 2011 -&gt; They can’t find a good developer and if by chance they find one they are way too expensive!</span></p>
                    <p><span className="text18">Therefore Peter Jouret started his</span> <span className="border_underline black_br line_un_9">nearshoring business</span> <span className="text19">to</span> <span className="green_marked"><span className="text20">help out</span></span> <span className="text21">our business friends and other ICT</span> <span className="green_marked"><span className="text22">companies in Belgium</span></span>.</p>
                {/* </div>
                </div> */}
                </div>
                </div>
                <a to="#" className="get-start-btn-2 en-text"  onClick={() => fullpageApi.moveSectionDown()}>
                 {/* <KeepReading/> */}
                 <span>Keep reading</span>
                </a>
                </div>
            
    )
}
export default HomeSection6;