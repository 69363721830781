import React,{useState,useEffect} from 'react'
import {Link} from 'gatsby'
import Layout from '../layouts'
import HomeSection2 from '../components/home/section2'
import HomeSection3 from '../components/home/section3'
import HomeSection4 from '../components/home/section4'
import HomeSection5 from '../components/home/section5'
import HomeSection6 from '../components/home/section6'
import HomeSection7 from '../components/home/section7'
import HomeSection8 from '../components/home/section8'
import HomeSection9 from '../components/home/section9'
import HomeSection10 from '../components/home/section10'
import ReactFullpage from '@fullpage/react-fullpage';
import NavMenu from '../components/home/navmenu'
import { FixedLoader } from '../components/loader'
import Logo from '../components/assets/images/logo'
import Helmet from 'react-helmet'
import MenuHomeSvg from '../components/assets/images/menu-home'
import MessageSvgImage from '../components/assets/images/message'

const HomePageSlide = () =>{
    const[mobileview,setMobileview]=useState(true)
    const[removesl2,setRemovesl2]=useState(false)
    const [pageloaded, setPageloaded]=useState(true)
    useEffect(()=>{
      if(window !== undefined){
       if(window.screen.width < 1024){
        setMobileview(false)
       }
       if(window.screen.width < 769){
        setRemovesl2(true)
      }
      }
    },[pageloaded,mobileview,removesl2])
  
    return(
      <React.Fragment>
        <FixedLoader showloader={pageloaded}/>
        <Layout pageloaded={pageloaded} page={'home-one-page'}>     
          {!removesl2
          ?
          <Fullpage setPageloaded={setPageloaded} mobileview={mobileview}/>
        :
          <Fullpage2 setPageloaded={setPageloaded} mobileview={mobileview}/>}
        </Layout>
        </React.Fragment>
    )
}
export default HomePageSlide;



const Fullpage2 = ({bulbClick,setPageloaded,mobileview}) => {
  const [title, setTitle] = useState(false);
  const [activeSection, setActiveSection] = useState('');
  useEffect(()=>{
    if(window !== undefined){
      if(`${window.location.origin}/story` === window.location.href){
        setTitle(false)
      }else{
        setTitle(true)
      }
    }
  },[activeSection])
  return(
    <React.Fragment>
       {title ? <Helmet defer={false}>
        <title>{`${activeSection.charAt(0).toUpperCase()}${activeSection.slice(1)} | JDSolutions `}</title>
        <meta property="og:title" content={`${activeSection.charAt(0).toUpperCase()}${activeSection.slice(1)} | JDSolutions `} />
        <meta property="og:type" content="page" />
        <meta property="og:image" content={Logo} /> 
      </Helmet> : null}
    <NavMenu>
    <li><Link activeClassName="active" className={`home`}  to="/"><MenuHomeSvg/></Link>
    <div className="fp-tooltip right">home</div>
    </li>
    <li><a className={`${activeSection ==='problem' ? 'active':''}`} href="#problem" ><span/></a>
    <div className="fp-tooltip right">problem</div>
    </li>
    <li><a className={`${activeSection ==='answer' ? 'active':''}`}  href="#answer"><span/></a>
    <div className="fp-tooltip right">answer</div>
    </li>
    <li><a className={`${activeSection ==='relief' ? 'active':''}`}  href="#relief"><span/></a>
    <div className="fp-tooltip right">relief</div>
    </li>
    <li><a className={`${activeSection ==='story' ? 'active':''}`}  href="#story"><span/></a>
    <div className="fp-tooltip right">story</div>
    </li>
    <li><a className={`${activeSection ==='nowadays' ? 'active':''}`}  href="#nowadays" ><span/></a>
    <div className="fp-tooltip right">nowadays</div>
    </li>
    <li><a className={`${activeSection ==='unravel' ? 'active':''}`}  href="#unravel"><span/></a>
    <div className="fp-tooltip right">unravel</div>
    </li>
    <li><a className={`${activeSection ==='lastwords' ? 'active':''}`}  href="#lastwords" ><span/></a>
    <div className="fp-tooltip right">lastwords</div>
    </li>
    <li><a className={`${activeSection ==='contact' ? 'active':''}`}  href="#contact" className="contacts"><MessageSvgImage/></a>
    <div className="fp-tooltip right">contact</div>
    </li>
    </NavMenu>
  <ReactFullpage
    //fullpage options
    scrollingSpeed = {700} /* Options here */
    anchors={['problem',
    'answer',
    'relief',
    'story',
    'nowadays',
    'unravel',
    'lastwords',
    'contact']}
    easing= 'easeInOutCubic'
    
    render={({fullpageApi}) => {
      if(fullpageApi){
        setActiveSection(fullpageApi.getActiveSection().anchor)
        setTimeout(() => {
          setPageloaded(false)
        }, 700);
        if(!mobileview){
          fullpageApi.setResponsive(true)
      }
       
       }
       
      return (
      <ReactFullpage.Wrapper>
        <HomeSection3 fullpageApi={fullpageApi}/>
        <HomeSection4 fullpageApi={fullpageApi}/>
        <HomeSection5 fullpageApi={fullpageApi}/>
        <HomeSection6 fullpageApi={fullpageApi}/>
        <HomeSection10 fullpageApi={fullpageApi}/>
        <HomeSection7 fullpageApi={fullpageApi}/>
        <HomeSection8 fullpageApi={fullpageApi}/>
        <HomeSection9 activeSection={activeSection}/>
      </ReactFullpage.Wrapper>
      
      );
    }}
  />
    </React.Fragment>
  )
}





const Fullpage = ({bulbClick,setPageloaded,mobileview}) => {
  const [title, setTitle] = useState(false);
  const [activeSection, setActiveSection] = useState('');
  useEffect(()=>{
    if(window !== undefined){
      if(`${window.location.origin}/story` === window.location.href){
        setTitle(false)
      }else{
        setTitle(true)
      }
    
    }
  },[activeSection])
  return(
    <React.Fragment>
       {title ? <Helmet defer={false}>
        <title>{`${activeSection.charAt(0).toUpperCase()}${activeSection.slice(1)} | JDSolutions `}</title>
      </Helmet> : null}
    <NavMenu>
    <li><Link activeClassName="active" className={`home`}  to="/"><MenuHomeSvg/></Link>
    <div className="fp-tooltip right">home</div>
    </li>
    <li><a className={`${activeSection ==='introduction' ? 'active':''}`} href="#introduction"><span/></a>
    <div className="fp-tooltip right">introduction</div>
    </li>
    <li><a className={`${activeSection ==='problem' ? 'active':''}`} href="#problem" ><span/></a>
    <div className="fp-tooltip right">problem</div>
    </li>
    <li><a className={`${activeSection ==='answer' ? 'active':''}`}  href="#answer"><span/></a>
    <div className="fp-tooltip right">answer</div>
    </li>
    <li><a className={`${activeSection ==='relief' ? 'active':''}`}  href="#relief"><span/></a>
    <div className="fp-tooltip right">relief</div>
    </li>
    <li><a className={`${activeSection ==='story' ? 'active':''}`}  href="#story"><span/></a>
    <div className="fp-tooltip right">story</div>
    </li>
    <li><a className={`${activeSection ==='nowadays' ? 'active':''}`}  href="#nowadays" ><span/></a>
    <div className="fp-tooltip right">nowadays</div>
    </li>
    <li><a className={`${activeSection ==='unravel' ? 'active':''}`}  href="#unravel"><span/></a>
    <div className="fp-tooltip right">unravel</div>
    </li>
    <li><a className={`${activeSection ==='lastwords' ? 'active':''}`}  href="#lastwords" ><span/></a>
    <div className="fp-tooltip right">lastwords</div>
    </li>
    <li><a className={`${activeSection ==='contact' ? 'active':''}`}  href="#contact" className="contacts"><MessageSvgImage/></a>
    <div className="fp-tooltip right">contact</div>
    </li>
    </NavMenu>
  <ReactFullpage
    //fullpage options
    scrollingSpeed = {700} /* Options here */
    anchors={['introduction', 
    'problem',
    'answer',
    'relief',
    'story',
    'nowadays',
    'unravel',
    'lastwords',
    'contact']}
    easing= 'easeInOutCubic'
    
    render={({fullpageApi}) => {
      if(fullpageApi){
        setActiveSection(fullpageApi.getActiveSection().anchor)
        setTimeout(() => {
          setPageloaded(false)
        }, 700);
        if(!mobileview){
          fullpageApi.setResponsive(true)
      }
       
       }
       
      return (
      <ReactFullpage.Wrapper>
        <HomeSection2 fullpageApi={fullpageApi}/>
        <HomeSection3 fullpageApi={fullpageApi}/>
        <HomeSection4 fullpageApi={fullpageApi}/>
        <HomeSection5 fullpageApi={fullpageApi}/>
        <HomeSection6 fullpageApi={fullpageApi}/>
        <HomeSection10 fullpageApi={fullpageApi}/>
        <HomeSection7 fullpageApi={fullpageApi}/>
        <HomeSection8 fullpageApi={fullpageApi}/>
        <HomeSection9 activeSection={activeSection}/>
      </ReactFullpage.Wrapper>
      
      );
    }}
  />
    </React.Fragment>
  )
}