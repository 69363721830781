import React from 'react'
import './style.scss'
const LeftImage2 =()=>{
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" viewBox="2415 7030 169.27 438">
        <defs>
          <clipPath id="clip-path-Problem">
            <path id="path" className="man-icon-1" d="M-240.984,585.167l-.069-.249-2.687-7.271c5.54,3.227,9.28,8.393,12.008,14.238l14.515-7.188a77.05,77.05,0,0,0-34.848-32.216c-4.9,5.443-11.08,8.67-17.659,8.67-6.413,0-12.327-3.019-17.147-8.116-.18.124-.346.263-.554.388l-1.385.776h0c-11.662,6.288-23.172,15.651-30.471,27.507l12.549,12.3a29.445,29.445,0,0,1,11.163-12.839c-6.4,21.247-6.177,43.643-6.4,60.152,0,.1.319,6.4,1.011,13.518h70.637l-.776-11.371C-232.438,629.668-235.153,605.707-240.984,585.167Z" transform="translate(319.28 -552.48)"></path>
          </clipPath>
        </defs>
        <g id="group-50" transform="translate(2150 6732)">
          <g id="group-45" transform="translate(-33.9 -48.421)">
            <g id="group-28" transform="translate(339.727 424.143)">
              <g id="group-26" transform="translate(7)">
                <g id="group-24" transform="translate(0 13.068)">
                  <path id="path-2" data-name="path" className="man-icon-2" d="M-206.817,625.251c-3.8-23.758-24.154-43.618-44.282-51.824-1.554-2.175.226-4.464,1.582-5.735q.593-.466,1.172-.975h0A39.438,39.438,0,0,0-235.519,539.2a12.54,12.54,0,0,0,2.825-3.7c2.232-4.308,1.78-8.969-.989-10.4a4.324,4.324,0,0,0-3.348-.155c-4.139-14.944-16-25.75-30-25.75s-26,10.918-30.072,25.976a4.564,4.564,0,0,0-3.969-.071c-2.825,1.413-3.22,6.088-.989,10.4a12.1,12.1,0,0,0,3.574,4.238c.833,11.611,6.144,21.7,13.871,27.854h0l.3.226c1.2.961,4.944,4.464-.24,7.684l-1.412.791h0c-19.436,10.495-38.49,29.338-39.027,54.084-.424,18.828,3.5,54.664,13.136,69.961h0l.353.579a79.436,79.436,0,0,1,2.627,7.953h0a16.834,16.834,0,0,0,3.461,6.257c5.2,5.989,13.39,7.4,18.292,3.136s4.647-12.557-.551-18.546a15.367,15.367,0,0,0-11.908-5.65c-6.737-8.743-9.661-38.137-9.393-50.327.311-14,1.737-31.075,16.088-40.058-6.512,21.625-6.286,44.409-6.512,61.2,0,.141.678,13.461,2.2,23.448a9.39,9.39,0,0,0,1.116.551l2.26-10.2h55.356a28.215,28.215,0,0,1-2.4,2.076L-235,693.107c.48-.226,3.828-10.777,8.475-14.435l-.791-11.625c-1.187-14.125-3.941-38.434-9.888-59.325l-.071-.254-2.726-7.4c13.546,7.952,16.95,27.43,19.111,40.553,1.794,11.2,2.825,36.4-1.412,47.488a15.34,15.34,0,0,0-11.865,5.763c-5.113,6.073-5.226,14.379-.268,18.56s13.136,2.656,18.235-3.418a16.711,16.711,0,0,0,3.5-6.85h0a14.587,14.587,0,0,0,.353-2.246,42.934,42.934,0,0,1,2.048-7.712v-.085C-202.862,676.482-203.851,643.783-206.817,625.251Z" transform="translate(325.032 -499.2)"></path>
                  <path id="path-3" data-name="path" className="man-icon-3" d="M-301.48,733.74" transform="translate(334.747 -402.453)"></path>
                  <path id="path-4" data-name="path" className="man-icon-4" d="M-239.432,585.815l-.071-.254-2.74-7.416c5.65,3.291,9.464,8.56,12.246,14.521l14.8-7.331a78.577,78.577,0,0,0-35.538-32.855c-5,5.551-11.3,8.842-18.009,8.842-6.54,0-12.571-3.079-17.487-8.277-.184.127-.353.268-.565.4l-1.412.791h0c-11.893,6.413-23.631,15.961-31.075,28.052l12.8,12.543A30.03,30.03,0,0,1-295.1,581.733c-6.526,21.668-6.3,44.508-6.526,61.345,0,.1.325,6.526,1.031,13.786h72.037l-.791-11.6C-230.717,631.2-233.485,606.762-239.432,585.815Z" transform="translate(327.405 -477.222)"></path>
                  <path id="path-5" data-name="path" className="man-icon-5" d="M35.707,271.122H53.928L57.234,155.9h-.268l7.387,115.867,16.272,1.314,10.17-145.826L18.63,126.92Z" transform="translate(7.716 52.34)"></path>
                  <path id="path-6" data-name="path" className="man-icon-5" d="M-256.317,727.74h-17.656l-1.257,21.484s44.762,5.862,39.719-1.3S-256.317,727.74-256.317,727.74Z" transform="translate(345.575 -404.929)"></path>
                  <path id="path-7" data-name="path" className="man-icon-5" d="M-258.88,732.81s43.872,17.02,5.255,16.568" transform="translate(352.319 -402.837)"></path>
                  <rect id="rectangle" className="man-icon-3" width="2.655" height="12.204" rx="0.94" transform="translate(91.741 323.747) rotate(60)"></rect>
                  <rect id="rectangle-2" data-name="rectangle" className="man-icon-3" width="2.655" height="12.204" rx="0.94" transform="translate(96.008 326.593) rotate(60)"></rect>
                  <rect id="rectangle-3" data-name="rectangle" className="man-icon-3" width="2.655" height="12.204" rx="0.94" transform="translate(100.025 329.382) rotate(60)"></rect>
                  <path id="path-8" data-name="path" className="man-icon-5" d="M-287.9,728.2s-16.272,13.009-21.484,20.185,40.962,1.3,40.962,1.3l-1.3-21.484Z" transform="translate(331.319 -404.739)"></path>
                  <path id="path-9" data-name="path" className="man-icon-5" d="M-299.359,750.5c-38.6.452,5.254-16.569,5.254-16.569l2.613-2.26c1.158,4.929,10.015,6.37,3.9,6.314" transform="translate(329.066 -403.307)"></path>
                  <rect id="rectangle-4" data-name="rectangle" className="man-icon-3" width="2.655" height="12.204" rx="0.94" transform="translate(30.606 332.342) rotate(-60)"></rect>
                  <rect id="rectangle-5" data-name="rectangle" className="man-icon-3" width="2.655" height="12.204" rx="0.94" transform="translate(34.872 329.481) rotate(-60)"></rect>
                  <rect id="rectangle-6" data-name="rectangle" className="man-icon-3" width="2.655" height="12.204" rx="0.94" transform="translate(38.882 326.704) rotate(-60)"></rect>
                </g>
                  <g id="face" transform="translate(-365 -375)">
                    <path className="face" d="M425.523,6939.373s7.624,1.518,10.553-3.8" transform="translate(0 -6495)"></path>
                    <g className="think-2" transform="translate(405 414)">
                      <circle className="think-3" cx="4" cy="4" r="4"></circle>
                      <circle className="think-4" cx="4" cy="4" r="3.5"></circle>
                    </g>
                    <g className="think-2" transform="translate(430 414)">
                      <circle className="think-3" cx="4" cy="4" r="4"></circle>
                      <circle className="think-4" cx="4" cy="4" r="3.5"></circle>
                    </g>
                  </g>
                <path id="path-17" className="man-icon-5" d="M-304.743,528.8s-4.647-13.412,1.614-23.5c0,0,3.478,5.913,8.348,0s9.392-26.088,42.436-10.783c0,0-6.609,7.653-2.783,10.783,0,0,15.973,9.322,9.558,23.277,0,0-12.119-26.255-27.535-19.715S-282.357,499.78-304.743,528.8Z" transform="translate(332.682 -488.902)"></path>
              </g>
            </g>
            <g id="group-mask-4" className="man-icon-6" transform="translate(355.863 513.483)">
              <rect id="rectangle-21" className="man-icon-5" width="114.562" height="49.716" transform="translate(-11.699 -9.101)"></rect>
              <rect id="rectangle-21-2" data-name="rectangle-21" className="man-icon-7" width="114.562" height="49.716" transform="translate(-11.699 73.038)"></rect>
            </g>
          </g>
          <g id="group-problem" transform="translate(-25 -74)">
            <g id="ellipse-9" className="man-icon-2" transform="translate(290 372)">
              <circle className="man-icon-9" cx="34" cy="34" r="34"></circle>
              <circle className="man-icon-10" cx="34" cy="34" r="33"></circle>
            </g>
            <g id="ellipse-9-2" data-name="ellipse-9" className="man-icon-8" transform="translate(352 434)">
              <circle className="man-icon-9" cx="8" cy="8" r="8"></circle>
              <circle className="man-icon-10" cx="8" cy="8" r="7"></circle>
            </g>
            <g id="group-44" transform="translate(9 -2)">
              <circle id="ellipse-10" className="man-icon-5" cx="2.25" cy="2.25" r="2.25" transform="translate(306 406)"></circle>
              <circle id="ellipse-10-2" data-name="ellipse-10" className="man-icon-5" cx="2.25" cy="2.25" r="2.25" transform="translate(312.75 406)"></circle>
              <circle id="ellipse-10-3" data-name="ellipse-10" className="man-icon-5" cx="2.25" cy="2.25" r="2.25" transform="translate(319.5 406)"></circle>
            </g>
          </g>
        </g>
      </svg>
    )
}

export default LeftImage2;