import React from 'react'
import './style.scss'
const StoryMobileSvg=()=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="3060.934 1913.641 274.773 125.634">
        
        <g id="Group_355" data-name="Group 355" transform="translate(3006 1463)">
          <g id="Group_274" data-name="Group 274" transform="translate(94.055 465.734)">
            <g id="group-277">
              <path id="path-55" className="icons-1" d="M24.211,33.22H0V0L17.343.2,24.211,7.46V33.22Z" transform="translate(0 4.949)"></path>
              <path id="path-55-2" data-name="path-55" className="icons-1" d="M24.211,33.22H0V0L17.343.2,24.211,7.46V33.22Z" transform="translate(5.773)"></path>
              <g id="group" transform="translate(10.863 9.127)">
                <path id="path" className="icons-2" d="M2.1,1.2A8.03,8.03,0,0,1,.832,0L0,.147,2.79,5.971S4.307,5.384,4.38,4.5A5.807,5.807,0,0,0,2.1,1.2Z" transform="translate(6.925 10.694)"></path>
                <path id="path-2" data-name="path" className="icons-2" d="M2.227,2.2.685,0,0,1.713,1.958,3.4Z" transform="translate(0.147 5.286)"></path>
                <path id="path-3" data-name="path" className="icons-2" d="M0,3.72l.147.759L5.947,3.3,5.384,0Z" transform="translate(6.363 5.947)"></path>
                <path id="path-4" data-name="path" className="icons-2" d="M6.093,10.376,6,10.4l-.122.024-.2-.93L11.11,5.751l-.1-.122a6.23,6.23,0,0,0-1.468-1l.269.073,4.258-3.573L10.865,0,7.831,4.136c-.1,0-.171-.024-.269-.024L9.936,0H6.828L5.555,4.429H5.6c-.122.049-.245.1-.392.147L5.335,4.5,5.8.685,3.328,1.713,3.7,5.58a2.206,2.206,0,0,0-.22.245L3.5,5.8,2.692,2.349,1,4.038,2.594,7.146a4.739,4.739,0,0,0-.465,1.86L2.1,8.859.147,7.611,0,9.4l2.154.734.073.44-2.1-.661.489,1.444h1.86c.024.073.073.171.1.245l-1.591.22.759,1.1,1.273-.587a5.026,5.026,0,0,0,.832.93l.024.024L3.083,15.1s.905-.734,4.478,0l.294-1-.514-1Z" transform="translate(0 0)"></path>
              </g>
            </g>
            <path id="path-95" className="icons-1" d="M8.317,0A6.671,6.671,0,0,1,3.932,1.631,6.742,6.742,0,0,1,0,.326,7.422,7.422,0,0,1,1.059,4.4,9.479,9.479,0,0,1,.3,7.994,6.9,6.9,0,0,1,3.932,6.852,7.21,7.21,0,0,1,8.015,8.157,7.17,7.17,0,0,1,7.107,4.4,8.679,8.679,0,0,1,8.317,0Z" transform="matrix(0.966, -0.259, 0.259, 0.966, 1.843, 28.91)"></path>
          </g>
          <g id="Group_279" data-name="Group 279" transform="translate(168.367 451.107)">
            <g id="group-275" transform="translate(0 6.918)">
              <path id="path-55-3" data-name="path-55" className="icons-1" d="M24.765,33.981H0V0L17.74.206l7.025,7.424v26.35Z" transform="translate(0 4.124)"></path>
              <path id="path-55-4" data-name="path-55" className="icons-1" d="M24.765,33.981H0V0L17.74.206l7.025,7.424v26.35Z" transform="translate(4.536 0)"></path>
              <g id="group-17" transform="translate(23.496 29.53) rotate(180)">
                <path id="path-10" className="icons-3" d="M0,12.294a6.879,6.879,0,0,1,.38-2.262l-.015-.008.084-.18a6.86,6.86,0,0,1,.406-.876L4.161,1.837A2.569,2.569,0,0,1,6.381,0h.765A2.69,2.69,0,0,1,9.474,2.139l3.752,7.4-.017,0A6.892,6.892,0,1,1,0,12.294Z" transform="translate(0 4.165)"></path>
                <path id="rectangle-6" className="icons-4" d="M2.3,0a2.3,2.3,0,0,1,2.3,2.3v.766A2.3,2.3,0,0,1,0,3.063V2.3A2.3,2.3,0,0,1,2.3,0Z" transform="translate(4.594 0.89)"></path>
                <path id="rectangle-7" className="icons-4" d="M.766,0a.766.766,0,0,1,.766.766V2.932A.766.766,0,0,1,0,2.932V.766A.766.766,0,0,1,.766,0Z" transform="translate(6.125 0)"></path>
              </g>
            </g>
            <path id="path-95-2" data-name="path-95" className="icons-1" d="M8.317,0A6.671,6.671,0,0,1,3.932,1.631,6.742,6.742,0,0,1,0,.326,7.422,7.422,0,0,1,1.059,4.4,9.479,9.479,0,0,1,.3,7.994,6.9,6.9,0,0,1,3.932,6.852,7.21,7.21,0,0,1,8.015,8.157,7.17,7.17,0,0,1,7.107,4.4,8.679,8.679,0,0,1,8.317,0Z" transform="matrix(0.966, -0.259, 0.259, 0.966, 0.538, 2.153)"></path>
            <path id="path-95-3" data-name="path-95" className="icons-1" d="M5.184,0A4.158,4.158,0,0,1,2.45,1.017,4.2,4.2,0,0,1,0,.2,4.625,4.625,0,0,1,.66,2.745,5.908,5.908,0,0,1,.188,4.982,4.3,4.3,0,0,1,2.45,4.27,4.493,4.493,0,0,1,5,5.084,4.469,4.469,0,0,1,4.43,2.745,5.409,5.409,0,0,1,5.184,0Z" transform="matrix(0.966, -0.259, 0.259, 0.966, 25.282, 38.031)"></path>
          </g>
          <g id="Group_275" data-name="Group 275" transform="translate(55.434 529.921)">
            <path id="path-55-5" data-name="path-55" className="icons-1" d="M24.211,33.22H0V0L17.343.2,24.211,7.46V33.22Z" transform="translate(0)"></path>
            <g id="group-122" transform="translate(6.055 9.92)">
              <path id="path1948_2_" className="icons-2" d="M6.374,0A8.471,8.471,0,0,0,4.865.13c-1.332.236-1.58.731-1.58,1.639v1.2h3.16v.4H2.094a1.987,1.987,0,0,0-1.981,1.6,5.814,5.814,0,0,0,0,3.207c.224.932.766,1.6,1.674,1.6H2.872V8.348A2.022,2.022,0,0,1,4.853,6.379h3.16a1.585,1.585,0,0,0,1.58-1.6V1.769A1.753,1.753,0,0,0,8.013.13,9.506,9.506,0,0,0,6.374,0ZM4.664.967a.594.594,0,0,1,.59.6.6.6,0,0,1-.59.6.6.6,0,0,1,0-1.2Z" transform="translate(0.113)"></path>
              <path id="path1950_2_" className="icons-2" d="M35.409,28.7v1.4a2.029,2.029,0,0,1-1.981,2H30.28a1.616,1.616,0,0,0-1.58,1.6v3.007c0,.861.743,1.356,1.58,1.6a5.336,5.336,0,0,0,3.16,0c.8-.236,1.58-.7,1.58-1.6v-1.2H31.86v-.4H36.6c.92,0,1.262-.637,1.58-1.6a5.388,5.388,0,0,0,0-3.207c-.224-.92-.66-1.6-1.58-1.6Zm-1.769,7.617a.594.594,0,0,1,.59.6.6.6,0,0,1-.59.6.594.594,0,0,1-.59-.6A.586.586,0,0,1,33.641,36.317Z" transform="translate(-25.303 -25.316)"></path>
            </g>
            <path id="path-95-4" data-name="path-95" className="icons-1" d="M8.317,0A6.671,6.671,0,0,1,3.932,1.631,6.742,6.742,0,0,1,0,.326,7.422,7.422,0,0,1,1.059,4.4,9.479,9.479,0,0,1,.3,7.994,6.9,6.9,0,0,1,3.932,6.852,7.21,7.21,0,0,1,8.015,8.157,7.17,7.17,0,0,1,7.107,4.4,8.679,8.679,0,0,1,8.317,0Z" transform="matrix(0.966, -0.259, 0.259, 0.966, 18.75, 20.621)"></path>
          </g>
          <g id="Group_281" data-name="Group 281" transform="translate(229.259 492.641)">
            <g id="group-281" transform="translate(0.955 4.842)">
              <path id="path-55-6" data-name="path-55" className="icons-1" d="M19.753,27.1H0V0L14.149.165l5.6,5.921V27.1Z" transform="translate(0 6.322)"></path>
              <path id="path-55-7" data-name="path-55" className="icons-1" d="M19.753,27.1H0V0L14.149.165l5.6,5.921V27.1Z" transform="translate(4.064 0)"></path>
              <text id="_" data-name="</>" className="icons-5" transform="translate(6.144 14.879)"><tspan x="0" y="0">&lt;/&gt;</tspan></text>
            </g>
            <path id="path-95-5" data-name="path-95" className="icons-1" d="M8.317,0A6.671,6.671,0,0,1,3.932,1.631,6.742,6.742,0,0,1,0,.326,7.422,7.422,0,0,1,1.059,4.4,9.479,9.479,0,0,1,.3,7.994,6.9,6.9,0,0,1,3.932,6.852,7.21,7.21,0,0,1,8.015,8.157,7.17,7.17,0,0,1,7.107,4.4,8.679,8.679,0,0,1,8.317,0Z" transform="matrix(0.966, -0.259, 0.259, 0.966, 0, 2.153)"></path>
            <path id="path-95-6" data-name="path-95" className="icons-1" d="M5.184,0A4.158,4.158,0,0,1,2.45,1.017,4.2,4.2,0,0,1,0,.2,4.625,4.625,0,0,1,.66,2.745,5.908,5.908,0,0,1,.188,4.982,4.3,4.3,0,0,1,2.45,4.27,4.493,4.493,0,0,1,5,5.084,4.469,4.469,0,0,1,4.43,2.745,5.409,5.409,0,0,1,5.184,0Z" transform="matrix(0.966, -0.259, 0.259, 0.966, 21.857, 28.958)"></path>
          </g>
          <g id="Group_280" data-name="Group 280" transform="translate(297.747 457.275)">
            <g id="group-283" transform="translate(0 0)">
              <path id="path-55-8" data-name="path-55" className="icons-1" d="M24.211,33.22H0V0L17.343.2,24.211,7.46V33.22Z" transform="translate(0 0)"></path>
              <g id="group-120" transform="translate(5.437 10.028)">
                <path id="path-5" data-name="path" className="icons-2" d="M68.65,54.388h1.44L68.658,51.4,67.4,54.388Z" transform="translate(-61.822 -47.146)"></path>
                <path id="path-6" data-name="path" className="icons-2" d="M6.844,0,0,2.408l1.084,8.963,5.768,3.17,5.8-3.211,1.084-8.963Zm2.9,10.651L8.814,8.5H5.023l-.852,2.119-1.581.033L6.828,1.233h0l4.428,9.443Z"></path>
              </g>
            </g>
            <path id="path-95-7" data-name="path-95" className="icons-1" d="M5.184,0A4.158,4.158,0,0,1,2.45,1.017,4.2,4.2,0,0,1,0,.2,4.625,4.625,0,0,1,.66,2.745,5.908,5.908,0,0,1,.188,4.982,4.3,4.3,0,0,1,2.45,4.27,4.493,4.493,0,0,1,5,5.084,4.469,4.469,0,0,1,4.43,2.745,5.409,5.409,0,0,1,5.184,0Z" transform="matrix(0.966, -0.259, 0.259, 0.966, 19.575, 2.887)"></path>
          </g>
          <g id="Group_276" data-name="Group 276" transform="translate(154.065 521.021)">
            <g id="group-279" transform="translate(5.168 0)">
              <path id="path-55-9" data-name="path-55" className="icons-1" d="M24.211,33.22H0V0L17.343.2,24.211,7.46V33.22Z" transform="translate(4.949 4.949)"></path>
              <path id="path-55-10" data-name="path-55" className="icons-1" d="M24.211,33.22H0V0L17.343.2,24.211,7.46V33.22Z"></path>
              <path id="path-114" className="icons-2" d="M.772,6.052c.188-.835.334-1.565.5-2.316h8.849c.1-.48.188-.918.292-1.44H1.523C1.67,1.5,1.795.772,1.941,0h11.1c-.626,3.3-1.252,6.574-1.9,10.017-.292.563-1.169.355-1.69.793A28.245,28.245,0,0,0,5.989,12c-.25.083-.48.146-.793.23-1.69-.647-3.443-1.336-5.2-2.024.167-.856.313-1.628.48-2.5,0,0,.1-.1.23-.209H2.671V8.6c1,.4,1.962.772,2.922,1.148,1.106-.376,2.233-.751,3.4-1.127a7.4,7.4,0,0,0,.668-2.567Z" transform="translate(5.643 10.722)"></path>
            </g>
            <path id="path-95-8" data-name="path-95" className="icons-1" d="M8.317,0A6.671,6.671,0,0,1,3.932,1.631,6.742,6.742,0,0,1,0,.326,7.422,7.422,0,0,1,1.059,4.4,9.479,9.479,0,0,1,.3,7.994,6.9,6.9,0,0,1,3.932,6.852,7.21,7.21,0,0,1,8.015,8.157,7.17,7.17,0,0,1,7.107,4.4,8.679,8.679,0,0,1,8.317,0Z" transform="matrix(0.966, -0.259, 0.259, 0.966, 0, 22.682)"></path>
          </g>
          <g id="Group_277" data-name="Group 277" transform="translate(276.514 517.033)">
            <path id="path-55-11" data-name="path-55" className="icons-1" d="M22.922,31.451H0V0L16.42.191l6.5,6.871V31.451Z" transform="translate(21.844 8.36)"></path>
            <path id="path-55-12" data-name="path-55" className="icons-1" d="M22.922,31.451H0V0L16.42.191l6.5,6.871V31.451Z" transform="translate(27.205 4.236)"></path>
            <path id="path-95-9" data-name="path-95" className="icons-1" d="M5.184,0A4.158,4.158,0,0,1,2.45,1.017,4.2,4.2,0,0,1,0,.2,4.625,4.625,0,0,1,.66,2.745,5.908,5.908,0,0,1,.188,4.982,4.3,4.3,0,0,1,2.45,4.27,4.493,4.493,0,0,1,5,5.084,4.469,4.469,0,0,1,4.43,2.745,5.409,5.409,0,0,1,5.184,0Z" transform="matrix(0.966, -0.259, 0.259, 0.966, 46.163, 33.907)"></path>
            <path id="path-95-10" data-name="path-95" className="icons-1" d="M8.317,0A6.671,6.671,0,0,1,3.932,1.631,6.742,6.742,0,0,1,0,.326,7.422,7.422,0,0,1,1.059,4.4,9.479,9.479,0,0,1,.3,7.994,6.9,6.9,0,0,1,3.932,6.852,7.21,7.21,0,0,1,8.015,8.157,7.17,7.17,0,0,1,7.107,4.4,8.679,8.679,0,0,1,8.317,0Z" transform="matrix(0.966, -0.259, 0.259, 0.966, 23.069, 2.153)"></path>
            <g id="group-196" transform="translate(32.251 15.476)">
              <path id="path-7" data-name="path" className="icons-2" d="M0,8.57l1.458-.885c.283.5.536.922,1.153.922.588,0,.96-.231.96-1.123V1.4H5.355V7.507a2.432,2.432,0,0,1-2.67,2.7A2.78,2.78,0,0,1,0,8.57" transform="translate(0 -1.296)"></path>
              <path id="path-8" data-name="path" className="icons-2" d="M85.1,7.081l1.458-.841a1.956,1.956,0,0,0,1.763,1.086c.744,0,1.212-.372,1.212-.885,0-.61-.483-.833-1.3-1.19l-.446-.193c-1.294-.55-2.15-1.242-2.15-2.7A2.386,2.386,0,0,1,88.254,0,2.642,2.642,0,0,1,90.8,1.428l-1.391.893a1.216,1.216,0,0,0-1.153-.766.781.781,0,0,0-.855.766c0,.536.335.751,1.1,1.086l.446.193c1.517.655,2.38,1.317,2.38,2.812,0,1.614-1.264,2.492-2.968,2.492A3.413,3.413,0,0,1,85.1,7.081" transform="translate(-78.77)"></path>
            </g>
            <g id="group-159" transform="translate(8.71 59.242) rotate(180)">
              <g id="group-160" transform="translate(0 0)">
                <path id="path-93" className="icons-6" d="M0,0H8.71l-4.4,7.231Z" transform="translate(0 14.578)"></path>
                <line id="line-33" className="icons-7" y1="14.846" transform="translate(4.395)"></line>
              </g>
            </g>
          </g>
          <g id="Group_282" data-name="Group 282" transform="translate(259.776 481.688)">
            <g id="group-159-2" data-name="group-159" transform="translate(8.71 21.809) rotate(180)">
              <g id="group-160-2" data-name="group-160" transform="translate(0 0)">
                <path id="path-93-2" data-name="path-93" className="icons-6" d="M0,0H8.71l-4.4,7.231Z" transform="translate(0 14.578)"></path>
                <line id="line-33-2" data-name="line-33" className="icons-7" y1="14.846" transform="translate(4.395)"></line>
              </g>
            </g>
          </g>
          <g id="Group_284" data-name="Group 284" transform="translate(115.059 528.303)">
            <g id="group-159-3" data-name="group-159" transform="translate(8.71 21.809) rotate(180)">
              <g id="group-160-3" data-name="group-160" transform="translate(0 0)">
                <path id="path-93-3" data-name="path-93" className="icons-6" d="M0,0H8.71l-4.4,7.231Z" transform="translate(0 14.578)"></path>
                <line id="line-33-3" data-name="line-33" className="icons-7" y1="14.846" transform="translate(4.395)"></line>
              </g>
            </g>
          </g>
          <g id="Group_283" data-name="Group 283" transform="translate(62.161 469.496)">
            <g id="group-159-4" data-name="group-159" transform="translate(8.71 21.809) rotate(180)">
              <g id="group-160-4" data-name="group-160" transform="translate(0 0)">
                <path id="path-93-4" data-name="path-93" className="icons-6" d="M0,0H8.71l-4.4,7.231Z" transform="translate(0 14.578)"></path>
                <line id="line-33-4" data-name="line-33" className="icons-7" y1="14.846" transform="translate(4.395)"></line>
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
}
export default StoryMobileSvg